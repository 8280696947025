import {
    GET_SURVEY_YEAR_FOR_TEAM_ACTION,
    getSurveyYearForTeam,
} from 'actions/survey/getSurveyYearForTeam';
import { getSurveyYearForUser } from 'actions/survey/getSurveyYearForUser';
import { sendSurveyEmail } from 'actions/survey/sendSurveyEmail';
import { sendSurveyEmailsToTeam } from 'actions/survey/sendSurveyEmailsToTeam';
import { SurveyTypes } from 'api/generated/enums';
import Checkbox from 'components/Checkbox';
import ConfirmationModal from 'components/ConfirmationModal';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';

const getCheckboxLabel = (takenSurveyCount: number) => {
    if (takenSurveyCount === 1) {
        return 'Also send survey to 1 member who has already completed the survey';
    }

    return `Also send survey to ${takenSurveyCount} members who have already completed the survey`;
};

type ISendSurveyModalProps = {
    onHide: () => void;
    surveyUserId?: string;
};
const SendSurveyModal = ({ onHide, surveyUserId }: ISendSurveyModalProps) => {
    const dispatch = useThunkDispatch();
    const { team, teamId } = useTeamProps();
    const { userId, userProfile } = useUserProps();
    const {
        hasBothSurveyTypes,
        membersWithVerifiedInfoCount,
        needSurveyCount,
        showActivity,
        surveyYear,
        userProfiles,
    } = useSelector((state: AppStore) => ({
        hasBothSurveyTypes: state.userProfilesMetadata.hasBothSurveyTypes,
        membersWithVerifiedInfoCount: state.userProfilesMetadata.hasVerifiedInfoCount,
        needSurveyCount: state.userProfilesMetadata.needSurveyCount,
        showActivity: hasApiActivity(state, GET_SURVEY_YEAR_FOR_TEAM_ACTION),
        surveyYear: state.survey.surveyYear,
        userProfiles: state.pagedUserProfiles.results ?? [],
    }));
    const [sendToAllMembers, setSendToAllMembers] = useState(false);
    useEffect(() => {
        if (hasValue(surveyUserId)) {
            dispatch(getSurveyYearForUser(surveyUserId));
        } else {
            dispatch(getSurveyYearForTeam(teamId));
        }
    }, [dispatch, surveyUserId, teamId, userId]);
    const sendSurvey = useCallback(
        (profile) => {
            dispatch(sendSurveyEmail(profile));
            onHide();
        },
        [dispatch, onHide]
    );
    const sendSurveysToTeam = useCallback(
        (sendToAll) => {
            dispatch(sendSurveyEmailsToTeam(team, sendToAll));
            onHide();
        },
        [dispatch, onHide, team]
    );
    const buildSendModalConfirmationBody = useCallback(
        (takenSurveyCountInternal, needSurveyCountInternal, surveyMessage, yearMessage) => {
            let surveyTakenMessage = '';
            if (takenSurveyCountInternal > 0) {
                const isOne = takenSurveyCountInternal === 1;
                surveyTakenMessage = `${takenSurveyCountInternal} member${
                    isOne ? ' has' : 's have'
                } taken the survey.`;
            }

            let confirmationQuestion = `Are you sure you want to send the survey to all ${needSurveyCountInternal} remaining members?`;
            if (takenSurveyCountInternal < 1) {
                if (needSurveyCountInternal === 1) {
                    confirmationQuestion = 'Are you sure you want to send the survey to 1 member?';
                } else {
                    confirmationQuestion = `Are you sure you want to send the survey to all ${needSurveyCountInternal} members?`;
                }
            } else if (needSurveyCountInternal === 0) {
                confirmationQuestion = `Are you sure you want to send the survey to all ${membersWithVerifiedInfoCount} members?`;
            } else if (needSurveyCountInternal === 1) {
                confirmationQuestion =
                    'Are you sure you want to send the survey to 1 remaining member?';
            }

            return (
                <React.Fragment>
                    {surveyMessage}
                    <br />
                    {yearMessage}
                    <br />
                    {surveyTakenMessage}
                    <br />
                    <br />
                    {confirmationQuestion}
                </React.Fragment>
            );
        },
        [membersWithVerifiedInfoCount]
    );

    const handleIsMultipleSurveyText = (isMultipleSurveys: boolean, isPreText: boolean) => {
        if (isMultipleSurveys) {
            return isPreText
                ? 'All members are'
                : "members' profiles or through the bulk set action.";
        } else {
            return isPreText ? 'This member is' : "member's page.";
        }
    };

    const getIsIchra = useCallback(() => {
        let user;
        if (hasValue(surveyUserId)) {
            user =
                surveyUserId === userId
                    ? userProfile.user
                    : userProfiles.find((up) => up.user?.userId === surveyUserId)?.user;
        } else {
            user = userProfiles[0]?.user;
        }
        return user?.surveyTypeToSend === SurveyTypes.ICHRA;
    }, [surveyUserId, userId, userProfile.user, userProfiles]);

    const { body, yesClick } = useMemo(() => {
        let bodyInternal: JSX.Element | string = '';
        let yesClickInternal;

        const isMultipleSurveys = needSurveyCount > 1 && !hasValue(surveyUserId);
        const isIchra = getIsIchra();
        const surveyType = isIchra ? 'ICHRA' : 'Standard';
        const surveyMessageContent = hasBothSurveyTypes ? (
            <span>
                Some members are currently configured to receive the <strong>ICHRA</strong> version
                of the survey, and some are configured to receive the <strong>Standard</strong>{' '}
                version. To change this, please change the configuration on the members&apos;
                profiles or through the bulk set action.
            </span>
        ) : (
            <span>
                {handleIsMultipleSurveyText(isMultipleSurveys, true)} currently configured to
                receive the <strong>{surveyType}</strong> version of the survey. If you would like
                to change this, please change the configuration on the{' '}
                {handleIsMultipleSurveyText(isMultipleSurveys, false)}
            </span>
        );
        const surveyMessage = <div className="mb-2 text-left">{surveyMessageContent}</div>;
        const standardSurveyAddition = isIchra ? ' ' : ' and income ';
        const yearMessage = (
            <div className="mb-2 text-left">
                The survey will ask for household{standardSurveyAddition}information for{' '}
                <span className="font-weight-bold">{surveyYear}</span>. If you would like to change
                the year that the survey will use, please adjust the Survey Year on the Team Profile
                Page.
            </div>
        );
        if (isEmpty(surveyUserId)) {
            yesClickInternal = () => sendSurveysToTeam(sendToAllMembers);
            let showSendAllCheckbox = true;
            if (needSurveyCount === 0 || membersWithVerifiedInfoCount === 0) {
                setSendToAllMembers(true);
                showSendAllCheckbox = false;
            }

            const bodyText = buildSendModalConfirmationBody(
                membersWithVerifiedInfoCount,
                needSurveyCount,
                surveyMessage,
                yearMessage
            );

            bodyInternal = (
                <React.Fragment>
                    <div>{bodyText}</div>
                    {showSendAllCheckbox && (
                        <Row className="mt-3 justify-content-center">
                            <Checkbox
                                checked={sendToAllMembers}
                                data-cy="send-survey-to-all-checkbox"
                                label={getCheckboxLabel(membersWithVerifiedInfoCount)}
                                onClick={() => setSendToAllMembers(!sendToAllMembers)}
                            />
                        </Row>
                    )}
                </React.Fragment>
            );
        } else {
            const profile =
                surveyUserId === userId
                    ? userProfile
                    : userProfiles.find((up) => up.user?.userId === surveyUserId);
            bodyInternal = (
                <React.Fragment>
                    {surveyMessage}
                    {yearMessage}
                    <div className="text-left">
                        Are you sure you want to send the survey to{' '}
                        <strong>{profile?.user?.displayName}</strong>?
                    </div>
                </React.Fragment>
            );
            yesClickInternal = () => sendSurvey(profile);
        }
        return {
            body: bodyInternal,
            yesClick: yesClickInternal,
        };
    }, [
        buildSendModalConfirmationBody,
        getIsIchra,
        hasBothSurveyTypes,
        membersWithVerifiedInfoCount,
        needSurveyCount,
        sendSurvey,
        sendSurveysToTeam,
        sendToAllMembers,
        surveyUserId,
        surveyYear,
        userId,
        userProfile,
        userProfiles,
    ]);

    return (
        <ConfirmationModal
            body={body}
            onNoClick={onHide}
            onYesClick={yesClick}
            showActivity={showActivity}
            title="Confirm"
            yesButtonText="Send"
        />
    );
};

export default hot(module)(SendSurveyModal);
