import EditableAttribute, { IEditableAttributeProps } from 'components/EditableAttribute';
import EditableSelect, { IEditableSelectProps } from 'components/EditableSelect';
import React from 'react';
import { hot } from 'react-hot-loader';

export type IEditableSelectAttributeProps<T, Q> = IEditableSelectProps<T, Q> &
    Omit<IEditableAttributeProps, 'children'> & {
        subtext?: string;
    };

const EditableSelectAttribute = <T, Q>({
    emptyValueMessage,
    footer,
    formatter,
    hrClassName,
    infoTooltip,
    label,
    readonly,
    ...props
}: IEditableSelectAttributeProps<T, Q>) => (
    <EditableAttribute
        emptyValueMessage={emptyValueMessage}
        footer={footer}
        formatter={formatter}
        hrClassName={hrClassName}
        infoTooltip={infoTooltip}
        label={label}
        readonly={readonly}
        {...props}
    >
        {({ onBlur }) => (
            <React.Fragment>
                <EditableSelect {...props} onBlur={onBlur} />
            </React.Fragment>
        )}
    </EditableAttribute>
);

export default hot(module)(EditableSelectAttribute);
