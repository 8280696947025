import Stack from '@mui/material/Stack';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { sendInvites, SEND_INVITES_ACTION } from 'actions/user/sendInvites';
import { IInvite } from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import InviteMemberRow from 'components/teamMembersActionButtons/InviteMemberRow';
import trim from 'lodash/trim';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatErrors, IFormErrors, validate } from 'utilities/forms';
import { array, object, string, ValidationError } from 'yup';

const schema = object({
    emails: array().of(
        string()
            .trim()
            .email()
            .required()
            .label('Email Address')
    ),
});

type IInviteMembersModalProps = DispatchProps &
    StateProps & {
        handleClose: () => void;
        invitingAdmin: boolean;
        invitingAdvisor: boolean;
        peopleUrl: string;
        teamId: string;
    };

export type IInviteMembersModalErrors = IFormErrors<typeof schema>;

class InviteMembersModal extends React.Component<IInviteMembersModalProps> {
    override state = {
        errors: null as IInviteMembersModalErrors | null,
        invites: [
            {
                email: '',
                isAdvisor: this.props.invitingAdvisor,
                isTeamAdmin: this.props.invitingAdmin,
                row: 0,
                teamId: this.props.teamId,
            },
        ],
    };

    onChange = (row: number, e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            invites: this.state.invites.map((invite) => {
                if (invite.row !== row) {
                    return invite;
                }
                return { ...invite, email: trim(e.target.value) };
            }),
        });
    };

    handleSubmit = async () => {
        try {
            await validate(schema, { emails: this.state.invites.map((x) => x.email) });
            this.setState({ errors: null });
            await this.props.sendInvites(this.state.invites, this.props.peopleUrl);
        } catch (_errors) {
            this.setState({ errors: formatErrors(_errors as ValidationError) });
        }
    };

    handleAddAnotherClick(e: React.MouseEvent) {
        e.preventDefault();
        const nextIndex = this.state.invites.length;
        this.setState({
            errors: null,
            invites: [
                ...this.state.invites,
                {
                    email: '',
                    isAdvisor: this.props.invitingAdvisor,
                    row: nextIndex,
                    teamId: this.props.teamId,
                },
            ],
        });
    }

    handleDeleteClick(row: number) {
        const invites = this.state.invites.filter((invite) => invite.row !== row);
        invites.forEach((invite, index) => (invite.row = index));
        this.setState({
            invites,
            errors: null,
        });
    }

    renderRows() {
        const rows = [];
        for (let i = 0; i < this.state.invites.length; i++) {
            rows.push(
                <InviteMemberRow
                    data-cy={`invite-email-${i}`}
                    email={this.state.invites[i]?.email}
                    errors={this.state.errors}
                    handleDeleteClick={this.handleDeleteClick.bind(this)}
                    hideDeleteButton={this.props.invitingAdmin}
                    key={i}
                    onChange={this.onChange}
                    row={i}
                />
            );
        }
        return <Stack gap={2}>{rows}</Stack>;
    }

    override render() {
        const { handleClose, invitingAdmin, invitingAdvisor, showActivity } = this.props;
        return (
            <Modal onHide={handleClose} scrollable show>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Invite
                        {(invitingAdmin && ' Admin') ||
                            (invitingAdvisor && ' Advisors') ||
                            ' Members'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="invite-members-modal"
                        isLoading={showActivity}
                        onSubmit={this.handleSubmit}
                    >
                        {this.renderRows()}
                        {!invitingAdmin && (
                            <Row className="mx-0 my-1">
                                <a href="#" onClick={this.handleAddAnotherClick.bind(this)}>
                                    <i className="mdi mdi-plus-circle-outline" /> Add another
                                </a>
                            </Row>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="centered">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button form="invite-members-modal" isLoading={showActivity} type="submit">
                        {invitingAdmin ? 'Send Invitation' : 'Send Invitations'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state: AppStore) => ({
    apiError: !!state.apiErrors[SEND_INVITES_ACTION.statusCode],
    apiErrorMessage: state.apiErrors[SEND_INVITES_ACTION.errorMessage],
    showActivity: hasApiActivity(state, SEND_INVITES_ACTION),
});

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    sendInvites: async (invites: IInvite[], redirectUrl: string) =>
        dispatch(sendInvites(invites, redirectUrl)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(InviteMembersModal));
