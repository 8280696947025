import classnames from 'classnames';
import React, { ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

type IDropDownArrowIconProps = {
    iconClass: string | undefined;
    iconFontSize: number | undefined;
};

export type IDropdownItems = {
    action: React.MouseEventHandler;
    label: ReactNode;
};

type IDropDownMenuProps = {
    'data-cy'?: string;
    className?: string;
    iconClass?: string;
    iconFontSize?: number;
    items: IDropdownItems[];
    text: ReactNode;
};

class DropDownMenu extends React.PureComponent<IDropDownMenuProps> {
    static defaultProps = {
        iconClass: 'mdi-menu-down',
        items: [] as IDropdownItems[],
    };

    node: HTMLDivElement | null = null;
    override state = {
        expanded: false,
    };

    override componentDidMount() {
        document.addEventListener(
            'mousedown',
            (this.handleClick as unknown) as EventListener,
            false
        );
    }

    override componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            (this.handleClick as unknown) as EventListener,
            false
        );
    }

    handleClick = (e: React.MouseEvent) => {
        if (this.node?.contains((e.target as unknown) as Node)) {
            return;
        }

        this.setState({ expanded: false });
    };

    expandMenu = async () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleSelect = async () => {
        this.setState({ expanded: false });
    };

    override render() {
        const { className, text, items, iconFontSize, iconClass } = this.props;
        const rootClasses = classnames(
            className,
            'dropdown',
            this.state.expanded ? 'show' : '',
            'mx-1'
        );
        const dropMenuClasses = classnames('dropdown-menu', this.state.expanded ? 'show' : '');
        let dropDownArrow;
        const areItems = items?.length > 0;
        if (areItems) {
            dropDownArrow = (
                <div>
                    <DropDownArrowIcon iconClass={iconClass} iconFontSize={iconFontSize} />
                </div>
            );
        }
        return (
            <div className={rootClasses} ref={(node) => (this.node = node)}>
                <Row
                    className={
                        'align-items-center dropdown-toggle arrow-none ' + (areItems && 'clickable')
                    }
                    data-cy={this.props['data-cy']}
                    onClick={areItems ? this.expandMenu : undefined}
                >
                    {text}
                    {dropDownArrow}
                </Row>
                <div className={dropMenuClasses} onClick={this.handleSelect}>
                    {items.map((item, index) => (
                        <button
                            className="dropdown-item"
                            data-cy={`${this.props['data-cy']}-${index}`}
                            key={index}
                            onClick={item.action}
                        >
                            {item.label}
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}

const DropDownArrowIcon = styled.i.attrs(({ iconClass }: IDropDownArrowIconProps) => ({
    className: `mdi ${iconClass}`,
}))`
    font-size: ${({ iconFontSize }: IDropDownArrowIconProps) => `${iconFontSize}px`};
`;

export default DropDownMenu;
