import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const version = process.env['VERSION'];
const StyledVersion = styled.div`
    width: 100%;
    text-align: center;
    color: #cccccc;
    font-size: 11px;
`;

const Version = () => <StyledVersion>v{version}</StyledVersion>;

export default hot(module)(Version);
