import {
    bulkSendRenewalInvites,
    BULK_SEND_RENEWAL_INVITES_ACTION,
} from 'actions/team/bulkSendRenewalInvites';
import Button from 'components/Button';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type ISendRenewalInvitesModal = {
    onClose: () => void;
};

const SendRenewalInvitesModal = ({ onClose }: ISendRenewalInvitesModal) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, BULK_SEND_RENEWAL_INVITES_ACTION),
    }));

    const sendInvites = useCallback(async () => {
        await dispatch(bulkSendRenewalInvites(teamId));
        onClose();
    }, [dispatch, teamId, onClose]);

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Send Renewal Invites?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This will send renewal invites to all users that have a Member Status of Renewing or
                Waived and an Enrollment Status of either Ready for Renewal, Invited to Renew or
                Advisement Needed.
                <br />
                <br />
                Are you sure you want to send renewal invites?
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button isLoading={isLoading} onClick={sendInvites} type="submit">
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(SendRenewalInvitesModal);
