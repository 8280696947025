import Box from '@mui/material/Box';
import React from 'react';
import { hot } from 'react-hot-loader';

const UnauthenticatedPageHeader = () => (
    <Box
        borderRadius="4px 4px 0 0"
        sx={(theme) => ({ backgroundColor: theme.palette.primary.main, py: 4 })}
        textAlign="center"
    >
        <span>
            <img alt="" height="50" src="assets/images/logo.png" />
        </span>
    </Box>
);

export default hot(module)(UnauthenticatedPageHeader);
