import { Card, CardContent } from '@mui/material';
import ConfirmText, {
    IConfirmTextProps as IConfirmTextPropsOriginal,
} from 'components/ConfirmText';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import ThreeDotDropdownToggle from 'components/ThreeDotDropdownToggle';
import Tooltip from 'components/Tooltip';
import React, { useCallback, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const SharedIconWrapper = styled.span`
    height: 42px;
`;

type IConfirmTextProps = Omit<IConfirmTextPropsOriginal, 'children'>;

export type IThingAction = {
    isConfirm?: boolean;
    isLoading?: boolean;
    isVisible: boolean;
    label: React.ReactNode;
} & (DropdownItemProps | IConfirmTextProps);

export type IThingItems = IThingAction[];

type IThingCardProps = {
    bottomLeftContent: React.ReactNode;
    children: React.ReactNode;
    isArchived?: boolean;
    isShared?: boolean;
    isUnSharable?: boolean;
    items: IThingItems;
    onClick?: () => void;
    sharedTooltipText?: string;
    topLeftContent: React.ReactNode;
};
const ThingCard = ({
    bottomLeftContent,
    children,
    isArchived = false,
    isShared = false,
    isUnSharable = false,
    items,
    onClick,
    sharedTooltipText = '',
    topLeftContent,
}: IThingCardProps) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const closeDropdown = useCallback(() => setShowDropdown(false), []);
    const onToggle = useCallback((isOpen, event, metadata) => {
        event.stopPropagation?.();
        if (isOpen || metadata.source !== 'select') {
            setShowDropdown(isOpen);
        }
        event.persist?.();
    }, []);
    return (
        <Card className={isArchived ? 'bg-light' : ''}>
            <CardContent className={onClick ? 'clickable' : ''} onClick={() => onClick?.()}>
                <Col>
                    <Row className="justify-content-between align-items-center flex-nowrap">
                        {topLeftContent}
                        <Dropdown onToggle={onToggle} show={showDropdown}>
                            <ThreeDotDropdownToggle />
                            <Dropdown.Menu>
                                {items.map(
                                    (
                                        {
                                            isConfirm = false,
                                            isLoading,
                                            isVisible,
                                            label,
                                            ...props
                                        },
                                        index
                                    ) =>
                                        isVisible &&
                                        (isConfirm ? (
                                            <ConfirmText
                                                as={Dropdown.Item}
                                                isLoading={isLoading}
                                                key={index}
                                                onComplete={closeDropdown}
                                                {...(props as IConfirmTextProps)}
                                            >
                                                {label}
                                            </ConfirmText>
                                        ) : (
                                            <Dropdown.Item
                                                disabled={isLoading}
                                                key={index}
                                                {...(props as DropdownItemProps)}
                                            >
                                                {isLoading ? <Spinner size="20" /> : label}
                                            </Dropdown.Item>
                                        ))
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Row>
                    <br />
                    {children}
                    <br />
                    <Row className="justify-content-between align-items-center flex-nowrap">
                        <div className="text-muted">
                            <div>
                                <small>{bottomLeftContent}</small>
                            </div>
                        </div>
                        <SharedIconWrapper>
                            {isShared && (
                                <Tooltip title={sharedTooltipText}>
                                    <Icon size="2" variant={isUnSharable ? 'muted' : 'primary'}>
                                        user-group
                                    </Icon>
                                </Tooltip>
                            )}
                        </SharedIconWrapper>
                    </Row>
                </Col>
            </CardContent>
        </Card>
    );
};

export default hot(module)(ThingCard);
