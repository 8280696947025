import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import useTheme from '@mui/material/styles/useTheme';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

export type ITextFieldProps = Partial<TextFieldProps> & {
    errors?: string[];
    isLoading?: boolean;
    isOptional?: boolean;
};
const TextField = ({
    disabled,
    errors,
    helperText,
    InputLabelProps: InputLabelPropsParent,
    InputProps: InputPropsParent,
    isLoading,
    isOptional,
    label: labelParent,
    onBlur: onBlurParent,
    onFocus: onFocusParent,
    inputRef: inputRefParent,
    ...props
}: ITextFieldProps) => {
    const theme = useTheme();
    const [hasFocus, setHasFocus] = useState(false);
    const [shouldRefocus, setShouldRefocus] = useState(false);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(
        (inputRefParent as unknown) as HTMLInputElement | HTMLTextAreaElement | null
    );

    const onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        onBlurParent?.(e);
        setHasFocus(false);
    };
    const onFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        onFocusParent?.(e);
        setHasFocus(true);
    };

    useEffect(() => {
        if (hasFocus && isLoading) {
            setShouldRefocus(true);
        }
    }, [hasFocus, isLoading]);

    useEffect(() => {
        if (!isLoading && shouldRefocus) {
            inputRef.current?.focus();
        }
    }, [isLoading, shouldRefocus]);

    const label = labelParent ?? '';
    let InputLabelProps = InputLabelPropsParent;
    const isLabelEmpty = !hasValue(labelParent);
    if (isLabelEmpty) {
        InputLabelProps = { shrink: false, ...InputLabelProps };
    } else if (hasValue(props.value)) {
        InputLabelProps = { shrink: true, ...InputLabelProps };
    }
    let InputProps = InputPropsParent;
    if (isLoading) {
        InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <CircularProgress color="primary" />
                </InputAdornment>
            ),
        };
    }
    return (
        <MuiTextField
            disabled={isLoading || disabled}
            error={!!errors}
            fullWidth
            helperText={errors?.[0] ?? helperText}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            inputRef={inputRef}
            label={
                isOptional && !isLabelEmpty ? (
                    <React.Fragment>
                        {label}
                        {isOptional && (
                            <em style={{ color: theme.palette.secondary.main, marginRight: 2.0 }}>
                                - Optional
                            </em>
                        )}
                    </React.Fragment>
                ) : (
                    label
                )
            }
            onBlur={onBlur}
            onFocus={onFocus}
            variant="outlined"
            {...props}
        />
    );
};

export default hot(module)(TextField);
