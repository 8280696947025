import CheckMark from 'components/headerDropdown/CheckMark';
import React, { useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { hot } from 'react-hot-loader';
import { HeaderProps } from 'react-table';
import { isFalse, isTrue } from 'utilities';

export type ISorted = {
    desc?: boolean;
    id: string;
};
export type ISetSorted =
    | HeaderProps<ISorted>['setSortBy']
    | React.Dispatch<React.SetStateAction<ISorted[]>>;

export type ISortDropdownMenuItemsProps = {
    columnId: string;
    setSorted: ISetSorted;
    sorted: ISorted[];
};
const SortDropdownMenuItems = ({ columnId, setSorted, sorted }: ISortDropdownMenuItemsProps) => {
    const isAToZ = useMemo(() => {
        if (sorted) {
            const sortedCol = sorted.find((s) => s.id === columnId);
            if (!sortedCol) {
                return null;
            } else if (isFalse(sortedCol.desc)) {
                return true;
            }
            return false;
        }
        return null;
    }, [columnId, sorted]);
    const onSortSelect = (newIsAToZ: boolean) => () => {
        if (newIsAToZ === isAToZ) {
            setSorted([...sorted.filter((x) => x.id !== columnId)]);
        } else {
            setSorted([
                ...sorted.filter((x) => x.id !== columnId),
                { desc: !newIsAToZ, id: columnId },
            ]);
        }
    };
    return (
        <React.Fragment>
            <Dropdown.Item className="text-dark" onSelect={onSortSelect(true)}>
                <CheckMark isVisible={isTrue(isAToZ)} /> Sort A &rarr; Z
            </Dropdown.Item>
            <Dropdown.Item className="text-dark" onSelect={onSortSelect(false)}>
                <CheckMark isVisible={isFalse(isAToZ)} /> Sort Z &rarr; A
            </Dropdown.Item>
            <Dropdown.Divider key="divider" />
        </React.Fragment>
    );
};

export default hot(module)(SortDropdownMenuItems);
