import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { hot } from 'react-hot-loader';

const MedicareChip = ({ sx, ...props }: ChipProps) => (
    <Chip
        key="medicare"
        label="Medicare"
        size="small"
        sx={{
            backgroundColor: (theme) => theme.palette.secondary.light,
            color: (theme) => theme.palette.primary.main,
            ...sx,
        }}
        {...props}
    />
);

export default hot(module)(MedicareChip);
