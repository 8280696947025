import { Flows } from 'api/generated/enums';
import { TaskDto } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import { APP_ROOT_ID } from 'constants/dom';
import { useCalendly } from 'hooks/useCalendly';
import useModalState from 'hooks/useModalState';
import AgreeToTermsModal from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/AgreeToTermsModal';
import React from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

const agreeToTermsIntroText =
    'Before meeting with one of our licensed healthcare advisors, please review and agree to the Remodel Health Member Terms. This step allows our team to assist you in reviewing your benefit options and to subsequently enroll and manage your benefit after you make a formal selection.';
export type ICalendlyModalBaseProps = {
    buttonLabel?: string;
    calendlyLinkOverride?: string;
    children: (openModal: () => void) => JSX.Element;
    memberTermsTask?: TaskDto;
    onAdvisementScheduled: () => void;
    showAgreeToTerms?: boolean;
};

const CalendlyModalBase = ({
    calendlyLinkOverride,
    children,
    memberTermsTask,
    onAdvisementScheduled,
    showAgreeToTerms,
}: ICalendlyModalBaseProps) => {
    const needCalendlyLink = !hasValue(calendlyLinkOverride);
    const { closeModal, isVisible, openModal } = useModalState({
        key: 'calendly',
    });
    const {
        closeModal: closeTermsModal,
        isVisible: isTermsModalVisible,
        openModal: openTermsModal,
    } = useModalState({ key: 'terms' });
    const { calendlyLink, isLoadingCalendlyLink, prefill } = useCalendly(needCalendlyLink);
    useCalendlyEventListener({
        onEventScheduled: () => {
            if (isVisible) {
                onAdvisementScheduled();
            }
        },
    });
    const handleCloseTermsModal = () => {
        closeTermsModal();
        openModal();
    };

    if (showAgreeToTerms) {
        return (
            <React.Fragment>
                {children(openTermsModal)}
                {isTermsModalVisible && (
                    <AgreeToTermsModal
                        currentTask={memberTermsTask}
                        flowId={Flows.IchraLaunch}
                        introText={agreeToTermsIntroText}
                        onClose={handleCloseTermsModal}
                    />
                )}
            </React.Fragment>
        );
    }

    return (
        <Skeleton count={1} isEnabled={needCalendlyLink && isLoadingCalendlyLink}>
            {children(openModal)}
            <PopupModal
                onModalClose={closeModal}
                open={isVisible}
                prefill={prefill}
                rootElement={document.getElementById(APP_ROOT_ID) as HTMLElement}
                url={calendlyLinkOverride ?? calendlyLink}
            />
        </Skeleton>
    );
};

export default hot(module)(CalendlyModalBase);
