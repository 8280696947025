import { Paper } from '@mui/material';
import React from 'react';
import { hot } from 'react-hot-loader';

type IPageSectionWrapper = {
    className?: string;
};

const PageSectionWrapper: React.FC<IPageSectionWrapper> = ({ className, children }) => (
    <Paper className={className} elevation={1} sx={{ mb: 3, padding: 3 }}>
        {children}
    </Paper>
);

export default hot(module)(PageSectionWrapper);
