import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { GET_COUNTIES_ACTION } from 'actions/marketplacePlan/getCounties';
import { SEARCH_PLANS_ACTION } from 'actions/marketplacePlan/searchMarketplacePlans';
import { ADD_ICHRA_CLASS_ACTION } from 'actions/pathwayBlueprint/addIchraClass';
import { APPLY_STRATEGY_ACTION } from 'actions/pathwayBlueprint/applyStrategy';
import { EDIT_ICHRA_CLASS_ACTION } from 'actions/pathwayBlueprint/editIchraClass';
import { AgeBandSizes, TopLineReimbursementStrategies } from 'api/generated/enums';
import AgeBandCoverageLevelInputs from 'components/pathwayModal/AgeBandCoverageLevelInputs';
import CalculatedAgeBandsTable from 'components/pathwayModal/CalculatedAgeBandsTable';
import PercentTextField from 'components/PercentTextField';
import Select from 'components/Select';
import Typography from 'components/Typography';
import { IchraClassFormContext } from 'pages/pathwayBlueprint/ichraClasses/IchraClassModal';
import MarketplacePathwayInputs from 'pages/pathwayBlueprint/MarketplacePathwayInputs';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray, hasValue } from 'utilities/index';

const ICHRA_AGE_BAND_FIVE = 5;
const ICHRA_AGE_BAND_TEN = 10;
const ageBandSizesArray = enumToNameValueArray(AgeBandSizes, {
    nameMap: { Five: '5-Years', One: '1-Year', Ten: '10-Years' },
}).filter((x) => x.value !== AgeBandSizes.Custom);

const getAgeBandSize = (ageBandId: AgeBandSizes) => {
    switch (ageBandId) {
        case AgeBandSizes.One:
            return 1;
        case AgeBandSizes.Five:
            return ICHRA_AGE_BAND_FIVE;
        case AgeBandSizes.Ten:
            return ICHRA_AGE_BAND_TEN;
        default:
            return 0;
    }
};

const handlePercentChange = (value: string | undefined) =>
    hasValue(value) ? Number(value) : undefined;

const CalculatedAgeBandStrategiesContent = () => {
    const { errors, ichraClass, setIchraClass } = useContext(IchraClassFormContext);
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            ADD_ICHRA_CLASS_ACTION,
            APPLY_STRATEGY_ACTION,
            EDIT_ICHRA_CLASS_ACTION,
            GET_COUNTIES_ACTION,
            SEARCH_PLANS_ACTION
        ),
    }));
    const setAgeBandValue = (name: string, value: number) => {
        let newObj = {};
        switch (name) {
            case 'ee64':
                newObj = { ee64: value };
                break;
            case 'es64':
                newObj = { es64: value };
                break;
            case 'ec64':
                newObj = { ec64: value };
                break;
            case 'ef64':
                newObj = { ef64: value };
                break;
            case 'eeRatio':
                newObj = { eeRatio: value };
                break;
            case 'esRatio':
                newObj = { esRatio: value };
                break;
            case 'ecRatio':
                newObj = { ecRatio: value };
                break;
            case 'efRatio':
                newObj = { efRatio: value };
                break;
            default:
                break;
        }
        setIchraClass({ ...ichraClass, ...newObj });
    };
    const isNotCustomClass =
        ichraClass.topLineReimbursementStrategyId !== TopLineReimbursementStrategies.Custom;

    return (
        <React.Fragment>
            {[
                TopLineReimbursementStrategies.CoverageLevelPercentage,
                TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage,
            ].includes(ichraClass.topLineReimbursementStrategyId) && (
                <Grid container gap={2} item xs={12}>
                    <MarketplacePathwayInputs
                        ichraClass={ichraClass}
                        isAgeBandedIchraPathway={false}
                        isCappedIchraPathway={false}
                    />
                    <PercentTextField
                        data-cy="dependentKickerCoveragePercent"
                        disabled={isLoading}
                        errors={errors?.dependentKickerCoveragePercent}
                        helperText="When the additional dependent kicker amount is recalculated, it will be set to this percentage of an 18 year-old's cost for the benchmark plan."
                        isOptional
                        label="Dependent Kicker Coverage Percentage"
                        name="dependentKickerCoveragePercent"
                        onChange={(e) =>
                            setIchraClass({
                                ...ichraClass,
                                dependentKickerCoveragePercentage: handlePercentChange(
                                    e.target.value
                                ),
                            })
                        }
                        placeholder="Enter Dependent Kicker Coverage Percentage"
                        value={ichraClass.dependentKickerCoveragePercentage}
                    />
                    <Stack direction="row" spacing={2} width="100%">
                        <PercentTextField
                            data-cy="eeCoveragePercent"
                            disabled={isLoading}
                            errors={errors?.eeCoveragePercent}
                            label="EE Coverage Percentage"
                            name="eeCoveragePercent"
                            onChange={(e) =>
                                setIchraClass({
                                    ...ichraClass,
                                    eeCoveragePercentage: handlePercentChange(e.target.value),
                                })
                            }
                            placeholder="Enter EE Coverage Percentage"
                            value={ichraClass.eeCoveragePercentage}
                        />
                        {ichraClass.topLineReimbursementStrategyId ===
                            TopLineReimbursementStrategies.CoverageLevelPercentage && (
                            <React.Fragment>
                                <PercentTextField
                                    data-cy="esCoveragePercent"
                                    disabled={isLoading}
                                    errors={errors?.esCoveragePercent}
                                    label="ES Coverage Percentage"
                                    name="esCoveragePercent"
                                    onChange={(e) =>
                                        setIchraClass({
                                            ...ichraClass,
                                            esCoveragePercentage: handlePercentChange(
                                                e.target.value
                                            ),
                                        })
                                    }
                                    placeholder="Enter ES Coverage Percentage"
                                    value={ichraClass.esCoveragePercentage}
                                />
                                <PercentTextField
                                    data-cy="ecCoveragePercent"
                                    disabled={isLoading}
                                    errors={errors?.ecCoveragePercent}
                                    label="EC Coverage Percentage"
                                    name="ecCoveragePercent"
                                    onChange={(e) =>
                                        setIchraClass({
                                            ...ichraClass,
                                            ecCoveragePercentage: handlePercentChange(
                                                e.target.value
                                            ),
                                        })
                                    }
                                    placeholder="Enter EC Coverage Percentage"
                                    value={ichraClass.ecCoveragePercentage}
                                />
                                <PercentTextField
                                    data-cy="efCoveragePercent"
                                    disabled={isLoading}
                                    errors={errors?.efCoveragePercent}
                                    label="EF Coverage Percentage"
                                    name="efCoveragePercent"
                                    onChange={(e) =>
                                        setIchraClass({
                                            ...ichraClass,
                                            efCoveragePercentage: handlePercentChange(
                                                e.target.value
                                            ),
                                        })
                                    }
                                    placeholder="Enter EF Coverage Percentage"
                                    value={ichraClass.efCoveragePercentage}
                                />
                            </React.Fragment>
                        )}
                        {ichraClass.topLineReimbursementStrategyId ===
                            TopLineReimbursementStrategies.SplitPrimaryHouseholdPercentage && (
                            <PercentTextField
                                data-cy="otherHouseholdMemberCoveragePercent"
                                disabled={isLoading}
                                errors={errors?.otherHouseholdMemberCoveragePercentage}
                                label="Other Household Coverage Percentage"
                                name="otherHouseholdMemberCoveragePercent"
                                onChange={(e) =>
                                    setIchraClass({
                                        ...ichraClass,
                                        otherHouseholdMemberCoveragePercentage: handlePercentChange(
                                            e.target.value
                                        ),
                                    })
                                }
                                placeholder="Enter Other Household Member Coverage Percentage"
                                value={ichraClass.otherHouseholdMemberCoveragePercentage}
                            />
                        )}
                    </Stack>
                </Grid>
            )}
            <Grid item xs={12}>
                <Select
                    disabled={isLoading}
                    errors={errors?.ageBandSizeId}
                    items={ageBandSizesArray}
                    label="Age Band Size"
                    name="ageBandSize"
                    onChange={(e) =>
                        setIchraClass({
                            ...ichraClass,
                            ageBandSizeId: Number(e.target.value),
                        })
                    }
                    optionText="name"
                    optionValue="value"
                    value={ichraClass.ageBandSizeId}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">64+ Reimbursement Amounts</Typography>
                <Stack direction="row" spacing={2}>
                    <AgeBandCoverageLevelInputs
                        ageBandsOnChange={setAgeBandValue}
                        errors={errors?.ee64}
                        groupLevelAbbrev="Ee"
                        reimbursementRatio={ichraClass?.eeRatio?.toString() ?? ''}
                        shouldDisable={isLoading || isNotCustomClass}
                        sixtyFourReimbursement={ichraClass?.ee64?.toString() ?? ''}
                    />
                    <AgeBandCoverageLevelInputs
                        ageBandsOnChange={setAgeBandValue}
                        errors={errors?.es64}
                        groupLevelAbbrev="Es"
                        reimbursementRatio={ichraClass?.esRatio?.toString() ?? ''}
                        shouldDisable={isLoading || isNotCustomClass}
                        sixtyFourReimbursement={ichraClass?.es64?.toString() ?? ''}
                    />
                    <AgeBandCoverageLevelInputs
                        ageBandsOnChange={setAgeBandValue}
                        errors={errors?.ec64}
                        groupLevelAbbrev="Ec"
                        reimbursementRatio={ichraClass?.ecRatio?.toString() ?? ''}
                        shouldDisable={isLoading || isNotCustomClass}
                        sixtyFourReimbursement={ichraClass?.ec64?.toString() ?? ''}
                    />
                    <AgeBandCoverageLevelInputs
                        ageBandsOnChange={setAgeBandValue}
                        errors={errors?.ef64}
                        groupLevelAbbrev="Ef"
                        reimbursementRatio={ichraClass?.efRatio?.toString() ?? ''}
                        shouldDisable={isLoading || isNotCustomClass}
                        sixtyFourReimbursement={ichraClass?.ef64?.toString() ?? ''}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <CalculatedAgeBandsTable
                    ageBand={getAgeBandSize(ichraClass.ageBandSizeId)}
                    ecCoverageLevelInputs={{
                        reimbursementRatio: ichraClass.ecRatio ?? 0,
                        sixtyFourReimbursement: ichraClass.ec64 ?? 0,
                    }}
                    eeCoverageLevelInputs={{
                        reimbursementRatio: ichraClass.eeRatio ?? 0,
                        sixtyFourReimbursement: ichraClass.ee64 ?? 0,
                    }}
                    efCoverageLevelInputs={{
                        reimbursementRatio: ichraClass.efRatio ?? 0,
                        sixtyFourReimbursement: ichraClass.ef64 ?? 0,
                    }}
                    esCoverageLevelInputs={{
                        reimbursementRatio: ichraClass.esRatio ?? 0,
                        sixtyFourReimbursement: ichraClass.es64 ?? 0,
                    }}
                />
            </Grid>
        </React.Fragment>
    );
};

export default hot(module)(CalculatedAgeBandStrategiesContent);
