import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import Skeleton from 'components/Skeleton';
import React from 'react';
import { hot } from 'react-hot-loader';

export type ITypographyProps = TypographyProps & {
    isLoading?: boolean;
};

const Typography = ({ children, isLoading = false, ...props }: ITypographyProps) => (
    <MuiTypography {...props}>
        <Skeleton isEnabled={isLoading}>{children}</Skeleton>
    </MuiTypography>
);

export default hot(module)(Typography);
