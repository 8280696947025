import Icon from 'components/Icon';
import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

export const NoCheckmarkSpacer = styled.span`
    margin-right: 20px;
`;

const CheckMark = ({ isVisible }: { isVisible: boolean }) =>
    isVisible ? <Icon variant="primary">checkmark</Icon> : <NoCheckmarkSpacer />;
export default hot(module)(CheckMark);
