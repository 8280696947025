import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import { ISurveyState } from 'pages/survey/surveyState';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { genderList } from 'reducers/options';
import { reduceToObject } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

const AboutYou = ({
    dateOfBirth,
    editSection,
    firstName,
    gender,
    hireDate,
    jobTitle,
    lastName,
    preferredName,
}: ISurveyState['member'] & { editSection: ((tabIndex: number) => void) | undefined }) => {
    const genders = useMemo(() => reduceToObject(genderList, 'id', 'value'), []);
    return (
        <Section onEdit={editSection && (() => editSection(SurveyTabs.Member))} title="About You">
            <Col className="p-0">
                <ContentWithLabel label="Legal First Name">{firstName}</ContentWithLabel>
                <ContentWithLabel label="Last Name">{lastName}</ContentWithLabel>
                {preferredName && (
                    <ContentWithLabel label="Preferred First Name">
                        {preferredName}
                    </ContentWithLabel>
                )}
                <ContentWithLabel label="Gender">{genders[gender as string]}</ContentWithLabel>
                <ContentWithLabel label="Date of Birth">
                    {formatDateForDisplay(dateOfBirth)}
                </ContentWithLabel>
                <ContentWithLabel label="Job Title">{jobTitle}</ContentWithLabel>
                <ContentWithLabel label="Hire Date">
                    {formatDateForDisplay(hireDate)}
                </ContentWithLabel>
            </Col>
        </Section>
    );
};

export default hot(module)(AboutYou);
