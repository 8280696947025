import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
const Section = ({
    children,
    noGutters = false,
    onEdit,
    title,
}: {
    children: ReactNode;
    noGutters?: boolean;
    onEdit: React.MouseEventHandler | undefined;
    title: string;
}) => (
    <div className="my-3">
        <Row className="pb-0 justify-content-between">
            <PageHeader variant="h4">{title}</PageHeader>
            {onEdit && (
                <Button
                    ButtonClassName="p-0"
                    color="secondary"
                    data-cy={kebabCase(`${title} edit`)}
                    noPadding
                    onClick={onEdit}
                    variant="text"
                >
                    Edit
                </Button>
            )}
        </Row>
        <Row as="hr" className="mt-0 mb-2" />
        <Row noGutters={noGutters}>{children}</Row>
    </div>
);

export default hot(module)(Section);
