import React from 'react';
import { hot } from 'react-hot-loader';

const Line = ({ isComplete = false, x }: { isComplete: boolean; x: number | string }) => (
    <svg height="20" width="45" x={x}>
        <line
            className={isComplete ? 'stroke-primary' : 'stroke-grey'}
            strokeWidth="2.5"
            x1="0"
            x2="100%"
            y1="50%"
            y2="50%"
        />
    </svg>
);

export default hot(module)(Line);
