import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import Button from 'components/Button';
import DiagramGrid from 'components/majorMedicalBenefitCostBreakdownModal/DiagramGrid';
import SuperScript from 'components/majorMedicalBenefitCostBreakdownModal/SuperScript';
import TotalMajorMedicalBenefitCostGrids from 'components/majorMedicalBenefitCostBreakdownModal/TotalMajorMedicalBenefitCostGrids';
import TotalPlanCostGrids from 'components/majorMedicalBenefitCostBreakdownModal/TotalPlanCostGrids';
import WagePlusGrids from 'components/majorMedicalBenefitCostBreakdownModal/WagePlusGrids';
import ScrollGradientIndicator, {
    UPDATE_SCROLL_GRADIENT_INDICATOR,
} from 'components/ScrollGradientIndicator';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import EventEmitter from 'utilities/eventEmitter';
import { formatCurrency } from 'utilities/format';

type IMajorMedicalBenefitCostBreakdownModal = {
    majorMedicalBenefitCost?: IMajorMedicalBenefitCostDto;
    onClose: () => void;
    shouldHideDeductibleBenefitText?: boolean;
};
const MajorMedicalBenefitCostBreakdownModal = ({
    majorMedicalBenefitCost: parentCostData,
    onClose,
    shouldHideDeductibleBenefitText = false,
}: IMajorMedicalBenefitCostBreakdownModal) => {
    const { memberBenefits } = useSelector((state: AppStore) => ({
        memberBenefits: state.memberBenefits,
    }));
    const [isFullBreakdownVisible, setIsFullBreakdownVisible] = useState(false);
    const onSeeBreakdownClick = () => setIsFullBreakdownVisible((x) => !x);
    const costData = parentCostData ?? memberBenefits.majorMedicalBenefitCost;

    useEffect(() => {
        EventEmitter.emit(UPDATE_SCROLL_GRADIENT_INDICATOR);
    }, [isFullBreakdownVisible]);

    const summaryContent = (
        <React.Fragment>
            <Typography variant="h4">Summary</Typography>
            <Typography>
                Your employer is giving you a monthly Wage+ of{' '}
                {formatCurrency(costData?.preTaxWagePlus)}
                {isFullBreakdownVisible && <SuperScript>a</SuperScript>}, which will be added to
                your wages before taxes on your paycheck.
            </Typography>
            <Typography>
                Your total monthly benefit cost is{' '}
                {formatCurrency(costData?.postTaxTotalPlanCost, {
                    preserveDecimal: true,
                })}
                {isFullBreakdownVisible && <SuperScript>b</SuperScript>}
                {shouldHideDeductibleBenefitText
                    ? '.'
                    : ', which will be deducted from your paycheck after taxes.'}
            </Typography>
            <Typography>
                After factoring in taxes paid and the Wage+ amount provided, your cost ends up being{' '}
                {formatCurrency(costData?.preTaxTotalMajorMedicalBenefitCost)}
                {isFullBreakdownVisible && <SuperScript>c</SuperScript>} per month.
            </Typography>
        </React.Fragment>
    );

    const fullCostBreakdownContent = (
        <Stack gap={2}>
            <Divider />
            <Typography variant="h4">Full Cost Breakdown</Typography>
            <Typography>
                With Remodel Health&apos;s Wage+ model, we factor in the benefit cost(s), Wage+
                amount provided by your employer, and the taxes that you pay when determining your
                cost.
            </Typography>
            <Typography mb={8}>
                You can think about your cost in two different ways: pre-tax and post-tax. Pre-tax
                would be your cost before taxes are taken out of your income (how you pay for a
                traditional group health benefit). Post-tax would be your cost after taxes are taken
                out of your income (how you pay for an individual health benefit).
            </Typography>
            <Grid container textAlign="center">
                <Grid columns={14} container item>
                    <Grid item paddingX={2} xs={2}></Grid>
                    <Grid borderRight={1} item paddingX={2} xs={4}>
                        <Typography variant="h3">Pre-Tax</Typography>
                    </Grid>
                    <DiagramGrid borderRight={1} xs={4}>
                        <Typography color={(theme) => theme.palette.muted.main} variant="caption">
                            Using a {(costData?.totalTaxRate ?? 0) * 100}%
                            <SuperScript>d</SuperScript> estimated tax rate
                        </Typography>
                    </DiagramGrid>
                    <Grid item paddingX={2} xs={4}>
                        <Typography variant="h3">Post-Tax</Typography>
                    </Grid>
                </Grid>
                <WagePlusGrids majorMedicalBenefitCost={parentCostData} />
                <TotalPlanCostGrids majorMedicalBenefitCost={parentCostData} />
                <TotalMajorMedicalBenefitCostGrids majorMedicalBenefitCost={parentCostData} />
            </Grid>
            <Stack>
                <div>
                    <SuperScript>a</SuperScript> Monthly pre-tax Wage+ amount
                </div>
                <div>
                    <SuperScript>b</SuperScript> Monthly post-tax total benefit cost amount
                </div>
                <div>
                    <SuperScript>c</SuperScript> Monthly total major medical benefit cost
                </div>
                <div>* Rounded for simplicity</div>
            </Stack>
        </Stack>
    );

    return (
        <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
            <DialogTitle>Major Medical Benefit Cost Breakdown</DialogTitle>
            <DialogContent dividers id="scrollContentContainer">
                <Stack gap={2}>
                    {summaryContent}
                    <Collapse in={!isFullBreakdownVisible}>
                        <Button
                            endIcon={<ExpandMoreIcon sx={{ fontSize: 50 }} />}
                            onClick={onSeeBreakdownClick}
                            variant="text"
                        >
                            See full cost breakdown
                        </Button>
                    </Collapse>

                    {isFullBreakdownVisible && (
                        <ScrollGradientIndicator
                            headerElementClassName="MuiDialogTitle-root"
                            scrollElementId="scrollContentContainer"
                            topOffset={20}
                        >
                            {fullCostBreakdownContent}
                        </ScrollGradientIndicator>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(MajorMedicalBenefitCostBreakdownModal);
