import { AppStoreThunkDispatch } from 'actions/commonAction';
import { addCustomSelectedPlans } from 'actions/selectedPlan/addCustomSelectedPlans';
import { patchSelectedPlans } from 'actions/selectedPlan/patchSelectedPlans';
import {
    BinderStatuses,
    HealthCareTypeIds,
    PayTypeIds,
    PlanStateIds,
    PlanTypeIds,
} from 'api/generated/enums';
import {
    IMarketplacePlanDto,
    IMediSharePlan,
    ISelectedPlan,
    SelectedPlan,
} from 'api/generated/models';
import { updatePlanInputs } from 'components/planModal/planModalActions';
import {
    getPlanModalSchema,
    IPlanInputs,
    IPlanModalState,
} from 'components/planModal/planModalState';
import { ahpOptions, legacyAhpOptions } from 'constants/ahpOptions';
import { CMS_OR_IDEON_PLAN_TYPES } from 'constants/selectedPlans';
import { AppStore } from 'reducers/appReducer';
import { stringToFloat, toBoolean } from 'utilities';
import { formatErrors, validate } from 'utilities/forms';
import { isSpouseOrParentPlanType, shouldIncludePremiumWithCredits } from 'utilities/plans';
import {
    buildSelectedAncillaryPlanFromPlanInputs,
    buildSelectedMarketplacePlan,
    buildSelectedMediSharePlan,
    buildSelectedOtherCoverageFromPlanInputs,
    buildSelectedTermMedicalPlanFromPlanInputs,
    buildSelectedVeteransAffairsFromPlanInputs,
    selectedChipPlan,
    selectedMedicaidPlan,
    selectedMedicarePlan,
} from 'utilities/selectedPlanBuilder';
import { ValidationError } from 'yup';

const buildMarketplacePlanFromPlanInputs = (
    planInputs: IPlanInputs,
    marketplacePlans: IMarketplacePlanDto[]
) => {
    const plan = marketplacePlans.find((x) => x.id === planInputs.selectedMarketplacePlan);
    const selectedPlan: Partial<ISelectedPlan> = buildSelectedMarketplacePlan(
        plan,
        planInputs.planType
    );
    selectedPlan.isCustom = true;
    return selectedPlan;
};

const buildMediSharePlanFromPlanInputs = (
    planInputs: IPlanInputs,
    mediSharePlans: IMediSharePlan[]
) => {
    const isNewAhp = ahpOptions.some((x) => x.id === +planInputs.selectedAhp);
    const mediSharePlan = mediSharePlans.find((x) => x.ahp === planInputs.selectedAhp);
    const selectedPlan = buildSelectedMediSharePlan(
        isNewAhp ? mediSharePlan : { ahp: planInputs.selectedAhp },
        [...ahpOptions, ...legacyAhpOptions]
    );
    selectedPlan.isCustom = true;
    return selectedPlan;
};

const buildSelectedPlanFromPlanInputs = (
    planInputs: IPlanInputs,
    marketplacePlans: IMarketplacePlanDto[],
    mediSharePlans: IMediSharePlan[]
) => {
    let selectedPlan: Partial<ISelectedPlan> | undefined;
    if (CMS_OR_IDEON_PLAN_TYPES.includes(planInputs.planType)) {
        selectedPlan = buildMarketplacePlanFromPlanInputs(planInputs, marketplacePlans);
    } else if (planInputs.planType === PlanTypeIds.MediShare) {
        selectedPlan = buildMediSharePlanFromPlanInputs(planInputs, mediSharePlans);
    } else if (planInputs.planType === PlanTypeIds.Medicare) {
        selectedPlan = selectedMedicarePlan;
    } else if (planInputs.planType === PlanTypeIds.Medicaid) {
        selectedPlan = selectedMedicaidPlan;
    } else if (planInputs.planType === PlanTypeIds.CHIP) {
        selectedPlan = selectedChipPlan;
    } else if (planInputs.planType === PlanTypeIds.VeteransAffairs) {
        selectedPlan = buildSelectedVeteransAffairsFromPlanInputs(planInputs);
    } else if (planInputs.planType === PlanTypeIds.Ancillary) {
        selectedPlan = buildSelectedAncillaryPlanFromPlanInputs(planInputs);
    } else if (
        [PlanTypeIds.SpouseEmployer, PlanTypeIds.ParentEmployer].includes(planInputs.planType)
    ) {
        selectedPlan = buildSelectedOtherCoverageFromPlanInputs(
            {
                deductible: Number(planInputs?.deductible),
                planPremium: Number(planInputs?.planPremium),
            },
            planInputs.planType
        );
    } else if (
        [
            PlanTypeIds.CustomOffExchange,
            PlanTypeIds.CustomMajorMedical,
            PlanTypeIds.CustomStateBasedExchange,
            PlanTypeIds.TermMedical,
            PlanTypeIds.CustomAncillary,
        ].includes(planInputs.planType)
    ) {
        selectedPlan = buildSelectedTermMedicalPlanFromPlanInputs(planInputs.planType, planInputs);
    }
    return selectedPlan;
};

const buildAndAddSelectedPlan = async (
    dispatch: AppStoreThunkDispatch,
    userId: string,
    { planInputs, selectedHouseholdMemberIds }: IPlanModalState,
    marketplacePlans: IMarketplacePlanDto[],
    mediSharePlans: IMediSharePlan[]
) => {
    const selectedPlan = buildSelectedPlanFromPlanInputs(
        planInputs,
        marketplacePlans,
        mediSharePlans
    );
    if (selectedPlan) {
        selectedPlan.coverageEndDate = planInputs.coverageEndDate;
        selectedPlan.coverageStartDate = planInputs.coverageStartDate;
        selectedPlan.planStateId = planInputs.planStateId as PlanStateIds;
        selectedPlan.userId = userId;
        selectedPlan.isPrimaryCovered = selectedHouseholdMemberIds.includes(userId);
        selectedPlan.householdMembersCovered = selectedHouseholdMemberIds.filter(
            (x) => x !== userId
        );
        selectedPlan.binderStatus = (planInputs.binderStatus as unknown) as BinderStatuses;
        selectedPlan.applicationLink = planInputs.applicationLink;
        selectedPlan.memberId = planInputs.memberId;
        selectedPlan.payTypeId = (planInputs.payTypeId as unknown) as PayTypeIds;
        selectedPlan.applicationDate = planInputs.applicationDate;
        selectedPlan.planPremium = stringToFloat(planInputs.planPremium);
        if (shouldIncludePremiumWithCredits(planInputs.planType)) {
            selectedPlan.planPremiumWithCredits = stringToFloat(planInputs.planPremiumWithCredits);
        } else {
            selectedPlan.planPremiumWithCredits = selectedPlan.planPremium;
        }
        selectedPlan.year = parseInt(planInputs.selectedYear);
        selectedPlan.isHsaEligible =
            planInputs.isHsaEligible === '' ? undefined : toBoolean(planInputs.isHsaEligible);
        selectedPlan.isPassiveRenewal = toBoolean(planInputs.isPassiveRenewal);
        selectedPlan.isAddedToPaymentTracking = toBoolean(planInputs.isAddedToPaymentTracking);
        selectedPlan.isAddedToPayrollReport = toBoolean(planInputs.isAddedToPayrollReport);
        selectedPlan.comment = planInputs.comment.trim();
        await dispatch(
            addCustomSelectedPlans([selectedPlan as ISelectedPlan], 'Successfully added plan!')
        );
    }
};

const patchPlan = async (
    dispatch: AppStoreThunkDispatch,
    planToEdit: ISelectedPlan,
    { planInputs }: IPlanModalState
) => {
    const isPlanSpouseOrParentPlanType = isSpouseOrParentPlanType(planToEdit.planTypeId);
    await dispatch(
        patchSelectedPlans(
            planToEdit.selectedPlanId,
            SelectedPlan.fromJS({
                applicationDate: planInputs.applicationDate,
                applicationLink: planInputs.applicationLink,
                binderStatus: planInputs.binderStatus,
                comment: planInputs.comment.trim(),
                coverageEndDate: planInputs.coverageEndDate,
                coverageStartDate: planInputs.coverageStartDate,
                familyDeductibleCost: isPlanSpouseOrParentPlanType
                    ? planInputs.deductible
                    : planToEdit.familyDeductibleCost,
                individualDeductibleCost: isPlanSpouseOrParentPlanType
                    ? planInputs.deductible
                    : planToEdit.individualDeductibleCost,
                isAddedToPaymentTracking: planInputs.isAddedToPaymentTracking,
                isAddedToPayrollReport: planInputs.isAddedToPayrollReport,
                isHsaEligible: planInputs.isHsaEligible,
                isPassiveRenewal: planInputs.isPassiveRenewal,
                memberId: planInputs.memberId,
                payTypeId: planInputs.payTypeId,
                planName: planInputs.planName,
                planPremium: planInputs.planPremium,
                planPremiumWithCredits: shouldIncludePremiumWithCredits(planInputs.planType)
                    ? planInputs.planPremiumWithCredits
                    : planInputs.planPremium,
                planStateId: planInputs.planStateId,
            })
        )
    );
};

export const saveSelectedPlan = (
    userId: string,
    planToEdit: ISelectedPlan | null | undefined = null
) => async (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => {
    const { marketplacePlans, mediSharePlans, planModalState } = getState();
    let isValid = true;
    try {
        const { planStateId, planType, selectedYear } = planModalState.planInputs;
        if (
            [
                PlanTypeIds.CustomOffExchange,
                PlanTypeIds.CustomMajorMedical,
                PlanTypeIds.CustomStateBasedExchange,
                PlanTypeIds.TermMedical,
                PlanTypeIds.CustomAncillary,
            ].includes(planType)
        ) {
            planModalState.planInputs.healthCareTypeId = HealthCareTypeIds.Medical.toString();
        }
        await validate(
            getPlanModalSchema(planType, planStateId, selectedYear),
            planModalState.planInputs
        );
        dispatch(updatePlanInputs({ errors: null }));
    } catch (_errors) {
        isValid = false;
        dispatch(updatePlanInputs({ errors: formatErrors(_errors as ValidationError) }));
    }
    if (isValid) {
        if (planToEdit?.selectedPlanId) {
            await patchPlan(dispatch, planToEdit, planModalState);
        } else {
            await buildAndAddSelectedPlan(
                dispatch,
                userId,
                planModalState,
                marketplacePlans,
                mediSharePlans
            );
        }
    }
    return isValid;
};
