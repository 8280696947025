import DropdownMenuItems, {
    IDropdownMenuItemsProps,
} from 'components/headerDropdown/DropdownMenuItems';
import SortDropdownMenuItems, {
    ISortDropdownMenuItemsProps,
    ISorted,
} from 'components/headerDropdown/SortDropdownMenuItems';
import UnderlinedContentWithTooltip from 'components/UnderlinedContentWithTooltip';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

export const COMMON_HEADER_DROPDOWN_TABLE_PROPS = {
    disableSortBy: true,
    headerClassName: 'overflow-visible',
};

type IHeaderDropdownProps = IDropdownMenuItemsProps &
    Partial<ISortDropdownMenuItemsProps> & {
        id: string;
        infoTooltip?: NonNullable<ReactNode>;
    };

const HeaderDropdown = ({
    columnId,
    id,
    infoTooltip,
    options,
    selected,
    setSelected,
    setSorted,
    sorted,
    title,
}: IHeaderDropdownProps) => {
    const [show, setShow] = useState(false);
    const shouldSort = hasValue(setSorted);
    useEffect(() => {
        if (shouldSort && !hasValue(columnId)) {
            throw new Error('Must have columnId prop to use sorting on HeaderDropdown');
        }
        if (shouldSort && !hasValue(sorted)) {
            throw new Error('Must have sorted prop to use sorting on HeaderDropdown');
        }
    }, [columnId, shouldSort, sorted]);
    const setShowWrapper = useCallback((value) => () => setShow(value), []);
    const onToggle = useCallback((_isOpen, event) => event?.persist?.(), []);
    const titleContent = useMemo(() => {
        if (!hasValue(infoTooltip)) {
            return title;
        }
        return (
            <UnderlinedContentWithTooltip title={infoTooltip}>{title}</UnderlinedContentWithTooltip>
        );
    }, [infoTooltip, title]);
    return (
        <Dropdown
            onMouseLeave={setShowWrapper(false)}
            onToggle={onToggle}
            show={show}
            style={{
                cursor: 'pointer',
            }}
        >
            <Dropdown.Toggle as="span" id={id} onClick={setShowWrapper(!show)}>
                {titleContent}
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2" popperConfig={{ positionFixed: true }}>
                {shouldSort && (
                    <SortDropdownMenuItems
                        columnId={columnId as string}
                        setSorted={setSorted}
                        sorted={sorted as ISorted[]}
                    />
                )}
                <DropdownMenuItems
                    options={options}
                    selected={selected}
                    setSelected={setSelected}
                    title={title}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default hot(module)(HeaderDropdown);
