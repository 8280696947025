import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

export type IIconProps = {
    'data-cy'?: string;
    children: string;
    className?: string;
    prefix?: 'dripicons' | 'mdi';
    /**
     * Applies font-size using `em` units
     */
    size?: number | string;
    variant?: string;
};

const StyledIcon = styled.i(({ size }: { size: IIconProps['size'] }) =>
    size ? `font-size: ${size}em;` : ''
);

const Icon = ({
    children,
    'data-cy': dataCy,
    prefix = 'dripicons',
    size,
    variant = 'default',
    className: additionalClassNames = '',
}: IIconProps) => (
    <StyledIcon
        className={
            `${prefix}-${children} text-${variant} ${additionalClassNames}` +
            (prefix === 'mdi' ? ' mdi' : '')
        }
        data-cy={dataCy}
        size={size}
    />
);

export default hot(module)(Icon);
