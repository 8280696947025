import { SingleDateMask } from 'components/DateTextField';
import EditableTextField, { IEditableTextFieldProps } from 'components/EditableTextField';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

const EditableDateTextField = <T,>({
    InputLabelProps,
    InputProps,
    onBlur: onBlurParent,
    onFocus: onFocusParent,
    ...props
}: IEditableTextFieldProps<T>) => {
    const [isFocused, setIsFocused] = useState(false);
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlurParent?.(e);
    };
    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocusParent?.(e);
    };
    const shrink = isFocused || hasValue(props.value);
    return (
        <EditableTextField
            InputLabelProps={{
                ...InputLabelProps,
                shrink,
            }}
            InputProps={{ ...InputProps, inputComponent: SingleDateMask }}
            onBlur={onBlur}
            onFocus={onFocus}
            {...props}
        />
    );
};
export default hot(module)(EditableDateTextField);
