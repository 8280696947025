import { PlanStateIds } from 'api/generated/enums';
import DateTextField from 'components/DateTextField';
import { updatePlanInputs } from 'components/planModal/planModalActions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const CommonPlanInputs = ({
    isMediShare = false,
    isStartDateRequired = false,
    isEndDateRequired = false,
}) => {
    const dispatch = useThunkDispatch();
    const { planInputs } = useSelector((state: AppStore) => ({
        planInputs: state.planModalState.planInputs,
    }));
    const onChange = useCallback(
        ({ target: { name, value } }) =>
            dispatch(updatePlanInputs({ ...planInputs, [name]: value })),
        [dispatch, planInputs]
    );
    const isEndOptional = useMemo(
        () => planInputs.planStateId !== PlanStateIds.Terminated && !isEndDateRequired,
        [isEndDateRequired, planInputs.planStateId]
    );
    const isStartOptional = useMemo(
        () =>
            ![PlanStateIds.Effective, PlanStateIds.Terminated].contains(planInputs.planStateId) &&
            !isStartDateRequired,
        [isStartDateRequired, planInputs.planStateId]
    );
    const { errors, coverageEndDate, coverageStartDate } = planInputs;
    return (
        <React.Fragment>
            <DateTextField
                data-cy="coverage-start-date"
                errors={errors?.coverageStartDate}
                isOptional={isStartOptional}
                label={isMediShare ? 'Member Since' : 'Effective Date'}
                name="coverageStartDate"
                onChange={onChange}
                value={coverageStartDate}
            />
            <DateTextField
                data-cy="coverage-end-date"
                errors={errors?.coverageEndDate}
                isOptional={isEndOptional}
                label="Termination Date"
                name="coverageEndDate"
                onChange={onChange}
                value={coverageEndDate}
            />
        </React.Fragment>
    );
};
export default hot(module)(CommonPlanInputs);
