import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

const WrappableH4 = (props: TypographyProps) => (
    <Typography
        overflow="hidden"
        textOverflow="ellipsis"
        variant="h4"
        whiteSpace="nowrap"
        {...props}
    />
);

export default hot(module)(WrappableH4);
