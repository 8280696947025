import Button from 'components/Button';
import Form from 'components/Form';
import React, { ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';

export type IConfirmationModalProps = {
    body?: ReactNode;
    children?: ReactNode;
    noButtonText?: string;
    onHide?: () => void;
    onNoClick: (() => void) | undefined;
    onYesClick: (() => void) | undefined;
    showActivity?: boolean;
    title: ReactNode;
    yesButtonText?: string;
};

const ConfirmationModal = ({
    body,
    children,
    noButtonText,
    onHide,
    onNoClick: parentOnNoClick,
    onYesClick: parentOnYesClick,
    showActivity,
    title,
    yesButtonText,
}: IConfirmationModalProps) => {
    const onYesClick: React.MouseEventHandler = (e) => {
        e.preventDefault();
        parentOnYesClick?.();
    };

    const onNoClick: React.MouseEventHandler = (e) => {
        e.preventDefault();
        parentOnNoClick?.();
    };
    return (
        <Modal onHide={onHide ?? parentOnNoClick} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="confirmation-modal" isLoading={showActivity}>
                    {body ?? children}
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button data-cy="confirmation-no" disabled={showActivity} onClick={onNoClick}>
                    {noButtonText ?? 'No'}
                </Button>
                <Button
                    data-cy="confirmation-yes"
                    form="confirmation-modal"
                    isLoading={showActivity}
                    onClick={onYesClick}
                    type="submit"
                >
                    {yesButtonText ?? 'Yes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(ConfirmationModal);
