import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import EditableTextField, { IEditableTextFieldProps } from 'components/EditableTextField';
import { ISelectProps } from 'components/Select';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';

export type IEditableSelectProps<T, Q> = IEditableTextFieldProps<Q> & ISelectProps<T>;

const EditableSelect = <T, Q>({
    defaultText = '--',
    defaultValue = '',
    items,
    optionText,
    optionValue,
    SelectProps: SelectPropsParent,
    ...props
}: IEditableSelectProps<T, Q>) => {
    const SelectProps = {
        native: true,
        ...SelectPropsParent,
    };
    let OptionComponent: React.ElementType = 'option';
    if (!SelectProps.native) {
        OptionComponent = MenuItem;
    }
    const isMultiple = useMemo(() => SelectProps.multiple, [SelectProps.multiple]);
    const getText = (value: unknown) => {
        const item = items.find((x) => x[optionValue] === value);
        if (typeof optionText === 'function') {
            return optionText(item);
        }
        return item?.[optionText];
    };
    if (isMultiple && !SelectProps.renderValue) {
        SelectProps.renderValue = (selected) =>
            (selected as unknown[]).map(getText).joinSerialComma();
    }
    return (
        <EditableTextField select SelectProps={SelectProps} {...props}>
            {defaultText && !isMultiple && (
                <OptionComponent value={defaultValue}>{defaultText}</OptionComponent>
            )}
            {items?.map((item) => {
                const value = (item[optionValue] as unknown) as number | string;
                const text = getText(value);
                return (
                    <OptionComponent
                        data-cy={`${props['data-cy']}-${text}-${value}`}
                        key={`${text}-${value}`}
                        value={value}
                    >
                        {isMultiple && (
                            <Checkbox checked={(props.value as unknown[]).includes(value)} />
                        )}
                        {text}
                    </OptionComponent>
                );
            })}
        </EditableTextField>
    );
};
export default hot(module)(EditableSelect);
