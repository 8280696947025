import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import ExternalLink from 'components/ExternalLink';
import {
    DEBIT_CARD_AGREEMENT_PDF_URL,
    DEPOSIT_ACCOUNT_AGREEMENT_PDF_URL,
    TISA_DISCLOSURE_PDF_URL,
} from 'constants/miscUrls';
import React from 'react';
import { hot } from 'react-hot-loader';

const AdditionalDisclosures = () => (
    <Card sx={{ mt: 1 }}>
        <CardHeader title="Additional Disclosures" />
        <CardContent>
            <Stack alignItems="center" spacing={2}>
                <ExternalLink href={DEPOSIT_ACCOUNT_AGREEMENT_PDF_URL}>
                    Deposit Account Agreement
                </ExternalLink>
                <ExternalLink href={DEBIT_CARD_AGREEMENT_PDF_URL}>
                    Cardholder Agreement
                </ExternalLink>
                <ExternalLink href={TISA_DISCLOSURE_PDF_URL}>
                    Truth In Savings Disclosure
                </ExternalLink>
            </Stack>
        </CardContent>
    </Card>
);

export default hot(module)(AdditionalDisclosures);
