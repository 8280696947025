import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import parse from 'autosuggest-highlight/parse';
import Autocomplete, { IAutocompleteProps } from 'components/Autocomplete';
import AutoFocusTextField from 'components/AutoFocusTextField';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

export const HighlightedSearchText = ({
    query,
    text,
}: {
    query: string;
    text: string | undefined;
}) => {
    // From: https://github.com/moroshko/autosuggest-highlight/issues/5#issuecomment-392333344
    if (!hasValue(query)) {
        return <span>{text}</span>;
    }
    const matches: [number, number][] = [];
    const trimmedQuery = query.trim().toLowerCase();
    const textLower = text?.toLowerCase();
    const queryLength: number = trimmedQuery.length;
    let indexOf: number | undefined = textLower?.indexOf(trimmedQuery);
    while (indexOf !== undefined && indexOf > -1) {
        matches.push([indexOf, indexOf + queryLength]);
        indexOf = textLower?.indexOf(query, indexOf + queryLength);
    }
    const parts = text !== undefined ? parse(text, matches) : [];
    return (
        <span>
            {parts.map((part, index) => {
                const PartWrapper = part.highlight ? 'strong' : 'span';
                return <PartWrapper key={index}>{part.text}</PartWrapper>;
            })}
        </span>
    );
};

type IHaveIdAndName = {
    id?: number | string;
    inputValue?: string;
    name?: string;
};
export type ICreatableAutocompleteProps<T> = Omit<
    IAutocompleteProps<T, true, false, false>,
    'renderInput'
> & {
    helperText: string;
    label: string;
};

const CreatableAutocomplete = <T extends IHaveIdAndName>({
    helperText,
    label,
    onChange: onChangeParent,
    value,
    ...props
}: ICreatableAutocompleteProps<T>) => {
    const onChange: ICreatableAutocompleteProps<T>['onChange'] = useCallback(
        (event, values, reason, details) => {
            const newValue = hasValue(values) ? values[values.length - 1] : undefined;
            let newValues = values ?? [];
            if (typeof newValue === 'string') {
                newValues = [
                    ...values.slice(0, -1),
                    ({
                        name: newValue,
                    } as unknown) as T,
                ];
            } else if (hasValue(newValue?.inputValue)) {
                newValues = [
                    ...values.slice(0, -1),
                    ({
                        name: newValue.inputValue,
                    } as unknown) as T,
                ];
            }
            onChangeParent?.(event, newValues as T[], reason, details);
        },
        [onChangeParent]
    );
    const filterOptions: ICreatableAutocompleteProps<T>['filterOptions'] = (options, params) => {
        const { inputValue } = params;

        if (inputValue === '') {
            options = [];
        }

        const isExisting = options.some((option) => inputValue === option.name);
        const isFreeSolo = !hasValue(props.freeSolo) || props.freeSolo;
        if (inputValue !== '' && !isExisting && isFreeSolo) {
            options.push(({
                inputValue,
                name: `Add "${inputValue}"`,
            } as unknown) as T);
        }
        return options;
    };
    return (
        <Autocomplete
            filterOptions={filterOptions}
            freeSolo
            getOptionLabel={(option) => startCase(lowerCase(option.name))}
            multiple
            onChange={
                onChange as (
                    event: React.SyntheticEvent,
                    value: (T | string)[],
                    reason: AutocompleteChangeReason,
                    details?: AutocompleteChangeDetails<T> | undefined
                ) => void
            }
            renderInput={(_props) => (
                <AutoFocusTextField
                    {..._props}
                    helperText={helperText}
                    InputProps={{
                        ..._props.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {props.loading ? (
                                    <Tooltip title="This can take a while...">
                                        <CircularProgress size={20} />
                                    </Tooltip>
                                ) : null}
                            </React.Fragment>
                        ),
                    }}
                    label={label}
                />
            )}
            {...props}
            value={value}
        />
    );
};

export default hot(module)(CreatableAutocomplete);
