import { PlanTypeIds } from 'api/generated/enums';
import { SelectedPlan } from 'api/generated/models';

const plansToHide = [
    PlanTypeIds.Medicaid,
    PlanTypeIds.CHIP,
    PlanTypeIds.Medicare,
    PlanTypeIds.SpouseEmployer,
    PlanTypeIds.ParentEmployer,
    PlanTypeIds.VeteransAffairs,
];

export const hideDeductibleBenefitText = (plans: SelectedPlan[]) =>
    plans.some((plan) => plansToHide.includes(plan.planTypeId));
