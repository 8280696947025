import Typography from '@mui/material/Typography';
import { HouseholdMemberTypes, SurveyTypes } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import GENDERS from 'constants/genders';
import useUserProps from 'hooks/useUserProps';
import isEmpty from 'lodash/isEmpty';
import AddressInfo from 'pages/profile/AddressInfo';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { getEnumNames } from 'utilities';
import { formatDateForDisplay, formatGender } from 'utilities/format';

const HouseholdMemberItem = ({
    address,
    dateOfBirth,
    firstName,
    gender,
    householdMemberTypeId,
    lastName,
    preferredName,
}: ISurveyHouseholdMemberDto) => {
    const { isInIchraPathway, user } = useUserProps();
    const householdMemberType = getEnumNames(HouseholdMemberTypes)[householdMemberTypeId];

    const getMemberLabelAndName = () => {
        let label;
        if (householdMemberType === 'Spouse') {
            label = 'Spouse';
        } else {
            if (user?.surveyTypeToSend === SurveyTypes.ICHRA || isInIchraPathway) {
                label = 'Child';
            } else if (
                user?.surveyTypeToSend === SurveyTypes.Standard &&
                householdMemberTypeId !== HouseholdMemberTypes.Unclaimed
            ) {
                label = 'Dependent';
            } else {
                label = 'Unclaimed';
            }
        }
        return `${label} - ${firstName}`;
    };

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between row text-secondary">
                <Typography variant="h5">{getMemberLabelAndName()}</Typography>
            </div>
            <Row>
                <Col className="p-0">
                    <ContentWithLabel label="Last Name">{lastName}</ContentWithLabel>
                    {preferredName && (
                        <ContentWithLabel label="Preferred First Name">
                            {preferredName}
                        </ContentWithLabel>
                    )}
                    <ContentWithLabel label="Gender">
                        {formatGender(gender as GENDERS)}
                    </ContentWithLabel>
                    <ContentWithLabel label="Date of Birth">
                        {formatDateForDisplay(dateOfBirth)}
                    </ContentWithLabel>
                    {!isEmpty(address) && (
                        <ContentWithLabel label="Address">
                            <AddressInfo data={address} />
                        </ContentWithLabel>
                    )}
                </Col>
            </Row>
        </div>
    );
};
export default hot(module)(HouseholdMemberItem);
