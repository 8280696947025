import Tooltip, { TooltipProps } from 'components/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';

export type IConditionalTooltipProps = TooltipProps & {
    isDisabled: boolean;
};

const ConditionalTooltip = ({ children, isDisabled, ...rest }: IConditionalTooltipProps) => {
    if (isDisabled) {
        return children;
    }

    return (
        <Tooltip {...rest}>
            <span>{children}</span>
        </Tooltip>
    );
};

export default hot(module)(ConditionalTooltip);
