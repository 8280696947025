import {
    GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION,
    getSingleFilerStandardDeduction,
} from 'actions/taxData/getSingleFilerStandardDeduction';
import { HouseholdMemberTypes, UserStatus } from 'api/generated/enums';
import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import Column from 'components/verifiedInfoSummary/Column';
import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { ISurveyReviewState } from 'pages/survey/surveyState';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { filingStatusOptions } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { reduceToObject } from 'utilities';
import { formatCurrency } from 'utilities/format';
import {
    getAllMembersOfType,
    getDisplayFirstName,
    isDependentAndOverStandardDeduction,
    isDependentAndUnderOrEqualToStandardDeduction,
} from 'utilities/household';

const UnclaimedMemberIncomes = ({
    data,
    unclaimedMembers,
}: {
    data: ISurveyReviewState['income'];
    unclaimedMembers: ISurveyHouseholdMemberDto[];
}) => (
    <React.Fragment>
        {unclaimedMembers.map((member) => (
            <ContentWithLabel
                key={member.householdMemberId}
                label={`${getDisplayFirstName(member)}'s Income`}
            >
                {formatCurrency(data[member.householdMemberId])}
            </ContentWithLabel>
        ))}
    </React.Fragment>
);

const filingStatusOptionsFlat = reduceToObject(filingStatusOptions, 'filingStatusId', 'name');

const Income = ({
    data,
    editSection,
    householdMembers,
    year,
}: {
    data: ISurveyReviewState['income'];
    editSection: ((tabIndex: number) => void) | undefined;
    householdMembers: ISurveyHouseholdMemberDto[] | undefined;
    year: number | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const { user } = useUserProps();
    const isRenewal = user?.status === UserStatus.Renewing;
    const { isLoading, singleFilerStandardDeduction } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION),
        singleFilerStandardDeduction: state.singleFilerStandardDeduction,
    }));
    useEffect(() => {
        if (singleFilerStandardDeduction === 0 && year && !isLoading) {
            dispatch(getSingleFilerStandardDeduction(year));
        }
    }, [dispatch, singleFilerStandardDeduction, year, isLoading]);
    const { additionalIncome, deductions, employeeIncome, filingStatus, householdIncome } = data;
    const unclaimedMembers = getAllMembersOfType(
        householdMembers ?? [],
        HouseholdMemberTypes.Unclaimed
    );
    const claimedEmployedHouseholdMembers = householdMembers?.filter(
        (x) =>
            (isRenewal || x.isEmployed) &&
            (x.householdMemberTypeId === HouseholdMemberTypes.Spouse ||
                isDependentAndOverStandardDeduction(
                    x,
                    Number(data[x.householdMemberId]),
                    singleFilerStandardDeduction
                ))
    );

    const dependentsUnderStandardDeduction = householdMembers?.filter(
        (x) =>
            (isRenewal || x.isEmployed) &&
            isDependentAndUnderOrEqualToStandardDeduction(
                x,
                Number(data[x.householdMemberId]),
                singleFilerStandardDeduction
            )
    );

    return (
        <Section onEdit={editSection && (() => editSection(SurveyTabs.Income))} title="Income">
            <Column>
                <ContentWithLabel label="Filing Status">
                    {filingStatusOptionsFlat[filingStatus]}
                </ContentWithLabel>
            </Column>
            <Column isRight>
                <ContentWithLabel label="Total Household Income">
                    {formatCurrency(householdIncome)}
                </ContentWithLabel>
                <ContentWithLabel isSecondary label="Employee" shouldIndentLabel>
                    {formatCurrency(employeeIncome)}
                </ContentWithLabel>
                {claimedEmployedHouseholdMembers?.map((member) => (
                    <ContentWithLabel
                        isSecondary
                        key={member.householdMemberId}
                        label={getDisplayFirstName(member)}
                        shouldIndentLabel
                    >
                        {formatCurrency(data[member.householdMemberId])}
                    </ContentWithLabel>
                ))}
                <ContentWithLabel isSecondary label="Additional Income" shouldIndentLabel>
                    {formatCurrency(additionalIncome)}
                </ContentWithLabel>
                <ContentWithLabel isSecondary label="Deductions" shouldIndentLabel>
                    {formatCurrency(deductions)}
                </ContentWithLabel>
                {dependentsUnderStandardDeduction?.map((member) => (
                    <ContentWithLabel
                        key={member.householdMemberId}
                        label={`${getDisplayFirstName(member)}'s Income`}
                    >
                        {formatCurrency(data[member.householdMemberId])}
                    </ContentWithLabel>
                ))}
                {unclaimedMembers.length > 0 && (
                    <UnclaimedMemberIncomes data={data} unclaimedMembers={unclaimedMembers} />
                )}
            </Column>
        </Section>
    );
};

export default hot(module)(Income);
