import Select from 'components/Select';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';

const getVisiblePages = (page: number, total: number) => {
    // prettier-ignore
    if (total < 6) { //NOSONAR
        return [1, 2, 3, 4, 5].filter((p) => p <= total); //NOSONAR
    } else {
        let middle = [];
        if (page > 2 && page < total - 1) { //NOSONAR
            middle = [page - 1, page, page + 1]; //NOSONAR
        } else if (page <= 2) { //NOSONAR
            middle = [2, 3, 4]; //NOSONAR
        } else {
            middle = [total - 3, total - 2, total - 1]; //NOSONAR
        }
        return [1, ...middle, total];
    }
};

type IPaginationProps = {
    canNextPage: boolean;
    canPreviousPage: boolean;
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
    hideAllPageSizeOption: boolean;
    nextPage: () => void;
    pageCount: number;
    pageIndex: number;
    pageSize: number;
    previousPage: () => void;
    setPageSize: (pageSize: number) => void;
    totalCount: number;
};

const Pagination = ({
    canNextPage,
    canPreviousPage,
    gotoPage,
    hideAllPageSizeOption,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageSize,
    totalCount,
}: IPaginationProps) => {
    const visiblePages = getVisiblePages(pageIndex + 1, pageCount);
    const onPageSizeChange = onChange((s: string) => setPageSize(+s));
    const lowerRowNumber = pageIndex * pageSize + 1;
    const upperRowNumber = Math.min((pageIndex + 1) * pageSize, totalCount);
    const itemsList = [
        { name: 5, value: 5 },
        { name: 10, value: 10 },
        { name: 15, value: 15 },
        { name: 20, value: 20 },
        { name: 25, value: 25 },
        { name: 50, value: 50 },
        { name: 100, value: 100 },
        { name: 'All', value: Number.MAX_SAFE_INTEGER },
    ];
    if (hideAllPageSizeOption) {
        itemsList.pop();
    }
    return (
        <React.Fragment>
            <div className="d-none d-sm-block pl-3"></div>
            <Row className="Table__pagination">
                <Col
                    className="text-center text-sm-left flex-column flex-sm-row row no-gutters align-items-center"
                    sm="6"
                    xs="12"
                >
                    {lowerRowNumber} - {upperRowNumber} of {totalCount}
                    <div className="d-block d-sm-none pb-2"></div>
                    <span>
                        <Select
                            data-cy="page-size"
                            items={itemsList}
                            name="pageSizeOptions"
                            onChange={onPageSizeChange}
                            optionText="name"
                            optionValue="value"
                            size="small"
                            sx={{ ml: { sm: 2 } }}
                            value={pageSize}
                        />
                    </span>
                </Col>
                <Col className="text-center text-sm-right" sm="6" xs="12">
                    <Row className="flex-column flex-sm-row justify-content-sm-end">
                        <div className="mb-1">
                            <button
                                className="Table__pageButton"
                                disabled={!canPreviousPage}
                                onClick={previousPage}
                            >
                                Previous
                            </button>
                        </div>
                        <div className="mb-1">
                            {visiblePages.map((visiblePage, index, array) => (
                                <button
                                    className={
                                        pageIndex + 1 === visiblePage
                                            ? 'Table__pageButton Table__pageButton--active'
                                            : 'Table__pageButton'
                                    }
                                    key={visiblePage}
                                    onClick={() => gotoPage(visiblePage - 1)}
                                >
                                    {Number(array[index - 1]) + 1 < visiblePage
                                        ? `...${visiblePage}`
                                        : visiblePage}
                                </button>
                            ))}
                        </div>
                        <div className="mb-1">
                            <button
                                className="Table__pageButton"
                                data-cy="pagination-next"
                                disabled={!canNextPage}
                                onClick={nextPage}
                            >
                                Next
                            </button>
                        </div>
                    </Row>
                </Col>
            </Row>
            <div className="d-none d-sm-block pr-3"></div>
        </React.Fragment>
    );
};

export default hot(module)(Pagination);
