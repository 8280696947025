import React from 'react';
import { hot } from 'react-hot-loader';

type IDiagonalStrikethroughLineProps = {
    isEnabled?: boolean;
};

/**
Component will have red strikethrough line over children when enabled
 */
const DiagonalStrikethroughLine: React.FC<IDiagonalStrikethroughLineProps> = ({
    children,
    isEnabled,
}) => {
    if (!isEnabled) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return (
        <div
            style={{
                background:
                    'linear-gradient(to right top, transparent 47.75%, red 49.5%, red 50.5%, transparent 52.25%)',
                display: 'inline-block',
                height: '100%',
                top: 0,
                width: '100%',
            }}
        >
            {children}
        </div>
    );
};

export default hot(module)(DiagonalStrikethroughLine);
