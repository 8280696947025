import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';

export type TooltipProps = MuiTooltipProps;

const Tooltip = ({ children, ...rest }: TooltipProps) => (
    <MuiTooltip {...rest}>
        {/*
            The wrapping span is essential. Without it the app will break and have console errors like
            `Cannot read properties of undefined (reading 'addEventListener')`
         */}
        <span>{children}</span>
    </MuiTooltip>
);
export default hot(module)(Tooltip);
