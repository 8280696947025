import Typography from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

const SuperScript = ({ children }: React.PropsWithChildren<unknown>) => (
    <Typography color="secondary" component="span">
        <sup>{children}</sup>
    </Typography>
);

export default hot(module)(SuperScript);
