import { clearSelectedPlans } from 'actions/clear';
import { GET_SELECTED_PLANS_ACTION, getSelectedPlans } from 'actions/selectedPlan/getSelectedPlans';
import { SET_USER_STATUS_ACTION, setUserStatus } from 'actions/user/setUserStatus';
import { PlanStateIds, UserStatus } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import Checkbox from 'components/Checkbox';
import ConfirmationModal from 'components/ConfirmationModal';
import DateTextField from 'components/DateTextField';
import Skeleton from 'components/Skeleton';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';
import useUserProps from 'hooks/useUserProps';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';
import { hasValue } from 'utilities/index';

const schema = object({
    terminationDate: string().isValidDate(true),
});

const OffBoardingConfirmationModal = ({
    isCurrent,
    onNoClick,
    user,
}: {
    isCurrent: boolean;
    onNoClick: () => void;
    user: IUser | undefined;
}) => {
    const { hasUserIdUrlParam } = useUserProps();
    const { firstName, lastName, userId } = user ?? {};
    const dispatch = useThunkDispatch();

    const [terminationDate, setTerminationDate] = useState('');
    const { peopleState, selectedPlans, selectedYear, showActivity } = useSelector(
        (state: AppStore) => ({
            peopleState: state.peopleState,
            selectedPlans: state.selectedPlans,
            selectedYear: +state.profileState.selectedYear,
            showActivity: hasApiActivity(state, SET_USER_STATUS_ACTION, GET_SELECTED_PLANS_ACTION),
        })
    );
    const [sendMemberConfirmationEmail, setsendMemberConfirmationEmail] = useState(false);
    const { errors, validate } = useForm(schema);

    useEffect(() => {
        dispatch(getSelectedPlans(userId, new Date().getFullYear()));

        return () => {
            dispatch(clearSelectedPlans());
        };
    }, [dispatch, userId]);

    const hasEnrolledEffectivePlans = selectedPlans.some((sp) =>
        [PlanStateIds.Enrolled, PlanStateIds.Effective].includes(sp.planStateId)
    );

    useEffect(() => {
        if (hasEnrolledEffectivePlans) {
            setsendMemberConfirmationEmail(true);
        }
    }, [hasEnrolledEffectivePlans]);

    const onYesClick = useCallback(async () => {
        const { isValid } = await validate({ terminationDate });

        if (isValid) {
            const toastSuccessMessage = 'Successfully set user to OffBoarding status';
            await dispatch(
                setUserStatus(
                    user?.userId,
                    UserStatus.OffBoarding,
                    isCurrent,
                    {
                        toastSuccessMessage,
                    },
                    sendMemberConfirmationEmail,
                    terminationDate
                )
            );
            if (!hasUserIdUrlParam && hasValue(user?.teamId)) {
                await dispatch(
                    listPagedUserProfiles((user as IUser).teamId, selectedYear, peopleState)
                );
            }
            onNoClick();
        }
    }, [
        dispatch,
        hasUserIdUrlParam,
        isCurrent,
        onNoClick,
        peopleState,
        selectedYear,
        sendMemberConfirmationEmail,
        terminationDate,
        user,
        validate,
    ]);

    const confirmationEmailHelperText = hasEnrolledEffectivePlans
        ? 'This cannot be changed because the member is currently enrolled in individual benefits'
        : 'This member is not currently enrolled in any individual benefits.';

    return (
        <ConfirmationModal
            body={
                <div>
                    <p>
                        This will start a process to off-board {firstName} {lastName} from your
                        organization. This action should only be used in case of a separation of
                        employment. If the member simply does not need Remodel Health services, but
                        they are still an employee, &quot;Waived&quot; is the more appropriate
                        status for the Member.
                    </p>

                    <p>
                        The Team Member will still be able to login to Remodel Health and this does
                        NOT affect their health care coverage.
                    </p>

                    <p>
                        If you confirm, your Remodel Health Care Rep will be in touch with you and
                        the Team Member to discuss next steps.
                    </p>
                    <Skeleton count={2} height="54px" isEnabled={showActivity}>
                        <DateTextField
                            autoFocus
                            errors={errors?.terminationDate}
                            helperText="This is the date the user will stop having benefits managed by Remodel Health"
                            label="Termination Date"
                            onChange={onChange(setTerminationDate)}
                            value={terminationDate}
                        />
                        <Checkbox
                            checked={sendMemberConfirmationEmail}
                            disabled={hasEnrolledEffectivePlans || showActivity}
                            helperText={`An email will be sent to the member summarizing this action. ${confirmationEmailHelperText}`}
                            label="Send Confirmation Email to Member"
                            name="Send Confirmation Email to Member"
                            onClick={() =>
                                setsendMemberConfirmationEmail(!sendMemberConfirmationEmail)
                            }
                        />
                    </Skeleton>
                </div>
            }
            onNoClick={onNoClick}
            onYesClick={onYesClick}
            showActivity={showActivity}
            title="Confirm"
        />
    );
};

export default hot(module)(OffBoardingConfirmationModal);
