import AboutYou from 'components/verifiedInfoSummary/AboutYou';
import Column from 'components/verifiedInfoSummary/Column';
import Contact from 'components/verifiedInfoSummary/Contact';
import Coverage from 'components/verifiedInfoSummary/Coverage';
import HouseholdMembers from 'components/verifiedInfoSummary/HouseholdMembers';
import IchraCoverage from 'components/verifiedInfoSummary/IchraCoverage';
import Income from 'components/verifiedInfoSummary/Income';
import Qualitative from 'components/verifiedInfoSummary/Qualitative';
import { ISurveyReviewState } from 'pages/survey/surveyState';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const VerifiedInfoSummary = ({
    data: {
        contact,
        coverage,
        household: { members },
        income,
        incomeYear,
        member,
        qualitative,
    },
    editSection,
    hideQualitative = false,
    isIchra = false,
    includeFaithBasedQuestionOverride,
}: {
    data: ISurveyReviewState;
    editSection?: (tabIndex: number) => void;
    hideIncome?: boolean;
    hideQualitative?: boolean;
    includeFaithBasedQuestionOverride?: boolean;
    isIchra?: boolean;
}) => (
    <React.Fragment>
        <Row noGutters>
            <Column md="6">
                <AboutYou {...member} editSection={editSection} />
            </Column>
            <Column isRight md="6">
                <Contact {...contact} editSection={editSection} />
            </Column>
        </Row>
        <Row>
            <Col>
                <HouseholdMembers editSection={editSection} householdMembers={members} />
            </Col>
        </Row>
        <Row>
            <Col>
                {isIchra ? (
                    <IchraCoverage
                        {...coverage}
                        editSection={editSection}
                        householdMembers={[
                            {
                                ...coverage,
                                ...member,
                            },
                            ...(members ?? []),
                        ]}
                    />
                ) : (
                    <Coverage
                        {...coverage}
                        editSection={editSection}
                        householdMembers={[
                            {
                                ...coverage,
                                ...member,
                            },
                            ...(members ?? []),
                        ]}
                        includeFaithBasedQuestionOverride={includeFaithBasedQuestionOverride}
                        isIchra={isIchra}
                    />
                )}
            </Col>
        </Row>
        {!isIchra && (
            <Row>
                <Col>
                    <Income
                        data={income}
                        editSection={editSection}
                        householdMembers={members}
                        year={incomeYear}
                    />
                </Col>
            </Row>
        )}
        {!hideQualitative && (
            <Row>
                <Col>
                    <Qualitative {...qualitative} editSection={editSection} />
                </Col>
            </Row>
        )}
    </React.Fragment>
);

export default hot(module)(VerifiedInfoSummary);
