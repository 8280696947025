import Checkbox from '@mui/material/Checkbox';
import { isEqual } from 'lodash';
import React from 'react';
import {
    ActionType,
    Cell,
    Column,
    Hooks,
    ReducerTableState,
    Row,
    RowPropGetter,
    TableInstance,
    TableState,
} from 'react-table';
import { isTrue } from 'utilities';

const selectionListener = <T extends object>(callback?: (selectedRows: Row<T>[]) => void) => (
    newState: TableState<T>,
    _action: ActionType,
    previousState?: TableState<T>,
    instance?: TableInstance<T>
): ReducerTableState<T> => {
    if (
        callback &&
        previousState?.selectedRowIds &&
        !isEqual(newState.selectedRowIds, previousState?.selectedRowIds)
    ) {
        const selectedRowIds = Object.keys(newState.selectedRowIds).filter((k) =>
            isTrue(newState.selectedRowIds[k])
        );
        const selectedRows = selectedRowIds
            .map((id) => instance?.rowsById[id])
            .filter((x) => x !== undefined) as Row<T>[];

        queueMicrotask(() => callback(selectedRows));
    }
    return newState;
};

export const addSelectionColumnHook = <T extends object>(
    callback?: (selectedRows: Row<T>[]) => void
) => (hooks: Hooks<T>) => {
    const selectionColumn: Column<T> = {
        Cell: ({ row }: Cell<T>) => (
            <div>
                <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
        ),
        className: 'justify-content-center',
        disableResizing: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
        ),
        headerClassName: 'justify-content-center',
        id: 'selection',
        width: 30,
    };

    hooks.visibleColumns.push((columns) => [selectionColumn, ...columns]);

    const getRowStyles: RowPropGetter<T> = (props, { row }) => [
        props,
        {
            style: {
                background: row.isSelected ? '#e3eaef' : undefined,
            },
        },
    ];
    hooks.getRowProps.push(getRowStyles);

    hooks.stateReducers.push(selectionListener(callback));
};
