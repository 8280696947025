import MuiAutocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import { clearMultiSelect } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import useThunkDispatch from 'hooks/useThunkDispatch';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

export type IAutocompleteProps<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> & {
    search?: (
        query: string
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
};

export const MIN_CHARACTERS_TO_SEARCH = 3;
const TYPE_AT_LEAST_TEXT = `Type at least ${MIN_CHARACTERS_TO_SEARCH} characters to search...`;
const NO_RESULTS_FOUND_TEXT = 'No results found...';
const SEARCH_DELAY_IN_MILLISECONDS = 750;
const Autocomplete = <
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    onInputChange: onInputChangeParent,
    search,
    ...props
}: IAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
    const dispatch = useThunkDispatch();
    const [noOptionsText, setNoOptionsText] = useState(
        search ? TYPE_AT_LEAST_TEXT : NO_RESULTS_FOUND_TEXT
    );
    useEffect(
        () => () => {
            dispatch(clearMultiSelect());
        },
        [dispatch]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(async (query) => {
            if (search) {
                dispatch(search(query));
            }
        }, SEARCH_DELAY_IN_MILLISECONDS),
        [dispatch, search]
    );
    const onInputChange: IAutocompleteProps<T>['onInputChange'] = useCallback(
        async (...args: [event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason]) => {
            const [, value, reason] = args;
            onInputChangeParent?.(...args);
            if (search) {
                if (!hasValue(value) || value?.trim().length < MIN_CHARACTERS_TO_SEARCH) {
                    setNoOptionsText(TYPE_AT_LEAST_TEXT);
                    dispatch(clearMultiSelect());
                } else if (reason === 'input') {
                    await debouncedSearch(value);
                    setTimeout(() => {
                        if (!hasValue(props.options) || props.options.length === 0) {
                            setNoOptionsText(NO_RESULTS_FOUND_TEXT);
                        }
                    }, SEARCH_DELAY_IN_MILLISECONDS);
                }
            }
        },
        [debouncedSearch, dispatch, onInputChangeParent, props.options, search]
    );
    return (
        <MuiAutocomplete noOptionsText={noOptionsText} onInputChange={onInputChange} {...props} />
    );
};

export default hot(module)(Autocomplete);
