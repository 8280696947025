import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
    BULK_SET_ALLOW_RECURRING_EXPENSES_ACTION,
    bulkSetAllowRecurringExpenses,
} from 'actions/team/bulkSetAllowRecurringExpenses';
import ConfirmationModal from 'components/ConfirmationModal';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const SetAllowRecurringExpensesForTeamModal = ({ close }: { close: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { selectedYear, showActivity } = useSelector((state: AppStore) => ({
        selectedYear: +state.profileState.selectedYear,
        showActivity: hasApiActivity(state, BULK_SET_ALLOW_RECURRING_EXPENSES_ACTION),
    }));
    const [allowRecurringExpenses, setAllowRecurringExpenses] = useState(true);
    const onYesClickSetAllowRecurringExpenses = useCallback(async () => {
        await dispatch(
            bulkSetAllowRecurringExpenses(teamId, +selectedYear, allowRecurringExpenses)
        );
        close();
    }, [allowRecurringExpenses, close, dispatch, selectedYear, teamId]);
    const body = useMemo(
        () => (
            <React.Fragment>
                <Stack gap={2}>
                    <Typography mb={3} mt={1} mx={1} variant="body2">
                        Please select the value you would like to set{' '}
                        <strong>Allow Recurring Expenses</strong> to for all members for{' '}
                        <strong>{selectedYear}</strong>.
                    </Typography>
                    <Select
                        data-cy="set-allow-recurring-expenses-dropdown"
                        items={yesOrNo}
                        label="Allow Recurring Expenses?"
                        onChange={onChange(setAllowRecurringExpenses)}
                        optionText="text"
                        optionValue="value"
                        value={allowRecurringExpenses.toString()}
                    />
                </Stack>
            </React.Fragment>
        ),
        [allowRecurringExpenses, selectedYear]
    );

    return (
        <ConfirmationModal
            body={body}
            noButtonText="Cancel"
            onNoClick={close}
            onYesClick={onYesClickSetAllowRecurringExpenses}
            showActivity={showActivity}
            title="Set Allow Recurring Expenses For All Members on Team"
            yesButtonText="Confirm"
        />
    );
};

export default hot(module)(SetAllowRecurringExpensesForTeamModal);
