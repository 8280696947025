import Stack, { StackProps } from '@mui/material/Stack';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import TextField from 'components/TextField';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

export const HeaderLabel = styled.label`
    margin: 7px 10px 7px 0px;
`;

type ITableHeaderProps = {
    OverrideActionButtons?: ReactNode;
    OverrideLeftSide?: ReactNode;
    children?: React.ReactNode;
    disableDropdown?: boolean;
    dropdownWhenLengthGreaterThan?: number;
    items?: IActionButtonItems;
    label?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    search?: string;
    stackDirection?: StackProps['direction'];
};

const TableHeader = ({
    children,
    disableDropdown,
    dropdownWhenLengthGreaterThan,
    items,
    label,
    onChange,
    OverrideActionButtons,
    OverrideLeftSide,
    search,
    stackDirection = { sm: 'row', xs: 'column' },
}: ITableHeaderProps) => (
    <Stack direction={stackDirection} flexWrap="wrap" gap={2} justifyContent="space-between">
        {OverrideLeftSide ? (
            OverrideLeftSide
        ) : (
            <Stack alignItems="center" direction={stackDirection} gap={2} textAlign="center">
                {label && <HeaderLabel>{label}</HeaderLabel>}
                {search !== undefined && ( // do not use hasValue here. we still want to show this if `search` is an empty string
                    <TextField
                        data-cy="search"
                        InputProps={{ sx: { minWidth: 200 } }}
                        name="search"
                        onChange={onChange}
                        placeholder="Search"
                        size="small"
                        sx={{ minWidth: 200 }}
                        type="search"
                        value={search}
                    />
                )}

                {children}
            </Stack>
        )}
        {(items || OverrideActionButtons) && (
            <Stack alignItems="center" direction={stackDirection} gap={2} textAlign="center">
                {OverrideActionButtons ? (
                    OverrideActionButtons
                ) : (
                    <ActionButtons
                        disabled={disableDropdown}
                        dropdownWhenLengthGreaterThan={
                            dropdownWhenLengthGreaterThan ?? items?.length
                        }
                        items={items as IActionButtonItems}
                    />
                )}
            </Stack>
        )}
    </Stack>
);

export default hot(module)(TableHeader);
