import Spinner from 'components/Spinner';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader';

export type IConfirmTextProps = {
    'data-cy'?: string;
    as?: React.ElementType;
    asProps?: object;
    children: React.ReactNode;
    confirmText?: React.ReactNode;
    isDisabled?: boolean;
    isLoading?: boolean;
    onClick: () => void;
    onComplete?: () => void;
};
const ConfirmText = ({
    as: As = 'span',
    asProps = {},
    children,
    confirmText = 'Are you sure?',
    'data-cy': dataCy,
    isDisabled,
    isLoading = false,
    onClick: parentOnClick,
    onComplete,
}: IConfirmTextProps) => {
    const ref = useRef<HTMLElement>(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isLoadingDelayed, setIsLoadingDelayed] = useState(false);
    const [minWidth, setMinWidth] = useState(0);
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (isLoadingDelayed) {
            timeout = setTimeout(() => {
                setIsLoadingDelayed(false);
                setIsConfirming(false);
                onComplete?.();
            }, 250);
        }
        return () => clearTimeout(timeout);
    }, [isLoadingDelayed, onComplete]);
    useLayoutEffect(() => {
        if (ref?.current && ref.current.offsetWidth > minWidth) {
            setMinWidth(ref.current.offsetWidth);
        }
    }, [minWidth]);
    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isConfirming) {
                setIsLoadingDelayed(true);
                parentOnClick();
            } else {
                setIsConfirming(true);
                // The following window.dispatchEvent is a kind of hack to fix when
                // we have scenarios where the text in this component is really long and switching back and forth between confirming and not
                // Found fix here: https://github.com/mui-org/material-ui/issues/10595#issuecomment-403130358
                window.dispatchEvent(new CustomEvent('resize'));
            }
        },
        [isConfirming, parentOnClick]
    );
    const onMouseLeave = useCallback(() => {
        setIsConfirming(false);
        window.dispatchEvent(new CustomEvent('resize'));
    }, []);
    return (
        <As
            data-cy={dataCy}
            disabled={isLoading || isDisabled}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            ref={ref}
            style={{ minWidth }}
            {...asProps}
        >
            {isLoading || isLoadingDelayed ? (
                <Spinner size="20" />
            ) : isConfirming ? (
                confirmText
            ) : (
                children
            )}
        </As>
    );
};

export default hot(module)(ConfirmText);
