import { dispatchWrapper } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { IPasswordInputs } from 'components/passwordInputs/passwordInputsState';

export const CLEAR_PASSWORD_INPUTS = 'CLEAR_PASSWORD_INPUTS';
export const clearPasswordInputs = () => dispatchWrapper(CLEAR_PASSWORD_INPUTS);

export const UPDATE_PASSWORD_INPUTS = 'UPDATE_PASSWORD_INPUTS';
export const updatePasswordInputs = (passwordInputs: IPasswordInputs) => (
    dispatch: AppStoreThunkDispatch
) =>
    dispatch({
        data: passwordInputs,
        type: UPDATE_PASSWORD_INPUTS,
    });
