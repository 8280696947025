/* eslint-disable react/display-name */
import Icon from 'components/Icon';
import React from 'react';
import { Cell, Column, Hooks, Row } from 'react-table';

export const addExpansionColumnHook = <T extends object>(
    canExpandRow: (row: Row<T>) => boolean
) => (hooks: Hooks<T>) => {
    const expansionColumn: Column<T> = {
        Cell: ({ row }: Cell<T>) =>
            canExpandRow(row) && (
                <div {...row.getToggleRowExpandedProps()}>
                    <Icon size="1.5">{row.isExpanded ? 'chevron-down' : 'chevron-right'}</Icon>
                </div>
            ),
        disableResizing: true,
        id: 'expansion',
        width: 20,
    };

    hooks.visibleColumns.push((columns) => [expansionColumn, ...columns]);
};
