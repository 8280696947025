import EditableNumberTextField, {
    IEditableNumberTextFieldProps,
} from 'components/EditableNumberTextField';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { hot } from 'react-hot-loader';

const EditableSocialSecurityTextField = <T,>(props: IEditableNumberTextFieldProps<T>) => (
    <EditableNumberTextField
        allowNegative={false}
        data-cy={kebabCase(props.label as string)}
        data-private="lipsum"
        format="###-##-####"
        isNumericString={false}
        placeholder="XXX-XX-XXXX"
        thousandSeparator={false}
        {...props}
    />
);
export default hot(module)(EditableSocialSecurityTextField);
