import { AppStoreThunkDispatch } from 'actions/commonAction';
import { INote } from 'api/generated/models';

export const HIDE_NOTE_MODAL = 'HIDE_NOTE_MODAL';
export const hideNoteModal = () => (dispatch: AppStoreThunkDispatch) =>
    dispatch({
        data: { noteToEdit: null },
        type: HIDE_NOTE_MODAL,
    });

export const SHOW_NOTE_MODAL = 'SHOW_NOTE_MODAL';
export const showNoteModal = (noteToEdit: INote) => (dispatch: AppStoreThunkDispatch) =>
    dispatch({
        data: { noteToEdit },
        type: SHOW_NOTE_MODAL,
    });
