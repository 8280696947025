import Tooltip, { TooltipProps } from 'components/Tooltip';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

const UnderlinedContentWithTooltip = (
    props: Omit<TooltipProps, 'children'> & { children: ReactNode }
) => (
    <Tooltip {...props}>
        <span style={{ borderBottom: 'dotted thin' }}>{props.children}</span>
    </Tooltip>
);

export default hot(module)(UnderlinedContentWithTooltip);
