import ProfileAttribute from 'components/ProfileAttribute';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

export const SubtextAttribute = ({
    label,
    subtext,
    value,
}: {
    label: string;
    subtext: string | undefined;
    value: React.ReactNode;
}) => (
    <ProfileAttribute label={label} textToCopy={value}>
        <div className="text-right">
            {value}
            {hasValue(subtext) && <div className="text-muted">{subtext}</div>}
        </div>
    </ProfileAttribute>
);

export default hot(module)(SubtextAttribute);
