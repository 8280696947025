import Column from 'components/verifiedInfoSummary/Column';
import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import useTeamProps from 'hooks/useTeamProps';
import { getShowQualitativeOpenEndedQuestions } from 'pages/survey/surveySelectors';
import { ISurveyReviewState } from 'pages/survey/surveyState';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

type IQualitativeProps = ISurveyReviewState['qualitative'] & {
    editSection: ((tabIndex: number) => void) | undefined;
};

const Qualitative = ({
    customQuestionAnswers,
    editSection,
    feedback,
    otherBenefits,
    rankCoverage,
}: IQualitativeProps) => {
    const teamProps = useTeamProps();
    const showQualitativeOpenEndedQuestions = getShowQualitativeOpenEndedQuestions(teamProps);
    const { customQuestions } = useSelector((state: AppStore) => ({
        customQuestions: state.customQuestions,
    }));

    const questions = customQuestions.map((q, i) => (
        <ContentWithLabel key={q.id} label={q.text} shouldTruncateValue>
            <div className={`text-truncate ${customQuestions.length - 1 === i ? '' : 'pb-1'}`}>
                {customQuestionAnswers[q.id]}
            </div>
        </ContentWithLabel>
    ));

    const renderSelected = <T extends { isSelected?: boolean }>(
        values: T[] | undefined,
        render: (values: T[]) => ReactNode
    ) => {
        if (values?.some((x) => x.isSelected)) {
            return render(values.filter((x) => x.isSelected));
        }
        return 'None Selected';
    };

    return (
        <Section
            onEdit={editSection && (() => editSection(SurveyTabs.Qualitative))}
            title="Benefits Questions"
        >
            <Column>
                <ContentWithLabel label="Rank Importance">
                    {renderSelected(rankCoverage, (values) =>
                        values.map((x) => (
                            <div className="text-left" key={x.text}>
                                {x.text}
                            </div>
                        ))
                    )}
                </ContentWithLabel>
            </Column>
            <Column isRight>
                <ContentWithLabel label="Other Benefits You Would Like">
                    {renderSelected(otherBenefits, (values) =>
                        values.map((x) => x.text).join(', ')
                    )}
                </ContentWithLabel>
                {showQualitativeOpenEndedQuestions && (
                    <React.Fragment>
                        <ContentWithLabel label="Feedback" shouldTruncateValue>
                            <div className="text-truncate pb-1">
                                {feedback.likeCurrentInsurance}
                            </div>
                            <div className="text-truncate pb-1">
                                {feedback.improveHealthInsurance}
                            </div>
                            <div
                                className={`text-truncate ${customQuestions.length ? 'pb-1' : ''}`}
                            >
                                {feedback.anyFeedback}
                            </div>
                        </ContentWithLabel>
                        {questions}
                    </React.Fragment>
                )}
            </Column>
        </Section>
    );
};

export default hot(module)(Qualitative);
