import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { closeUnderConstructionModal } from 'components/underConstructionModal/underConstructionAction';
import { push } from 'connected-react-router';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH } from 'routers/routes';

type IUnderConstructionModalProps = RouteComponentProps & {
    description?: string;
    header?: string;
    useDashboard?: boolean;
};

const UnderConstructionModal = ({
    description = 'For an optimized experience, please view the website from a desktop or laptop computer.',
    header = 'Shopping is not currently supported on mobile',
    history,
    useDashboard = true,
}: IUnderConstructionModalProps) => {
    const [count, setCount] = useState(1);
    const { isOpen } = useSelector((state: AppStore) => ({
        isOpen: state.underConstructionModalState.isOpen,
    }));
    const dispatch = useThunkDispatch();
    const close = useCallback(() => dispatch(closeUnderConstructionModal()), [dispatch]);
    const submit = useCallback(
        () => (useDashboard ? dispatch(push(DASHBOARD_PATH)) : history.goBack()),
        [dispatch, history, useDashboard]
    );
    return (
        <Modal onHide={close} scrollable show={isOpen}>
            <Modal.Body className="text-center px-7">
                {count > 5 && (
                    <img
                        alt=""
                        src="https://media.giphy.com/media/ZTans30ONaaIM/giphy.gif"
                        width="150"
                    />
                )}
                <i
                    className="dripicons-code text-primary h4"
                    onClick={() => setCount(count + 1)}
                ></i>
                <Typography color={(theme) => theme.palette.primary.main} mb={4} variant="h4">
                    {header}
                </Typography>
                <p className="mb-4">{description}</p>
            </Modal.Body>
            <Modal.Footer className="centered border-0">
                <Row className="justify-content-center">
                    <Button onClick={submit} type="submit">
                        {useDashboard ? 'Back to Dashboard' : 'Go back'}
                    </Button>
                    <div className="w-100 my-1" />
                    <Button color="secondary" onClick={close} variant="text">
                        Proceed Anyway
                    </Button>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(withRouter(UnderConstructionModal));
