import Stack from '@mui/material/Stack';
import { TEXT_TO_MATCH } from 'components/teamBenefitActionButtons/TeamBenefitTermDetailsModal';
import TextField from 'components/TextField';
import UnselectableText from 'components/UnselectableText';
import React from 'react';
import { hot } from 'react-hot-loader';

type ITeamBenefitTypedConfirmationProps = {
    confirmationText: string;
    errors: string[] | undefined;
    onFormChange: ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
};

const TeamBenefitTypedConfirmation = ({
    confirmationText,
    errors,
    onFormChange,
}: ITeamBenefitTypedConfirmationProps) => (
    <Stack alignItems="center" direction="column" spacing={2} sx={{ mb: 2 }}>
        <span>
            You&apos;ve edited part of the Benefit Term Details. This will recalculate costs for all
            members for this benefit term, which will impact any unlocked payroll reports.
        </span>
        <span>
            Please type the phrase <UnselectableText as="strong">{TEXT_TO_MATCH}</UnselectableText>{' '}
            exactly as it appears to confirm this action.
        </span>
        <TextField
            data-cy="confirm-recalculate-costs"
            defaultValue={confirmationText}
            errors={errors}
            onChange={onFormChange}
        />
    </Stack>
);

export default hot(module)(TeamBenefitTypedConfirmation);
