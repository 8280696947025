import { EditSocialSecurityNumber, ViewSocialSecurityNumber } from 'api/generated/permissions';
import EditableAttribute, {
    IEditableAttributeProps,
    IValueType,
} from 'components/EditableAttribute';
import { IEditableNumberTextFieldProps } from 'components/EditableNumberTextField';
import EditableSocialSecurityTextField from 'components/EditableSocialSecurityTextField';
import useUserProps from 'hooks/useUserProps';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue, invalidSsn } from 'utilities';
import { string } from 'yup';

const formatSocialSecurityNumber = (socialSecurityNumber: unknown) => {
    if (typeof socialSecurityNumber === 'string') {
        const cleaned = (socialSecurityNumber ?? '').removeNonNumericCharacters();
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`; // NOSONAR
        }
    }
    return undefined;
};

type IEditableSocialSecurityAttributeProps<T> = Omit<
    IEditableNumberTextFieldProps<T>,
    'validationSchema'
> &
    Omit<IEditableAttributeProps, 'children'> & {
        userId: string | undefined;
        validationSchema?: IEditableNumberTextFieldProps<T>['validationSchema'];
    };

const EditableSocialSecurityAttribute = <T,>({
    emptyValueMessage,
    footer,
    formatter: formatterParent,
    hrClassName,
    infoTooltip,
    label,
    readonly: readonlyParent,
    readOnly: readOnlyParent,
    userId,
    ...props
}: IEditableSocialSecurityAttributeProps<T>) => {
    const userProps = useUserProps();
    const { canViewSsn, readonly } = useSelector((state: AppStore) => ({
        canViewSsn:
            state.login.up.includes(ViewSocialSecurityNumber) || userProps.userId === userId,
        readonly: !state.login.up.includes(EditSocialSecurityNumber),
    }));
    const [isOver, setIsOver] = useState(false);
    const validationSchema = string()
        .test(
            'validSsn',
            ({ label: _label }) => `${_label} is invalid`,
            (value) => !invalidSsn(value)
        )
        .label('Social Security Number');
    const formatter = (unFormattedValue: IValueType) =>
        isOver || !hasValue(unFormattedValue)
            ? formatSocialSecurityNumber(unFormattedValue)
            : 'XXX-XX-XXXX';
    if (!canViewSsn) {
        return <React.Fragment />;
    }
    return (
        <span onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)}>
            <EditableAttribute
                data-private
                emptyValueMessage={emptyValueMessage}
                footer={footer}
                formatter={formatterParent ?? formatter}
                hrClassName={hrClassName}
                infoTooltip={infoTooltip}
                label={label}
                readonly={readonly || readonlyParent}
                {...props}
            >
                {({ onBlur }) => (
                    <EditableSocialSecurityTextField
                        readOnly={readonly || readOnlyParent}
                        validationSchema={validationSchema}
                        {...props}
                        onBlur={onBlur}
                    />
                )}
            </EditableAttribute>
        </span>
    );
};

export default hot(module)(EditableSocialSecurityAttribute);
