import { Chip } from '@mui/material';
import { IUser } from 'api/generated/models';
import { EditUserVerifiedInfo, ViewUsers } from 'api/generated/permissions';
import ConditionalTooltip from 'components/ConditionalTooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import styled from 'styled-components';
import { hasValue } from 'utilities';

const TootlipContainer = styled.div`
    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: left;
`;

const NeedsFollowUpPill = ({ user }: { user: IUser | undefined }) => {
    const { canEditVerifiedInfo, canViewUsers } = useSelector((state: AppStore) => ({
        canEditVerifiedInfo: hasSomePermissions(state, EditUserVerifiedInfo),
        canViewUsers: hasSomePermissions(state, ViewUsers),
    }));

    if (!canViewUsers || !user?.needsFollowUp) {
        return <React.Fragment />;
    }

    const tooltipContent = <TootlipContainer>{user?.followUpNote}</TootlipContainer>;

    return (
        <ConditionalTooltip
            isDisabled={!canEditVerifiedInfo || !hasValue(user?.followUpNote)}
            title={tooltipContent}
        >
            <Chip color="secondary" label="Needs Follow-up" size="small" sx={{ ml: 1 }} />
        </ConditionalTooltip>
    );
};

export default hot(module)(NeedsFollowUpPill);
