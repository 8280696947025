import { HouseholdMemberTypes, PlanTypeIds } from 'api/generated/enums';
import { IHouseholdMember, ISelectedPlan, IUser } from 'api/generated/models';
import HouseholdMemberInput from 'components/householdMemberInput/HouseholdMemberInput';
import InformationIconTooltip from 'components/InformationIconTooltip';
import { updateSelectedHouseholdMemberIds } from 'components/planModal/planModalActions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const HouseholdMemberPlanInputWrapper = ({
    householdMembers,
    isDisabled,
    label,
}: {
    householdMembers: (IHouseholdMember | IUser | undefined)[];
    isDisabled: boolean;
    label: string;
}) => {
    const dispatch = useThunkDispatch();
    const { isMedicaidOrMedicare, selectedHouseholdMemberIds } = useSelector((state: AppStore) => ({
        isMedicaidOrMedicare: [PlanTypeIds.Medicaid, PlanTypeIds.Medicare].includes(
            state.planModalState.planInputs.planType
        ),
        selectedHouseholdMemberIds: state.planModalState.selectedHouseholdMemberIds,
    }));
    const onChange = useCallback(
        (newSelectedMemberIds) => dispatch(updateSelectedHouseholdMemberIds(newSelectedMemberIds)),
        [dispatch]
    );
    return (
        <HouseholdMemberInput
            disableMultiSelect={isMedicaidOrMedicare}
            householdMembers={householdMembers}
            isDisabled={isDisabled}
            label={
                <React.Fragment>
                    {label}{' '}
                    {isMedicaidOrMedicare && (
                        <InformationIconTooltip title="Only one member may be selected because Medicare and Medicaid applications are filed separately" />
                    )}
                </React.Fragment>
            }
            onChange={onChange}
            selectedHouseholdMembers={selectedHouseholdMemberIds}
        />
    );
};

const HouseholdMemberPlanInput = ({ planToEdit }: { planToEdit: ISelectedPlan | undefined }) => {
    const dispatch = useThunkDispatch();
    const isEdit = !!planToEdit?.selectedPlanId;
    const {
        claimedHouseholdMembersForSelectedYear,
        unclaimedHouseholdMembersForSelectedYear,
    } = useSelector((state: AppStore) => ({
        claimedHouseholdMembersForSelectedYear: [
            state.userProfile.user,
            ...state.householdMembers.filter(
                (hhm) =>
                    hhm.householdMemberTypeId !== HouseholdMemberTypes.Unclaimed &&
                    hhm.haveDataForYear
            ),
        ],
        unclaimedHouseholdMembersForSelectedYear: [
            ...state.householdMembers.filter(
                (hhm) =>
                    hhm.householdMemberTypeId === HouseholdMemberTypes.Unclaimed &&
                    hhm.haveDataForYear
            ),
        ],
    }));
    useEffect(() => {
        if (planToEdit) {
            let initialSelectedHouseholdMemberIds = planToEdit.householdMembersCovered;
            if (planToEdit.isPrimaryCovered) {
                initialSelectedHouseholdMemberIds = [
                    ...(initialSelectedHouseholdMemberIds ?? []),
                    planToEdit.userId,
                ];
            }
            dispatch(updateSelectedHouseholdMemberIds(initialSelectedHouseholdMemberIds));
        }
    }, [dispatch, planToEdit]);
    const hasUnclaimed = unclaimedHouseholdMembersForSelectedYear.length > 0;
    return (
        <React.Fragment>
            <HouseholdMemberPlanInputWrapper
                householdMembers={claimedHouseholdMembersForSelectedYear}
                isDisabled={isEdit}
                label={`Household Members${hasUnclaimed ? ' - Claimed on taxes' : ''}`}
            />
            {hasUnclaimed && (
                <HouseholdMemberPlanInputWrapper
                    householdMembers={unclaimedHouseholdMembersForSelectedYear}
                    isDisabled={isEdit}
                    label="Household Members - Unclaimed on taxes"
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(HouseholdMemberPlanInput);
