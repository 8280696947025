import { ILaunchDetails } from 'api/generated/models';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import 'scss/launchDetailsCard.scss';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { formatDateForDisplay, formatPhoneNumber, formatTimeForDisplay } from 'utilities/format';
const emptyFieldIndicator = '--------';

const LaunchDetailsTable = ({
    canEdit,
    launchDetails: {
        advisementDeadlineDate,
        meetingConferenceId,
        meetingEndDateTime,
        meetingLink,
        meetingPhoneNumber,
        meetingStartDateTime,
    },
}: {
    canEdit: boolean | undefined;
    launchDetails: ILaunchDetails;
}) => {
    const hideEmptyMeetingLink = useMemo(() => !hasValue(meetingLink) && !canEdit, [
        canEdit,
        meetingLink,
    ]);
    const hideEmptyMeetingPhoneNumber = useMemo(() => !hasValue(meetingPhoneNumber) && !canEdit, [
        canEdit,
        meetingPhoneNumber,
    ]);
    const hideEmptyMeetingId = useMemo(() => !hasValue(meetingConferenceId) && !canEdit, [
        canEdit,
        meetingConferenceId,
    ]);
    return (
        <FixedTable>
            <tbody>
                <tr>
                    <td>
                        <strong>Kickoff Webinar Date:</strong>
                    </td>
                    <td className="ld-value">{formatDateForDisplay(meetingStartDateTime)}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Webinar Start Time (EST):</strong>
                    </td>
                    <td className="ld-value">{formatTimeForDisplay(meetingStartDateTime)}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Webinar End Time (EST):</strong>
                    </td>
                    <td className="ld-value">{formatTimeForDisplay(meetingEndDateTime)}</td>
                </tr>
                {!hideEmptyMeetingLink && (
                    <tr>
                        <td>
                            <strong>Webinar Link:</strong>
                        </td>
                        <td className="ld-value">
                            {hasValue(meetingLink) ? (
                                <a href={meetingLink} rel="noopener noreferrer" target="_blank">
                                    {meetingLink}
                                </a>
                            ) : (
                                emptyFieldIndicator
                            )}
                        </td>
                    </tr>
                )}
                {!hideEmptyMeetingPhoneNumber && (
                    <tr>
                        <td>
                            <strong>Webinar Dial-In Number:</strong>
                        </td>
                        <td className="ld-value">
                            {hasValue(meetingPhoneNumber)
                                ? formatPhoneNumber(meetingPhoneNumber)
                                : emptyFieldIndicator}
                        </td>
                    </tr>
                )}
                {!hideEmptyMeetingId && (
                    <tr>
                        <td>
                            <strong>Webinar Dial-in Conference ID:</strong>
                        </td>
                        <td className="ld-value">
                            {hasValue(meetingConferenceId)
                                ? meetingConferenceId
                                : emptyFieldIndicator}
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <strong>Advisement Deadline:</strong>
                    </td>
                    <td className="ld-value">{formatDateForDisplay(advisementDeadlineDate)}</td>
                </tr>
            </tbody>
        </FixedTable>
    );
};

const FixedTable = styled.table.attrs(() => ({
    className: 'launch-details-table w-100',
}))`
    table-layout: fixed;
`;

export default hot(module)(LaunchDetailsTable);
