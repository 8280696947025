import Stack from '@mui/material/Stack';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { ADD_TEAM_NOTE_ACTION } from 'actions/teamNote/addTeamNote';
import { EDIT_TEAM_NOTE_ACTION } from 'actions/teamNote/editTeamNote';
import { ADD_USER_NOTE_ACTION } from 'actions/userNote/addUserNote';
import { EDIT_USER_NOTE_ACTION } from 'actions/userNote/editUserNote';
import { INote, Note } from 'api/generated/models';
import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Form from 'components/Form';
import TextArea from 'components/TextArea';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const schema = object({
    noteText: string()
        .trim()
        .required()
        .label('Note'),
    title: string()
        .trim()
        .required()
        .label('Title'),
});

type INoteAction = (
    note: Note
) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;

type INoteModalProps = {
    addNote: INoteAction;
    canEditNotes: boolean;
    editNote: INoteAction;
    note?: INote;
    onClose: () => void;
};
const NoteModal = ({ addNote, canEditNotes, editNote, note, onClose }: INoteModalProps) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            ADD_TEAM_NOTE_ACTION,
            EDIT_TEAM_NOTE_ACTION,
            ADD_USER_NOTE_ACTION,
            EDIT_USER_NOTE_ACTION
        ),
    }));
    const { errors, validate } = useForm(schema);
    const [title, setTitle] = useState(note?.title ?? '');
    const [noteText, setNoteText] = useState(note?.noteText ?? '');
    const submit = useCallback(async () => {
        const { data, isValid } = await validate({ ...note, noteText, title });
        if (isValid) {
            if (note?.noteId) {
                await dispatch(editNote(new Note((data as unknown) as INote)));
            } else {
                await dispatch(addNote(new Note((data as unknown) as INote)));
            }
            onClose();
        }
    }, [addNote, dispatch, editNote, note, noteText, onClose, title, validate]);
    const isDisabled = useMemo(() => (note?.noteId && !canEditNotes) || note?.isArchived, [
        canEditNotes,
        note,
    ]);
    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="note-modal" onSubmit={submit}>
                    <Stack gap={2}>
                        <TextField
                            autoFocus
                            disabled={isDisabled}
                            errors={errors?.title}
                            label="Title"
                            onChange={onChange(setTitle)}
                            placeholder="Enter a title"
                            value={title}
                        />
                        <TextArea
                            disabled={isDisabled}
                            errors={errors?.noteText}
                            label="Note"
                            minRows={4}
                            onChange={onChange(setNoteText)}
                            placeholder="Enter a note"
                            value={noteText}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <ConditionalTooltip
                    isDisabled={!note?.isArchived}
                    title="Cannot edit archived note"
                >
                    <Button
                        disabled={isDisabled}
                        form="note-modal"
                        isLoading={isLoading}
                        type="submit"
                    >
                        Save
                    </Button>
                </ConditionalTooltip>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(NoteModal);
