import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ExpenseInputTypes, MedicalReimbursementTypes } from 'api/generated/enums';
import { ExpenseInput, IExpenseInput, IExpenseType } from 'api/generated/models';
import CurrencyTextField from 'components/CurrencyTextField';
import NumberTextField from 'components/NumberTextField';
import Select from 'components/Select';
import { ExpenseTypeIds } from 'constants/reimbursementProgramExpenseTypes';
import startCase from 'lodash/startCase';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { enumToNameValueArray } from 'utilities';

const ExpenseTypeSectionHeader = ({ children }: { children: ReactNode }) => (
    <Typography
        color={(theme) => theme.palette.muted.main}
        mb={2}
        mt={2}
        pb={1}
        pt={2}
        variant="h3"
    >
        <Box pb={2}>
            <Divider />
        </Box>
        {children}
    </Typography>
);

type IExpenseTypeSectionsProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: any;
    expenseInputs: Partial<IExpenseInput>[] | undefined;
    expenseTypes: IExpenseType[];
    isDisabled: boolean;
    onKeyDown: () => void;
    setExpenseInputs: Dispatch<SetStateAction<Partial<IExpenseInput>[] | undefined>>;
};

const ExpenseTypeSections = ({
    errors,
    expenseInputs,
    expenseTypes,
    isDisabled,
    onKeyDown,
    setExpenseInputs,
}: IExpenseTypeSectionsProps) => {
    const medicalReimbursementTypeOptions = enumToNameValueArray(MedicalReimbursementTypes, {
        formatName: startCase,
        nameMap: {
            NonEmbedded: 'Non-Embedded',
        },
    });
    const uniqueExpenseTypes = useMemo(
        () => [...new Set(expenseInputs?.map((x) => x.expenseTypeId) ?? [])],
        [expenseInputs]
    );
    const getInputErrors = useCallback(
        (expenseTypeId: number | undefined, expenseInputTypeId: ExpenseInputTypes) => {
            try {
                return errors[`${expenseTypeId}.${ExpenseInputTypes[expenseInputTypeId]}`];
            } catch (e) {
                return undefined;
            }
        },
        [errors]
    );
    const updateExpenseInputs = useCallback(
        (
            inputValue: string,
            expenseTypeId: number | undefined,
            expenseInputTypeId: ExpenseInputTypes
        ) => {
            if (expenseInputs) {
                setExpenseInputs(
                    expenseInputs.map((x) => {
                        const data = { ...x, value: inputValue };
                        return x.expenseTypeId === expenseTypeId &&
                            x.expenseInputTypeId === expenseInputTypeId
                            ? (data as ExpenseInput)
                            : x;
                    })
                );
            }
        },
        [expenseInputs, setExpenseInputs]
    );
    const getExpenseInputValue = useCallback(
        (expenseTypeId: number | undefined, expenseInputTypeId: ExpenseInputTypes) =>
            expenseInputs?.find(
                (x) =>
                    x.expenseTypeId === expenseTypeId && x.expenseInputTypeId === expenseInputTypeId
            )?.value,
        [expenseInputs]
    );

    return (
        <React.Fragment>
            {uniqueExpenseTypes.map((x) => {
                const selectedExpenseType = expenseTypes.find((y: { id: number }) => y.id === x);
                const selectedExpenseTypeId = selectedExpenseType?.id;
                const isMedical = selectedExpenseTypeId === ExpenseTypeIds.Medical;
                const isCounseling = [
                    ExpenseTypeIds.Counseling,
                    ExpenseTypeIds.BiblicalAndMarriageCounseling,
                ].contains(selectedExpenseTypeId);
                const isDefault = !isMedical && !isCounseling;
                const onChange = (
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                    expenseInputType: ExpenseInputTypes
                ) => {
                    updateExpenseInputs(e.target.value, x, expenseInputType);
                    onKeyDown();
                };
                return (
                    <React.Fragment key={x}>
                        {selectedExpenseType && (
                            <React.Fragment>
                                <ExpenseTypeSectionHeader key={selectedExpenseTypeId}>
                                    {selectedExpenseType.name}
                                </ExpenseTypeSectionHeader>
                                {isMedical && (
                                    <React.Fragment>
                                        <CurrencyTextField
                                            data-cy="individual-medical-responsibility"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.IndividualMedicalResponsibility
                                            )}
                                            label="Individual Medical Responsibility"
                                            name="individualMedicalResponsibility"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.IndividualMedicalResponsibility
                                                )
                                            }
                                            placeholder="Enter individual medical responsibility"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.IndividualMedicalResponsibility
                                            )}
                                        />
                                        <CurrencyTextField
                                            data-cy="family-medical-responsibility"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.FamilyMedicalResponsibility
                                            )}
                                            label="Family Medical Responsibility"
                                            name="familyMedicalResponsibility"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.FamilyMedicalResponsibility
                                                )
                                            }
                                            placeholder="Enter family medical responsibility"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.FamilyMedicalResponsibility
                                            )}
                                        />
                                        <CurrencyTextField
                                            data-cy="individual-medical-reimbursement-amount"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.IndividualMedicalReimbursementAmount
                                            )}
                                            label="Individual Medical Reimbursement Amount"
                                            name="individualMedicalReimbursementAmount"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.IndividualMedicalReimbursementAmount
                                                )
                                            }
                                            placeholder="Enter individual medical reimbursement amount"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.IndividualMedicalReimbursementAmount
                                            )}
                                        />
                                        <CurrencyTextField
                                            data-cy="family-medical-reimbursement-amount"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.FamilyMedicalReimbursementAmount
                                            )}
                                            label="Family Medical Reimbursement Amount"
                                            name="familyMedicalReimbursementAmount"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.FamilyMedicalReimbursementAmount
                                                )
                                            }
                                            placeholder="Enter family medical reimbursement amount"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.FamilyMedicalReimbursementAmount
                                            )}
                                        />
                                        <Select
                                            data-cy="medical-reimbursement-type"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.MedicalReimbursementType
                                            )}
                                            items={medicalReimbursementTypeOptions}
                                            label="Medical Reimbursement Type"
                                            name="medicalReimbursementType"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.MedicalReimbursementType
                                                )
                                            }
                                            optionText="name"
                                            optionValue="value"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.MedicalReimbursementType
                                            )}
                                        />
                                    </React.Fragment>
                                )}
                                {isCounseling && (
                                    <React.Fragment>
                                        <CurrencyTextField
                                            data-cy="per-visit-reimbursement-amount"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.PerVisitReimbursementAmount
                                            )}
                                            label="Per Visit Reimbursement Amount"
                                            name="perVisitReimbursementAmount"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.PerVisitReimbursementAmount
                                                )
                                            }
                                            placeholder="Enter per visit reimbursement amount"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.PerVisitReimbursementAmount
                                            )}
                                        />
                                        <NumberTextField
                                            data-cy="maximum-visits-per-year"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.MaximumVisitsPerYear
                                            )}
                                            label="Maximum Visits Per Year"
                                            name="maximumVisitsPerYear"
                                            onChange={(e) =>
                                                onChange(e, ExpenseInputTypes.MaximumVisitsPerYear)
                                            }
                                            placeholder="Enter maximum visits per year"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.MaximumVisitsPerYear
                                            )}
                                        />
                                    </React.Fragment>
                                )}
                                {isDefault && (
                                    <React.Fragment>
                                        <CurrencyTextField
                                            data-cy="annual-reimbursement-amount"
                                            disabled={isDisabled}
                                            errors={getInputErrors(
                                                x,
                                                ExpenseInputTypes.AnnualReimbursementAmount
                                            )}
                                            label="Annual Reimbursement Amount"
                                            name="annualReimbursementAmount"
                                            onChange={(e) =>
                                                onChange(
                                                    e,
                                                    ExpenseInputTypes.AnnualReimbursementAmount
                                                )
                                            }
                                            placeholder="Enter annual reimbursement amount"
                                            value={getExpenseInputValue(
                                                x,
                                                ExpenseInputTypes.AnnualReimbursementAmount
                                            )}
                                        />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default hot(module)(ExpenseTypeSections);
