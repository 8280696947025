import React from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const DisclaimerLabel = styled.div.attrs(() => ({
    className: 'text-muted',
}))`
    font-size: 10px;
`;

const MediShareDisclaimerLabel = ({ noTopMargin = false }) => (
    <Row className={`justify-content-between mx-2 align-items-center ${noTopMargin ? '' : 'mt-3'}`}>
        <DisclaimerLabel>
            Medi-Share is not insurance. It is a not-for-profit ministry and is not guaranteed in
            any way. Medi-Share is exempt from insurance regulation.
        </DisclaimerLabel>
    </Row>
);

export default hot(module)(MediShareDisclaimerLabel);
