import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

const LabelAndValue = ({
    isIndented = false,
    label,
    labelSuperscript,
    labelProps,
    value,
    variant = 'body1',
}: {
    isIndented?: boolean;
    label: string | undefined;
    labelProps?: TypographyProps;
    labelSuperscript?: string;
    value: JSX.Element | string | undefined;
    variant?: TypographyProps['variant'];
}) => (
    <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
        <Typography
            color={isIndented ? 'text.secondary' : 'text.primary'}
            ml={isIndented ? 2 : 0}
            variant={variant}
            {...labelProps}
        >
            {label}
            {hasValue(labelSuperscript) && <sup>{labelSuperscript}</sup>}
        </Typography>
        <Typography color={isIndented ? 'text.secondary' : 'text.primary'} variant={variant}>
            {value}
        </Typography>
    </Stack>
);

export default hot(module)(LabelAndValue);
