import { Typography } from '@mui/material';
import InformationIconTooltip from 'components/InformationIconTooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const IchraMediShareMyCostTooltip = ({
    planCost,
    title = 'Your Cost',
}: {
    planCost: number | undefined;
    title?: string;
}) => (
    <InformationIconTooltip
        className="ml-1"
        data-cy="my-cost-tooltip-trigger"
        title={
            <span data-cy="my-cost-tooltip">
                <Typography variant="h6">{title}</Typography>
                <p>This is the monthly cost for you to be on this benefit.</p>
                <p>
                    The price for this benefit is{' '}
                    {formatCurrency(planCost, { preserveDecimal: true })}. This program is not
                    eligible for reimbursement, so the final cost to you will be{' '}
                    {formatCurrency(planCost, { preserveDecimal: true })}.
                </p>
            </span>
        }
    />
);

export default hot(module)(IchraMediShareMyCostTooltip);
