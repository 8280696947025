import Link from '@mui/material/Link';
import { TaskDto } from 'api/generated/models';
import CalendlyModalBase, { ICalendlyModalBaseProps } from 'components/calendly/CalendlyModalBase';
import React from 'react';
import { hot } from 'react-hot-loader';

type ICalendlyModalLinkProps = Omit<ICalendlyModalBaseProps, 'children'> & {
    memberTermsTask?: TaskDto;
    showAgreeToTerms?: boolean;
    text: string;
};

const CalendlyModalLink = ({
    calendlyLinkOverride,
    memberTermsTask,
    onAdvisementScheduled,
    showAgreeToTerms,
    text,
}: ICalendlyModalLinkProps) => (
    <CalendlyModalBase
        calendlyLinkOverride={calendlyLinkOverride}
        memberTermsTask={memberTermsTask}
        onAdvisementScheduled={onAdvisementScheduled}
        showAgreeToTerms={showAgreeToTerms}
    >
        {(openModal) => (
            <Link
                color="secondary"
                component="button"
                onClick={openModal}
                sx={{ verticalAlign: 'top' }}
                underline="none"
            >
                {text}
            </Link>
        )}
    </CalendlyModalBase>
);

export default hot(module)(CalendlyModalLink);
