import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { IHouseholdMember, IUser } from 'api/generated/models';
import Checkbox from 'components/Checkbox';
import RadioGroup from 'components/RadioGroup';
import React, { ReactNode, useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { onChange as onChangeForm } from 'utilities/forms';
import { getDisplayFirstName } from 'utilities/household';

type IHouseholdMemberInputProps = {
    disableMultiSelect?: boolean;
    errors?: string[];
    householdMembers: (IHouseholdMember | IUser | undefined)[];
    isDisabled?: boolean;
    label?: ReactNode;
    onChange: (newSelectedHouseholdIds: string[]) => void;
    selectedHouseholdMembers: string[];
};
const HouseholdMemberInput = ({
    disableMultiSelect,
    errors,
    householdMembers,
    isDisabled,
    label = 'Household Members',
    onChange: parentOnChange,
    selectedHouseholdMembers,
}: IHouseholdMemberInputProps) => {
    const onChange = useCallback(
        (memberId: string | undefined) => ({
            target: { checked },
        }: React.ChangeEvent<HTMLInputElement>) => {
            const newSelectedMemberIds = checked
                ? [...selectedHouseholdMembers, memberId]
                : selectedHouseholdMembers?.filter((x) => x !== memberId);
            parentOnChange(newSelectedMemberIds.filter((x) => x !== undefined) as string[]);
        },
        [parentOnChange, selectedHouseholdMembers]
    );
    if (disableMultiSelect) {
        const options = householdMembers?.map((x) => ({
            label: getDisplayFirstName(x),
            value: (x as IHouseholdMember).householdMemberId ?? x?.userId,
        }));
        return (
            <RadioGroup
                disabled={isDisabled}
                label={label}
                onChange={onChangeForm((value: string) => parentOnChange([value]))}
                options={options}
                row
                value={selectedHouseholdMembers[0] ?? ''}
            />
        );
    }
    return (
        <FormControl error={!!errors}>
            <FormLabel>{label}</FormLabel>
            <FormGroup row>
                {householdMembers.map((x, index) => {
                    const memberId = (x as IHouseholdMember).householdMemberId || x?.userId;
                    const id = `${memberId}-member-checks`;
                    return (
                        <Checkbox
                            checked={selectedHouseholdMembers?.some((s) => s === memberId)}
                            disabled={isDisabled}
                            id={id}
                            key={index}
                            label={getDisplayFirstName(x)}
                            name={id}
                            onChange={onChange(memberId)}
                        />
                    );
                })}
            </FormGroup>
            {errors && <FormHelperText>{errors[0]}</FormHelperText>}
        </FormControl>
    );
};

export default hot(module)(HouseholdMemberInput);
