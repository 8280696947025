import { TeamStateIds } from 'api/generated/enums';
import {
    ViewEnrollmentVerification,
    ViewMedicaidDetails,
    ViewPaymentsInfo,
    ViewQualitativeAnswers,
    ViewSelectedPlans,
    ViewUserNotes,
    ViewUserRhFiles,
} from 'api/generated/permissions';
import Tabs, { ITab } from 'components/authenticatedPageWrapper/Tabs';
import useExternalTeamProps from 'components/authenticatedPageWrapper/useExternalTeamProps';
import useExternalUserProps from 'components/authenticatedPageWrapper/useExternalUserProps';
import { MemberNavIndex } from 'constants/memberNavIndex';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    TEAMS_MEDICAID_DETAILS_PATH,
    TEAMS_MEMBER_SURVEY_FOR_USER_PATH,
    TEAMS_MY_BENEFITS_PATH,
    TEAMS_PEOPLE_EDIT_PATH,
    TEAMS_PEOPLE_PATH,
    TEAMS_PEOPLE_PROFILE_PATH,
    TEAMS_PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    TEAMS_SELECTIONS_PATH,
    TEAMS_SHOP_PATH,
    TEAMS_USER_NOTES_PATH,
    TEAMS_USER_PAYMENTS_PATH,
    TEAMS_USER_QUALITATIVE_PATH,
    TEAMS_USER_RH_FILES_PATH,
    TEAMS_VERIFICATION_DOCUMENTS_PATH,
} from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { getCanShopForMember } from 'selectors/shopping';

const MemberTabs = () => {
    const { teamHasPaymentsSetup, teamId, teamStateId } = useExternalTeamProps();
    const { userId } = useExternalUserProps();
    const {
        canShopForMember,
        canViewEnrollmentVerification,
        canViewMedicaidDetails,
        canViewMemberPlans,
        canViewUserRhFiles,
        canViewUserNotes,
        canViewQualitativeAnswers,
        canViewPaymentsInfo,
    } = useSelector((state: AppStore) => ({
        canShopForMember: getCanShopForMember(state),
        canViewEnrollmentVerification: hasSomePermissions(state, ViewEnrollmentVerification),
        canViewMedicaidDetails: hasSomePermissions(state, ViewMedicaidDetails),
        canViewMemberPlans: hasSomePermissions(state, ViewSelectedPlans),
        canViewPaymentsInfo: hasSomePermissions(state, ViewPaymentsInfo),
        canViewQualitativeAnswers: hasSomePermissions(state, ViewQualitativeAnswers),
        canViewUserNotes: hasSomePermissions(state, ViewUserNotes),
        canViewUserRhFiles: hasSomePermissions(state, ViewUserRhFiles),
    }));
    const generateTeamRoute = useCallback((path) => generatePath(path, { teamId, userId }), [
        teamId,
        userId,
    ]);
    const isShopVisible = useMemo(() => {
        switch (teamStateId) {
            case TeamStateIds.MemberLaunch:
            case TeamStateIds.Customer:
            case TeamStateIds.Renewing:
                return true;
            default:
                return false;
        }
    }, [teamStateId]);
    const data = useMemo(
        (): ITab[] => [
            {
                isVisible: true,
                TabProps: {
                    className: 'unselectable',
                    'data-cy': 'people-tab',
                    id: `member-tab-index-${-1}`,
                    label: <span>&laquo; Members</span>,
                    to: generateTeamRoute(TEAMS_PEOPLE_PATH),
                },
            },
            {
                isVisible: true,
                possiblePaths: [
                    TEAMS_PEOPLE_PROFILE_PATH,
                    TEAMS_PEOPLE_EDIT_PATH,
                    TEAMS_MEMBER_SURVEY_FOR_USER_PATH,
                ],
                TabProps: {
                    id: `member-tab-index-${MemberNavIndex.Profile}`,
                    label: 'Profile',
                    to: generateTeamRoute(TEAMS_PEOPLE_PROFILE_PATH),
                },
            },
            {
                isVisible: canViewMemberPlans,
                possiblePaths: [
                    TEAMS_MY_BENEFITS_PATH,
                    TEAMS_PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
                ],
                TabProps: {
                    'data-cy': 'benefits-tab',
                    id: `member-tab-index-${MemberNavIndex.Benefits}`,
                    label: 'Benefits',
                    to: generateTeamRoute(TEAMS_MY_BENEFITS_PATH),
                },
            },
            {
                isVisible: canShopForMember && isShopVisible,
                possiblePaths: [TEAMS_SHOP_PATH, TEAMS_SELECTIONS_PATH],
                TabProps: {
                    'data-cy': 'shopping-tab',
                    id: `member-tab-index-${MemberNavIndex.Shopping}`,
                    label: 'Shopping',
                    to: generateTeamRoute(TEAMS_SHOP_PATH),
                },
            },
            {
                isVisible: canViewEnrollmentVerification,
                possiblePaths: [TEAMS_VERIFICATION_DOCUMENTS_PATH],
                TabProps: {
                    'data-cy': 'verification-documents-tab',
                    id: `member-tab-index-${MemberNavIndex.VerificationDocuments}`,
                    label: 'Verification Documents',
                    to: generateTeamRoute(TEAMS_VERIFICATION_DOCUMENTS_PATH),
                },
            },
            {
                isVisible: canViewMedicaidDetails,
                possiblePaths: [TEAMS_MEDICAID_DETAILS_PATH],
                TabProps: {
                    'data-cy': 'medicaid-details-tab',
                    id: `member-tab-index-${MemberNavIndex.MedicaidDetails}`,
                    label: 'Medicaid Details',
                    to: generateTeamRoute(TEAMS_MEDICAID_DETAILS_PATH),
                },
            },
            {
                isVisible: canViewUserRhFiles,
                possiblePaths: [TEAMS_USER_RH_FILES_PATH],
                TabProps: {
                    id: `member-tab-index-${MemberNavIndex.Files}`,
                    label: 'Files',
                    to: generateTeamRoute(TEAMS_USER_RH_FILES_PATH),
                },
            },
            {
                isVisible: canViewUserNotes,
                possiblePaths: [TEAMS_USER_NOTES_PATH],
                TabProps: {
                    id: `member-tab-index-${MemberNavIndex.Notes}`,
                    label: 'Notes',
                    to: generateTeamRoute(TEAMS_USER_NOTES_PATH),
                },
            },
            {
                isVisible: canViewQualitativeAnswers,
                possiblePaths: [TEAMS_USER_QUALITATIVE_PATH],
                TabProps: {
                    id: `member-tab-index-${MemberNavIndex.Qualitative}`,
                    label: 'Qualitative HBA Questions',
                    to: generateTeamRoute(TEAMS_USER_QUALITATIVE_PATH),
                },
            },
            {
                isVisible: canViewPaymentsInfo && (teamHasPaymentsSetup as boolean),
                possiblePaths: [TEAMS_USER_PAYMENTS_PATH],
                TabProps: {
                    id: `member-tab-index-${MemberNavIndex.Payments}`,
                    label: 'Payments',
                    to: generateTeamRoute(TEAMS_USER_PAYMENTS_PATH),
                },
            },
        ],
        [
            generateTeamRoute,
            canViewMemberPlans,
            canShopForMember,
            isShopVisible,
            canViewEnrollmentVerification,
            canViewMedicaidDetails,
            canViewUserRhFiles,
            canViewUserNotes,
            canViewQualitativeAnswers,
            canViewPaymentsInfo,
            teamHasPaymentsSetup,
        ]
    );
    return <Tabs data={data} />;
};

export default hot(module)(MemberTabs);
