import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { PathwayTypes } from 'api/generated/enums';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';

const seeWhyTooltipContent =
    "Remodel Health recommends health plans to fit member's needs primarily based on the following components: " +
    'network strength, affordability, plan structure (HSA, Co-pay) and metal tier. ' +
    'By considering all of these factors, we tailor plans to people of highly varying situations and circumstances.';

const TooltipText = styled('span')(
    ({ theme }) => `
    color: ${theme.palette['secondary'].main};
    cursor: default;
`
);

const PathwayTitle = ({
    pathwayType,
    showMessage,
}: {
    pathwayType?: PathwayTypes;
    showMessage?: boolean;
}) => {
    const isOnOrOffExchangePathway = ([
        PathwayTypes.MarketplaceCappedEmployeeCost,
        PathwayTypes.MarketplaceCappedEmployerCost,
        PathwayTypes.IchraCappedEmployeeCost,
        PathwayTypes.IchraCappedEmployerCost,
        PathwayTypes.IchraAgeBanded,
    ] as (PathwayTypes | undefined)[]).includes(pathwayType);

    let modalTitle = (
        <Typography variant="h3">Your Recommended Health Benefits Solution</Typography>
    );
    if (isOnOrOffExchangePathway || showMessage) {
        modalTitle = (
            <Typography variant="h3">
                Your Recommended Health Benefits Solution.{' '}
                <Tooltip title={seeWhyTooltipContent}>
                    <TooltipText>See why</TooltipText>
                </Tooltip>
            </Typography>
        );
    }

    return modalTitle;
};
export default hot(module)(PathwayTitle);
