import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import kebabCase from 'lodash/kebabCase';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown, { DropdownProps } from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import { hasValue } from 'utilities';

const DisableableElement = styled.span.attrs<{
    disabled: boolean;
}>(({ className, disabled }) => ({
    className: `${className} ${disabled ? 'disabled shadow-none' : ''}`,
}))<{ disabled: boolean }>`
    ${({ disabled }) => (disabled ? 'cursor: default !important;' : '')}
    &::after {
        left: 5px;
        margin-bottom: auto;
        margin-left: auto;
        margin-top: auto;
        position: relative;
    }
`;

enum Sources {
    click = 'click',
    keydown = 'keydown',
    rootClose = 'rootClose',
    select = 'select',
}

export type IDropDownValue<T> = T[keyof T] | T[keyof T][];

type IDropDownProps<T> = {
    className?: string;
    dataCy?: string;
    invalidMessage?: string;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isLoading?: boolean;
    isMultiple?: boolean;
    isRequired?: boolean;
    items: T[];
    label?: string;
    onChange: (selectedItems: IDropDownValue<T> | undefined) => void;
    optionTextKey: keyof T;
    optionValueKey: keyof T;
    placeholder?: string;
    text?: string;
    value?: IDropDownValue<T>;
};

const Spacer = styled.span`
    height: 16px;
    width: 22px;
`;

const DropDown = <T,>({
    className = '',
    dataCy,
    invalidMessage,
    isDisabled,
    isInvalid,
    isLoading = false,
    isMultiple = false,
    isRequired,
    items,
    label,
    onChange,
    optionTextKey,
    optionValueKey,
    placeholder: parentPlaceholder,
    text,
    value: parentValue,
}: IDropDownProps<T>) => {
    const getValue = useCallback(
        (existingValue?: IDropDownValue<T> | undefined) => {
            if (isMultiple) {
                return existingValue ?? [];
            }
            return existingValue;
        },
        [isMultiple]
    );
    const [showMenu, setShowMenu] = useState(false);
    const [value, setValue] = useState<IDropDownValue<T> | undefined>(getValue(parentValue));
    useEffect(() => {
        setValue(getValue(parentValue));
    }, [getValue, parentValue]);
    const handleSave = useCallback(
        (newValue: IDropDownValue<T> | undefined) => {
            setShowMenu(false);
            const originalValue = getValue(parentValue);
            let hasChanged = `${newValue}` !== `${originalValue}`;
            if (isMultiple && Array.isArray(newValue)) {
                hasChanged =
                    orderBy((originalValue as unknown) as []).join('') !==
                    orderBy(newValue).join('');
            }
            if (hasChanged) {
                onChange(newValue);
            }
        },
        [getValue, isMultiple, onChange, parentValue]
    );
    const toggleOnClick = useCallback(() => {
        if (showMenu) {
            handleSave(value);
        } else {
            setShowMenu(true);
        }
    }, [handleSave, showMenu, value]);
    const handleOnToggle: DropdownProps['onToggle'] = useCallback(
        (_isOpen, e, { source }) => {
            if (
                source === Sources.keydown &&
                ((e as unknown) as KeyboardEvent)?.key?.toLowerCase() === 'escape'
            ) {
                setShowMenu(false);
                setValue(getValue(parentValue));
            }
        },
        [getValue, parentValue]
    );
    const onBlur = useCallback(
        (e: React.FocusEvent) => {
            if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                handleSave(value);
            }
        },
        [handleSave, value]
    );
    const handleOnSelect = useCallback(
        (eventKey) => {
            if (isMultiple && Array.isArray(value)) {
                if (value.some((item) => item === eventKey)) {
                    setValue(value.filter((item) => item !== eventKey));
                } else {
                    setValue(value.concat([eventKey]));
                }
            } else if (!isMultiple) {
                if (`${eventKey}` !== `${getValue(parentValue)}`) {
                    setValue(eventKey);
                    handleSave(eventKey);
                } else {
                    setShowMenu(false);
                }
            }
        },
        [getValue, handleSave, isMultiple, parentValue, value]
    );
    const getOptionText = useCallback((item) => item[optionTextKey], [optionTextKey]);
    const options = useMemo(
        () =>
            items.map((item) => {
                let isActive = item[optionValueKey] === value;
                if (isMultiple && Array.isArray(value)) {
                    isActive = value.some((itemKey) => itemKey === item[optionValueKey]);
                }
                const optionText = getOptionText(item);
                return (
                    <Dropdown.Item
                        className={isActive ? 'bg-primary text-white' : ''}
                        data-cy={`${dataCy}-${kebabCase(optionText)}`}
                        eventKey={(item[optionValueKey] as unknown) as string}
                        key={(item[optionValueKey] as unknown) as string}
                        onSelect={handleOnSelect}
                    >
                        <Row className="flex-nowrap">
                            {isActive ? (
                                <Icon className="mr-1" variant="white">
                                    checkmark
                                </Icon>
                            ) : (
                                <Spacer />
                            )}
                            {optionText}
                        </Row>
                    </Dropdown.Item>
                );
            }),
        [dataCy, getOptionText, handleOnSelect, isMultiple, items, optionValueKey, value]
    );
    const toggleText = useMemo(() => {
        if (hasValue(text)) {
            return text;
        }
        if (isMultiple && Array.isArray(value)) {
            return value.length > 0
                ? items
                      .filter((item) => value.includes(item[optionValueKey]))
                      .map(getOptionText)
                      .join(', ')
                : parentPlaceholder;
        }
        return getOptionText(items.find((item) => `${value}` === `${item[optionValueKey]}`));
    }, [getOptionText, isMultiple, items, optionValueKey, parentPlaceholder, text, value]);
    return (
        <Skeleton count={1} height="50px" isEnabled={isLoading} width="120px">
            <Form.Group className={className}>
                {label && (
                    <label>
                        {label}
                        {!isRequired && <em className="text-primary"> - Optional</em>}
                    </label>
                )}
                <Dropdown
                    data-cy={dataCy}
                    onBlur={onBlur}
                    onToggle={handleOnToggle}
                    show={showMenu}
                >
                    <DisableableElement
                        as={Dropdown.Toggle}
                        className={`py-2 w-100 flex ${isInvalid ? 'border-invalid-red' : ''}`}
                        data-cy={`${dataCy}-toggle`}
                        disabled={isDisabled}
                        id={kebabCase(`${parentPlaceholder}-dropdown`)}
                        onClick={toggleOnClick}
                        variant="outline-dark"
                    >
                        {toggleText}
                    </DisableableElement>
                    <Dropdown.Menu className="p-0">{options}</Dropdown.Menu>
                </Dropdown>
                {isInvalid && (
                    <div
                        className="d-block invalid-feedback w-100"
                        data-cy={`${kebabCase(label)}-invalid-message`}
                    >
                        {invalidMessage}
                    </div>
                )}
            </Form.Group>
        </Skeleton>
    );
};

export default hot(module)(DropDown);
