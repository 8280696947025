import TextField, { ITextFieldProps } from 'components/TextField';
import React, { useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

const AUTO_FOCUS_DELAY_IN_MILLISECONDS = 250;
const setFocus = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: React.MutableRefObject<any>,
    autoFocus: boolean | undefined,
    delayInMilliseconds = AUTO_FOCUS_DELAY_IN_MILLISECONDS
) => {
    if (ref !== null && autoFocus) {
        setTimeout(() => {
            ref.current?.inputElement?.focus();
            ref.current?.getInputDOMNode?.().focus();
            ref.current?.focus?.();
        }, delayInMilliseconds);
    }
};

export type ITextAreaProps = ITextFieldProps & {
    maxLength?: number;
};

const TextArea = ({ autoFocus, helperText, maxLength, ...props }: ITextAreaProps) => {
    const ref = useRef(null);
    useEffect(() => {
        setFocus(ref, autoFocus);
    }, [autoFocus, ref]);
    if (!hasValue(helperText) && hasValue(maxLength)) {
        const characterCount = ((props.value as object)?.toString() ?? '').length ?? 0;
        helperText = `${characterCount} of ${maxLength} max character${
            characterCount === 1 ? '' : 's'
        }`;
    }
    return <TextField {...props} helperText={helperText} inputRef={ref} multiline />;
};

export default hot(module)(TextArea);
