import {
    CLOSE_UNDER_CONSTRUCTION_MODAL,
    OPEN_UNDER_CONSTRUCTION_MODAL,
} from 'components/underConstructionModal/underConstructionAction';

const initialState = { isOpen: false };

export const underConstructionModalState = (state = initialState, action: { type: string }) => {
    if (action.type === CLOSE_UNDER_CONSTRUCTION_MODAL) {
        return { isOpen: false };
    } else if (action.type === OPEN_UNDER_CONSTRUCTION_MODAL) {
        return { isOpen: true };
    }
    return state;
};
