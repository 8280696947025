import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import DiagramGrid from 'components/majorMedicalBenefitCostBreakdownModal/DiagramGrid';
import SuperScript from 'components/majorMedicalBenefitCostBreakdownModal/SuperScript';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';

const TotalMajorMedicalBenefitCostGrids = ({
    majorMedicalBenefitCost: parentCostData,
}: {
    majorMedicalBenefitCost?: IMajorMedicalBenefitCostDto;
}) => {
    const { memberBenefits } = useSelector((state: AppStore) => ({
        memberBenefits: state.memberBenefits,
    }));
    const costData = parentCostData ?? memberBenefits.majorMedicalBenefitCost;
    return (
        <Grid borderTop={1} columns={14} container item>
            <DiagramGrid xs={2}>
                <Typography variant="h3">
                    Total Major Medical Benefit Cost<sup>*</sup>
                </Typography>
            </DiagramGrid>
            <DiagramGrid borderRight={1} xs={4}>
                <Typography>
                    <Typography component="span" variant="h4">
                        {formatCurrency(costData?.preTaxTotalMajorMedicalBenefitCost)}
                    </Typography>
                    <SuperScript>c</SuperScript> per month
                </Typography>
                <Typography paragraph variant="caption">
                    The cost we show, comparable to a cost for a traditional group plan
                </Typography>
            </DiagramGrid>
            <DiagramGrid borderRight={1} paddingTop={3} xs={4}>
                <Typography paragraph variant="caption">
                    These final numbers represent the same cost, just separated by your estimated
                    tax responsibility
                </Typography>
                <WestIcon sx={{ fontSize: 50 }} />
                <Typography color={(theme) => theme.palette.muted.main} paragraph variant="caption">
                    {formatCurrency(costData?.postTaxTotalMajorMedicalBenefitCost)} / (1 -{' '}
                    {costData?.totalTaxRate}
                    <SuperScript>d</SuperScript>)
                </Typography>
                <EastIcon sx={{ fontSize: 50 }} />
                <Typography color={(theme) => theme.palette.muted.main} paragraph variant="caption">
                    {formatCurrency(costData?.preTaxTotalMajorMedicalBenefitCost)}
                    <SuperScript>c</SuperScript> x (1 - {costData?.totalTaxRate}
                    <SuperScript>d</SuperScript>)
                </Typography>
            </DiagramGrid>
            <DiagramGrid xs={4}>
                <Typography>
                    <Typography component="span" variant="h4">
                        {formatCurrency(costData?.postTaxTotalMajorMedicalBenefitCost)}
                    </Typography>{' '}
                    per month
                </Typography>
            </DiagramGrid>
        </Grid>
    );
};

export default hot(module)(TotalMajorMedicalBenefitCostGrids);
