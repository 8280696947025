import { Edit as EditIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import BreakableSpan from 'components/BreakableSpan';
import CopyText from 'components/CopyText';
import ProfileAttribute, { IProfileAttributeProps } from 'components/ProfileAttribute';
import Tooltip from 'components/Tooltip';
import kebabCase from 'lodash/kebabCase';
import React, { useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

export const INLINE_EDIT_TOOLTIP_TITLE = 'Click to edit';

export type IValueType = number[] | string[] | number | string | undefined;
type IEditableAttributeChildrenProps = {
    onBlur: React.FocusEventHandler;
};
export type IEditableAttributeFormatter = (value: IValueType) => string | undefined;
export type IEditableAttributeProps = Pick<
    IProfileAttributeProps,
    'footer' | 'hrClassName' | 'infoTooltip'
> & {
    'data-cy'?: string;
    'data-private'?: boolean | 'lipsum' | 'redact';
    children: ({ onBlur }: IEditableAttributeChildrenProps) => JSX.Element;
    defaultValue?: IValueType;
    emptyValueMessage?: string;
    formatter?: IEditableAttributeFormatter;
    label: string;
    readonly?: boolean;
    subtext?: string;
    value: IValueType;
};

const EditableAttribute = ({
    children,
    'data-cy': dataCy,
    'data-private': dataPrivate,
    defaultValue,
    emptyValueMessage,
    footer,
    formatter,
    hrClassName,
    infoTooltip,
    label,
    readonly,
    subtext,
    value,
}: IEditableAttributeProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const valueToShow = useMemo(() => {
        let _valueToShow = value;
        if (!isEditing && !hasValue(value)) {
            if (!readonly) {
                return emptyValueMessage ?? `Set ${label}`;
            }
            if (defaultValue) {
                _valueToShow = defaultValue;
            }
        }
        return formatter?.(_valueToShow) ?? _valueToShow;
    }, [value, isEditing, formatter, readonly, defaultValue, emptyValueMessage, label]);
    const content = useMemo(() => {
        const textToCopy = valueToShow?.toString() ?? '';
        if (readonly) {
            return (
                <CopyText text={textToCopy}>
                    <BreakableSpan
                        className="clickable"
                        data-cy={`${kebabCase(label.toString())}-value`}
                        data-private={dataPrivate}
                    >
                        {valueToShow ? valueToShow : <span>&nbsp;&nbsp;&nbsp;</span>}
                    </BreakableSpan>
                </CopyText>
            );
        } else {
            let className = 'clickable';
            if (!hasValue(value)) {
                className += ' text-muted';
            }
            return (
                <React.Fragment>
                    {isEditing ? (
                        children({
                            onBlur: () => setIsEditing(false),
                        })
                    ) : (
                        <div className="d-flex align-items-center justify-content-end flex-nowrap">
                            {hasValue(value) ? (
                                <Stack
                                    alignItems="flex-end"
                                    className="mr-1"
                                    direction="column"
                                    justifyContent="center"
                                    spacing={0}
                                >
                                    <CopyText text={textToCopy}>
                                        <BreakableSpan
                                            className={className}
                                            data-cy={`${kebabCase(label.toString())}-value`}
                                            data-private={dataPrivate}
                                        >
                                            {valueToShow}
                                        </BreakableSpan>
                                    </CopyText>
                                    {hasValue(subtext) && (
                                        <div className="text-muted">{subtext}</div>
                                    )}
                                </Stack>
                            ) : (
                                <Tooltip title={INLINE_EDIT_TOOLTIP_TITLE}>
                                    <BreakableSpan
                                        className={`mr-1 ${className}`}
                                        data-cy={`${kebabCase(label.toString())}-value`}
                                        data-private={dataPrivate}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        {valueToShow}
                                    </BreakableSpan>
                                </Tooltip>
                            )}

                            <IconButton
                                color="primary"
                                data-cy={dataCy}
                                onClick={() => setIsEditing(true)}
                                size="small"
                            >
                                <EditIcon sx={{ height: 17, width: 17 }} />
                            </IconButton>
                        </div>
                    )}
                </React.Fragment>
            );
        }
    }, [children, dataCy, dataPrivate, isEditing, label, readonly, subtext, value, valueToShow]);
    return (
        <ProfileAttribute
            footer={footer}
            hrClassName={hrClassName}
            infoTooltip={infoTooltip}
            label={label}
        >
            {content}
        </ProfileAttribute>
    );
};

export default hot(module)(EditableAttribute);
