import EditableNumberTextField, {
    IEditableNumberTextFieldProps,
} from 'components/EditableNumberTextField';
import React from 'react';
import { hot } from 'react-hot-loader';

const EditableCurrencyTextField = <T,>({
    name,
    save: saveParent,
    ...props
}: IEditableNumberTextFieldProps<T>) => (
    <EditableNumberTextField
        decimalScale={2}
        name={name as never}
        prefix="$"
        save={async (name, value) =>
            saveParent(
                name,
                typeof value === 'string' ? value?.replace('$', '')?.replace(',', '') : value
            )
        }
        {...props}
    />
);
export default hot(module)(EditableCurrencyTextField);
