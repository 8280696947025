import { Stack } from '@mui/material';
import {
    COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA_ACTION,
    copyTeamBenefitManageMemberData,
} from 'actions/teamBenefit/copyTeamBenefitManageMemberData';
import { SET_USER_STATUS_ACTION } from 'actions/user/setUserStatus';
import { pageUserTermDetails } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import {
    ITeamBenefit,
    ITeamBenefitWithTermDetailsDto,
    TeamBenefitTermDetailDto,
} from 'api/generated/models';
import Select from 'components/Select';
import TypedConfirmationModal from 'components/TypedConfirmationModal';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const CONFIRMATION_TEXT = 'Overwrite member data for target term';

const schema = object({
    sourceTerm: string()
        .required()
        .test(
            'notEqual',
            'Source Term cannot equal Target Term',
            (value, context) => value !== context.parent.targetTerm
        )
        .label('Source Term'),
    targetTerm: string()
        .required()
        .test(
            'notEqual',
            'Target Term cannot equal Source Term',
            (value, context) => value !== context.parent.sourceTerm
        )
        .label('Target Term'),
});

type ITermItem = {
    index: number;
    name: string;
    value: TeamBenefitTermDetailDto;
};

const TeamBenefitCopyMemberBenefitDataModal = ({
    onClose,
    selectedTermDetailId,
    teamBenefit,
}: {
    onClose: () => void;
    selectedTermDetailId?: string;
    teamBenefit: ITeamBenefit | ITeamBenefitWithTermDetailsDto;
}) => {
    const dispatch = useThunkDispatch();
    const { showActivity, paginationParams } = useSelector((state: AppStore) => ({
        paginationParams: state.manageMembersState,
        showActivity: hasApiActivity(
            state,
            COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA_ACTION,
            SET_USER_STATUS_ACTION
        ),
    }));
    const { teamId } = useTeamProps();
    const [sourceTermIndex, setSourceTermIndex] = useState<number>();
    const { errors, validate } = useForm(schema);

    const termItems = (teamBenefit as ITeamBenefitWithTermDetailsDto).teamBenefitTermDetails?.map(
        (termDetail, index) => {
            const termItem: ITermItem = {
                index,
                name: `${formatDateForDisplay(termDetail.startDate)} - ${formatDateForDisplay(
                    termDetail.endDate
                )}`,
                value: termDetail,
            };
            return termItem;
        }
    ) as ITermItem[];

    useEffect(() => {
        if (!hasValue(sourceTermIndex)) {
            setSourceTermIndex(0);
        }
    }, [setSourceTermIndex, sourceTermIndex]);

    const onYesClick = useCallback(async () => {
        const sourceTermDetail = termItems.find((x) => x.index === Number(sourceTermIndex));
        const targetTermDetail = termItems.find((x) => x.index === Number(sourceTermIndex) + 1);

        const { isValid } = await validate({
            sourceTerm: sourceTermDetail?.name,
            targetTerm: targetTermDetail?.name,
        });
        if (isValid) {
            await dispatch(
                copyTeamBenefitManageMemberData(
                    sourceTermDetail?.value.id as string,
                    targetTermDetail?.value.id as string
                )
            );
            if (hasValue(selectedTermDetailId)) {
                dispatch(pageUserTermDetails(selectedTermDetailId, teamId, paginationParams));
            }
            onClose();
        }
    }, [
        dispatch,
        onClose,
        paginationParams,
        selectedTermDetailId,
        sourceTermIndex,
        teamId,
        termItems,
        validate,
    ]);

    const { sourceTermItems, targetTermItems } = useMemo(() => {
        const defaultTermItems: { sourceTermItems: ITermItem[]; targetTermItems: ITermItem[] } = {
            sourceTermItems: termItems,
            targetTermItems: termItems,
        };
        defaultTermItems.targetTermItems = [...defaultTermItems.targetTermItems.slice(1)];
        defaultTermItems.sourceTermItems = [...defaultTermItems.sourceTermItems.slice(0, -1)];
        if (hasValue(sourceTermIndex)) {
            defaultTermItems.targetTermItems = termItems.filter(
                (term) => term.index === Number(sourceTermIndex) + 1
            );
        }
        return defaultTermItems;
    }, [sourceTermIndex, termItems]);

    return (
        <TypedConfirmationModal
            body={
                <Stack gap={2}>
                    <b>
                        Are you sure you want to copy the source term&#39;s member data to the
                        target term?
                    </b>
                    <b>
                        This will copy the Status, Coverage Level, and Household Members Covered for
                        each member to the next term, and it WILL overwrite any values already in
                        that term.
                    </b>
                    <b>
                        For any member in the &quot;Enrolled&quot; status, their Coverage Start Date
                        in the next term will be set to the next term&#39;s Start Date.
                    </b>
                    <b>Costs and contributions will be recalculated for the next term.</b>
                    <Select
                        defaultText=""
                        defaultValue=""
                        errors={errors?.sourceTerm}
                        items={sourceTermItems}
                        label="Source Term"
                        name="sourceTerm"
                        onChange={onChange(setSourceTermIndex)}
                        optionText="name"
                        optionValue="index"
                        value={sourceTermIndex}
                    />
                    <Select
                        defaultText=""
                        defaultValue=""
                        disabled
                        errors={errors?.targetTerm}
                        items={targetTermItems}
                        label="Target Term"
                        name="targetTerm"
                        onChange={() => null}
                        optionText="name"
                        optionValue="index"
                        value={undefined}
                    />
                </Stack>
            }
            onNoClick={onClose}
            onYesClick={onYesClick}
            showActivity={showActivity}
            textToMatch={CONFIRMATION_TEXT}
            title="Copy Member Benefit Data"
        />
    );
};

export default hot(module)(TeamBenefitCopyMemberBenefitDataModal);
