import { Stack } from '@mui/material';
import { SET_USER_STATUS_ACTION, setUserStatus } from 'actions/user/setUserStatus';
import { UserStatus } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import Checkbox from 'components/Checkbox';
import ConfirmationModal from 'components/ConfirmationModal';
import Typography from 'components/Typography';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import useUserProps from 'hooks/useUserProps';
import { hasValue } from 'utilities/index';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';

const InactiveConfirmationModal = ({
    isCurrent,
    isRhMember,
    onClose,
    user,
}: {
    isCurrent: boolean;
    isRhMember: boolean;
    onClose: () => void;
    user: IUser | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const { hasUserIdUrlParam } = useUserProps();
    const [
        sendConfirmationEmailToMemberAndTeamAdmin,
        setSendConfirmationEmailToMemberAndTeamAdmin,
    ] = useState(true);
    const { peopleState, selectedYear, showActivity } = useSelector((state: AppStore) => ({
        peopleState: state.peopleState,
        selectedYear: +state.profileState.selectedYear,
        showActivity: hasApiActivity(state, SET_USER_STATUS_ACTION),
    }));

    const inactiveConfirmationText = (userData: IUser | undefined) => (
        <div>
            <p>
                You are about to set {userData?.displayName} to Inactive status. This status is for
                a member who is no longer employed with the organization. If the member is still
                employed with the organization, but simply does not need Remodel Health services
                then &quot;Waived&quot; is the more appropriate status.
            </p>

            <p>
                Once this action is confirmed the Team Member will no longer be able to log in. The
                Team Member&apos;s data will still stay in the platform and you can access it via
                the Team Members page using the &quot;Inactive&quot; filter status.
            </p>

            <p>
                Ensure you update any relevant Member and Plan data for this Member to keep the
                platform accurate as well as confirm with Finance for billing and payments reasons.
            </p>
        </div>
    );
    const inactiveRhMemberConfirmationText = (userData: IUser | undefined) => (
        <div>
            <p>
                You are about to set {userData?.displayName} to Inactive status. This status is for
                a member who is no longer employed with Remodel Health.
            </p>
            <p>
                Once this action is confirmed the Team Member will no longer be able to log in. The
                Team Member&apos;s data will still stay in the platform.
            </p>
            <p>
                If this member is set as a representative for a team, a new representative should be
                assigned for that team.
            </p>
        </div>
    );
    const confirmationModalText = isRhMember
        ? inactiveRhMemberConfirmationText(user)
        : inactiveConfirmationText(user);

    const onYesClick = useCallback(async () => {
        const toastSuccessMessage = 'Successfully set user to Inactive status';
        await dispatch(
            setUserStatus(
                user?.userId,
                UserStatus.Inactive,
                isCurrent,
                {
                    toastSuccessMessage,
                },
                sendConfirmationEmailToMemberAndTeamAdmin
            )
        );
        if (!hasUserIdUrlParam && hasValue(user?.teamId)) {
            await dispatch(
                listPagedUserProfiles((user as IUser).teamId, selectedYear, peopleState)
            );
        }
        onClose();
    }, [
        dispatch,
        hasUserIdUrlParam,
        isCurrent,
        onClose,
        peopleState,
        selectedYear,
        sendConfirmationEmailToMemberAndTeamAdmin,
        user,
    ]);

    return (
        <ConfirmationModal
            body={
                <React.Fragment>
                    {confirmationModalText}
                    <Stack direction="column">
                        <Checkbox
                            checked={sendConfirmationEmailToMemberAndTeamAdmin}
                            className="m-0 ml-1"
                            label="Send Confirmation Email To Member and Team Admin"
                            onClick={() =>
                                setSendConfirmationEmailToMemberAndTeamAdmin(
                                    !sendConfirmationEmailToMemberAndTeamAdmin
                                )
                            }
                        />
                        <Typography align="center" variant="caption">
                            An email will be sent to the Member and Team Admin summarizing this
                            action.
                        </Typography>
                    </Stack>
                </React.Fragment>
            }
            onNoClick={onClose}
            onYesClick={onYesClick}
            showActivity={showActivity}
            title="Set Member Inactive"
        />
    );
};

export default hot(module)(InactiveConfirmationModal);
