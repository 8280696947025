import TextField, { ITextFieldProps } from 'components/TextField';
import React, { useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader';

const AutoFocusTextField = (props: ITextFieldProps) => {
    const ref = useRef<HTMLElement>(null);
    useEffect(() => {
        ref?.current?.focus?.();
    }, []);
    return <TextField {...props} inputRef={ref} />;
};

export default hot(module)(AutoFocusTextField);
