import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

type INoRowsMessage = {
    actionText?: string;
    buttonText?: string;
    canTakeAction?: boolean;
    onAction?: () => void;
    text: string;
};

const NoRowsMessage = ({
    actionText,
    buttonText,
    canTakeAction = false,
    onAction,
    text,
}: INoRowsMessage) => (
    <Card>
        <CardContent>
            <Typography textAlign="center" variant="h3">
                {text}
            </Typography>
            {canTakeAction && (
                <React.Fragment>
                    <Typography textAlign="center" variant="h3">
                        {actionText}
                    </Typography>
                    <Row className="justify-content-center">
                        <Button data-cy="add-pathway" onClick={onAction} variant="outlined">
                            {buttonText}
                        </Button>
                    </Row>
                </React.Fragment>
            )}
        </CardContent>
    </Card>
);
export default hot(module)(NoRowsMessage);
