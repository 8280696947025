import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import TextField from 'components/TextField';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';

const recursiveMap = (children: ReactNode[], fn: (child: ReactNode) => ReactNode): ReactNode[] =>
    React.Children.map(children, (child) => {
        if (!React.isValidElement(child) || typeof child.props.children === 'string') {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, ({
                children: recursiveMap(child.props.children, fn),
            } as unknown) as Partial<unknown> & React.Attributes);
        }

        return fn(child);
    });

const Form = ({
    children: parentChildren,
    isLoading,
    onSubmit: parentOnSubmit,
    ...rest
}: React.ComponentProps<'form'> & { isLoading?: boolean }) => {
    const [children, setChildren] = useState(parentChildren);
    useEffect(() => {
        setChildren(
            recursiveMap(parentChildren as ReactNode[], (child) => {
                if (
                    React.isValidElement(child) &&
                    (child?.type === TextField ||
                        child?.type === Button ||
                        child?.type === LoadingButton)
                ) {
                    return React.cloneElement(child, {
                        ...(child.props as object),
                        disabled: isLoading || (child.props as { disabled: boolean })?.disabled,
                    } as Partial<unknown> & React.Attributes);
                }
                return child;
            })
        );
    }, [isLoading, parentChildren]);
    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            parentOnSubmit?.(e);
        },
        [parentOnSubmit]
    );
    return (
        <form noValidate onSubmit={onSubmit} {...rest}>
            <fieldset disabled={isLoading}>{children}</fieldset>
        </form>
    );
};

export default hot(module)(Form);
