import { hideNewVersionModal } from 'components/newVersion/newVersionActions';
import NewVersionModal from 'components/newVersion/NewVersionModal';
import DesktopContext from 'contexts/DesktopContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { clearCacheAndReload } from 'utilities';

const NewVersion = () => {
    const isDesktop = useContext(DesktopContext);
    const dispatch = useThunkDispatch();
    const { isNewVersionModalVisible } = useSelector((state: AppStore) => ({
        isNewVersionModalVisible: state.newVersionState.isNewVersionModalVisible,
    }));
    const onNoClick = useCallback(() => {
        if (!isDesktop) {
            document.body.classList.add('sidebar-enable');
        }
        dispatch(hideNewVersionModal());
    }, [dispatch, isDesktop]);
    const onYesClick = useCallback(() => clearCacheAndReload(), []);
    if (isNewVersionModalVisible) {
        return <NewVersionModal onNoClick={onNoClick} onYesClick={onYesClick} />;
    }
    return <React.Fragment />;
};

export default hot(module)(NewVersion);
