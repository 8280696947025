import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { GET_BENEFITS_ACTION } from 'actions/benefits/getBenefits';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import MajorMedicalBenefitCostBreakdownModal from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCostBreakdownModal';
import useModalState from 'hooks/useModalState';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';

const MajorMedicalBenefitCostButton = ({
    children,
    costText = 'Your Major Medical Benefit Cost',
    shouldHideDeductibleBenefitText,
    majorMedicalBenefitCost: parentCostData,
}: {
    children?: ReactNode;
    costText?: string;
    majorMedicalBenefitCost?: IMajorMedicalBenefitCostDto;
    shouldHideDeductibleBenefitText?: boolean;
}) => {
    const { isLoading, majorMedicalBenefitCost } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION,
            GET_BENEFITS_ACTION
        ),
        majorMedicalBenefitCost: state.memberBenefits.majorMedicalBenefitCost,
    }));
    const costData = parentCostData ?? majorMedicalBenefitCost;
    const {
        closeModal: closeCostBreakdownModal,
        isVisible: isCostBreakdownModalVisible,
        openModal: openCostBreakdownModal,
    } = useModalState();
    const content = children ?? (
        <Typography noWrap>
            {costText}{' '}
            <Typography color="secondary" component="span">
                {formatCurrency(costData?.preTaxTotalMajorMedicalBenefitCost)}
            </Typography>{' '}
            <Typography component="span" variant="caption">
                per month
            </Typography>
        </Typography>
    );
    return (
        <React.Fragment>
            {isCostBreakdownModalVisible && (
                <MajorMedicalBenefitCostBreakdownModal
                    majorMedicalBenefitCost={costData}
                    onClose={closeCostBreakdownModal}
                    shouldHideDeductibleBenefitText={shouldHideDeductibleBenefitText}
                />
            )}
            <LoadingButton
                endIcon={<InfoOutlinedIcon color="secondary" />}
                loading={isLoading}
                onClick={openCostBreakdownModal}
                size="small"
                variant="text"
            >
                {content}
            </LoadingButton>
        </React.Fragment>
    );
};

export default hot(module)(MajorMedicalBenefitCostButton);
