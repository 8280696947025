import ConfirmationModal, { IConfirmationModalProps } from 'components/ConfirmationModal';
import TextField from 'components/TextField';
import UnselectableText from 'components/UnselectableText';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { onChange } from 'utilities/forms';

type ITypedConfirmationModal = IConfirmationModalProps & {
    confirmationTextFieldDataCy?: string;
    textToMatch: string;
};

const TypedConfirmationModal = ({
    confirmationTextFieldDataCy,
    textToMatch,
    body: parentBody,
    onYesClick: parentOnYesClick,
    ...confirmationModalProps
}: ITypedConfirmationModal) => {
    const [inputValue, setInputValue] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const onYesClick = () => {
        if (inputValue === textToMatch) {
            setIsInvalid(false);
            parentOnYesClick?.();
        } else {
            setIsInvalid(true);
        }
    };
    const body = (
        <React.Fragment>
            {parentBody}
            <br />
            <br />
            <span>
                Please type the phrase{' '}
                <UnselectableText as="strong">{textToMatch}</UnselectableText> exactly as it appears
                to confirm this action.
            </span>
            <br />
            <br />
            <TextField
                autoFocus
                data-cy={confirmationTextFieldDataCy}
                defaultValue={inputValue}
                error={isInvalid}
                helperText={
                    isInvalid
                        ? 'Input text must match the bolded phrase above exactly to confirm this action'
                        : ''
                }
                onChange={onChange(setInputValue)}
            />
        </React.Fragment>
    );

    return (
        <ConfirmationModal
            {...confirmationModalProps}
            body={body}
            noButtonText="Cancel"
            onYesClick={onYesClick}
        ></ConfirmationModal>
    );
};
export default hot(module)(TypedConfirmationModal);
