import Button from 'components/Button';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';

const FormButton = ({
    className,
    'data-cy': dataCy,
    disabled,
    isLoading,
    label,
    onClick,
}: {
    'data-cy'?: string;
    className?: string;
    disabled?: boolean;
    isLoading?: boolean;
    label: string;
    onClick?: React.MouseEventHandler;
}) => (
    <Row className={`justify-content-center form-group mb-0 text-center ${className}`}>
        <Button
            data-cy={dataCy}
            disabled={disabled}
            isLoading={isLoading}
            onClick={onClick}
            type="submit"
        >
            {label}
        </Button>
    </Row>
);

export default hot(module)(FormButton);
