import Typography from '@mui/material/Typography';
import InformationIconTooltip from 'components/InformationIconTooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

const ReimbursementMyCostTooltip = ({
    memberCost,
    planCost,
    reimbursement,
    title = 'Your Cost',
}: {
    memberCost: number | undefined;
    planCost: number | undefined;
    reimbursement: number | undefined;
    title?: string;
}) => (
    <InformationIconTooltip
        className="ml-1"
        data-cy="my-cost-tooltip-trigger"
        title={
            <span data-cy="my-cost-tooltip">
                <Typography variant="h6">{title}</Typography>
                <p>
                    This is the monthly cost for you to be on this benefit considering how much will
                    be reimbursed by your employer.
                </p>
                <p>
                    The price of this benefit is{' '}
                    {formatCurrency(planCost, { preserveDecimal: true })}. Your employer will
                    reimburse your premiums up to{' '}
                    {formatCurrency(reimbursement, { preserveDecimal: true })}. That makes the final
                    cost to you {formatCurrency(memberCost, { preserveDecimal: true })}
                </p>
            </span>
        }
    />
);

export default hot(module)(ReimbursementMyCostTooltip);
