import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const CursorDefault = styled.span`
    cursor: default;
`;

const DoubleBangIcon = ({ className }: { className?: string }) => (
    <CursorDefault className={`h3 my-0 text-danger ${className}`}>‼️</CursorDefault>
);

export default hot(module)(DoubleBangIcon);
