import {
    getMediSharePlansAndRatesForUser,
    GET_MEDISHARE_PLANS_FOR_USER_ACTION,
} from 'actions/medishare/getMediSharePlansAndRatesForUser';
import CommonPlanInputs from 'components/planModal/CommonPlanInputs';
import { IGetCommonPropsPlanInputs } from 'components/planModal/PlanInputs';
import Select from 'components/Select';
import Skeleton from 'components/Skeleton';
import { ahpOptions, legacyAhpOptions } from 'constants/ahpOptions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import React, { useCallback, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';

const ONE_THOUSAND_MILLISECONDS = 1000;
const MediSharePlanInputs = ({
    getCommonProps,
    isEdit,
}: {
    getCommonProps: IGetCommonPropsPlanInputs;
    isEdit: boolean;
}) => {
    const dispatch = useThunkDispatch();
    const allAhpOptions = useMemo(() => [...ahpOptions, ...legacyAhpOptions], []);
    const { userId } = useUserProps();
    const {
        householdMemberIdsForSelectedYear,
        isLoading,
        planInputs,
        selectedHouseholdMemberIds,
    } = useSelector((state: AppStore) => ({
        householdMemberIdsForSelectedYear: state.householdMembers
            .filter((x) => x.haveDataForYear)
            .map((x) => x.householdMemberId)
            .concat(userId),
        isLoading: hasApiActivity(state, GET_MEDISHARE_PLANS_FOR_USER_ACTION),
        planInputs: state.planModalState.planInputs,
        selectedHouseholdMemberIds: state.planModalState.selectedHouseholdMemberIds,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceGetMediSharePlansAndRatesForUser = useCallback(
        debounce((id: string, year: number, excludingMembers: string[]) => {
            dispatch(getMediSharePlansAndRatesForUser(id, year, false, excludingMembers));
        }, ONE_THOUSAND_MILLISECONDS),
        []
    );
    useEffect(() => {
        if (hasValue(planInputs.selectedYear) && selectedHouseholdMemberIds.length > 0 && !isEdit) {
            debounceGetMediSharePlansAndRatesForUser(
                userId,
                parseInt(planInputs.selectedYear),
                difference(householdMemberIdsForSelectedYear, selectedHouseholdMemberIds)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, planInputs.selectedYear, planInputs.planType, selectedHouseholdMemberIds]);
    return (
        <Skeleton count={2} height="54px" isEnabled={isLoading}>
            <Select
                {...getCommonProps('Annual Household Portion (AHP)', 'selectedAhp')}
                data-cy="annual-household-portion"
                defaultText="Choose an Option"
                defaultValue=""
                disabled={isEdit}
                items={allAhpOptions}
                optionText="name"
                optionValue="id"
            />
            <CommonPlanInputs isMediShare />
        </Skeleton>
    );
};

export default hot(module)(MediSharePlanInputs);
