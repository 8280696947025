import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { searchDrugs, SEARCH_DRUGS_ACTION } from 'actions/marketplacePlan/searchDrugs';
import { IDrug } from 'api/generated/models';
import { IAutocompleteProps } from 'components/Autocomplete';
import {
    HighlightedSearchText,
    ICreatableAutocompleteProps,
} from 'components/creatableAutocomplete/CreatableAutocomplete';
import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const getDrugRenderOptions: IAutocompleteProps<
    Partial<IDrug>,
    true,
    false,
    false
>['renderOption'] = (props, { fullName, name, route, rxcui, strength }, state) => (
    <li
        {...props}
        key={`${rxcui}-${kebabCase(fullName)}-${kebabCase(route)}-${kebabCase(strength)}`}
    >
        <Stack borderBottom={0.5} pb={1} width="100%">
            <Box pb={1}>
                <HighlightedSearchText query={state.inputValue} text={startCase(lowerCase(name))} />
            </Box>
            {fullName && (
                <Typography pb={1} variant="caption">
                    <strong>Full Name:</strong> {fullName}
                </Typography>
            )}
            {strength && route && (
                <Typography variant="caption">
                    {strength} - {route}
                </Typography>
            )}
        </Stack>
    </li>
);

const useDrugAutocomplete = ({
    onChange,
    value,
}: {
    onChange?: ICreatableAutocompleteProps<IDrug>['onChange'];
    value?: IDrug[];
}): ICreatableAutocompleteProps<IDrug> => {
    const { loading, options } = useSelector((state: AppStore) => ({
        loading: hasApiActivity(state, SEARCH_DRUGS_ACTION),
        options: state.drugs,
    }));
    const search = useCallback((query) => searchDrugs(query), []);
    return {
        loading,
        onChange,
        options,
        search,
        value,
        helperText: 'Add prescription(s) that are important to your household.',
        isOptionEqualToValue: (option: Partial<IDrug>, _value: Partial<IDrug>) => {
            const optionKey = typeof option === 'string' ? option : option.rxcui ?? option.name;
            const valueKey = typeof _value === 'string' ? _value : _value.rxcui ?? _value.name;
            return optionKey === valueKey;
        },
        label: 'Search Rx',
        renderOption: getDrugRenderOptions,
    };
};

export default useDrugAutocomplete;
