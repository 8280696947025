import { UserInfoStatus } from 'api/generated/enums';
import { IUser } from 'api/generated/models';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Tooltip from 'components/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';

const UserInfoTableCell = ({
    user,
    includeBasicInfoToolTipText = true,
}: {
    includeBasicInfoToolTipText?: boolean;
    user: IUser | undefined;
}) => {
    if (user?.userInfoStatus === UserInfoStatus.MemberVerifiedInfo) {
        return (
            <Tooltip
                title={
                    <span>
                        We have received data from the Health Benefits Analysis (HBA) for this
                        member and we have the ideal level of information needed to calculating
                        accurate costs.
                    </span>
                }
            >
                <span className="color text-success">✔</span>
            </Tooltip>
        );
    } else if (user?.userInfoStatus === UserInfoStatus.MemberQuoteInfo) {
        return (
            <Tooltip
                title={
                    <span>
                        Quote-level information has been provided, which is sufficient for
                        calculating costs, but won&apos;t always provide as accurate cost as
                        verified info. Try the Take/Send Survey(s) actions on the &quot;Team
                        Members&quot; page to provide/receive verified info.
                    </span>
                }
            >
                <span className="color text-warning">ⓘ</span>
            </Tooltip>
        );
    } else {
        return (
            <ConditionalTooltip
                isDisabled={!includeBasicInfoToolTipText}
                title={
                    <span>
                        This member has only basic information, which is insufficient for
                        calculating costs. Try the &quot;Add Quote Info&quot; action for the member
                        on the &quot;Team Members&quot; page to provide sufficient information for
                        calculating costs.
                    </span>
                }
            >
                <span className="color text-secondary">ⓘ</span>
            </ConditionalTooltip>
        );
    }
};

export default hot(module)(UserInfoTableCell);
