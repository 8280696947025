import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { ViewEnrollmentVerification } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import MemberTabs from 'components/authenticatedPageWrapper/MemberTabs';
import TeamTabs from 'components/authenticatedPageWrapper/TeamTabs';
import useExternalUserProps from 'components/authenticatedPageWrapper/useExternalUserProps';
import VipChip from 'components/chip/VipChip';
import NeedsFollowUpPill from 'components/NeedsFollowUpPill';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import {
    isAdvisorSelector,
    isRhSelector,
    isTeamAdminSelector,
    isUserInRoleSelector,
} from 'selectors/role';

const TabBarHeader = () => {
    const { hasUserIdUrlParam, user } = useExternalUserProps();
    const {
        areVerificationRhFilesNeeded,
        canViewEnrollmentVerification,
        canViewVip,
        isTeamAdmin,
        isTeamPayrollAdmin,
        showRestrategized,
    } = useSelector((state: AppStore) => ({
        areVerificationRhFilesNeeded: state.userProfile.areVerificationRhFilesNeeded,
        canViewEnrollmentVerification: hasSomePermissions(state, ViewEnrollmentVerification),
        canViewVip: isRhSelector(state) || isAdvisorSelector(state) || isTeamAdminSelector(state),
        isTeamAdmin: isUserInRoleSelector(state, user?.userId, ROLE_IDS.TEAM_ADMIN),
        isTeamPayrollAdmin: isUserInRoleSelector(state, user?.userId, ROLE_IDS.TEAM_PAYROLL_ADMIN),
        showRestrategized:
            (isRhSelector(state) || isAdvisorSelector(state)) &&
            state.pathwayUser?.useRestrategizedRenewalFlow,
    }));

    if (hasUserIdUrlParam) {
        return (
            <React.Fragment>
                <Box pl={2} pt={2}>
                    <Typography
                        alignItems="center"
                        display="flex"
                        fontWeight="500"
                        m={0}
                        variant="h3"
                    >
                        {user?.displayName}
                        {canViewEnrollmentVerification && areVerificationRhFilesNeeded && (
                            <Chip
                                color="warning"
                                label="Docs Needed"
                                size="small"
                                sx={{ ml: 1 }}
                                variant="outlined"
                            />
                        )}
                        <NeedsFollowUpPill user={user} />
                        {isTeamAdmin && (
                            <Chip
                                color="info"
                                label="Team Admin"
                                size="small"
                                sx={{ ml: 1 }}
                                variant="outlined"
                            />
                        )}
                        {isTeamPayrollAdmin && (
                            <Chip
                                color="info"
                                label="Team Payroll Admin"
                                size="small"
                                sx={{ ml: 1 }}
                                variant="outlined"
                            />
                        )}
                        {canViewVip && user?.isVip && <VipChip sx={{ ml: 1 }} variant="outlined" />}
                        {showRestrategized && (
                            <Tooltip
                                title={
                                    "This member's pathway has been restrategized for renewal. The member will be presented with their " +
                                    'pathway benefit during their renewal flow instead of being asked whether they want to keep or change their current plan.'
                                }
                            >
                                <Chip
                                    color="secondary"
                                    label="Restrategized"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    variant="outlined"
                                />
                            </Tooltip>
                        )}
                    </Typography>
                </Box>
                <MemberTabs />
            </React.Fragment>
        );
    }
    return <TeamTabs />;
};

export default hot(module)(TabBarHeader);
