import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FileResponse, IRhFile, IRhFileDto } from 'api/generated/models';
import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import FilePreviewUnsupported from 'components/rhFile/FilePreviewUnsupported';
import Skeleton from 'components/Skeleton';
import { SPREADSHEET_EXTENSIONS, VIDEO_EXTENSIONS } from 'constants/fileExtensions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { hot } from 'react-hot-loader';

type IRhFilePreviewModal = {
    canDownloadRhFiles: boolean;
    downloadRhFile: () => Promise<AxiosResponse<FileResponse>>;
    onClose: () => void;
    rhFile: IRhFile | IRhFileDto;
};
const RhFilePreviewModal = ({
    canDownloadRhFiles,
    rhFile: { fileExtension, name },
    downloadRhFile,
    onClose,
}: IRhFilePreviewModal) => {
    const [fileName, setFileName] = useState<string | undefined>('');
    const [filePath, setFilePath] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const fileType = fileExtension?.toLowerCase().replace('.', '');
    useEffect(() => {
        downloadRhFile?.().then(({ data: { data: blob, fileName: newFileName } }) => {
            setFileName(newFileName);
            setFilePath(URL.createObjectURL(blob));
            setIsLoading(false);
        });
        return () => URL.revokeObjectURL(filePath);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isTypeInExtensions = useCallback(
        (extensions: typeof SPREADSHEET_EXTENSIONS | typeof VIDEO_EXTENSIONS) =>
            ((extensions as unknown) as string[]).map((x) => x.replace('.', '')).contains(fileType),
        [fileType]
    );
    const isSpreadSheet = useMemo(() => isTypeInExtensions(SPREADSHEET_EXTENSIONS), [
        isTypeInExtensions,
    ]);
    const isVideo = useMemo(() => isTypeInExtensions(VIDEO_EXTENSIONS), [isTypeInExtensions]);
    return (
        <Dialog fullWidth id="rh-file-preview-modal" maxWidth="xl" onClose={onClose} open>
            <DialogTitle>{name}</DialogTitle>
            <DialogContent
                dividers
                sx={{ backgroundColor: (theme) => theme.palette.grey['200'], height: 1000 }}
            >
                <Skeleton height={900} isEnabled={isLoading}>
                    {isSpreadSheet ? (
                        <div className="bg-white text-center py-6">
                            <FilePreviewUnsupported fileType={fileType} />
                        </div>
                    ) : isVideo ? (
                        <video className="h-100 w-100" controls src={filePath}></video>
                    ) : filePath ? (
                        <FileViewer
                            filePath={filePath}
                            fileType={fileType}
                            unsupportedComponent={FilePreviewUnsupported}
                        />
                    ) : (
                        ''
                    )}
                </Skeleton>
            </DialogContent>
            <DialogActions>
                {canDownloadRhFiles && (
                    <Button
                        data-cy="file-preview-download"
                        download={fileName}
                        href={filePath}
                        variant="text"
                    >
                        Download
                    </Button>
                )}
                <Button data-cy="file-preview-close" onClick={onClose} variant="text">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(RhFilePreviewModal);
