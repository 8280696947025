import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
    BULK_SET_SURVEY_TYPE_TO_SEND_ACTION,
    bulkSetSurveyTypeToSend,
} from 'actions/team/bulkSetSurveyTypeToSend';
import { SurveyTypes } from 'api/generated/enums';
import ConfirmationModal from 'components/ConfirmationModal';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray } from 'utilities';
import { onChange } from 'utilities/forms';

const SURVEY_TYPE_ITEMS = enumToNameValueArray(SurveyTypes);
const SetSurveyTypeToSendForTeamModal = ({ close }: { close: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(state, BULK_SET_SURVEY_TYPE_TO_SEND_ACTION),
    }));
    const [surveyTypeToSend, setSurveyTypeToSend] = useState(SurveyTypes.Standard);
    const onYesClickSetSurveyTypeToSend = useCallback(async () => {
        await dispatch(bulkSetSurveyTypeToSend(teamId, surveyTypeToSend));
        close();
    }, [surveyTypeToSend, close, dispatch, teamId]);

    const body = (
        <React.Fragment>
            <Stack gap={2}>
                <Typography mb={3} mt={1} mx={1} variant="body2">
                    Please select the value you would like to set{' '}
                    <strong>Survey Type To Send</strong> to for all members.
                </Typography>
                <Select
                    data-cy="set-survey-type-to-send-dropdown"
                    items={SURVEY_TYPE_ITEMS}
                    label="Survey Type To Send?"
                    onChange={onChange(setSurveyTypeToSend)}
                    optionText="name"
                    optionValue="value"
                    value={surveyTypeToSend.toString()}
                />
            </Stack>
        </React.Fragment>
    );

    return (
        <ConfirmationModal
            body={body}
            noButtonText="Cancel"
            onNoClick={close}
            onYesClick={onYesClickSetSurveyTypeToSend}
            showActivity={showActivity}
            title="Set Survey Type To Send For All Members on Team"
            yesButtonText="Confirm"
        />
    );
};

export default hot(module)(SetSurveyTypeToSendForTeamModal);
