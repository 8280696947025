import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

export type ISpinnerProps = {
    color?: string;
    size?: number | string;
};

const Spinner = ({ color, size }: ISpinnerProps) => {
    const additionalClasses = color ? `stroke-${color}` : '';
    return (
        <Loader size={size}>
            <Svg>
                <AnimatedCircle
                    className={`path ${additionalClasses}`}
                    color={color}
                    cx="40"
                    cy="40"
                    fill="none"
                    r="18"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
            </Svg>
        </Loader>
    );
};

const AnimatedCircle = styled.circle<{ color: string | undefined }>`
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite
        ${({ color }) => (color ? '' : ', color 6s ease-in-out infinite')};
    stroke-linecap: round;
`;

const Loader = styled.div<{ size: number | string | undefined }>`
    position: relative;
    margin: 0 auto;
    width: 100px;
    &:before {
        content: '';
        display: block;
        padding-top: ${({ size }) => (size ? size + '%' : '100%')};
    }
`;

const Svg = styled.svg.attrs(() => ({
    viewBox: '20 20 40 40',
}))`
    animation: rotate 2s linear infinite;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center center;
    width: 100%;
`;

export default hot(module)(Spinner);
