import { reopenBenefitsSelection } from 'actions/taskFlows/reopenBenefitsSelection';
import { getHousehold } from 'actions/user/getHousehold';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';

const ReopenBenefitsSelectionModal = ({
    onClose,
    showActivity,
    userId,
    year,
}: {
    onClose: () => void;
    showActivity: boolean;
    userId: string;
    year: number;
}) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user } = useUserProps();
    const body = (
        <span>
            This will re-open the member&apos;s enrollment flow and move the member to the shopping
            experience.
            <br />
            <br />
            Reminder: if the member has previously submitted plan selections for{' '}
            {user?.activeDate?.getYear()} on their My Benefits page, you may want to remove them to
            avoid confusion with their new selections.
        </span>
    );

    const handleYesClick = async () => {
        await dispatch(reopenBenefitsSelection(userId));
        await dispatch(getHousehold(userId, isCurrent, year));
        onClose();
    };

    return (
        <ConfirmationModal
            body={body}
            onNoClick={onClose}
            onYesClick={handleYesClick}
            showActivity={showActivity}
            title="Confirm"
        />
    );
};

export default hot(module)(ReopenBenefitsSelectionModal);
