import { GET_SHARED_RESOURCES_ACTION } from 'actions/resources/getSharedResources';
import { IResource } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import ResourceCard from 'pages/resourceManagement/ResourceCard';
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';

type IResourceCards = {
    onClick?: (resource?: IResource) => () => void;
    search?: string;
    showMenu?: boolean;
    xl: Col['props']['xl'];
};
const ResourceCards = ({ onClick, search, showMenu, xl }: IResourceCards) => {
    const { isLoading, resources } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, GET_SHARED_RESOURCES_ACTION),
        resources: state.resources,
    }));
    let content: ReactNode = <div>Check back periodically for new learning resources.</div>;
    if (resources.length > 0) {
        content = resources
            .filter((resource) =>
                hasValue(search)
                    ? resource.title?.toLowerCase().includes(search.toLowerCase())
                    : true
            )
            .map((resource) => (
                <Col className="d-flex" key={resource.id} xl={xl}>
                    <ResourceCard
                        id={resource.id}
                        onClick={onClick?.(resource)}
                        showMenu={showMenu}
                        title={resource.title}
                        url={resource.url}
                    ></ResourceCard>
                </Col>
            ));
    }
    return (
        <Skeleton
            count={3}
            height="132px"
            isEnabled={isLoading}
            Wrapper={({ children }) => <div className="col-12 mb-3">{children}</div>}
        >
            {content}
        </Skeleton>
    );
};

export default hot(module)(ResourceCards);
