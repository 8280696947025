/* eslint-disable react/jsx-key */
import classnames from 'classnames';
import HorizontalScrollGradientIndicator from 'components/HorizontalScrollGradientIndicator';
import { addExpansionColumnHook } from 'components/reactTable/addExpansionColumnHook';
import { addPaginatedSelectionColumnHook } from 'components/reactTable/addPaginatedSelectionColumnHook';
import addResetPageOnFilterChangeHook from 'components/reactTable/addResetPageOnFilterChangeHook';
import { addSelectionColumnHook } from 'components/reactTable/addSelectionColumnHook';
import Pagination from 'components/reactTable/Pagination';
import Skeleton from 'components/Skeleton';
import React, { ReactNode, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import {
    Column,
    Filters,
    FilterTypes,
    HeaderGroup,
    PluginHook,
    Row,
    RowPropGetter,
    SortingRule,
    TableRowProps,
    TableState,
    useAsyncDebounce,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import 'scss/react-table.scss';
import { hasValue, isFalse, isTrue } from 'utilities';

const DEFAULT_PAGE_SIZE = 10;
export const GLOBAL_SEARCH_DEBOUNCE_MILLISECONDS = 200;

const renderRows = <T extends object>(
    page: Row<T>[],
    rowsWhenNoData: number,
    prepareRow: (r: Row<T>) => void,
    getCustomRowProps?: (r: Row<T>) => RowPropGetter<T>,
    getSubComponent?: (r: Row<T>) => ReactNode
) => {
    if (page.length === 0) {
        return Array.from({ length: rowsWhenNoData }, (_, index) => (
            <div
                className={classnames('py-3', 'rt-tr', index % 2 === 0 ? '-odd' : '-even')} //NOSONAR
                key={index}
            ></div>
        ));
    }

    return page.map((row, index) => {
        prepareRow(row);
        return (
            <React.Fragment key={row.id}>
                <div
                    {...row.getRowProps(getCustomRowProps?.(row))}
                    className={classnames('rt-tr', index % 2 === 0 ? '-odd' : '-even')} //NOSONAR
                >
                    {row.cells.map((cell) => (
                        <div
                            {...cell.getCellProps()}
                            className={classnames('rt-td', cell.column.className)}
                        >
                            {cell.render('Cell')}
                        </div>
                    ))}
                </div>
                {row.isExpanded && getSubComponent?.(row)}
            </React.Fragment>
        );
    });
};

const createHeader = <T extends object>(column: HeaderGroup<T>) => {
    const headerClasses = classnames('rt-th rt-resizable-header', column.headerClassName, {
        '-sort-asc': isFalse(column.isSortedDesc),
        '-sort-desc': isTrue(column.isSortedDesc),
    });
    return (
        <div {...column.getHeaderProps()} className={headerClasses}>
            <div {...column.getSortByToggleProps()} className="rt-resizable-header-content">
                {column.render('Header')}
            </div>
            {column.canResize && <div {...column.getResizerProps()} className="rt-resizer" />}
        </div>
    );
};

const createFooter = <T extends object>(column: HeaderGroup<T>) => (
    <div {...column.getFooterProps()} className="p-2 text-center">
        {column.render('Footer')}
    </div>
);

export type IReactTableProps<T extends object> = {
    allPossibleRowIds?: string[];
    autoResetOnDataChange?: boolean;
    canRowExpand?: (r: Row<T>) => boolean;
    checkedRowIds?: string[];
    className?: string;
    columns: Column<T>[];
    data: T[];
    filterTypes?: FilterTypes<T>;
    filters?: Filters<T>;
    getCustomRowProps?: (r: Row<T>) => TableRowProps;
    getRowId?: (originalRow: T, relativeIndex: number, parent?: Row<T>) => string;
    getSubComponent?: (r: Row<T>) => ReactNode;
    getSubRows?: (r: T, relativeIndex: number) => T[];
    globalFilter?: string;
    globalFilterType?: string;
    hiddenColumns?: string[];
    hideAllPageSizeOption?: boolean;
    initialFilters?: Filters<T>;
    initialSortBy?: SortingRule<T>[];
    isPaginationEnabled?: boolean;
    isRowExpandingEnabled?: boolean;
    isRowSelectionEnabled?: boolean;
    loading?: boolean;
    manualPagination?: boolean;
    noRowsMessage?: string;
    onFetchData?: (state: TableState<T>) => void;
    onSelectedRowsChanged?: (selectedRows: Row<T>[]) => void;
    pageSize?: number;
    rowsWhenNoData?: number;
    setCheckedRowIds?: (checkedRows: string[]) => void;
    totalCount?: number;
};

const ReactTable = <T extends object>({
    allPossibleRowIds = [],
    autoResetOnDataChange = false,
    canRowExpand = () => false,
    className,
    columns,
    data,
    filters = [],
    filterTypes,
    getCustomRowProps,
    getRowId,
    getSubComponent,
    getSubRows,
    globalFilter,
    globalFilterType,
    hiddenColumns = [],
    hideAllPageSizeOption = false,
    initialFilters = [],
    checkedRowIds = undefined,
    initialSortBy = [],
    isPaginationEnabled = true,
    isRowExpandingEnabled = false,
    isRowSelectionEnabled = false,
    loading,
    manualPagination = false,
    noRowsMessage = 'No rows found',
    onFetchData,
    onSelectedRowsChanged,
    pageSize: parentPageSize = DEFAULT_PAGE_SIZE,
    rowsWhenNoData = DEFAULT_PAGE_SIZE,
    setCheckedRowIds,
    totalCount,
}: IReactTableProps<T>) => {
    const plugins: PluginHook<T>[] = [
        useResizeColumns,
        useFlexLayout,
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        addResetPageOnFilterChangeHook,
    ];

    if (isRowSelectionEnabled) {
        if (manualPagination && hasValue(setCheckedRowIds)) {
            plugins.push(
                addPaginatedSelectionColumnHook(
                    checkedRowIds ?? [],
                    setCheckedRowIds,
                    allPossibleRowIds
                )
            );
        } else {
            plugins.push(useRowSelect);
            plugins.push(addSelectionColumnHook(onSelectedRowsChanged));
        }
    }

    if (isRowExpandingEnabled) {
        plugins.push(addExpansionColumnHook(canRowExpand));
    }

    const initialPageSize = parentPageSize > 0 ? parentPageSize : 1;
    const controlledPageCount = manualPagination
        ? Math.ceil((totalCount ?? 0) / initialPageSize)
        : undefined;
    const {
        canNextPage,
        canPreviousPage,
        footerGroups,
        getTableBodyProps,
        getTableProps,
        gotoPage,
        headerGroups,
        nextPage,
        prepareRow,
        page,
        pageCount,
        previousPage,
        rows,
        setFilter,
        setGlobalFilter,
        setPageSize,
        state,
    } = useTable<T>(
        {
            columns,
            data,
            filterTypes,
            getRowId,
            getSubRows,
            manualPagination,
            autoResetFilters: autoResetOnDataChange,
            autoResetPage: autoResetOnDataChange,
            autoResetSortBy: autoResetOnDataChange,
            defaultColumn: { Footer: <React.Fragment /> },
            globalFilter: globalFilterType,
            initialState: {
                hiddenColumns,
                filters: initialFilters,
                pageSize: initialPageSize,
                sortBy: initialSortBy,
            },
            manualFilters: manualPagination,
            manualSortBy: manualPagination,
            pageCount: controlledPageCount,
            paginateExpandedRows: false,
        },
        ...plugins
    );
    const { pageIndex, pageSize } = state;

    useEffect(() => {
        filters.forEach((f) => {
            setFilter(f.id, f.value);
        });
    }, [filters, setFilter]);

    useEffect(() => {
        const size = parentPageSize > 0 ? parentPageSize : 1;
        setPageSize(size);
    }, [parentPageSize, setPageSize]);

    const onFetchDataDebounced = useAsyncDebounce(
        (state: TableState<T>) => onFetchData?.(state),
        GLOBAL_SEARCH_DEBOUNCE_MILLISECONDS
    );
    useEffect(() => {
        onFetchDataDebounced?.(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onFetchDataDebounced, state.pageIndex, state.pageSize, state.filters, state.sortBy]);

    const setGlobalFilterDebounced = useAsyncDebounce(
        setGlobalFilter,
        GLOBAL_SEARCH_DEBOUNCE_MILLISECONDS
    );
    useEffect(() => {
        setGlobalFilterDebounced(globalFilter);
    }, [globalFilter, setGlobalFilterDebounced]);

    const isPaginationVisible = isPaginationEnabled && !loading && page.length > 0;

    const actualPageCount = manualPagination ? Math.ceil((totalCount ?? 0) / pageSize) : pageCount;
    return (
        <div className={classnames('ReactTable', className)}>
            <HorizontalScrollGradientIndicator>
                <div {...getTableProps()} className="rt-table">
                    <div className="rt-inner">
                        <div className="rt-thead -header">
                            {headerGroups.map((headerGroup) => (
                                <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                                    {headerGroup.headers.map(createHeader)}
                                </div>
                            ))}
                        </div>
                        <div {...getTableBodyProps()} className="rt-tbody">
                            <Skeleton
                                count={rowsWhenNoData}
                                height={50}
                                isEnabled={!!loading}
                                sx={{ mb: 2 }}
                            >
                                {renderRows(
                                    page,
                                    rowsWhenNoData,
                                    prepareRow,
                                    getCustomRowProps,
                                    getSubComponent
                                )}
                            </Skeleton>
                            {page.length === 0 && !loading && (
                                <div className="rt-noData">{noRowsMessage}</div>
                            )}
                        </div>
                        <div className="rt-tfoot">
                            {footerGroups.map((footerGroup) => (
                                <div {...footerGroup.getFooterGroupProps()} className="rt-tr">
                                    {footerGroup.headers.map(createFooter)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </HorizontalScrollGradientIndicator>

            {isPaginationVisible && (
                <Pagination
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    gotoPage={gotoPage}
                    hideAllPageSizeOption={hideAllPageSizeOption}
                    nextPage={nextPage}
                    pageCount={actualPageCount}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    totalCount={totalCount ?? rows.length}
                />
            )}
        </div>
    );
};

export default hot(module)(ReactTable);
