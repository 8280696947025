import {
    HIDE_NEW_VERSION_MODAL,
    SHOW_NEW_VERSION_MODAL,
} from 'components/newVersion/newVersionActions';
import basicReducer from 'reducers/basicReducer';

export const newVersionState = basicReducer(
    { isNewVersionModalVisible: false, willUpdateLater: false },
    HIDE_NEW_VERSION_MODAL,
    SHOW_NEW_VERSION_MODAL
);
