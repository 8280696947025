import NumberTextField, { INumberTextFieldProps } from 'components/NumberTextField';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

const ONE_HUNDRED_PERCENT = 100;
const PercentTextField = (props: INumberTextFieldProps) => (
    <NumberTextField
        isAllowed={({ floatValue, value }) =>
            !hasValue(value) || floatValue === undefined || floatValue <= ONE_HUNDRED_PERCENT
        }
        suffix="%"
        {...props}
    />
);

export default hot(module)(PercentTextField);
