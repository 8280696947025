import TextField, { ITextFieldProps } from 'components/TextField';
import React from 'react';
import { hot } from 'react-hot-loader';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

export type INumberTextFieldProps = Omit<ITextFieldProps, 'onChange' | 'ref'> &
    Omit<NumberFormatProps<typeof TextField>, 'size'>;

const NumberTextField = ({ onChange, size, ...props }: INumberTextFieldProps) => (
    <NumberFormat
        allowNegative={false}
        customInput={TextField}
        decimalScale={0}
        isNumericString
        onValueChange={(values: NumberFormatValues) =>
            onChange?.({ target: { name: props.name, value: values.value } } as React.ChangeEvent<
                HTMLInputElement
            >)
        }
        size={(size as unknown) as number}
        thousandSeparator
        {...props}
    />
);

export default hot(module)(NumberTextField);
