import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { downloadPathwayIchraAgeBandedTable } from 'actions/pathwayBlueprint/downloadPathwayIchraAgeBandedTable';
import { GroupLevels } from 'api/generated/enums';
import { PathwayIchraAgeBandedTableDto } from 'api/generated/models';
import AgeContainer from 'components/AgeContainer';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import { groupLevelAbbreviations } from 'constants/groupLevels';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';
import { downloadFileFromApi } from 'utilities/rhFile';

const eeAbbreviation = groupLevelAbbreviations[GroupLevels.EE] as string;
const esAbbreviation = groupLevelAbbreviations[GroupLevels.ES] as string;
const ecAbbreviation = groupLevelAbbreviations[GroupLevels.EC] as string;
const efAbbreviation = groupLevelAbbreviations[GroupLevels.EF] as string;

type IAgeBandsTableProps = { isLoading: boolean; tableData: PathwayIchraAgeBandedTableDto[] };

const AgeBandsTable = ({ isLoading, tableData }: IAgeBandsTableProps) => {
    const [isDownloadingAgeBandedTable, setIsDownloadingAgeBandedTable] = useState(false);
    const onDownloadClick = useCallback(async () => {
        setIsDownloadingAgeBandedTable(true);
        await downloadFileFromApi(
            async () => downloadPathwayIchraAgeBandedTable(tableData),
            'Failed to download ICHRA Age Banded Table'
        );
        setIsDownloadingAgeBandedTable(false);
    }, [tableData]);

    return (
        <Skeleton count={4} isEnabled={isLoading}>
            <AgeContainer gridTemplateColumns="repeat(5, 1fr)">
                <Typography>Age</Typography>
                <Typography>{eeAbbreviation}</Typography>
                <Typography>{esAbbreviation}</Typography>
                <Typography>{ecAbbreviation}</Typography>
                <Typography>{efAbbreviation}</Typography>
            </AgeContainer>
            <Divider sx={{ mb: 1 }} />
            <AgeContainer gridTemplateColumns="repeat(5, 1fr)">
                {tableData.map((rowData) => (
                    <React.Fragment key={rowData.bandLabel}>
                        <Typography key={rowData.bandLabel}>{rowData.bandLabel}</Typography>
                        <Stack alignItems="center" direction="row" gap={1} key={eeAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.eeAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1} key={esAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.esAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1} key={ecAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.ecAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1} key={efAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.efAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                    </React.Fragment>
                ))}
            </AgeContainer>
            <Button
                color="secondary"
                isLoading={isDownloadingAgeBandedTable}
                onClick={onDownloadClick}
                sx={{ marginX: 'auto', marginY: 2, maxWidth: '16rem' }}
                variant="text"
            >
                Download Age-Banded Table
            </Button>
        </Skeleton>
    );
};

export default hot(module)(AgeBandsTable);
