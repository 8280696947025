import Icon from 'components/Icon';
import React, { ReactNode } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { hot } from 'react-hot-loader';

const CustomToggleWrapper = (
    {
        children,
        dataCy,
        onClick,
    }: { children: ReactNode; dataCy?: string; onClick?: React.MouseEventHandler },
    ref: React.Ref<HTMLSpanElement>
): JSX.Element => (
    <span className="clickable" data-cy={dataCy} onClick={onClick} ref={ref}>
        {children}
    </span>
);
const CustomToggle = React.forwardRef(CustomToggleWrapper);

const ThreeDotDropdownToggle = ({ dataCy }: { dataCy?: string }) => (
    <Dropdown.Toggle as={CustomToggle} dataCy={dataCy} id="actions-dropdown-button">
        <Icon size="2">dots-3</Icon>
    </Dropdown.Toggle>
);

export default hot(module)(ThreeDotDropdownToggle);
