import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { readableFileSize } from 'utilities';
const FileTypeViolationMessage = ({
    invalidFiles,
    accept,
}: {
    accept: string | undefined;
    invalidFiles: File[];
}) => {
    const fileList = useMemo(() => (Array.isArray(invalidFiles) ? Array.from(invalidFiles) : []), [
        invalidFiles,
    ]);
    if (invalidFiles.length === 1) {
        const file = invalidFiles[0];
        return (
            <span>
                {file?.name} file type is invalid.
                <div>Valid file types are: {accept}</div>
            </span>
        );
    }
    return (
        <span>
            <div>Valid file types are: {accept}</div>
            The following files have invalid types:
            <ul>
                {fileList.map((file, index) => (
                    <li key={index}>
                        {file.name} ({readableFileSize(file.size)})
                    </li>
                ))}
            </ul>
        </span>
    );
};
export default hot(module)(FileTypeViolationMessage);
