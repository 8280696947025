import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const DragBox = styled.div(
    ({ disableFullScreen, isVisible }: { disableFullScreen: boolean; isVisible: boolean }) => {
        const position = disableFullScreen ? 'absolute' : 'fixed';
        return isVisible
            ? `
                position: ${position};
                display: flex;
                border: 15px dashed white;
                width: 100%;
                height: 100%;
                z-index: 2000;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                flex: 1;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 30px;
                font-weight: 600;
                color: white;
                letter-spacing: 1px;
                margin: auto;
            `
            : 'display: none;';
    }
);

let dragCounter = 0;
const DropZone = ({
    children = null,
    disableFullScreen = false,
    isDisabled = false,
    isMultiple = false,
    onDrop: parentOnDrop,
}: {
    children?: ReactNode;
    disableFullScreen?: boolean;
    isDisabled?: boolean;
    isMultiple?: boolean;
    onDrop: (data: File | FileList, e: React.DragEvent) => void;
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const onDragEnter = useCallback((e) => {
        dragCounter++;
        setIsVisible(true);
        e.stopPropagation();
        e.preventDefault();
        return false;
    }, []);
    const onDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);
    const onDragLeave = useCallback((e) => {
        if (dragCounter > 0) {
            dragCounter--;
        }
        if (dragCounter === 0) {
            setIsVisible(false);
            e.stopPropagation();
            e.preventDefault();
        }
        return false;
    }, []);
    const onDrop = useCallback(
        (e: React.DragEvent) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            parentOnDrop(isMultiple ? files : (files[0] as File | FileList), e);
            setIsVisible(false);
            return false;
        },
        [isMultiple, parentOnDrop]
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener('mouseup', onDragLeave);
        window.removeEventListener('dragenter', onDragEnter);
        window.removeEventListener('dragover', onDragOver);
        window.removeEventListener('drop', (onDrop as unknown) as EventListener);
    }, [onDragEnter, onDragLeave, onDragOver, onDrop]);

    useEffect(() => {
        if (isDisabled || disableFullScreen) {
            removeListeners();
        } else {
            window.addEventListener('mouseup', onDragLeave);
            window.addEventListener('dragenter', onDragEnter);
            window.addEventListener('dragover', onDragOver);
            window.addEventListener('drop', (onDrop as unknown) as EventListener);
        }
        return removeListeners;
    }, [
        disableFullScreen,
        isDisabled,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
        removeListeners,
    ]);

    return (
        <div
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {children}
            <DragBox
                className="bg-secondary-25-percent-transparent"
                disableFullScreen={disableFullScreen}
                isVisible={isVisible}
                onDragLeave={onDragLeave}
            >
                Drop {isMultiple ? 'files' : 'a file'} to Upload
            </DragBox>
        </div>
    );
};

export default hot(module)(DropZone);
