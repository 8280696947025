import EditableAttribute, { IEditableAttributeProps } from 'components/EditableAttribute';
import EditableNumberTextField, {
    IEditableNumberTextFieldProps,
} from 'components/EditableNumberTextField';
import React from 'react';
import { hot } from 'react-hot-loader';

type IEditableNumberAttributeProps<T> = IEditableNumberTextFieldProps<T> &
    Omit<IEditableAttributeProps, 'children'>;

const EditableNumberAttribute = <T,>({
    emptyValueMessage,
    footer,
    formatter,
    hrClassName,
    infoTooltip,
    label,
    readonly,
    ...props
}: IEditableNumberAttributeProps<T>) => (
    <EditableAttribute
        emptyValueMessage={emptyValueMessage}
        footer={footer}
        formatter={formatter}
        hrClassName={hrClassName}
        infoTooltip={infoTooltip}
        label={label}
        readonly={readonly}
        {...props}
    >
        {({ onBlur }) => <EditableNumberTextField {...props} onBlur={onBlur} />}
    </EditableAttribute>
);

export default hot(module)(EditableNumberAttribute);
