import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppDrawerContents from 'components/authenticatedPageWrapper/appDrawer/AppDrawerContents';
import { Logo } from 'components/Logo';
import React from 'react';
import { hot } from 'react-hot-loader';

export const DRAWER_WIDTH = 240;
const AppDrawer = () => {
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const handleDrawerToggle = () => setIsDrawerOpen(!isDrawerOpen);
    const container = window !== undefined ? () => window.document.body : undefined;
    const paperStyle = {
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.primary.main,
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
        },
    };

    return isLg ? (
        <Drawer
            anchor="left"
            sx={{
                ...paperStyle,
                flexShrink: 0,
                width: DRAWER_WIDTH,
            }}
            variant="permanent"
        >
            <AppDrawerContents />
        </Drawer>
    ) : (
        <React.Fragment>
            <AppBar
                color="default"
                position="fixed"
                sx={{
                    ml: { lg: `${DRAWER_WIDTH}px` },
                    width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
                    zIndex: 1040,
                }}
            >
                <Toolbar>
                    <Box
                        alignItems="center"
                        display="flex"
                        flex="auto"
                        justifyContent="space-between"
                    >
                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Logo />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                ModalProps={{
                    keepMounted: true,
                }}
                onClose={handleDrawerToggle}
                open={isDrawerOpen}
                sx={{
                    ...paperStyle,
                }}
                variant="temporary"
            >
                <AppDrawerContents setIsDrawerOpen={setIsDrawerOpen} />
            </Drawer>
        </React.Fragment>
    );
};

export default hot(module)(AppDrawer);
