import { clearTeamBenefit } from 'actions/clear';
import {
    ADD_TEAM_BENEFIT_RH_FILE_ACTION,
    addTeamBenefitRhFile,
} from 'actions/rhFile/addTeamBenefitRhFile';
import {
    DELETE_TEAM_BENEFIT_ACTION,
    deleteTeamBenefit,
} from 'actions/teamBenefit/deleteTeamBenefit';
import { downloadHsaBankFile } from 'api/downloadHsaBankFile';
import { TeamBenefitTypes } from 'api/generated/enums';
import {
    ITeamBenefit,
    ITeamBenefitWithTermDetailsDto,
    TeamBenefitTermDetailDto,
} from 'api/generated/models';
import {
    CreateTeamBenefitRhFile,
    DeleteTeamBenefit,
    EditSubmittedExpense,
    ViewSocialSecurityNumber,
    ViewSubmittedExpenses,
} from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import ConfirmationModal from 'components/ConfirmationModal';
import FileUploadModal, { AddFilesActionThunk } from 'components/FileUploadModal';
import DownloadPrevMecCensusModal from 'components/teamBenefitActionButtons/DownloadPrevMecCensusModal';
import TeamBenefitModal from 'components/teamBenefitActionButtons/TeamBenefitModal';
import TeamBenefitTermDetailsModal from 'components/teamBenefitActionButtons/TeamBenefitTermDetailsModal';
import { push } from 'connected-react-router';
import { HSA_BANK_TEAM_BENEFIT_ID } from 'constants/teamBenefits';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { useTeamBenefitActionButtons } from 'pages/teamBenefits/useTeamBenefitActionButtons';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import {
    TEAMS_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH,
    TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH,
    TEAM_REIMBURSEMENT_EXPENSE_PATH,
} from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { downloadFileFromApi } from 'utilities/rhFile';

type ITeamBenefitActionButtonsProps = {
    additionalActionItems?: IActionButtonItems;
    selectedTermDetailId?: string;
    teamBenefit: ITeamBenefit | ITeamBenefitWithTermDetailsDto;
};

const TeamBenefitActionButtons = ({
    additionalActionItems,
    selectedTermDetailId,
    teamBenefit,
}: ITeamBenefitActionButtonsProps) => {
    const dispatch = useThunkDispatch();
    const { teamId, useReimbursementProgramManagement } = useTeamProps();
    const {
        canAddFile,
        canDeleteTeamBenefit,
        canDownloadFilesWithSsns,
        canEditSubmittedExpense,
        canViewSubmittedExpenses,
        isDeletingTeamBenefit,
        isFileUploadLoading,
        isRh,
    } = useSelector((state: AppStore) => ({
        canAddFile: hasSomePermissions(state, CreateTeamBenefitRhFile),
        canDeleteTeamBenefit: hasSomePermissions(state, DeleteTeamBenefit),
        canDownloadFilesWithSsns: hasSomePermissions(state, ViewSocialSecurityNumber),
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        canViewSubmittedExpenses: hasSomePermissions(state, ViewSubmittedExpenses),
        isDeletingTeamBenefit: hasApiActivity(state, DELETE_TEAM_BENEFIT_ACTION),
        isFileUploadLoading: hasApiActivity(state, ADD_TEAM_BENEFIT_RH_FILE_ACTION),
        isRh: isRhSelector(state),
    }));

    const [isDownloading, setIsDownloading] = useState(false);
    const {
        canEditTeamBenefits,
        canManage,
        canViewButNotEdit,
        canViewOrEdit,
    } = useTeamBenefitActionButtons(teamBenefit);

    const { teamBenefitTermDetailId } = useParams<{ teamBenefitTermDetailId?: string }>();
    const isManagingMembers = hasValue(teamBenefitTermDetailId);

    const YEAR_SORT_FACTOR = 100; // multiply year by 100 so dates like 12-2022 & 01-2023 can look like 202212 & 202301 (for sorting)
    const latestTeamBenefitTermDetail:
        | TeamBenefitTermDetailDto
        | undefined = teamBenefit.teamBenefitTermDetails
        ?.sort(
            (a, b) =>
                a.startDate.getYear() * YEAR_SORT_FACTOR +
                a.startDate.getMonth() -
                (b.startDate.getYear() * YEAR_SORT_FACTOR + b.startDate.getMonth())
        )
        .slice(-1)[0];

    const teamBenefitTermDetail =
        teamBenefit.teamBenefitTermDetails?.find(
            (termDetail) => termDetail.id === selectedTermDetailId
        ) ?? latestTeamBenefitTermDetail;

    const showDownloadHsaBankFile =
        canDownloadFilesWithSsns &&
        teamBenefit.teamBenefitTypesCarrier?.teamBenefitCarrier?.id === HSA_BANK_TEAM_BENEFIT_ID;

    const showDownloadPreventativeMecEnrollmentCensus =
        canDownloadFilesWithSsns &&
        teamBenefit.teamBenefitTypesCarrier?.teamBenefitTypeId ===
            TeamBenefitTypes.PreventativeMinimumEssentialCoverage &&
        (teamBenefit.teamBenefitTermDetails?.length ?? 0) > 0;

    useEffect(
        () => () => {
            dispatch(clearTeamBenefit());
        },
        [dispatch]
    );

    const {
        closeModal: closeTeamBenefitModal,
        isVisible: isTeamBenefitModalVisible,
        openModal: openTeamBenefitModal,
    } = useModalState();
    const {
        closeModal: closeTeamBenefitTermDetailsModal,
        isVisible: isTeamBenefitTermDetailsModalVisible,
        openModal: openTeamBenefitTermDetailsModal,
    } = useModalState();
    const {
        openModal: openFileUploadModal,
        closeModal: closeFileUploadModal,
        isVisible: isFileUploadModalVisible,
    } = useModalState();
    const {
        openModal: openDeleteConfirmationModal,
        closeModal: closeDeleteConfirmationModal,
        isVisible: isDeleteConfirmationModalVisible,
    } = useModalState();
    const {
        closeModal: closePrevMecDownloadModal,
        isVisible: isPrevMecDownloadModalVisible,
        openModal: openPrevMecDownloadModal,
    } = useModalState();
    const onDeleteTeamBenefitYesClick = async () => {
        await dispatch(deleteTeamBenefit(teamBenefit.id, teamId));
        closeDeleteConfirmationModal();
    };
    const handleManageMembersClick = () => {
        dispatch(
            push(
                generatePath(TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH, {
                    teamId,
                    teamBenefitId: teamBenefit.id,
                    teamBenefitTermDetailId: teamBenefitTermDetail?.id ?? '0',
                })
            )
        );
    };
    const handleViewSubmittedExpensesClick = () => {
        dispatch(
            push(
                generatePath(expenseManagementPath, {
                    teamId,
                })
            )
        );
    };
    const handleDownloadEnrollmentClick = useCallback(async () => {
        setIsDownloading(true);
        await downloadFileFromApi(async () => downloadHsaBankFile(teamId, false));
        setIsDownloading(false);
    }, [teamId]);
    const handleDownloadDemographicClick = useCallback(async () => {
        setIsDownloading(true);
        await downloadFileFromApi(async () => downloadHsaBankFile(teamId, true));
        setIsDownloading(false);
    }, [teamId]);

    let teamBenefitManagementText = 'Manage Members';
    let teamBenefitModalText = 'Edit';
    let teamBenefitTermDetailsText = 'Manage Benefit Term Details';
    if (canViewButNotEdit) {
        teamBenefitManagementText = 'View Members';
        teamBenefitModalText = 'View Basic Information';
        teamBenefitTermDetailsText = 'View Term Details';
    }

    const expenseManagementPath = isRh
        ? generatePath(TEAMS_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, { teamId })
        : generatePath(TEAM_REIMBURSEMENT_EXPENSE_PATH);

    const showManageTermDetailsButton =
        canManage &&
        (canEditTeamBenefits ||
            (teamBenefit?.teamBenefitTermDetails?.length !== 0 && canViewOrEdit));
    let items = [
        {
            isVisible: canViewOrEdit,
            onClick: openTeamBenefitModal,
            text: teamBenefitModalText,
        },
        {
            dataCy: 'manage-benefit-term-details',
            isVisible: showManageTermDetailsButton,
            onClick: openTeamBenefitTermDetailsModal,
            text: teamBenefitTermDetailsText,
        },
        {
            dataCy: 'manage-members',
            isVisible: canManage && !isManagingMembers,
            onClick: handleManageMembersClick,
            text: teamBenefitManagementText,
        },
        {
            isVisible: canAddFile,
            onClick: openFileUploadModal,
            text: 'Add Team Benefits File',
        },
        {
            isVisible:
                canViewSubmittedExpenses &&
                (useReimbursementProgramManagement || canEditSubmittedExpense) &&
                teamBenefit.teamBenefitTypesCarrier?.teamBenefitTypeId ===
                    TeamBenefitTypes.ReimbursementProgram,
            onClick: handleViewSubmittedExpensesClick,
            text: 'View Submitted Expenses',
        },
        {
            isLoading: isDownloading,
            isVisible: showDownloadHsaBankFile,
            onClick: handleDownloadEnrollmentClick,
            text: 'Download Enrollment File',
        },
        {
            isLoading: isDownloading,
            isVisible: showDownloadHsaBankFile,
            onClick: handleDownloadDemographicClick,
            text: 'Download Demographic File',
        },
        {
            isVisible: showDownloadPreventativeMecEnrollmentCensus,
            onClick: openPrevMecDownloadModal,
            text: 'Download Enrollment Census',
        },
    ] as IActionButtonItems;

    if (hasValue(additionalActionItems)) {
        items = items.concat(additionalActionItems);
    }

    items = items.concat([
        {
            isVisible: canDeleteTeamBenefit,
            onClick: openDeleteConfirmationModal,
            text: 'Delete',
        },
    ]);

    const addFile: AddFilesActionThunk = (documentsToAdd, successTooltipMessage) =>
        addTeamBenefitRhFile(teamBenefit.id, documentsToAdd, successTooltipMessage);

    return (
        <React.Fragment>
            {isFileUploadModalVisible && (
                <FileUploadModal
                    body={`Uploaded files are always visible to Remodel Health and the Team Admin.
                        If you want this file to be visible on the Member's card, share it using the "Share To Members" action.`}
                    isLoading={isFileUploadLoading}
                    onHide={closeFileUploadModal}
                    onSubmit={addFile}
                />
            )}
            {isTeamBenefitModalVisible && (
                <TeamBenefitModal
                    onClose={closeTeamBenefitModal}
                    teamBenefit={teamBenefit}
                    teamId={teamId}
                />
            )}
            {isTeamBenefitTermDetailsModalVisible && (
                <TeamBenefitTermDetailsModal
                    currentTermDetailId={teamBenefitTermDetail?.id}
                    onClose={closeTeamBenefitTermDetailsModal}
                    teamBenefit={teamBenefit}
                />
            )}
            {isDeleteConfirmationModalVisible && (
                <ConfirmationModal
                    body="Are you sure you want to delete the Team Benefit?"
                    onNoClick={closeDeleteConfirmationModal}
                    onYesClick={onDeleteTeamBenefitYesClick}
                    showActivity={isDeletingTeamBenefit}
                    title="Are You Sure?"
                />
            )}
            {isPrevMecDownloadModalVisible && (
                <DownloadPrevMecCensusModal
                    onClose={closePrevMecDownloadModal}
                    termDetails={teamBenefit.teamBenefitTermDetails ?? []}
                ></DownloadPrevMecCensusModal>
            )}
            <ActionButtons dropdownWhenLengthGreaterThan={1} items={items} />
        </React.Fragment>
    );
};

export default hot(module)(TeamBenefitActionButtons);
