import Axios from 'axios';
import { showNewVersionModal } from 'components/newVersion/newVersionActions';
import { History } from 'history';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { useCallback, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { clearCacheAndReload } from 'utilities';

const CacheBuster = ({ children, history }: { children: JSX.Element; history: History }) => {
    const dispatch = useThunkDispatch();
    const { willUpdateLater } = useSelector((state: AppStore) => ({
        willUpdateLater: state.newVersionState.willUpdateLater,
    }));
    const checkVersion = useCallback(
        async (isInitialRender = false) => {
            const meta = await Axios.get(`/version.json?ts=${+new Date()}`);
            const latestVersion = meta.data.version;
            const currentVersion = process.env['VERSION'];
            const shouldForceRefresh = currentVersion ? latestVersion > currentVersion : false;
            if (!willUpdateLater && shouldForceRefresh) {
                if (isInitialRender) {
                    clearCacheAndReload();
                } else {
                    dispatch(showNewVersionModal());
                }
            }
        },
        [dispatch, willUpdateLater]
    );
    useEffect(() => {
        checkVersion(true);
        return history.listen(() => {
            checkVersion();
        });
    }, [checkVersion, history]);

    return children;
};

export default hot(module)(CacheBuster);
