import StarOutlineRounded from '@mui/icons-material/StarOutlineRounded';
import StarRounded from '@mui/icons-material/StarRounded';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { addFavoriteTeam } from 'actions/favoriteTeams/addFavoriteTeam';
import { deleteFavoriteTeam } from 'actions/favoriteTeams/deleteFavoriteTeam';
import {
    GET_FAVORITE_TEAMS_ACTION,
    getFavoriteTeams,
} from 'actions/favoriteTeams/getFavoriteTeams';
import { FavoriteTeamDto } from 'api/generated/models';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities/index';

type IFavoriteTeamStar = {
    children: JSX.Element;
    teamId?: string;
};

const FavoriteTeamStar = ({ children, teamId }: IFavoriteTeamStar) => {
    const dispatch = useThunkDispatch();
    const theme = useTheme();
    const { favoriteTeams, hasLoadedFavoriteTeams, isLoadingFavoriteTeams, isRh } = useSelector(
        (state: AppStore) => ({
            favoriteTeams: state.favoriteTeams,
            hasLoadedFavoriteTeams: hasCompletedRequest(state, GET_FAVORITE_TEAMS_ACTION),
            isLoadingFavoriteTeams: hasApiActivity(state, GET_FAVORITE_TEAMS_ACTION),
            isRh: isRhSelector(state),
        })
    );

    const [favoriteTeam, setFavoriteTeam] = useState<FavoriteTeamDto | undefined>();
    const [isFavoriteTeam, setIsFavoriteTeam] = useState<boolean>();
    const tooltipText = isFavoriteTeam
        ? 'Remove from your favorite teams'
        : 'Add to your favorite teams';

    const handleStarClick = () => {
        if (hasValue(favoriteTeam)) {
            setIsFavoriteTeam(false);
            dispatch(deleteFavoriteTeam(favoriteTeam.id));
        } else {
            setIsFavoriteTeam(true);
            dispatch(addFavoriteTeam(teamId as string));
        }
    };

    useEffect(() => {
        if (!hasLoadedFavoriteTeams && !isLoadingFavoriteTeams) {
            dispatch(getFavoriteTeams());
        }
    }, [dispatch, hasLoadedFavoriteTeams, isLoadingFavoriteTeams]);

    useEffect(() => {
        const favTeam = favoriteTeams.find((ft) => ft.teamId === teamId);
        setFavoriteTeam(favTeam);
        setIsFavoriteTeam(hasValue(favTeam));
    }, [favoriteTeams, teamId]);

    if (!isRh) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return (
        <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={1.5}>
            <Tooltip title={tooltipText}>
                {isFavoriteTeam ? (
                    <StarRounded
                        onClick={handleStarClick}
                        sx={{
                            color: theme.palette.yellow.main,
                            cursor: 'pointer',
                            marginBottom: '2px',
                        }}
                    />
                ) : (
                    <StarOutlineRounded
                        onClick={handleStarClick}
                        sx={{
                            color: theme.palette.blueGray.main,
                            cursor: 'pointer',
                            marginBottom: '2px',
                        }}
                    />
                )}
            </Tooltip>
            {children}
        </Stack>
    );
};

export default hot(module)(FavoriteTeamStar);
