import { InputBaseComponentProps } from '@mui/material';
import TextField, { ITextFieldProps } from 'components/TextField';
import IMask from 'imask';
import { kebabCase } from 'lodash';
import React, { ElementType, useState } from 'react';
import { hot } from 'react-hot-loader';
import { IMaskInput } from 'react-imask';
import { MaskedElement } from 'react-imask/dist/mixin';
import { hasValue } from 'utilities';

const blocks = {
    d: { from: 1, mask: IMask.MaskedRange, maxLength: 2, placeholderChar: 'd', to: 31 },
    m: { from: 1, mask: IMask.MaskedRange, maxLength: 2, placeholderChar: 'm', to: 12 },
    Y: { from: 1900, mask: IMask.MaskedRange, maxLength: 4, placeholderChar: 'y', to: 9999 },
};

export const DateTextMask = <T,>(
    pattern: string,
    parse: (s: string) => T,
    format: (d: T) => string
) =>
    React.forwardRef<HTMLElement, React.HTMLProps<HTMLInputElement>>(function DateTextMask(
        props,
        ref
    ) {
        const { onChange, onBlur: onBlurParent, onFocus: onFocusParent, value, ...other } = props;
        const [isFocused, setIsFocused] = useState(false);
        const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);
            onBlurParent?.(e);
        };
        const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(true);
            onFocusParent?.(e);
        };
        return (
            <IMaskInput
                {...other}
                autofix
                blocks={blocks}
                format={format}
                inputRef={
                    (ref as unknown) as React.Ref<HTMLElement> & ((el: MaskedElement) => void)
                }
                lazy={!isFocused}
                mask={Date}
                onAccept={(givenValue: string) => {
                    let value = givenValue;
                    if (givenValue === '//') {
                        value = '';
                    }
                    onChange?.(({
                        target: { value, name: props.name },
                    } as unknown) as React.FormEvent<HTMLInputElement>);
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                overwrite
                parse={parse}
                pattern={pattern}
                unmask
                value={value as string | undefined}
            />
        );
    });

type IDateMaskFieldProps = ITextFieldProps & {
    inputComponent: ElementType<InputBaseComponentProps>;
};

const DateMaskField = ({
    InputLabelProps,
    InputProps,
    inputComponent,
    onBlur: onBlurParent,
    onFocus: onFocusParent,
    ...props
}: IDateMaskFieldProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlurParent?.(e);
    };
    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocusParent?.(e);
    };
    const shrink = hasValue(props.label) && (isFocused || hasValue(props.value));
    return (
        <TextField
            data-cy={kebabCase(props.label as string)}
            data-private
            InputLabelProps={{
                ...InputLabelProps,
                shrink,
            }}
            InputProps={{ ...InputProps, inputComponent: inputComponent }}
            onBlur={onBlur}
            onFocus={onFocus}
            {...props}
        />
    );
};

export default hot(module)(DateMaskField);
