import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const Title = styled.p.attrs((props) => ({
    className: props.className || 'my-0',
}))`
    font-weight: 600;
    font-size: 20px;
`;

export default hot(module)(Title);
