import {
    LIST_AGE_PREMIUM_RATIOS_ACTION,
    listAgePremiumRatios,
} from 'actions/pathwayBlueprint/listAgePremiumRatios';
import { PathwayIchraAgeBandedTableDto } from 'api/generated/models';
import AgeBandsTable from 'components/pathwayModal/AgeBandsTable';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const MIN_AGE = 23;
const MAX_AGE = 64;
const DEFAULT_AGE_BAND = 5;

type ICoverageLevelInputs = {
    reimbursementRatio: number;
    sixtyFourReimbursement: number;
};

type ICalculatedAgeBandsTableProps = {
    ageBand: number;
    ecCoverageLevelInputs: ICoverageLevelInputs;
    eeCoverageLevelInputs: ICoverageLevelInputs;
    efCoverageLevelInputs: ICoverageLevelInputs;
    esCoverageLevelInputs: ICoverageLevelInputs;
};

const CalculatedAgeBandsTable = ({
    ageBand,
    ecCoverageLevelInputs,
    eeCoverageLevelInputs,
    efCoverageLevelInputs,
    esCoverageLevelInputs,
}: ICalculatedAgeBandsTableProps) => {
    const dispatch = useThunkDispatch();
    const { agePremiumRatios, isLoading } = useSelector((state: AppStore) => ({
        agePremiumRatios: state.agePremiumRatios,
        isLoading: hasApiActivity(state, LIST_AGE_PREMIUM_RATIOS_ACTION),
    }));
    useEffect(() => {
        if (Object.keys(agePremiumRatios).length === 0) {
            dispatch(listAgePremiumRatios());
        }
    }, [agePremiumRatios, dispatch]);

    const ages: number[] = useMemo(() => {
        let ageBound = MAX_AGE - 1;
        const agesList = [MAX_AGE];
        while (ageBound >= MIN_AGE) {
            agesList.push(ageBound);
            ageBound -= ageBand > 0 ? ageBand : DEFAULT_AGE_BAND;
        }
        return agesList;
    }, [ageBand]);

    const calculateReimbursement = useCallback(
        (age: number, coverageLevelInputs: ICoverageLevelInputs) => {
            const { reimbursementRatio, sixtyFourReimbursement } = coverageLevelInputs;
            const defaultPremiumRatioForAge = agePremiumRatios[age] ?? 0;
            const scaledPremiumRatioForAge =
                ((defaultPremiumRatioForAge - 1) / 2) * (reimbursementRatio - 1) + 1;
            return (scaledPremiumRatioForAge * sixtyFourReimbursement) / reimbursementRatio;
        },
        [agePremiumRatios]
    );

    const getLabel = useCallback(
        (age: number): string => {
            if (age === MAX_AGE) {
                return `${MAX_AGE} and over`;
            }
            if (age === MIN_AGE) {
                return `${MIN_AGE} and under`;
            }
            if (ageBand !== 1) {
                return `${age + 1 - ageBand} - ${age}`;
            }
            return age.toString();
        },
        [ageBand]
    );

    const tableData = useMemo(
        () =>
            ages.map((age) => ({
                bandLabel: getLabel(age),
                ecAmount: calculateReimbursement(age, ecCoverageLevelInputs),
                eeAmount: calculateReimbursement(age, eeCoverageLevelInputs),
                efAmount: calculateReimbursement(age, efCoverageLevelInputs),
                esAmount: calculateReimbursement(age, esCoverageLevelInputs),
            })),
        [
            ages,
            calculateReimbursement,
            ecCoverageLevelInputs,
            eeCoverageLevelInputs,
            efCoverageLevelInputs,
            esCoverageLevelInputs,
            getLabel,
        ]
    ) as PathwayIchraAgeBandedTableDto[];

    return <AgeBandsTable isLoading={isLoading} tableData={tableData} />;
};

export default hot(module)(CalculatedAgeBandsTable);
