import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import { BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers';
import { hot } from 'react-hot-loader';
import { Link, LinkProps } from 'react-router-dom';

export type IButtonProps = ButtonProps &
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
        ButtonClassName?: string;
        children?: React.ReactNode;
        gtXsClassName?: string;
        isLoading?: boolean;
        link?: LinkProps;
        noPadding?: boolean;
        xsClassName?: string;
    };

function bsExtend<Props>(
    component: (props: Props) => JSX.Element
): <As extends React.ReactType>(props: ReplaceProps<As, BsPrefixProps<As> & Props>) => JSX.Element {
    return component as any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Wrapper = ({
    children: wrapperChildren,
    link,
}: {
    children: JSX.Element;
    link: LinkProps | undefined;
}) => (link ? <Link {...link}>{wrapperChildren}</Link> : wrapperChildren);

const InnerButton = ({
    autoFocus,
    children,
    className,
    disabled,
    isLoading,
    variant = 'outlined',
    ...props
}: IButtonProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (autoFocus) {
            ref?.current?.focus();
        }
    }, [autoFocus]);
    return (
        <LoadingButton
            {...props}
            className={className}
            disabled={disabled || isLoading}
            loading={isLoading}
            ref={ref}
            variant={variant}
        >
            <div className="position-relative">{children}</div>
        </LoadingButton>
    );
};

const XS_SCREEN_SIZE_BREAKPOINT = 576;
/**
 * @deprecated Use MUI buttons instead of this component
 */
const Button = ({
    autoFocus,
    ButtonClassName = '',
    children,
    className = '',
    disabled,
    gtXsClassName = '',
    isLoading,
    link,
    noPadding,
    xsClassName = '',
    ...props
}: IButtonProps) => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    let buttonClassName = 'col text-wrap ';
    if (width < XS_SCREEN_SIZE_BREAKPOINT) {
        buttonClassName += `d-block d-sm-none ${xsClassName}`;
    } else {
        buttonClassName += `d-none d-sm-block ${noPadding ? '' : 'px-3 py-2'} ${gtXsClassName}`;
    }
    return (
        <span className={className}>
            <Wrapper link={link}>
                <InnerButton
                    autoFocus={autoFocus}
                    disabled={disabled}
                    isLoading={isLoading}
                    {...props}
                    className={`${buttonClassName} ${ButtonClassName}`}
                >
                    {children}
                </InnerButton>
            </Wrapper>
        </span>
    );
};

export default hot(module)(bsExtend<IButtonProps>(Button));
