import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import {
    GET_HOUSEHOLD_MEMBERS_ACTION,
    getHouseholdMembers,
} from 'actions/householdMember/getHouseholdMembers';
import { GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { ADD_CUSTOM_SELECTED_PLANS_ACTION } from 'actions/selectedPlan/addCustomSelectedPlans';
import { PATCH_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/patchSelectedPlans';
import { PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import BottomInputs from 'components/planModal/BottomInputs';
import HouseholdMemberPlanInput from 'components/planModal/HouseholdMemberPlanInput';
import PlanInputs from 'components/planModal/PlanInputs';
import {
    clearPlanInputs,
    updatePlanInputs,
    updateSelectedHouseholdMemberIds,
} from 'components/planModal/planModalActions';
import { saveSelectedPlan } from 'components/planModal/saveSelectedPlanAction';
import Select, { ISelectProps } from 'components/Select';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import {
    CMS_OR_IDEON_PLAN_TYPES,
    MONTHLY_SHARE_AMOUNT,
    PLAN_TYPE_ITEMS,
    PREMIUM,
} from 'constants/selectedPlans';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { enumToNameValueArray, hasValue, IS_DEV_MODE } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { shouldIncludePremiumWithCredits } from 'utilities/plans';
import { getYears } from 'utilities/year';

export const HSA_ELIGIBLE_PLANS = [
    PlanTypeIds.Ancillary,
    PlanTypeIds.CustomMajorMedical,
    PlanTypeIds.Marketplace,
    PlanTypeIds.CustomOffExchange,
    PlanTypeIds.ParentEmployer,
    PlanTypeIds.SpouseEmployer,
    PlanTypeIds.CustomStateBasedExchange,
    PlanTypeIds.OffExchange,
    PlanTypeIds.CustomAncillary,
];

const getPlanStateItems = (
    isAutoEnrollmentEligible: boolean | undefined,
    issuerName: string | undefined
) => {
    const planStates = enumToNameValueArray(PlanStateIds, {
        formatName: startCase,
        nameMap: {
            ReadyForAutoEnrollment: 'Ready for Auto-Enrollment',
        },
    });

    let planStatesToReturn =
        isAutoEnrollmentEligible && issuerName !== 'Anthem'
            ? planStates
            : planStates.filter((s) => s.value !== PlanStateIds.ReadyForAutoEnrollment);
    if (IS_DEV_MODE) {
        planStatesToReturn = isAutoEnrollmentEligible
            ? planStates
            : planStates.filter((s) => s.value !== PlanStateIds.ReadyForAutoEnrollment);
    }

    return planStatesToReturn;
};

const PlanModal = ({
    onClose: parentOnClose,
    onSubmit: parentOnSubmit,
    defaultPlanState = PlanStateIds.Effective,
    planToEdit,
    userId,
}: {
    defaultPlanState?: PlanStateIds;
    onClose: () => void;
    onSubmit?: () => void;
    planToEdit?: ISelectedPlan;
    userId: string;
}) => {
    const dispatch = useThunkDispatch();
    const isEdit = !!planToEdit?.selectedPlanId;
    const {
        householdMembersForSelectedYear,
        isAdvisor,
        isLoading,
        isLoadingHouseholdMembers,
        isRh,
        marketplacePlans,
        mediSharePlans,
        planInputs,
        selectedHouseholdMemberIds,
    } = useSelector((state: AppStore) => ({
        householdMembersForSelectedYear: [
            state.userProfile.user,
            ...state.householdMembers.filter((hhm) => hhm.haveDataForYear),
        ],
        isAdvisor: isAdvisorSelector(state),
        isLoading: hasApiActivity(
            state,
            GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION,
            ADD_CUSTOM_SELECTED_PLANS_ACTION,
            PATCH_SELECTED_PLAN_ACTION,
            GET_HOUSEHOLD_MEMBERS_ACTION
        ),
        isLoadingHouseholdMembers: hasApiActivity(state, GET_HOUSEHOLD_MEMBERS_ACTION),
        isRh: isRhSelector(state),
        marketplacePlans: state.marketplacePlans,
        mediSharePlans: state.mediSharePlans,
        planInputs: state.planModalState.planInputs,
        selectedHouseholdMemberIds: state.planModalState.selectedHouseholdMemberIds,
    }));
    const hasMarketplacePlanRequest = CMS_OR_IDEON_PLAN_TYPES.includes(planInputs.planType);
    const isMarketplace = planInputs.planType === PlanTypeIds.Marketplace;
    const isMediShare = planInputs.planType === PlanTypeIds.MediShare;
    const isMedicare = planInputs.planType === PlanTypeIds.Medicare;
    const isPlanPremiumRequired = [
        PlanTypeIds.Marketplace,
        PlanTypeIds.CustomStateBasedExchange,
        PlanTypeIds.CustomOffExchange,
        PlanTypeIds.TermMedical,
        PlanTypeIds.MediShare,
        PlanTypeIds.Ancillary,
        PlanTypeIds.OffExchange,
    ].includes(planInputs.planType);
    const isPlanPremiumWithCreditsRequired = [
        PlanTypeIds.Marketplace,
        PlanTypeIds.CustomStateBasedExchange,
    ].includes(planInputs.planType);
    const onClose = useCallback(() => {
        dispatch(clearPlanInputs());
        parentOnClose();
    }, [dispatch, parentOnClose]);
    useEffect(() => {
        const year = Number(planInputs.selectedYear);
        if (year > 0) {
            dispatch(getHouseholdMembers(userId, year, true));
        }
    }, [dispatch, userId, planInputs.selectedYear]);
    useEffect(() => {
        if (!isEdit) {
            let planPremium;
            let planPremiumWithCredits;
            let exchange: string | undefined = '';
            if (hasMarketplacePlanRequest) {
                const plan = marketplacePlans.find(
                    (x) => x.id === planInputs.selectedMarketplacePlan
                );
                planPremium = plan?.premium;
                planPremiumWithCredits = plan?.premiumWithCredits;
                if (isMarketplace) {
                    exchange = marketplacePlans[0]?.exchange;
                    if (hasValue(exchange)) {
                        dispatch(
                            updatePlanInputs({
                                exchange,
                            })
                        );
                    }
                }
            }
            if (isMediShare) {
                const plan = mediSharePlans.find((x) => x.ahp === planInputs.selectedAhp);
                planPremium = plan?.totalAmount;
                planPremiumWithCredits = plan?.totalAmount;
            }
            if (hasValue(planPremium) && hasValue(planPremiumWithCredits)) {
                dispatch(
                    updatePlanInputs({
                        planPremium: planPremium.toString(),
                        planPremiumWithCredits: planPremiumWithCredits.toString(),
                    })
                );
            }
        }
    }, [
        dispatch,
        hasMarketplacePlanRequest,
        isEdit,
        isMarketplace,
        isMediShare,
        marketplacePlans,
        mediSharePlans,
        planInputs.selectedAhp,
        planInputs.selectedMarketplacePlan,
    ]);
    const getValue = (value: string | undefined) => value ?? '';
    useEffect(() => {
        if (planToEdit) {
            dispatch(
                updatePlanInputs({
                    applicationDate: getValue(formatDateForDisplay(planToEdit.applicationDate)),
                    applicationLink: getValue(planToEdit.applicationLink),
                    binderStatus: getValue(planToEdit.binderStatus?.toString()),
                    comment: getValue(planToEdit.comment),
                    coverageEndDate: getValue(formatDateForDisplay(planToEdit.coverageEndDate)),
                    coverageStartDate: getValue(formatDateForDisplay(planToEdit.coverageStartDate)),
                    deductible: planToEdit.familyDeductibleCost,
                    exchange: getValue(planToEdit.exchange),
                    healthCareTypeId: getValue(planToEdit.healthCareTypeId?.toString()),
                    isAddedToPaymentTracking: getValue(
                        planToEdit.isAddedToPaymentTracking?.toString()
                    ),
                    isAddedToPayrollReport: getValue(planToEdit.isAddedToPayrollReport?.toString()),
                    isHsaEligible: getValue(planToEdit.isHsaEligible?.toString()),
                    isPassiveRenewal: getValue(planToEdit.isPassiveRenewal?.toString()),
                    issuerName: getValue(planToEdit.issuerName),
                    memberId: getValue(planToEdit.memberId),
                    payTypeId: getValue(planToEdit.payTypeId?.toString()),
                    planName: getValue(planToEdit.planName),
                    planPremium: getValue(planToEdit.planPremium?.toString()),
                    planPremiumWithCredits: getValue(planToEdit.planPremiumWithCredits?.toString()),
                    planStateId: planToEdit.planStateId,
                    planType: planToEdit.planTypeId,
                    selectedAhp: getValue(planToEdit.planId),
                    selectedCarrier: getValue(planToEdit.issuerName),
                    selectedMarketplacePlan: getValue(planToEdit.planId),
                    selectedYear: getValue(planToEdit.year?.toString()),
                })
            );
        } else {
            dispatch(updatePlanInputs({ planStateId: defaultPlanState }));
        }
    }, [dispatch, planToEdit, defaultPlanState]);
    const onSubmit = useCallback(async () => {
        const isValid = await dispatch(saveSelectedPlan(userId, planToEdit));
        if (isValid) {
            onClose();
            parentOnSubmit?.();
        }
    }, [dispatch, userId, planToEdit, onClose, parentOnSubmit]);
    const isDisabled = useMemo(() => {
        const noSelectedMembersOrNoPlanType =
            isEmpty(selectedHouseholdMemberIds) ||
            isNaN(planInputs.planType) ||
            !hasValue(planInputs.planType);
        return (
            noSelectedMembersOrNoPlanType ||
            !hasValue(planInputs.planStateId) ||
            isNaN(planInputs.planStateId)
        );
    }, [planInputs.planStateId, planInputs.planType, selectedHouseholdMemberIds]);
    const handlePlanStateChange = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(updatePlanInputs({ planStateId: +value }));
        },
        [dispatch]
    );
    const handlePlanTypeChange = useCallback(
        ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                updateSelectedHouseholdMemberIds(
                    householdMembersForSelectedYear.length === 0 ? [userId] : []
                )
            );
            dispatch(
                updatePlanInputs({
                    errors: null,
                    exchange: '',
                    issuerName: '',
                    planName: '',
                    planPremium: '',
                    planPremiumWithCredits: '',
                    planType: +value,
                    selectedAhp: '',
                    selectedCarrier: '',
                    selectedMarketplacePlan: '',
                })
            );
        },
        [dispatch, householdMembersForSelectedYear, userId]
    );
    const onSelectedYearChange = useCallback(
        async ({ target: { value } }) => {
            dispatch(updateSelectedHouseholdMemberIds([]));
            dispatch(updatePlanInputs({ selectedYear: value }));
        },
        [dispatch]
    );
    const setInput = useCallback(
        ({ target: { name, value } }) =>
            dispatch(updatePlanInputs({ ...planInputs, [name]: value })),
        [dispatch, planInputs]
    );
    const includeBottomInputs = useMemo(
        () =>
            ![PlanTypeIds.SpouseEmployer, PlanTypeIds.ParentEmployer].includes(planInputs.planType),
        [planInputs.planType]
    );
    const items = useMemo(() => getYears(() => 1).map((x) => ({ name: `${x}` })), []);
    type ICommonDropdownProps =
        | 'defaultText'
        | 'defaultValue'
        | 'onChange'
        | 'optionText'
        | 'optionValue';
    const commonDropdownProps: Pick<
        ISelectProps<{ text: string; value: boolean }>,
        ICommonDropdownProps
    > = {
        defaultText: '--',
        defaultValue: '',
        onChange: setInput,
        optionText: 'text' as const,
        optionValue: 'value' as const,
    };
    const getRhAndAdvisorInputs = () => {
        const content = [];
        if (isRh || isAdvisor) {
            content.push(
                <Select
                    {...commonDropdownProps}
                    isOptional
                    items={yesOrNo}
                    key="isAddedToPaymentTracking"
                    label="Is Added To Payment Tracking?"
                    name="isAddedToPaymentTracking"
                    value={planInputs.isAddedToPaymentTracking}
                />
            );
            content.push(
                <Select
                    {...commonDropdownProps}
                    isOptional
                    items={yesOrNo}
                    key="isAddedToPayrollReport"
                    label="Is Added To Payroll Report?"
                    name="isAddedToPayrollReport"
                    value={planInputs.isAddedToPayrollReport}
                />
            );
        }
        if (isRh) {
            content.push(
                <Select
                    {...commonDropdownProps}
                    defaultText={undefined}
                    items={yesOrNo}
                    key="isPassiveRenewal"
                    label="Is Passive Renewal?"
                    name="isPassiveRenewal"
                    value={planInputs.isPassiveRenewal}
                />
            );
        }

        return <React.Fragment>{content}</React.Fragment>;
    };
    const premiumLabel = isMediShare ? MONTHLY_SHARE_AMOUNT : PREMIUM;
    const filteredPlanStateItems = getPlanStateItems(
        planToEdit?.isAutoEnrollEligible,
        planToEdit?.issuerName
    );

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit' : 'Add'} Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="plan-modal" onSubmit={onSubmit}>
                    <Stack gap={2} mb={2}>
                        <Select
                            data-cy="plan-year"
                            defaultText="Select a Year"
                            defaultValue=""
                            disabled={isEdit}
                            items={items}
                            label="Year"
                            name="selectedYear"
                            onChange={onSelectedYearChange}
                            optionText="name"
                            optionValue="name"
                            value={planInputs.selectedYear}
                        />
                        <Collapse in={!!planInputs.selectedYear}>
                            <Stack gap={2}>
                                <Skeleton
                                    count={5}
                                    height="54px"
                                    isEnabled={isLoadingHouseholdMembers}
                                >
                                    <Select
                                        autoFocus={isEdit}
                                        data-cy="plan-state"
                                        items={filteredPlanStateItems}
                                        label="Plan State"
                                        name="planState"
                                        onChange={handlePlanStateChange}
                                        optionText="name"
                                        optionValue="value"
                                        value={planInputs.planStateId}
                                    />
                                    <Select
                                        data-cy="plan-type"
                                        defaultText="Choose a Plan Type"
                                        disabled={isEdit}
                                        items={PLAN_TYPE_ITEMS}
                                        label="Plan Type"
                                        name="planType"
                                        onChange={handlePlanTypeChange}
                                        optionText="name"
                                        optionValue="value"
                                        value={planInputs.planType}
                                    />
                                    <Collapse
                                        in={
                                            !isNaN(planInputs.planType) &&
                                            hasValue(planInputs.planType)
                                        }
                                    >
                                        <Stack gap={2}>
                                            <HouseholdMemberPlanInput planToEdit={planToEdit} />
                                            <PlanInputs planToEdit={planToEdit} />
                                            <CurrencyTextField
                                                data-cy="plan-premium"
                                                errors={planInputs.errors?.planPremium}
                                                isOptional={!isPlanPremiumRequired}
                                                label={premiumLabel}
                                                name="planPremium"
                                                onChange={setInput}
                                                value={planInputs.planPremium}
                                            />
                                            {shouldIncludePremiumWithCredits(
                                                planInputs.planType
                                            ) && (
                                                <CurrencyTextField
                                                    data-cy="plan-premium-with-credits"
                                                    errors={
                                                        planInputs.errors?.planPremiumWithCredits
                                                    }
                                                    isOptional={!isPlanPremiumWithCreditsRequired}
                                                    label="Premium after Tax Credits"
                                                    name="planPremiumWithCredits"
                                                    onChange={setInput}
                                                    value={planInputs.planPremiumWithCredits}
                                                />
                                            )}
                                            {HSA_ELIGIBLE_PLANS.includes(planInputs.planType) && (
                                                <Select
                                                    {...commonDropdownProps}
                                                    disabled={CMS_OR_IDEON_PLAN_TYPES.includes(
                                                        planInputs.planType
                                                    )}
                                                    isOptional
                                                    items={yesOrNo}
                                                    label="Is HSA Eligible"
                                                    name="isHsaEligible"
                                                    value={planInputs.isHsaEligible}
                                                />
                                            )}
                                            <TextField
                                                errors={planInputs.errors?.comment}
                                                isOptional
                                                label="Comment"
                                                name="comment"
                                                onChange={setInput}
                                                placeholder="Enter a comment"
                                                value={planInputs.comment}
                                            />
                                            {includeBottomInputs && (
                                                <BottomInputs
                                                    isMedicare={isMedicare}
                                                    isMediShare={isMediShare}
                                                />
                                            )}
                                            {getRhAndAdvisorInputs()}
                                        </Stack>
                                    </Collapse>
                                </Skeleton>
                            </Stack>
                        </Collapse>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button disabled={isDisabled} form="plan-modal" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(PlanModal);
