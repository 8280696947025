import { IRhFile } from 'api/generated/models';
import ProfileAttribute from 'components/ProfileAttribute';
import SingleButtonModal from 'components/SingleButtonModal';
import React from 'react';
import { hot } from 'react-hot-loader';
import { readableFileSize } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

const RhFileDetailsModal = ({
    rhFile: {
        archivedByUser,
        archivedDate,
        createdByUser,
        createdDate,
        fileSizeInBytes,
        isArchived,
        lastModifiedByUser,
        lastModifiedDate,
        name,
        originalFileName,
    },
    onClose,
}: {
    onClose: () => void;
    rhFile: IRhFile;
}) => (
    <SingleButtonModal
        body={
            <React.Fragment>
                <ProfileAttribute label="Name">{name}</ProfileAttribute>
                <ProfileAttribute label="Original File Name">{originalFileName}</ProfileAttribute>
                {fileSizeInBytes && (
                    <ProfileAttribute label="File Size">
                        {readableFileSize(fileSizeInBytes)}
                    </ProfileAttribute>
                )}
                <ProfileAttribute label="Uploaded By">
                    {createdByUser?.displayName}
                </ProfileAttribute>
                <ProfileAttribute label="Uploaded Date">
                    {formatDateForDisplay(createdDate)}
                </ProfileAttribute>
                {lastModifiedByUser && (
                    <ProfileAttribute label="Last Modified By">
                        {lastModifiedByUser.displayName}
                    </ProfileAttribute>
                )}
                {lastModifiedDate && (
                    <ProfileAttribute label="Last Modified Date">
                        {formatDateForDisplay(lastModifiedDate)}
                    </ProfileAttribute>
                )}
                {isArchived && (
                    <React.Fragment>
                        <ProfileAttribute label="Archived By">
                            {archivedByUser?.displayName}
                        </ProfileAttribute>
                        <ProfileAttribute label="Archived Date">
                            {formatDateForDisplay(archivedDate)}
                        </ProfileAttribute>
                    </React.Fragment>
                )}
            </React.Fragment>
        }
        buttonClickHandler={onClose}
        buttonText="Close"
        title="File Details"
    />
);

export default hot(module)(RhFileDetailsModal);
