import { clearUser } from 'actions/clear';
import { GET_USER_ACTION, getUser } from 'actions/user/getUser';
import { IUserBudget } from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatUserOnDateAtTime } from 'utilities/format';

const BudgetModalFooter = ({
    budget: { isCustom, lastModifiedBy, lastModifiedDate },
    hideHr = false,
}: {
    budget: IUserBudget;
    hideHr?: boolean;
}) => {
    const dispatch = useThunkDispatch();
    const { isLoadingUser, lastModifiedByUser } = useSelector((state: AppStore) => ({
        isLoadingUser: hasApiActivity(state, GET_USER_ACTION),
        lastModifiedByUser: state.user,
    }));
    useEffect(() => {
        if (isCustom && lastModifiedBy) {
            dispatch(getUser(lastModifiedBy));
        }
        return () => {
            dispatch(clearUser());
        };
    }, [isCustom, dispatch, lastModifiedBy]);
    return (
        <React.Fragment>
            {!hideHr && <hr className="mt-4" />}
            <Row className="justify-content-center">
                <Skeleton count={1} isEnabled={isLoadingUser} width={250}>
                    {!isEmpty(lastModifiedByUser) && (
                        <small className="text-muted">
                            Last edited by{' '}
                            {formatUserOnDateAtTime(lastModifiedByUser, lastModifiedDate)}
                        </small>
                    )}
                </Skeleton>
            </Row>
        </React.Fragment>
    );
};
export default hot(module)(BudgetModalFooter);
