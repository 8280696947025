import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
    BULK_SET_ALLOW_SHOPPING_ACTION,
    bulkSetAllowShopping,
} from 'actions/team/bulkSetAllowShopping';
import ConfirmationModal from 'components/ConfirmationModal';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const SetAllowShoppingForTeamModal = ({ close }: { close: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { selectedYear, showActivity } = useSelector((state: AppStore) => ({
        selectedYear: +state.profileState.selectedYear,
        showActivity: hasApiActivity(state, BULK_SET_ALLOW_SHOPPING_ACTION),
    }));
    const [allowShopping, setAllowShopping] = useState(false);
    const onYesClickSetAllowShopping = useCallback(async () => {
        await dispatch(bulkSetAllowShopping(teamId, +selectedYear, allowShopping));
        close();
    }, [allowShopping, close, dispatch, selectedYear, teamId]);
    const body = useMemo(
        () => (
            <Stack gap={2}>
                <Typography mb={3} mt={1} mx={1} variant="body2">
                    Please select the value you would like to set <strong>Allow Shopping</strong> to
                    for all members for <strong>{selectedYear}</strong>.
                </Typography>
                <Select
                    data-cy="set-allow-shopping-dropdown"
                    items={yesOrNo}
                    label="Allow Shopping?"
                    onChange={onChange(setAllowShopping)}
                    optionText="text"
                    optionValue="value"
                    value={allowShopping.toString()}
                />
            </Stack>
        ),
        [allowShopping, selectedYear]
    );

    return (
        <ConfirmationModal
            body={body}
            noButtonText="Cancel"
            onNoClick={close}
            onYesClick={onYesClickSetAllowShopping}
            showActivity={showActivity}
            title="Set Allow Shopping For All Members on Team"
            yesButtonText="Confirm"
        />
    );
};

export default hot(module)(SetAllowShoppingForTeamModal);
