import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { IInviteMembersModalErrors } from 'components/teamMembersActionButtons/InviteMembersModal';
import TextField from 'components/TextField';
import React from 'react';
import { hot } from 'react-hot-loader';

const InviteMemberRow = ({
    'data-cy': dataCy,
    email,
    errors,
    handleDeleteClick,
    hideDeleteButton,
    onChange,
    row,
}: {
    'data-cy': string;
    email: string | undefined;
    errors: IInviteMembersModalErrors;
    handleDeleteClick: (row: number) => void;
    hideDeleteButton: boolean;
    onChange: (row: number, e: React.ChangeEvent<HTMLInputElement>) => void;
    row: number;
}) => (
    <TextField
        autoFocus={row === 0}
        data-cy={dataCy}
        errors={errors?.[(`emails[${row}]` as unknown) as keyof IInviteMembersModalErrors]}
        InputProps={{
            endAdornment: hideDeleteButton ? (
                <React.Fragment />
            ) : (
                <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => handleDeleteClick(row)}>
                        <CloseIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
        label="Email Address"
        name={`emails[${row}]`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(row, e)}
        placeholder="Enter an email address"
        value={email}
    />
);

export default hot(module)(InviteMemberRow);
