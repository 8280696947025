import NumberTextField, { INumberTextFieldProps } from 'components/NumberTextField';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { hot } from 'react-hot-loader';

const SocialSecurityTextField = (props: INumberTextFieldProps) => (
    <NumberTextField
        allowNegative={false}
        data-cy={kebabCase(props.label as string)}
        data-private="lipsum"
        format="###-##-####"
        placeholder="XXX-XX-XXXX"
        thousandSeparator={false}
        {...props}
    />
);

export default hot(module)(SocialSecurityTextField);
