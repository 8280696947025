import { Stack, Typography } from '@mui/material';
import {
    BULK_SET_USE_PREMIUM_TAX_CREDITS_ACTION,
    bulkSetUsePremiumTaxCredits,
} from 'actions/team/bulkSetUsePremiumTaxCredits';
import ConfirmationModal from 'components/ConfirmationModal';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';

const SetUsePremiumTaxCreditsForTeamModal = ({ close }: { close: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { selectedYear, showActivity } = useSelector((state: AppStore) => ({
        selectedYear: +state.profileState.selectedYear,
        showActivity: hasApiActivity(state, BULK_SET_USE_PREMIUM_TAX_CREDITS_ACTION),
    }));
    const [usePremiumTaxCredits, setUsePremiumTaxCredits] = useState(true);
    const onYesClickSetUsePremiumTaxCredits = useCallback(async () => {
        await dispatch(bulkSetUsePremiumTaxCredits(teamId, +selectedYear, usePremiumTaxCredits));
        close();
    }, [usePremiumTaxCredits, close, dispatch, selectedYear, teamId]);
    const body = useMemo(
        () => (
            <React.Fragment>
                <Stack gap={2}>
                    <Typography mb={3} mt={1} mx={1} variant="body2">
                        Please select the value you would like to set{' '}
                        <strong>Use Premium Tax Credits</strong> to for all members for{' '}
                        <strong>{selectedYear}</strong>.
                    </Typography>
                    <Select
                        data-cy="set-use-premium-tax-credits-dropdown"
                        items={yesOrNo}
                        label="Use Premium Tax Credits?"
                        onChange={onChange(setUsePremiumTaxCredits)}
                        optionText="text"
                        optionValue="value"
                        value={usePremiumTaxCredits.toString()}
                    />
                </Stack>
            </React.Fragment>
        ),
        [usePremiumTaxCredits, selectedYear]
    );

    return (
        <ConfirmationModal
            body={body}
            noButtonText="Cancel"
            onNoClick={close}
            onYesClick={onYesClickSetUsePremiumTaxCredits}
            showActivity={showActivity}
            title="Set Use Premium Tax Credits For All Members on Team"
            yesButtonText="Confirm"
        />
    );
};

export default hot(module)(SetUsePremiumTaxCreditsForTeamModal);
