import { completeAllIchraEnrollmentTasks } from 'actions/taskFlows/completeAllIchraEnrollmentTasks';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';
import { IUser } from 'api/generated/models';
import ConfirmationModal from 'components/ConfirmationModal';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const CompleteIchraEnrollmentTasksModal = ({
    onClose,
    showActivity,
    user,
}: {
    onClose: () => void;
    showActivity: boolean;
    user: IUser | undefined;
}) => {
    const { hasUserIdUrlParam } = useUserProps();
    const { teamId } = useTeamProps();
    const dispatch = useThunkDispatch();
    const { peopleState, selectedYear } = useSelector((state: AppStore) => ({
        peopleState: state.peopleState,
        selectedYear: +state.profileState.selectedYear,
    }));
    const body = `Are you sure you want to complete the ICHRA enrollment task flow for ${user?.firstName}?  This will skip all remaining tasks and set their Enrollment Status to Pending Application.`;

    const handleYesClick = async () => {
        await dispatch(completeAllIchraEnrollmentTasks(user?.userId as string));
        if (!hasUserIdUrlParam) {
            await dispatch(listPagedUserProfiles(teamId, selectedYear, peopleState));
        }
        onClose();
    };
    return (
        <ConfirmationModal
            body={body}
            onNoClick={onClose}
            onYesClick={handleYesClick}
            showActivity={showActivity}
            title="Confirm"
        />
    );
};

export default hot(module)(CompleteIchraEnrollmentTasksModal);
