import { Grid, Paper } from '@mui/material';
import React from 'react';
import { hot } from 'react-hot-loader';

type IShopHeaderContainerProps = {
    leftSide: JSX.Element;
    rightSide: JSX.Element;
};
const ShopHeaderContainer = ({ leftSide, rightSide }: IShopHeaderContainerProps) => (
    <Paper sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container direction="row">
            <Grid container item lg={9} xs={12}>
                {leftSide}
            </Grid>
            <Grid
                alignItems="center"
                container
                item
                justifyContent={{ lg: 'flex-end', xs: 'center' }}
                lg={3}
                xs={12}
            >
                {rightSide}
            </Grid>
        </Grid>
    </Paper>
);

export default hot(module)(ShopHeaderContainer);
