import {
    deleteSelectedPlan,
    DELETE_SELECTED_PLAN_ACTION,
} from 'actions/selectedPlan/deleteSelectedPlan';
import { getUserProfile } from 'actions/user/getUserProfile';
import { ISelectedPlan } from 'api/generated/models';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

type IRemovePlanModalProps = {
    onClose: () => void;
    onSubmit?: () => void;
    plan: ISelectedPlan | undefined;
    userId: string | undefined;
};

const RemovePlanModal = ({ onClose, onSubmit, plan, userId }: IRemovePlanModalProps) => {
    const { householdMembersCovered, selectedPlanId, isPrimaryCovered, planName } = plan ?? {};
    const { isCurrent } = useUserProps();
    const { showActivity } = useSelector((state: AppStore) => ({
        showActivity: hasApiActivity(state, DELETE_SELECTED_PLAN_ACTION),
    }));
    const memberCount = useMemo(
        () =>
            isPrimaryCovered
                ? (householdMembersCovered?.length ?? 0) + 1
                : householdMembersCovered?.length,
        [householdMembersCovered, isPrimaryCovered]
    );
    const dispatch = useThunkDispatch();
    const onYesClick = useCallback(async () => {
        await dispatch(deleteSelectedPlan(selectedPlanId));
        dispatch(getUserProfile(userId, isCurrent));
        onClose();
        onSubmit?.();
    }, [dispatch, selectedPlanId, userId, isCurrent, onClose, onSubmit]);
    return (
        <ConfirmationModal
            noButtonText="Cancel"
            onNoClick={onClose}
            onYesClick={onYesClick}
            showActivity={showActivity}
            title="Remove Plan"
            yesButtonText="Yes, Remove Plan"
        >
            Are you sure you want remove {planName}? This will remove the plan for&nbsp;
            {memberCount} member{memberCount === 1 ? '' : 's'}.
        </ConfirmationModal>
    );
};
export default hot(module)(RemovePlanModal);
