import { compareVerifiedInfo, COMPARE_VERIFIED_INFO } from 'actions/user/compareVerifiedInfo';
import { IUser } from 'api/generated/models';
import Select from 'components/Select';
import SingleButtonModal from 'components/SingleButtonModal';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { isTrueOrFalse } from 'utilities';
import { formatYesOrNo } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { getYears } from 'utilities/year';

const yearNumbers = getYears(() => 1);
const years = yearNumbers.map((x) => ({ name: `${x}` }));

type ICompareInfoProps = {
    user: IUser | undefined;
};

const CompareInfo = ({ user }: ICompareInfoProps) => {
    const dispatch = useThunkDispatch();

    const { isLoading, selectedYear, verifiedInfoComparison } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, COMPARE_VERIFIED_INFO),
        selectedYear: Number(state.profileState.selectedYear),
        verifiedInfoComparison: state.verifiedInfoComparison,
    }));

    const hasPreviousYear = yearNumbers.includes(selectedYear - 1);
    const [sourceYear, setSourceYear] = useState(hasPreviousYear ? selectedYear - 1 : selectedYear);
    const [destinationYear, setDestinationYear] = useState(
        hasPreviousYear ? selectedYear : selectedYear + 1
    );

    useEffect(() => {
        dispatch(compareVerifiedInfo(user?.userId, sourceYear, destinationYear));
    }, [destinationYear, dispatch, sourceYear, user?.userId]);

    const formatDifferenceValue = (value: string | undefined): string | undefined =>
        value !== undefined && isTrueOrFalse(value) ? formatYesOrNo(value) : value;

    const isUnableToCompareVerifiedInfo = useMemo(
        () =>
            !verifiedInfoComparison?.hasVerifiedInfoForSourceYear ||
            !verifiedInfoComparison?.hasVerifiedInfoForDestinationYear ||
            sourceYear.toString() === destinationYear.toString(),
        [destinationYear, sourceYear, verifiedInfoComparison]
    );

    const differenceRows = verifiedInfoComparison?.differences?.map((difference, index, diffs) => (
        <React.Fragment key={difference.name}>
            <Row className="align-items-center">
                <Col>{difference.name}</Col>
                <Col className="text-center">{formatDifferenceValue(difference.before)}</Col>
                <Col className="text-center">{formatDifferenceValue(difference.after)}</Col>
            </Row>
            {index !== diffs?.length - 1 && <hr />}
        </React.Fragment>
    ));
    const unableToCompareReasons = (
        <Col>
            <Row>Unable to compare verified info for the following reason(s):</Row>
            {!verifiedInfoComparison?.hasVerifiedInfoForSourceYear && (
                <Row className="pl-3">- No verified info for First Year: {sourceYear}</Row>
            )}
            {!verifiedInfoComparison?.hasVerifiedInfoForDestinationYear && (
                <Row className="pl-3">- No verified info for Second Year: {destinationYear}</Row>
            )}
            {sourceYear.toString() === destinationYear.toString() && (
                <Row className="pl-3">- First and Second Years are the same</Row>
            )}
        </Col>
    );
    const noDifferencesLabel = (
        <Col>
            <Row className="justify-content-center">
                No differences between {sourceYear} and {destinationYear}.
            </Row>
        </Col>
    );

    return (
        <Skeleton count={1} height="100px" isEnabled={isLoading} sx={{ mb: 2 }}>
            <Col className="pb-3">
                <Row className="align-items-end justify-content-end">
                    <Col>
                        <label>Field</label>
                    </Col>
                    <Col className="align-items-center">
                        <Select
                            data-cy="source-year"
                            items={years.filter((year) => Number(year.name) < destinationYear)}
                            label="First Year"
                            name="sourceYear"
                            onChange={onChange(setSourceYear)}
                            optionText="name"
                            optionValue="name"
                            size="small"
                            value={sourceYear}
                        />
                    </Col>
                    <Col>
                        <Select
                            data-cy="destination-year"
                            items={years.filter((year) => Number(year.name) > sourceYear)}
                            label="Second Year"
                            name="destinationYear"
                            onChange={onChange(setDestinationYear)}
                            optionText="name"
                            optionValue="name"
                            size="small"
                            value={destinationYear}
                        />
                    </Col>
                </Row>
                <hr />
                {differenceRows}
                {isUnableToCompareVerifiedInfo && unableToCompareReasons}
                {!isUnableToCompareVerifiedInfo &&
                    differenceRows?.length === 0 &&
                    noDifferencesLabel}
            </Col>
        </Skeleton>
    );
};

type ICompareInfoModalProps = {
    onClose: () => void;
    user: IUser | undefined;
};

const CompareInfoModal = ({ onClose, user }: ICompareInfoModalProps) => (
    <SingleButtonModal
        body={<CompareInfo user={user} />}
        buttonClickHandler={onClose}
        buttonText="Close"
        size="lg"
        title="Compare Verified Info"
    />
);

export default hot(module)(CompareInfoModal);
