import useUserProps from 'hooks/useUserProps';
import { useRouteMatch } from 'react-router';

/**
 * For getting user props in components that are rendered
 * outside of react-router `Route`. If a component is rendered outside
 * of the `Route` component then we do not have access to the url
 * params for that route so we are unable to get the proper `userId`.
 * **Important**: Downside is we rely on url structure
 * @returns User props from `useUserProps`
 */
const useExternalUserProps = () => {
    const match = useRouteMatch<{ userId?: string }>({
        exact: false,
        path: ['/people/:userId', '/teams/:teamId/people/:userId'],
        strict: false,
    });
    return useUserProps(match?.params?.userId);
};

export default useExternalUserProps;
