import { Grid, Typography, useTheme } from '@mui/material';
import { ReimbursementDisplayLevels } from 'api/generated/enums';
import { IReimbursementOverlayDto } from 'api/generated/models';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';

type IReimbursementChartLegend = {
    displayLevel: ReimbursementDisplayLevels | undefined;
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined;
};

const ReimbursementChartLegend = ({
    displayLevel,
    reimbursementOverlayDto: reimbursement,
}: IReimbursementChartLegend) => {
    const theme = useTheme();
    const extraTextExplanationContent = (
        <React.Fragment>
            <br />
            <Typography>
                This visual shows how the reimbursement program interacts with this plan only. If
                your household has multiple benefits, the actual distribution of your family
                reimbursement amount (
                {formatCurrency(reimbursement?.fullFamilyMedicalReimbursementAmount)}) may differ.
            </Typography>
        </React.Fragment>
    );
    const extraTextContent = reimbursement?.showExtraTextExplanation && extraTextExplanationContent;
    let explanationContent;
    switch (displayLevel) {
        case ReimbursementDisplayLevels.Individual: {
            explanationContent =
                reimbursement?.individualMedicalResponsibility === 0 ? (
                    <React.Fragment>
                        <Typography>
                            You can start submitting medical expenses for reimbursement as soon as
                            your benefits start.
                        </Typography>
                        {extraTextContent}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography>
                            Once you reach the individual responsibility amount (
                            {formatCurrency(reimbursement?.individualMedicalResponsibility, {
                                includeDollarSign: true,
                            })}
                            ), you can start submitting expenses for reimbursement
                        </Typography>
                        {extraTextContent}
                    </React.Fragment>
                );
            break;
        }
        case ReimbursementDisplayLevels.IndividualAndFamily: {
            explanationContent =
                reimbursement?.individualMedicalResponsibility === 0 &&
                reimbursement?.familyMedicalResponsibility === 0 ? (
                    <React.Fragment>
                        <Typography>
                            You can start submitting medical expenses for reimbursement as soon as
                            your benefits start.
                        </Typography>
                        {extraTextContent}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography>
                            An individual member of your family can start receiving reimbursements
                            after the individual employee responsibility (
                            {formatCurrency(reimbursement?.individualMedicalResponsibility, {
                                includeDollarSign: true,
                            })}
                            ) is met.
                        </Typography>
                        <br />
                        <Typography>
                            Once your family responsibility amount (
                            {formatCurrency(reimbursement?.familyMedicalResponsibility, {
                                includeDollarSign: true,
                            })}
                            ) is met, all members of the family can submit expenses for
                            reimbursement.
                        </Typography>
                        {extraTextContent}
                    </React.Fragment>
                );
            break;
        }
        case ReimbursementDisplayLevels.Family: {
            explanationContent =
                reimbursement?.familyMedicalResponsibility === 0 ? (
                    <React.Fragment>
                        <Typography>
                            You can start submitting medical expenses for reimbursement as soon as
                            your benefits start.
                        </Typography>
                        {extraTextContent}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography>
                            Once your family responsibility amount (
                            {formatCurrency(reimbursement?.familyMedicalResponsibility, {
                                includeDollarSign: true,
                            })}
                            ) is met, all members of the family can submit expenses for
                            reimbursement.
                        </Typography>
                        {extraTextContent}
                    </React.Fragment>
                );
        }
    }

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="space-evenly"
            marginY={2}
            spacing={2}
        >
            <Grid
                alignItems="center"
                container
                direction="row"
                item
                justifyContent="center"
                rowSpacing={2}
                width="80%"
            >
                {hasValue(reimbursement?.hsaEmployerContribution) && (
                    <Grid container direction="row" item justifyContent="center" md={3}>
                        <Grid
                            item
                            sx={{
                                backgroundColor: theme.palette.orange.main,
                                height: 21,
                                marginRight: 1,
                                width: 28,
                            }}
                        ></Grid>
                        <Grid item>Employer HSA Contribution</Grid>
                    </Grid>
                )}
                {(hasValue(reimbursement?.individualMedicalResponsibility) ||
                    hasValue(reimbursement?.familyMedicalResponsibility)) && (
                    <Grid container direction="row" item justifyContent="center" md={3}>
                        <Grid
                            item
                            sx={{
                                backgroundColor: theme.palette.secondary.main,
                                height: 21,
                                marginRight: 1,
                                width: 28,
                            }}
                        ></Grid>
                        <Grid item>Employee Responsibility</Grid>
                    </Grid>
                )}
                {(hasValue(reimbursement?.individualMedicalReimbursementAmount) ||
                    hasValue(reimbursement?.familyMedicalReimbursementAmount)) && (
                    <Grid container direction="row" item justifyContent="center" md={3}>
                        <Grid
                            item
                            sx={{
                                backgroundColor: theme.palette.primary.dark,
                                height: 21,
                                marginRight: 1,
                                width: 28,
                            }}
                        ></Grid>
                        <Grid item>Employer Responsibility</Grid>
                    </Grid>
                )}
                <Grid container direction="row" item justifyContent="center" md={2}>
                    <Grid
                        item
                        sx={{
                            backgroundColor: theme.palette.grey[600],
                            height: 21,
                            marginRight: 1,
                            width: 28,
                        }}
                    ></Grid>
                    <Grid item>Plan Coverage</Grid>
                </Grid>
            </Grid>

            <Grid item width="100%">
                <hr />
            </Grid>

            <Grid item width="80%">
                {explanationContent}
            </Grid>
        </Grid>
    );
};

export default hot(module)(ReimbursementChartLegend);
