import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

const AgeContainer = (props: BoxProps) => (
    <Box
        alignItems="center"
        display="grid"
        gap={2}
        maxHeight={400}
        maxWidth="80vw"
        overflow="auto"
        pr={2}
        {...props}
    />
);

export default AgeContainer;
