import { AppStoreThunkDispatch } from 'actions/commonAction';
import { DELETE_TEAM_NOTE_ACTION } from 'actions/teamNote/deleteTeamNote';
import { TOGGLE_ARCHIVE_TEAM_NOTE_ACTION } from 'actions/teamNote/toggleArchiveTeamNote';
import { DELETE_USER_NOTE_ACTION } from 'actions/userNote/deleteUserNote';
import { TOGGLE_ARCHIVE_USER_NOTE_ACTION } from 'actions/userNote/toggleArchiveUserNote';
import { INote } from 'api/generated/models';
import { showNoteModal } from 'components/note/notesActions';
import ThingCard from 'components/ThingCard';
import WrappableH4 from 'components/WrappableH4';
import useThunkDispatch from 'hooks/useThunkDispatch';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import styled from 'styled-components';
import { formatDateForDisplay } from 'utilities/format';

const NotePreviewContainer = styled(Row).attrs(() => ({ className: 'align-items-center' }))`
    min-height: 84px;
`;

const NotePreview = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`;

type INoteProps = {
    canArchiveNotes: boolean;
    canDeleteNotes: boolean;
    deleteNote: (
        noteId: string
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
    note: INote;
    toggleArchiveNote: (
        noteId: string,
        isArchived: boolean
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
};

const Note = ({
    canArchiveNotes,
    canDeleteNotes,
    deleteNote,
    note,
    toggleArchiveNote,
}: INoteProps) => {
    const dispatch = useThunkDispatch();
    const { createdByUser, createdDate, isArchived, noteId, noteText, title } = useMemo(
        () => note,
        [note]
    );
    const { isArchiveLoading, isDeleteLoading } = useSelector((state: AppStore) => ({
        isArchiveLoading: hasApiActivity(
            state,
            TOGGLE_ARCHIVE_TEAM_NOTE_ACTION,
            TOGGLE_ARCHIVE_USER_NOTE_ACTION
        ),
        isDeleteLoading: hasApiActivity(state, DELETE_TEAM_NOTE_ACTION, DELETE_USER_NOTE_ACTION),
    }));
    const openNoteModal = useCallback(() => dispatch(showNoteModal(note)), [dispatch, note]);
    const items = useMemo(
        () => [
            {
                isConfirm: true,
                isLoading: isArchiveLoading,
                isVisible: canArchiveNotes,
                label: isArchived ? 'Un-Archive' : 'Archive',
                onClick: async () => dispatch(toggleArchiveNote(noteId, isArchived)),
            },
            {
                isConfirm: true,
                isLoading: isDeleteLoading,
                isVisible: canDeleteNotes,
                label: 'Delete',
                onClick: async () => dispatch(deleteNote(noteId)),
            },
        ],
        [
            canArchiveNotes,
            canDeleteNotes,
            deleteNote,
            dispatch,
            isArchiveLoading,
            isArchived,
            isDeleteLoading,
            noteId,
            toggleArchiveNote,
        ]
    );
    return (
        <Col lg="3" sm="6">
            <ThingCard
                bottomLeftContent={`Created by ${createdByUser?.firstName} ${
                    createdByUser?.lastName
                } on ${formatDateForDisplay(createdDate)}`}
                isArchived={isArchived}
                items={items}
                onClick={openNoteModal}
                topLeftContent={<WrappableH4 pr={3}>{title}</WrappableH4>}
            >
                <Row>
                    <NotePreviewContainer noGutters>
                        <NotePreview>{noteText}</NotePreview>
                    </NotePreviewContainer>
                </Row>
            </ThingCard>
        </Col>
    );
};

Note.propTypes = {
    note: PropTypes.object,
};

export default hot(module)(Note);
