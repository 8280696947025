import UploadIcon from '@mui/icons-material/Upload';
import { Card, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import DropZone from 'components/DropZone';
import { FileViolation } from 'components/fileInput/FileViolation';
import FileViolationMessage from 'components/fileInput/FileViolationMessage';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import without from 'lodash/without';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { hasContents, hasValue } from 'utilities';
import { v4 as guid } from 'uuid';

const isFileExtensionValid = (file: File, accept: string[] = []) => {
    const fileExt = file.name.split('.').pop();
    return accept.some((x) => x.replace('.', '').toLowerCase() === fileExt?.toLowerCase());
};

type IFileInputProps = {
    accept?: string[];
    buttonLabel?: string;
    disabled?: boolean;
    errors?: string[];
    fileData?: File | File[];
    hideFiles?: boolean;
    isDropZoneDisabled?: boolean;
    isMultiple?: boolean;
    label?: string;
    maxFileNameLength?: number;
    maxFileSizeInBytes?: number;
    minFileSizeInBytes?: number;
    onChange: (data: File | File[] | undefined) => void;
    shouldReplaceOnChange?: boolean;
};

const FileInput = ({
    accept: parentAccept,
    buttonLabel: parentButtonLabel,
    disabled = false,
    errors,
    fileData,
    hideFiles,
    isDropZoneDisabled = false,
    isMultiple = false,
    label,
    maxFileNameLength,
    maxFileSizeInBytes,
    minFileSizeInBytes,
    onChange: onChangeParent,
    shouldReplaceOnChange = false,
}: IFileInputProps) => {
    const [invalidFileMessages, setInvalidFileMessages] = useState<(JSX.Element | undefined)[]>([]);

    const accept = useMemo(() => parentAccept?.join(', '), [parentAccept]);
    const fileList = useMemo(() => {
        if (Array.isArray(fileData)) {
            return Array.from(fileData);
        } else if (fileData instanceof File) {
            return [fileData];
        } else {
            return [];
        }
    }, [fileData]);

    const isAboveMinFileSize = useCallback((file) => file.size >= (minFileSizeInBytes as number), [
        minFileSizeInBytes,
    ]);
    const isUnderMaxFileSize = useCallback((file) => file.size <= (maxFileSizeInBytes as number), [
        maxFileSizeInBytes,
    ]);
    const isUnderMaxFileNameLength = useCallback(
        (file) => file.name.length <= (maxFileNameLength as number),
        [maxFileNameLength]
    );
    const filterInvalidFiles = useCallback(
        <T,>(
            violationType: FileViolation,
            condition: boolean,
            uploadedFileData: File[],
            newFileData: File[],
            isValid: (file: File, ...additionalArgs: T[]) => boolean,
            ...additionalIsValidArgs: T[]
        ): { invalidFileMessage?: JSX.Element; newFileData: File[] } => {
            if (condition) {
                const invalidFiles = uploadedFileData.filter(
                    (x) => !isValid(x, ...additionalIsValidArgs)
                );
                newFileData = newFileData.filter((x) => isValid(x, ...additionalIsValidArgs));
                let invalidFileMessage;
                if (invalidFiles.length) {
                    invalidFileMessage = (
                        <FileViolationMessage
                            accept={accept}
                            invalidFiles={invalidFiles}
                            key={guid()}
                            maxFileNameLength={maxFileNameLength}
                            maxFileSizeInBytes={maxFileSizeInBytes}
                            minFileSizeInBytes={minFileSizeInBytes}
                            violationType={violationType}
                        />
                    );
                }
                return { invalidFileMessage, newFileData };
            }
            return { newFileData };
        },
        [accept, maxFileNameLength, maxFileSizeInBytes, minFileSizeInBytes]
    );
    const filterByAccept = useCallback(
        (uploadedFileData: File[], newFileData: File[]) =>
            filterInvalidFiles(
                FileViolation.Type,
                hasContents(parentAccept),
                uploadedFileData,
                newFileData,
                isFileExtensionValid,
                parentAccept
            ),
        [filterInvalidFiles, parentAccept]
    );
    const filterByMaxSize = useCallback(
        (uploadedFileData: File[], newFileData: File[]) =>
            filterInvalidFiles(
                FileViolation.MaxSize,
                hasValue(maxFileSizeInBytes),
                uploadedFileData,
                newFileData,
                isUnderMaxFileSize
            ),
        [filterInvalidFiles, isUnderMaxFileSize, maxFileSizeInBytes]
    );
    const filterByMinSize = useCallback(
        (uploadedFileData: File[], newFileData: File[]) =>
            filterInvalidFiles(
                FileViolation.MinSize,
                hasValue(minFileSizeInBytes),
                uploadedFileData,
                newFileData,
                isAboveMinFileSize
            ),
        [filterInvalidFiles, isAboveMinFileSize, minFileSizeInBytes]
    );
    const filterByMaxNameLength = useCallback(
        (uploadedFileData: File[], newFileData: File[]) =>
            filterInvalidFiles(
                FileViolation.MaxNameLength,
                hasValue(maxFileNameLength),
                uploadedFileData,
                newFileData,
                isUnderMaxFileNameLength
            ),
        [filterInvalidFiles, isUnderMaxFileNameLength, maxFileNameLength]
    );
    const getFileDataEnforcingSize = useCallback(
        (files: File | File[] | FileList, replaceOnChange = shouldReplaceOnChange) => {
            const uploadedFileData = 'length' in files ? Array.from(files) : [files];
            let newFileData = Array.from(uploadedFileData);
            const filterAcceptResult = filterByAccept(uploadedFileData, newFileData);
            newFileData = filterAcceptResult.newFileData;
            const filterMaxResult = filterByMaxSize(uploadedFileData, newFileData);
            newFileData = filterMaxResult.newFileData;
            const filterMinResult = filterByMinSize(uploadedFileData, newFileData);
            newFileData = filterMinResult.newFileData;
            const filterMaxNameLengthResult = filterByMaxNameLength(uploadedFileData, newFileData);
            newFileData = filterMaxNameLengthResult.newFileData;
            const newInvalidMessages = without(
                [
                    filterAcceptResult.invalidFileMessage,
                    filterMaxResult.invalidFileMessage,
                    filterMinResult.invalidFileMessage,
                    filterMaxNameLengthResult.invalidFileMessage,
                ],
                undefined
            );
            setInvalidFileMessages(newInvalidMessages);
            if (fileList?.length > 0 && !replaceOnChange) {
                newFileData = newFileData.concat(fileList);
            }
            const uniqueFileSet = new Set();
            const uniqueFileData = newFileData.filter((file) => {
                const isPresentInSet = uniqueFileSet.has(file.name);
                uniqueFileSet.add(file.name);
                return !isPresentInSet;
            });
            return isMultiple ? uniqueFileData : uniqueFileData[0];
        },
        [
            shouldReplaceOnChange,
            filterByAccept,
            filterByMaxSize,
            filterByMinSize,
            filterByMaxNameLength,
            fileList,
            isMultiple,
        ]
    );

    useEffect(() => {
        if (fileData) {
            const newFileData = getFileDataEnforcingSize(fileData, true);
            onChangeParent(newFileData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buttonLabel = useMemo(() => parentButtonLabel ?? `Select File${isMultiple ? 's' : ''}`, [
        parentButtonLabel,
        isMultiple,
    ]);

    const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const element = event.target as HTMLInputElement;
        element.value = '';
    };

    const onDrop = useCallback(
        (event) => {
            const files = (Array.from(
                (hasValue(event.target) ? event.target.files : event) || []
            ) as File[] | FileList) ?? [event];
            const allFiles = files.length === 0 && hasValue(event) ? [event] : files;
            const newFileData = getFileDataEnforcingSize(allFiles);
            if (newFileData) {
                onChangeParent(newFileData);
            }
        },
        [getFileDataEnforcingSize, onChangeParent]
    );
    const removeFile = useCallback(
        (index) => () => {
            fileList.splice(index, 1);
            onChangeParent(fileList);
        },
        [fileList, onChangeParent]
    );

    const isInvalid = useMemo(() => !!errors || !isEmpty(invalidFileMessages), [
        errors,
        invalidFileMessages,
    ]);
    const feedback = useMemo(() => {
        if (errors) {
            return errors[0];
        } else if (isInvalid) {
            return <span>{...invalidFileMessages}</span>;
        }
        return `${label} is required`;
    }, [errors, isInvalid, label, invalidFileMessages]);

    return (
        <DropZone isDisabled={isDropZoneDisabled} isMultiple={isMultiple} onDrop={onDrop}>
            <Box textAlign="center">
                <InputLabel>{label}</InputLabel>
                <Button
                    color={isInvalid ? 'error' : 'primary'}
                    component="label"
                    endIcon={<UploadIcon />}
                    variant="outlined"
                >
                    {buttonLabel}
                    <input
                        accept={accept}
                        data-cy={kebabCase(label)}
                        disabled={disabled}
                        hidden
                        multiple={isMultiple}
                        onChange={onDrop}
                        onClick={onInputClick}
                        title={label ?? 'No File Chosen'}
                        type="file"
                    />
                </Button>
                {isInvalid && (
                    <FormHelperText component="div" error>
                        {feedback}
                    </FormHelperText>
                )}
                {!hideFiles &&
                    fileList?.length > 0 &&
                    fileList.map((x: File, idx) => (
                        <Card
                            className={idx === 0 ? 'mt-3 mb-1' : 'mt-2 mb-1'}
                            key={idx}
                            sx={{ py: 0.5 }}
                        >
                            <CardContent className="px-4 py-2">
                                <Row className="justify-content-between text-break flex-nowrap">
                                    <div>{x.name}</div>
                                    <i
                                        className="dripicons-cross text-muted clickable"
                                        onClick={removeFile(idx)}
                                    ></i>
                                </Row>
                            </CardContent>
                        </Card>
                    ))}
            </Box>
        </DropZone>
    );
};

export default hot(module)(FileInput);
