import Circle from 'components/progress/Circle';
import Line from 'components/progress/Line';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';

const CIRCLE_OFFSET_IN_PIXELS = 58.333;
const LINE_OFFSET_IN_PIXELS = 20;
const CIRCLE_LINE_OFFSET_IN_PIXELS = 65;
const Progress = ({
    current = 0,
    isReviewing = false,
    total,
}: {
    current?: number;
    isReviewing?: boolean;
    total: number;
}) => {
    const stepsArray = useMemo(() => Array.from({ length: +total }, () => 0), [total]);
    let x = 0;
    return (
        <svg
            height="20"
            viewBox={`0 0 ${CIRCLE_OFFSET_IN_PIXELS * stepsArray.length} 20`}
            width="100%"
        >
            {stepsArray.map((_, index) => {
                const circleX = x;
                const lineX = x + LINE_OFFSET_IN_PIXELS;
                x += CIRCLE_LINE_OFFSET_IN_PIXELS;
                return (
                    <React.Fragment key={index}>
                        <Circle
                            isActive={index === current}
                            isComplete={
                                (isReviewing && current !== index) ||
                                (!isReviewing && current > index)
                            }
                            x={circleX}
                        />
                        {total > 0 && index !== total - 1 && (
                            <Line isComplete={isReviewing || current > index} x={lineX} />
                        )}
                    </React.Fragment>
                );
            })}
        </svg>
    );
};

export default hot(module)(Progress);
