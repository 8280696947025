import { IAction } from 'actions/commonAction';
import {
    CLEAR_PASSWORD_INPUTS,
    UPDATE_PASSWORD_INPUTS,
} from 'components/passwordInputs/passwordInputsActions';

export type IPasswordInputs = {
    isValid: boolean;
    password?: string;
};

const initialState: IPasswordInputs = {
    isValid: false,
    password: '',
};

export const passwordInputsState = (
    state = initialState,
    action: IAction<IPasswordInputs>
): IPasswordInputs => {
    switch (action.type) {
        case UPDATE_PASSWORD_INPUTS:
            return {
                ...state,
                ...action.data,
            };
        case CLEAR_PASSWORD_INPUTS:
            return initialState;
    }
    return state;
};
