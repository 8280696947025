import Stack from '@mui/material/Stack';
import { SAVE_COMPLETED_USER_TASK_ACTION } from 'actions/task/saveCompletedUserTask';
import {
    EDIT_SOCIAL_SECURITY_FOR_HOUSEHOLD_ACTION,
    IHouseholdSocialSecurityRequests,
} from 'actions/user/editSocialSecurityNumbers';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { EntityType } from 'api/generated/enums';
import Button from 'components/Button';
import Form from 'components/Form';
import Icon from 'components/Icon';
import SocialSecurityTextField from 'components/SocialSecurityTextField';
import useForm from 'hooks/useForm';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { NumberFormatValues } from 'react-number-format';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue, invalidSsn } from 'utilities';
import { getDisplayFirstName } from 'utilities/household';
import { StringSchema, object, string } from 'yup';

type IEnterSocialSecurityModalProps = {
    includeEntireHousehold?: boolean;
    onCancelClick: () => void;
    onHide: () => void;
    onSaveClick: (
        socialSecurityRequests: IHouseholdSocialSecurityRequests,
        isEdit?: boolean
    ) => void;
};

const EnterSocialSecurityModal = ({
    onCancelClick,
    onSaveClick,
    onHide,
    includeEntireHousehold = false,
}: IEnterSocialSecurityModalProps) => {
    const { memberVerifiedInfo, user, userId } = useUserProps();
    const { hasPrimarySsn, householdMembers, isLoading } = useSelector((state: AppStore) => ({
        hasPrimarySsn: hasValue(memberVerifiedInfo?.socialSecurityNumber),
        householdMembers: state.householdMembers,
        isLoading: hasApiActivity(
            state,
            SAVE_COMPLETED_USER_TASK_ACTION,
            EDIT_SOCIAL_SECURITY_FOR_HOUSEHOLD_ACTION,
            GET_HOUSEHOLD_ACTION
        ),
    }));
    const [socialSecurityNumbers, setSocialSecurityNumber] = useState(
        {} as IHouseholdSocialSecurityRequests
    );
    const [socialSecurityNumbersToSave, setSocialSecurityNumberToSave] = useState(
        {} as IHouseholdSocialSecurityRequests
    );
    const householdMembersNeedingSsn = useMemo(
        () =>
            includeEntireHousehold
                ? householdMembers
                : householdMembers.filter(
                      (x) => !hasValue(x.socialSecurityNumber) && x.needsCoverage
                  ),
        [householdMembers, includeEntireHousehold]
    );
    const schema = useMemo(() => {
        const objectShape: Record<string, StringSchema> = {};
        const isValidSsn = (value: string | undefined) =>
            includeEntireHousehold ? !hasValue(value) || !invalidSsn(value) : !invalidSsn(value);
        if ((!hasPrimarySsn || includeEntireHousehold) && userId !== undefined) {
            objectShape[userId] = string().test(
                'primaryValidSsn',
                `${getDisplayFirstName(user)}'s SSN is invalid`,
                isValidSsn
            );
        }
        householdMembersNeedingSsn.forEach((hm) => {
            objectShape[hm.householdMemberId] = string().test(
                'memberValidSsn',
                `${getDisplayFirstName(hm)}'s SSN is invalid`,
                isValidSsn
            );
        });
        return object(objectShape);
    }, [hasPrimarySsn, householdMembersNeedingSsn, includeEntireHousehold, user, userId]);
    const { errors, validate } = useForm(schema);
    const onSubmit = useCallback(async () => {
        const objectToValidate = Object.fromEntries(
            Object.entries(socialSecurityNumbersToSave).map(([k, v]) => [k, v.socialSecurityNumber])
        );
        const { isValid } = await validate(objectToValidate);
        if (isValid) {
            let isEdit = false;
            if (includeEntireHousehold) {
                isEdit =
                    hasValue(memberVerifiedInfo?.socialSecurityNumber) ||
                    householdMembersNeedingSsn.some((hm) => hasValue(hm.socialSecurityNumber));
                Object.keys(socialSecurityNumbersToSave).forEach((k) => {
                    if (socialSecurityNumbersToSave[k]?.socialSecurityNumber === '') {
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete socialSecurityNumbersToSave[k];
                    }
                });
            }
            onSaveClick(socialSecurityNumbersToSave, isEdit);
        }
    }, [
        householdMembersNeedingSsn,
        includeEntireHousehold,
        memberVerifiedInfo?.socialSecurityNumber,
        onSaveClick,
        socialSecurityNumbersToSave,
        validate,
    ]);
    const onChange = useCallback(
        (entityId, entityType) => (values: NumberFormatValues) => {
            const currentValuesToSave = { ...socialSecurityNumbersToSave };
            currentValuesToSave[entityId] = {
                entityId,
                entityType,
                socialSecurityNumber: values.formattedValue,
            };
            setSocialSecurityNumberToSave(currentValuesToSave);
            const currentValues = { ...socialSecurityNumbers };
            currentValues[entityId] = {
                entityId,
                entityType,
                socialSecurityNumber: values.value,
            };
            setSocialSecurityNumber(currentValues);
        },
        [socialSecurityNumbers, socialSecurityNumbersToSave]
    );
    return (
        <Modal onHide={onHide} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Enter Social Security Numbers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="social-security-modal" isLoading={isLoading} onSubmit={onSubmit}>
                    <Stack gap={2}>
                        <Alert className="pl-2" variant="info">
                            <Row className="align-items-center" noGutters>
                                <span className="mr-2">
                                    <Icon size="2" variant="secondary">
                                        lock
                                    </Icon>
                                </span>
                                <Col>
                                    We use 256-bit AES encryption to secure social security numbers
                                    and store them as one-way 256-bit SHA hashes.
                                </Col>
                            </Row>
                        </Alert>
                        {!includeEntireHousehold && (
                            <p className="text-muted">
                                We are only asking for social security numbers for members who have
                                been indicated as needing coverage and who we do not already have a
                                social security number for.
                            </p>
                        )}
                        {(!hasPrimarySsn || includeEntireHousehold) && (
                            <SocialSecurityTextField
                                autoFocus
                                errors={errors?.[userId]}
                                label={`${getDisplayFirstName(user)}'s SSN`}
                                name={userId}
                                onValueChange={onChange(userId, EntityType.User)}
                                value={socialSecurityNumbers[userId]?.socialSecurityNumber}
                            />
                        )}
                        {householdMembersNeedingSsn.map((hm, index) => (
                            <SocialSecurityTextField
                                autoFocus={index === 0 && hasPrimarySsn && !includeEntireHousehold}
                                errors={errors?.[hm.householdMemberId]}
                                key={hm.householdMemberId}
                                label={`${getDisplayFirstName(hm)}'s SSN`}
                                name={hm.householdMemberId}
                                onValueChange={onChange(
                                    hm.householdMemberId,
                                    EntityType.HouseholdMember
                                )}
                                value={
                                    socialSecurityNumbers[hm.householdMemberId]
                                        ?.socialSecurityNumber
                                }
                            />
                        ))}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button form="social-security-modal" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(EnterSocialSecurityModal);
