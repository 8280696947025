import Typography from '@mui/material/Typography';
import ConfirmationModal from 'components/ConfirmationModal';
import React from 'react';
import { hot } from 'react-hot-loader';

const NewVersionModal = ({
    onNoClick,
    onYesClick,
}: {
    onNoClick: () => void;
    onYesClick: () => void;
}) => (
    <ConfirmationModal
        noButtonText="Later, I'm Busy"
        onNoClick={onNoClick}
        onYesClick={onYesClick}
        title="Great News!"
        yesButtonText="Update now!"
    >
        <Typography textAlign="center" variant="h4">
            A new version is available!
        </Typography>
        <p className="text-center">
            Warning: Updating now will refresh the page and you may lose any unsaved changes.
        </p>
    </ConfirmationModal>
);

export default hot(module)(NewVersionModal);
