import { ActionType, Hooks, ReducerTableState, TableState } from 'react-table';

const filterChangeListener = <T extends object>(
    newState: TableState<T>,
    _action: ActionType,
    previousState?: TableState<T>
): ReducerTableState<T> => {
    if (
        newState.filters.some(
            (n) => previousState?.filters.find((p) => p.id === n.id)?.value !== n.value
        ) ||
        newState.globalFilter !== previousState?.globalFilter
    ) {
        return {
            ...newState,
            pageIndex: 0,
        };
    }

    return newState;
};

const addResetPageOnFilterChangeHook = <T extends object>(hooks: Hooks<T>) => {
    hooks.stateReducers.push(filterChangeListener);
};

export default addResetPageOnFilterChangeHook;
