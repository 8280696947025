import Stack from '@mui/material/Stack';
import { ADD_TEAM_RH_FILES_ACTION } from 'actions/rhFile/addTeamRhFiles';
import { ADD_TEAM_PLAN_ACTION } from 'actions/teamPlan/addTeamPlan';
import { ITeamPlan } from 'api/generated/models';
import Button from 'components/Button';
import FileInput from 'components/fileInput/FileInput';
import Form from 'components/Form';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import React, { useCallback, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { mixed, object, string } from 'yup';

const ONE_HUNDRED_MEGABYTES = 100000000;
const FIFTY_BYTES = 50;
const TWO_HUNDRED_AND_FIFTY_FIVE_CHARACTERS = 255;

const CARRIER_MAX_LENGTH = 50;
const PLAN_NAME_MAX_LENGTH = 250;
const schema = object({
    carrier: string()
        .trim()
        .required()
        .max(CARRIER_MAX_LENGTH)
        .label('Carrier'),
    planName: string()
        .trim()
        .required()
        .max(PLAN_NAME_MAX_LENGTH)
        .label('Plan Name'),
    summaryOfBenefits: mixed()
        .required()
        .label('Summary of Benefits'),
});

type ITeamGroupPlanModalProps = {
    carrier?: string;
    onClose: () => void;
    onSubmit: (
        teamPlan: Partial<ITeamPlan> | undefined,
        summaryOfBenefitsFile: File | File[] | undefined,
        teamPlanIndex: number | undefined
    ) => Promise<void>;
    planName?: string;
    teamId: string;
    teamPlanIndex?: number;
};

const TeamGroupPlanModal = ({
    carrier: parentCarrier,
    onClose,
    onSubmit,
    planName: parentPlanName,
    teamId,
    teamPlanIndex,
}: ITeamGroupPlanModalProps) => {
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, ADD_TEAM_PLAN_ACTION, ADD_TEAM_RH_FILES_ACTION),
    }));
    const [carrier, setCarrier] = useState(parentCarrier ?? '');
    const [planName, setPlanName] = useState(parentPlanName ?? '');
    const [summaryOfBenefits, setSummaryOfBenefits] = useState<File | File[]>();
    const { errors, validate } = useForm(schema);

    const handleSubmit = useCallback(async () => {
        const { data: teamPlan, isValid } = await validate({
            carrier,
            planName,
            summaryOfBenefits,
            teamId,
        });
        if (isValid) {
            await onSubmit(teamPlan, summaryOfBenefits, teamPlanIndex);
            onClose();
        }
    }, [carrier, onClose, onSubmit, planName, summaryOfBenefits, teamId, teamPlanIndex, validate]);

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Group Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="team-group-plan-modal" isLoading={isLoading} onSubmit={handleSubmit}>
                    <Stack gap={2}>
                        <TextField
                            autoFocus
                            data-cy="carrier"
                            errors={errors?.carrier}
                            label="Carrier"
                            name="carrier"
                            onChange={onChange(setCarrier)}
                            placeholder="Enter the carrier for your group plan"
                            value={carrier}
                        />
                        <TextField
                            data-cy="plan-name"
                            errors={errors?.planName}
                            label="Plan Name"
                            name="planName"
                            onChange={onChange(setPlanName)}
                            placeholder="Enter your group plan name"
                            value={planName}
                        />
                        <Row>
                            <Col xs="12">
                                <FileInput
                                    errors={errors?.summaryOfBenefits}
                                    fileData={summaryOfBenefits}
                                    label="Summary of Benefits"
                                    maxFileNameLength={TWO_HUNDRED_AND_FIFTY_FIVE_CHARACTERS}
                                    maxFileSizeInBytes={ONE_HUNDRED_MEGABYTES}
                                    minFileSizeInBytes={FIFTY_BYTES}
                                    onChange={setSummaryOfBenefits}
                                />
                            </Col>
                        </Row>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button form="team-group-plan-modal" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(TeamGroupPlanModal);
