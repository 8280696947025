import Spinner, { ISpinnerProps } from 'components/Spinner';
import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: ${({ backgroundColor }: React.CSSProperties) => backgroundColor};
    bottom: ${({ bottom }: React.CSSProperties) => (bottom ? `${bottom}px` : 0)};
    display: flex;
    left: ${({ left }: React.CSSProperties) => (left ? `${left}px` : 0)};
    position: absolute;
    right: ${({ right }: React.CSSProperties) => (right ? `${right}px` : 0)};
    top: ${({ top }: React.CSSProperties) => (top ? `${top}px` : 0)};
`;

/**
 * A loading spinner
 * @deprecated Please use components/Skeleton to show loading instead of using this indicator
 */
const ActivityIndicator = ({
    backgroundColor,
    bottom,
    color,
    left,
    right,
    size,
    top,
}: ISpinnerProps & React.CSSProperties) => (
    <Wrapper
        backgroundColor={backgroundColor}
        bottom={bottom}
        data-cy="loading-spinner"
        left={left}
        right={right}
        top={top}
    >
        <Spinner color={color} size={size} />
    </Wrapper>
);

export default hot(module)(ActivityIndicator);
