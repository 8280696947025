import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GLOBAL_SEARCH_ACTION, globalSearch } from 'actions/search/globalSearch';
import { SearchResultType } from 'api/generated/enums';
import { ISearchResult } from 'api/generated/models';
import Autocomplete, { IAutocompleteProps } from 'components/Autocomplete';
import { HighlightedSearchText } from 'components/creatableAutocomplete/CreatableAutocomplete';
import GlobalSearchTextField from 'components/GlobalSearchTextField';
import Icon from 'components/Icon';
import { push } from 'connected-react-router';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import {
    PEOPLE_PROFILE_PATH,
    TEAMS_DASHBOARD_PATH,
    TEAMS_PEOPLE_PROFILE_PATH,
} from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatPhoneNumber } from 'utilities/format';
import { clearPeoplePagePaginationParams } from 'pages/people/peopleActions';

type IDisableableSearchResult = Partial<ISearchResult> & {
    isDisabled?: boolean;
};
const getGlobalSearchRenderOptions: IAutocompleteProps<
    IDisableableSearchResult,
    false,
    false,
    false
>['renderOption'] = (props, { id, metadata, name, email, type }, state) => {
    let typeIconClassName;
    const resultText = email ? `${name} - ${email}` : name;
    const Metadata = () => {
        if (metadata) {
            const formattedPhone = metadata['phone'] ? (
                <React.Fragment>
                    {formatPhoneNumber(metadata['phone'])}
                    <Icon>dot</Icon>
                </React.Fragment>
            ) : (
                ''
            );

            switch (type) {
                case SearchResultType.User:
                    return (
                        <Typography variant="caption">
                            User<Icon>dot</Icon>
                            {formattedPhone}
                            {metadata['teamName']}
                        </Typography>
                    );
                case SearchResultType.Team:
                    return (
                        <Typography variant="caption">
                            Team<Icon>dot</Icon>
                            {formattedPhone}
                            {metadata['state']}
                        </Typography>
                    );
                case SearchResultType.HouseholdMember:
                    return (
                        <Typography variant="caption">
                            Household Member<Icon>dot</Icon>
                            {metadata['teamName']}
                        </Typography>
                    );
                default:
                    typeIconClassName = '';
                    return <Typography variant="caption">🤷🏻‍♂️</Typography>;
            }
        }
        return <React.Fragment />;
    };

    switch (type) {
        case SearchResultType.User:
            typeIconClassName = 'dripicons-user';
            break;
        case SearchResultType.Team:
            typeIconClassName = 'dripicons-store';
            break;
        case SearchResultType.HouseholdMember:
            typeIconClassName = 'dripicons-user-group';
            break;
        default:
            typeIconClassName = '';
            break;
    }

    return (
        <li {...props} key={id}>
            <Stack
                alignItems="center"
                borderBottom={0.5}
                direction="row"
                justifyContent="space-between"
                overflow="auto"
                pb={1}
                sx={{ wordBreak: 'break-all' }}
                width="100%"
            >
                {hasValue(type) && <i className={typeIconClassName} />}
                <Col className="ml-2">
                    <div>
                        <HighlightedSearchText query={state.inputValue} text={resultText} />
                    </div>
                    <Metadata />
                </Col>
            </Stack>
        </li>
    );
};

const MAX_NUMBER_OF_RESULTS_SHOWN = 10;
const GlobalSearch = ({ className }: { className?: string }) => {
    const dispatch = useThunkDispatch();
    const { currentTeamId, isLoading, search } = useSelector((state: AppStore) => ({
        currentTeamId: state.current.teamProfile.team?.teamId,
        isLoading: hasApiActivity(state, GLOBAL_SEARCH_ACTION),
        search: state.search,
    }));
    const [value, setValue] = useState<IDisableableSearchResult>({});
    const [includeArchived, setIncludeArchived] = useState<boolean>(false);
    const toggleArchived = useCallback(() => setIncludeArchived(!includeArchived), [
        includeArchived,
    ]);
    const onChange = useCallback(
        (_, result: IDisableableSearchResult | null) => {
            if (result) {
                const { id, metadata, type } = result;
                dispatch(clearPeoplePagePaginationParams());
                dispatch(setSelectedYear(''));
                if (type === SearchResultType.Team) {
                    dispatch(push(TEAMS_DASHBOARD_PATH.replace(':teamId', id ?? '')));
                } else if (
                    type === SearchResultType.User ||
                    type === SearchResultType.HouseholdMember
                ) {
                    const route =
                        currentTeamId === metadata?.['teamId']
                            ? generatePath(PEOPLE_PROFILE_PATH, { userId: id })
                            : generatePath(TEAMS_PEOPLE_PROFILE_PATH, {
                                  teamId: metadata?.['teamId'],
                                  userId: id,
                              });
                    dispatch(push(route));
                }
                setValue({});
            }
        },
        [currentTeamId, dispatch]
    );
    return (
        <Autocomplete
            className={className}
            clearOnBlur
            clearOnEscape
            data-cy="global-search"
            filterOptions={(options) => {
                if (options.length === MAX_NUMBER_OF_RESULTS_SHOWN) {
                    options.push({
                        isDisabled: true,
                        name: 'Too many results. Please narrow your search.',
                    });
                } else if (options.length > 0) {
                    options.push({
                        isDisabled: true,
                        name: `Showing ${options.length} result${options.length === 1 ? '' : 's'}`,
                    });
                }
                return options;
            }}
            getOptionDisabled={(option) => option.isDisabled ?? false}
            getOptionLabel={(x) => x.name ?? ''}
            loading={isLoading}
            onChange={onChange}
            options={(search.results ?? []) as IDisableableSearchResult[]}
            renderInput={(_props) => (
                <GlobalSearchTextField
                    includeArchived={includeArchived}
                    props={_props}
                    toggleIncludeArchived={toggleArchived}
                />
            )}
            renderOption={getGlobalSearchRenderOptions}
            search={(query) => globalSearch(query, includeArchived)}
            value={value}
        />
    );
};

export default hot(module)(GlobalSearch);
