import ConfirmationModal from 'components/ConfirmationModal';
import React from 'react';
import { hot } from 'react-hot-loader';

type IUpdateIncomeVerifiedDateModal = {
    name: string | undefined;
    onNoClick: () => void;
    onYesClick: () => void;
    showActivity: boolean;
    year: number | undefined;
};

const UpdateIncomeVerifiedDateModal = ({
    name,
    onNoClick,
    onYesClick,
    showActivity,
    year,
}: IUpdateIncomeVerifiedDateModal) => (
    <ConfirmationModal
        body={
            <div>
                <p>
                    This will set the Income Verified Date for {name}&apos;s {year} info to
                    today&apos;s date. You should only do this if you collected new income values
                    from the member, and you collected the salaries for each household member
                    separately from any additional income.
                </p>
                <p>
                    If you have questions about this, please click &quot;No&quot; to cancel the
                    action and contact the Product Team.
                </p>
            </div>
        }
        onNoClick={onNoClick}
        onYesClick={onYesClick}
        showActivity={showActivity}
        title="Are you sure you want to update the Income Verified Date?"
    />
);

export default hot(module)(UpdateIncomeVerifiedDateModal);
