import Box, { BoxProps } from '@mui/material/Box';
import MuiSkeleton, { SkeletonProps } from '@mui/material/Skeleton';
import times from 'lodash/times';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities/index';

type ISkeletonProps = SkeletonProps & {
    Wrapper?: React.ComponentType;
    count?: number;
    isEnabled?: boolean;
    rowProps?: BoxProps;
    structure?: ISkeletonStructure[][];
};
type ISkeletonStructure = Omit<ISkeletonProps, 'isEnabled' | 'rowProps' | 'structure'> & {
    columnProps?: BoxProps;
};
const Skeleton = ({
    children,
    isEnabled = true,
    rowProps,
    structure,
    variant = 'rectangular',
    Wrapper = React.Fragment,
    ...props
}: ISkeletonProps) => {
    if (!isEnabled) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    const getSkeleton = ({ count = 1, ...skeletonProps }: ISkeletonStructure) => (
        <React.Fragment>
            {times(count, (index) => (
                <Wrapper key={`skeleton-${index}`}>
                    <MuiSkeleton key={`skeleton-${index}`} variant={variant} {...skeletonProps} />
                </Wrapper>
            ))}
        </React.Fragment>
    );
    if (hasValue(structure)) {
        return (
            <Box>
                {structure.map((row, idx) => (
                    <Box display="flex" flexDirection="row" key={idx} {...rowProps}>
                        {row.map(({ columnProps, ...col }, colIdx) => (
                            <Box
                                display="flex"
                                flexDirection="column"
                                key={colIdx}
                                {...columnProps}
                            >
                                {getSkeleton(col)}
                            </Box>
                        ))}
                    </Box>
                ))}
            </Box>
        );
    }
    return getSkeleton(props);
};
export default hot(module)(Skeleton);
