import { ISurveyHouseholdMemberDto } from 'api/generated/models';
import Column from 'components/verifiedInfoSummary/Column';
import HouseholdMemberItem from 'components/verifiedInfoSummary/HouseholdMemberItem';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import React from 'react';
import { hot } from 'react-hot-loader';

const HouseholdMembers = ({
    editSection,
    householdMembers,
}: {
    editSection: ((tabIndex: number) => void) | undefined;
    householdMembers: ISurveyHouseholdMemberDto[] | undefined;
}) => (
    <Section
        noGutters
        onEdit={editSection && (() => editSection(SurveyTabs.Household))}
        title="Household Members"
    >
        {householdMembers?.length ? (
            householdMembers?.map((member, index) => (
                <Column isRight={!!(index & 1)} key={index}>
                    <HouseholdMemberItem {...member} />
                </Column>
            ))
        ) : (
            <div className="pl-1 text-muted">No Household Members added</div>
        )}
    </Section>
);

export default hot(module)(HouseholdMembers);
