import MenuItem from '@mui/material/MenuItem';
import TextField, { ITextFieldProps } from 'components/TextField';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';

export type ISelectProps<T> = ITextFieldProps & {
    'data-cy'?: string;
    defaultText?: string;
    defaultValue?: string;
    items: T[];
    optionText: keyof T | ((i: T | undefined) => string);
    optionValue: keyof T;
};

const Select = <T,>({
    'data-cy': dataCy,
    defaultText,
    defaultValue,
    items,
    optionText,
    optionValue,
    SelectProps: SelectPropsParent,
    ...props
}: ISelectProps<T>) => {
    const [isFocused, setIsFocused] = useState(false);
    const SelectProps = {
        native: true,
        ...SelectPropsParent,
    };
    let OptionComponent: React.ElementType = 'option';
    if (!SelectProps.native) {
        OptionComponent = MenuItem;
    }
    return (
        <TextField
            data-cy={dataCy}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            select
            SelectProps={SelectProps}
            {...props}
        >
            {defaultText && !SelectProps.multiple && (
                <OptionComponent value={defaultValue}>
                    {isFocused ? defaultText : ''}
                </OptionComponent>
            )}
            {items?.map((item) => {
                const text = typeof optionText === 'function' ? optionText(item) : item[optionText];
                const value = (item[optionValue] as unknown) as number | string;
                return (
                    <OptionComponent
                        data-cy={`${dataCy}-${text}-${value}`}
                        key={`${text}-${value}`}
                        value={value}
                    >
                        {text}
                    </OptionComponent>
                );
            })}
        </TextField>
    );
};

export default hot(module)(Select);
