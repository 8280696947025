import Tooltip from 'components/Tooltip';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';

const CLICK_TO_COPY_TEXT = 'Click to copy';
const TOOLTIP_TEXT_RESET_TIMEOUT = 150;

const CopyText = ({ children, text }: { children: ReactNode; text: string }) => {
    const [tooltipText, setTooltipText] = useState(CLICK_TO_COPY_TEXT);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(text);
            setTooltipText('Copied to clipboard!');
        },
        [text]
    );

    const onMouseLeave = () =>
        setTimer(setTimeout(() => setTooltipText(CLICK_TO_COPY_TEXT), TOOLTIP_TEXT_RESET_TIMEOUT));

    useEffect(
        () => () => {
            if (timer) {
                clearTimeout(timer);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Tooltip title={tooltipText}>
            <a onClick={onClick} onMouseLeave={onMouseLeave}>
                {children}
            </a>
        </Tooltip>
    );
};

export default hot(module)(CopyText);
