import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import { hot } from 'react-hot-loader';

type ICheckboxProps = CheckboxProps &
    Pick<FormControlLabelProps, 'label'> & { helperText?: string };

const Checkbox = ({ disabled, helperText, label, ...props }: ICheckboxProps) => (
    <FormControl>
        <FormControlLabel
            control={<MuiCheckbox {...props} />}
            disabled={disabled}
            label={label}
            sx={{ m: 0 }}
        />
        <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
);

export default hot(module)(Checkbox);
