import { INVITE_USERS_FOR_TEAM_ACTION, inviteUsersForTeam } from 'actions/team/inviteUsersForTeam';
import ConfirmationModal from 'components/ConfirmationModal';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const SendInvitesModal = ({
    close,
    onYesClick,
}: {
    close: () => void;
    onYesClick?: () => void;
}) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { invitableMemberCount, showActivity } = useSelector((state: AppStore) => ({
        invitableMemberCount: state.userProfilesMetadata.invitableMemberCount,
        showActivity: hasApiActivity(state, INVITE_USERS_FOR_TEAM_ACTION),
        userProfiles: state.userProfiles,
    }));
    const body = useMemo(
        () =>
            invitableMemberCount === 1
                ? 'Are you sure you want to send an invite to one member?'
                : `Are you sure you want to send invites to ${invitableMemberCount} members?`,
        [invitableMemberCount]
    );
    const yesClick = useCallback(async () => {
        await dispatch(inviteUsersForTeam(teamId));
        onYesClick ? onYesClick() : close();
    }, [close, dispatch, onYesClick, teamId]);

    return (
        <ConfirmationModal
            body={body}
            onNoClick={close}
            onYesClick={yesClick}
            showActivity={showActivity}
            title="Confirm"
        />
    );
};

export default hot(module)(SendInvitesModal);
