import { hot } from 'react-hot-loader';
import styled, { keyframes } from 'styled-components';

const spinTransform = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }`;

export const SpinAnimation = styled.div`
    animation: ${spinTransform} 1s linear infinite;
`;

export default hot(module)(SpinAnimation);
