import { IMemberVerifiedInfo } from 'api/generated/models';
import { IValueType } from 'components/EditableAttribute';
import EditableNumberAttribute from 'components/EditableNumberAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { nameof } from 'utilities';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const NUMBER_OF_INCHES_IN_FOOT = 12;
const HeightProfileAttribute = ({
    heightInInches: initialHeightInInches,
    readonly,
    save: parentSave,
}: {
    heightInInches: number | undefined;
    readonly: boolean;
    save: ISaveEditableTextField<IMemberVerifiedInfo>;
}) => {
    const format = (value: number | string | undefined) => {
        if (value === undefined) {
            return '';
        }
        let inches = value;
        if (typeof inches === 'string') {
            inches = Number(value);
        }
        const feet = Math.floor(inches / NUMBER_OF_INCHES_IN_FOOT);
        inches %= NUMBER_OF_INCHES_IN_FOOT;
        return `${feet}ft ${inches}in`;
    };
    const [heightInInches, setHeightInInches] = useState(format(initialHeightInInches));
    const save = async (name: keyof IMemberVerifiedInfo, value: IValueType) => {
        const splitValues = (value as string)?.replace(/[a-z]|_/g, '').split(' ');
        const inchesFromFeet = Number(splitValues[0]) * NUMBER_OF_INCHES_IN_FOOT;
        let totalInches: number | string = Number(splitValues[1]) + inchesFromFeet;
        if (isNaN(totalInches)) {
            totalInches = '';
        } else {
            setHeightInInches(format(totalInches));
        }
        return parentSave(name, totalInches);
    };
    return (
        <EditableNumberAttribute
            format="#ft ##in"
            formatter={() => format(initialHeightInInches)}
            isNumericString={false}
            isOptional
            label="Height"
            name={nameof<IMemberVerifiedInfo>('heightInInches')}
            onChange={onChange(setHeightInInches)}
            placeholder="Enter a height"
            readonly={readonly}
            save={save}
            validationSchema={string().label('Height')}
            value={heightInInches}
        />
    );
};

export default hot(module)(HeightProfileAttribute);
