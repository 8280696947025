import Alert from '@mui/material/Alert';
import TeamBenefitModalNavigationButton from 'components/teamBenefitActionButtons/TeamBenefitModalNavigationButton';
import React from 'react';
import { hot } from 'react-hot-loader';

type ITeamBenefitModalPreviousNextProps = {
    isLastTerm?: boolean;
    isLocked: boolean;
    isNewTerm?: boolean;
    isNextVisible: boolean;
    isPreviousVisible: boolean;
    onNextClick: () => void;
    onPreviousClick: () => void;
};

const TeamBenefitModalPreviousNext = ({
    isLastTerm = false,
    isLocked,
    isNewTerm = false,
    isPreviousVisible,
    isNextVisible,
    onPreviousClick,
    onNextClick,
}: ITeamBenefitModalPreviousNextProps) => (
    <React.Fragment>
        <TeamBenefitModalNavigationButton
            dataCy="previous-period"
            isDisabled={isLocked}
            isVisible={isPreviousVisible}
            onClick={onPreviousClick}
        >
            Previous
        </TeamBenefitModalNavigationButton>
        {isNewTerm && (
            <Alert severity="warning" style={{ marginLeft: '2rem' }}>
                This term has not yet been created. To create the term, ensure all fields are filled
                out and click &quot;Save&quot; at the bottom of the form.
            </Alert>
        )}
        <TeamBenefitModalNavigationButton
            dataCy="next-period"
            isDisabled={isLocked}
            isVisible={isNextVisible}
            onClick={onNextClick}
        >
            {isLastTerm ? 'Create New Term' : 'Next'}
        </TeamBenefitModalNavigationButton>
    </React.Fragment>
);

export default hot(module)(TeamBenefitModalPreviousNext);
