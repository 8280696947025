import { HouseholdMemberTypes } from 'api/generated/enums';
import { IHouseholdMember, ISurveyHouseholdMemberDto, IUser } from 'api/generated/models';
import Column from 'components/verifiedInfoSummary/Column';
import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import { ISurveyHouseholdMember, ISurveyState } from 'pages/survey/surveyState';
import React, { ReactNode, useCallback, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import titleCase from 'titleize';
import { hasValue, isFalse, reduceToObject } from 'utilities';
import { formatYesOrNo } from 'utilities/format';
import {
    getDisplayFirstName,
    getMemberNames,
    isEmployedAndNotSameEmployer,
} from 'utilities/household';

const ListSummary = <T extends { entityIds?: string[]; name?: string }>({
    householdMembers,
    keyProperty,
    label,
    selectedData,
}: {
    householdMembers: Partial<ISurveyHouseholdMember>[];
    keyProperty: keyof T;
    label: ReactNode;
    selectedData: T[];
}) => (
    <div className="pb-2">
        <Row className="text-secondary-45-lighten" noGutters>
            {label}
        </Row>
        {selectedData.length > 0
            ? selectedData.map((x, index) => (
                  <React.Fragment key={`${x[keyProperty] ?? index}`}>
                      <Row>
                          <Col>{titleCase(x.name ?? '')}</Col>
                          <Col className="text-right">
                              {getMemberNames(
                                  x.entityIds,
                                  householdMembers as (IHouseholdMember | IUser)[]
                              )?.join(', ')}
                          </Col>
                      </Row>
                      {index !== selectedData.length - 1 && <hr className="my-1" />}
                  </React.Fragment>
              ))
            : 'None'}
    </div>
);

const getMemberFirstNamesForProp = (
    prop: keyof ISurveyHouseholdMember,
    members: Partial<ISurveyHouseholdMember>[]
) =>
    members
        .filter((x) => x[prop])
        .map((x) => {
            const name = getDisplayFirstName(x);
            if (
                prop === 'isEmployed' &&
                x.householdMemberTypeId === HouseholdMemberTypes.Spouse &&
                x.hasSameEmployer
            ) {
                return `${name} (same as primary)`;
            }
            return name;
        });

type ICoverageProps = ISurveyState['coverage'] & {
    editSection: ((tabIndex: number) => void) | undefined;
    householdMembers: Partial<ISurveyHouseholdMemberDto>[];
    includeFaithBasedQuestionOverride?: boolean;
    isIchra?: boolean;
};

const Coverage = ({
    editSection,
    hasFaithBasedSharingInterest,
    hasHouseholdPreExistingConditions,
    householdMembers,
    householdPreExistingConditionsNote,
    includeFaithBasedQuestionOverride,
    isIchra,
    selectedDrugs,
    selectedProviders,
}: ICoverageProps) => {
    const { includeFaithBasedQuestion } = useSelector((state: AppStore) => ({
        includeFaithBasedQuestion:
            includeFaithBasedQuestionOverride ??
            state.surveyState.includeFaithBasedQuestionInSurvey,
    }));
    const hasSpouseOtherCoverage = householdMembers.some(
        (h) => h.householdMemberTypeId === HouseholdMemberTypes.Spouse && h.hasEmployerOtherCoverage
    );
    const yesOrNoFlat = useMemo(() => reduceToObject(yesOrNo, 'value', 'text'), []);
    const getMemberStringForProp = useCallback(
        (prop: keyof ISurveyHouseholdMember) =>
            getMemberFirstNamesForProp(prop, householdMembers).join(', ') || 'No',
        [householdMembers]
    );
    const needsCoverageString = useMemo(() => getMemberStringForProp('needsCoverage'), [
        getMemberStringForProp,
    ]);
    const tobaccoString = useMemo(() => getMemberStringForProp('usesTobacco'), [
        getMemberStringForProp,
    ]);
    const pregnantString = useMemo(() => getMemberStringForProp('isPregnant'), [
        getMemberStringForProp,
    ]);
    const isEmployedString = useMemo(() => getMemberStringForProp('isEmployed'), [
        getMemberStringForProp,
    ]);
    const hasParentOtherCoverageString = useMemo(
        () => getMemberFirstNamesForProp('hasParentOtherCoverage', householdMembers),
        [householdMembers]
    );
    const hasDependentEmployerOtherString = useMemo(
        () =>
            getMemberFirstNamesForProp(
                'hasEmployerOtherCoverage',
                householdMembers.filter(
                    (h) => h.householdMemberTypeId !== HouseholdMemberTypes.Spouse
                )
            ),
        [householdMembers]
    );
    const membersNotNeedingCoverage = householdMembers.filter((x) => isFalse(x.needsCoverage));
    const membersWithEmployerName = householdMembers.filter(
        (x) => isEmployedAndNotSameEmployer(x) && hasValue(x.employerName)
    );
    return (
        <Section onEdit={editSection && (() => editSection(SurveyTabs.Coverage))} title="Coverage">
            <Column>
                <ContentWithLabel label="Needs Major Medical Coverage">
                    {needsCoverageString}
                </ContentWithLabel>
                {membersNotNeedingCoverage.length > 0 && (
                    <ContentWithLabel label="Coverage Source" useCol>
                        {membersNotNeedingCoverage.map((member) => (
                            <div key={member.householdMemberId}>
                                {getDisplayFirstName(member)} - {member.otherCoverageSource}
                            </div>
                        ))}
                    </ContentWithLabel>
                )}
                {includeFaithBasedQuestion && !isIchra && (
                    <ContentWithLabel label="Interested in Faith-Based Options">
                        {yesOrNoFlat[hasFaithBasedSharingInterest.toString()]}
                    </ContentWithLabel>
                )}
                <ContentWithLabel label="Tobacco">{tobaccoString}</ContentWithLabel>
                {!isIchra && (
                    <React.Fragment>
                        <ContentWithLabel label="Pregnant">{pregnantString}</ContentWithLabel>
                        <ContentWithLabel label="Employed">{isEmployedString}</ContentWithLabel>
                        {membersWithEmployerName.length > 0 && (
                            <ContentWithLabel label="Employer Names" useCol>
                                {membersWithEmployerName.map((member) => (
                                    <div key={member.householdMemberId}>
                                        {getDisplayFirstName(member)} - {member.employerName}
                                    </div>
                                ))}
                            </ContentWithLabel>
                        )}
                        <ContentWithLabel label="Other Coverage" useCol>
                            {hasSpouseOtherCoverage && (
                                <div className="mb-2">
                                    Spouse Employer
                                    <div className="text-muted">
                                        Your spouse has a job that offers health coverage.
                                    </div>
                                </div>
                            )}
                            {hasParentOtherCoverageString.length > 0 && (
                                <div className="mb-2">
                                    Parent
                                    <div className="text-muted">
                                        {hasParentOtherCoverageString.joinSerialComma()}{' '}
                                        {hasParentOtherCoverageString.length > 1 ? 'have' : 'has'}{' '}
                                        coverage through a parent.
                                    </div>
                                </div>
                            )}
                            {hasDependentEmployerOtherString.length > 0 && (
                                <div>
                                    Dependent Employer
                                    <div className="text-muted">
                                        {hasDependentEmployerOtherString.joinSerialComma()}{' '}
                                        {hasDependentEmployerOtherString.length > 1
                                            ? 'have'
                                            : 'has'}{' '}
                                        coverage through an employer.
                                    </div>
                                </div>
                            )}
                            {!hasSpouseOtherCoverage &&
                                hasParentOtherCoverageString.length === 0 &&
                                hasDependentEmployerOtherString.length === 0 &&
                                'No'}
                        </ContentWithLabel>
                    </React.Fragment>
                )}
            </Column>
            <Column isRight>
                <ListSummary
                    householdMembers={householdMembers}
                    keyProperty="nationalProviderId"
                    label="Preferred Providers"
                    selectedData={selectedProviders}
                />
                <ListSummary
                    householdMembers={householdMembers}
                    keyProperty="rxcui"
                    label="Prescriptions"
                    selectedData={selectedDrugs}
                />
                <ContentWithLabel data-private label="Has Conditions" useCol>
                    {formatYesOrNo(hasHouseholdPreExistingConditions)}
                    {hasHouseholdPreExistingConditions && (
                        <div className="text-muted">{householdPreExistingConditionsNote}</div>
                    )}
                </ContentWithLabel>
            </Column>
        </Section>
    );
};

export default hot(module)(Coverage);
