import EditableAttribute, { IEditableAttributeProps } from 'components/EditableAttribute';
import EditableCurrencyTextField from 'components/EditableCurrencyTextField';
import { IEditableNumberTextFieldProps } from 'components/EditableNumberTextField';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrencyOptionalDecimal } from 'utilities/format';

export type IEditableCurrencyAttributeProps<T> = IEditableNumberTextFieldProps<T> &
    Omit<IEditableAttributeProps, 'children'>;

const EditableCurrencyAttribute = <T,>({
    emptyValueMessage,
    footer,
    formatter = formatCurrencyOptionalDecimal,
    hrClassName,
    infoTooltip,
    label,
    readonly,
    ...props
}: IEditableCurrencyAttributeProps<T>) => (
    <EditableAttribute
        emptyValueMessage={emptyValueMessage}
        footer={footer}
        formatter={formatter}
        hrClassName={hrClassName}
        infoTooltip={infoTooltip}
        label={label}
        readonly={readonly}
        {...props}
    >
        {({ onBlur }) => <EditableCurrencyTextField {...props} onBlur={onBlur} />}
    </EditableAttribute>
);

export default hot(module)(EditableCurrencyAttribute);
