import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import { ReimbursementDisplayLevels } from 'api/generated/enums';
import { IReimbursementOverlayDto } from 'api/generated/models';
import Button from 'components/Button';
import ReimbursementChart from 'components/reimbursementOverlayModal/ReimbursementChart';
import ReimbursementChartLegend from 'components/reimbursementOverlayModal/ReimbursementChartLegend';
import React from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';

export enum CoverageLevels {
    None = 0,
    EE = 1,
    EEPlusEmbedded = 2,
    EEPlusNonEmbedded = 3,
}

const BlackLine = () => (
    <Box
        sx={{
            background: '#999',
            height: 5,
            marginBlock: '0.6rem',
            marginInline: 'auto',
            width: '100%',
        }}
    ></Box>
);

type IReimbursementOverlayModal = {
    familyMoopCost: number | undefined;
    individualMoopCost: number | undefined;
    individualOnly?: boolean;
    onClose: () => void;
    planName: string | undefined;
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined;
};
const ReimbursementOverlayModal = ({
    familyMoopCost,
    individualMoopCost,
    individualOnly,
    onClose,
    planName,
    reimbursementOverlayDto: reimbursement,
}: IReimbursementOverlayModal) => {
    if (individualOnly && hasValue(reimbursement)) {
        reimbursement.displayLevelId = ReimbursementDisplayLevels.Individual;
    }

    return (
        <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
            <DialogTitle>{planName} + Reimbursements</DialogTitle>
            <DialogContent dividers>
                <Grid
                    alignItems="center"
                    container
                    justifyContent="center"
                    marginLeft={1}
                    marginRight={2}
                    marginTop={2}
                    spacing={0}
                    width="100%"
                >
                    <Grid item maxWidth="35%" minWidth={80} width="100%">
                        <BlackLine />
                    </Grid>
                    <Grid item maxWidth="30%" minWidth={80}>
                        <Grid alignItems="center" container height="100%">
                            <Typography
                                align="center"
                                alignItems="center"
                                color="#777"
                                justifyContent="center"
                                marginX={1}
                            >
                                Medical Expenses
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item maxWidth="35%" minWidth={80} width="100%">
                        <Grid alignItems="center" container direction="row" width="100%">
                            <Grid item width="98%">
                                <BlackLine />
                            </Grid>
                            <Grid item sx={{ width: '2%' }}>
                                <ArrowRightIcon
                                    sx={{
                                        color: '#999',
                                        fontSize: 35,
                                        marginLeft: -2,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ReimbursementChart
                    displayLevel={reimbursement?.displayLevelId}
                    familyMoopCost={familyMoopCost}
                    individualMoopCost={individualMoopCost}
                    reimbursementOverlayDto={reimbursement}
                />
                <ReimbursementChartLegend
                    displayLevel={reimbursement?.displayLevelId}
                    reimbursementOverlayDto={reimbursement}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(ReimbursementOverlayModal);
