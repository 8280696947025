import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import { ISurveyState } from 'pages/survey/surveyState';
import React from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import { formatPhoneNumber } from 'utilities/format';

const Contact = ({
    addressLine1,
    addressLine2,
    city,
    county,
    editSection,
    email,
    phone,
    state,
    zip,
}: ISurveyState['contact'] & { editSection: ((tabIndex: number) => void) | undefined }) => (
    <Section onEdit={editSection && (() => editSection(SurveyTabs.Contact))} title="Contact">
        <Col className="p-0">
            <ContentWithLabel label="Email">{email}</ContentWithLabel>
            <ContentWithLabel data-private label="Phone">
                {formatPhoneNumber(phone)}
            </ContentWithLabel>
            <ContentWithLabel data-private label="Address">
                {addressLine1}
                <div>{addressLine2}</div>
                <div>
                    {city}, {state} {zip}
                </div>
            </ContentWithLabel>
            <ContentWithLabel data-private label="County">
                {county}
            </ContentWithLabel>
        </Col>
    </Section>
);

export default hot(module)(Contact);
