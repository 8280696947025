import React from 'react';
import { hot } from 'react-hot-loader';
import Script from 'react-load-script';

export type IHandlePlaceChanged = (addressData: {
    addressLine1: string;
    city: string;
    county: string;
    state: string;
    zip: string;
}) => void;

type AddressAutocompleteProps = {
    handlePlaceChanged: IHandlePlaceChanged;
    inputId: string;
};

class AddressAutocomplete extends React.PureComponent<AddressAutocompleteProps> {
    autocomplete: google.maps.places.Autocomplete | undefined;

    placeChanged() {
        const addressObject = this.autocomplete?.getPlace();
        if (addressObject?.address_components !== undefined) {
            const address = addressObject.address_components;
            let streetNumber = '';
            let streetName = '';
            let city = '';
            let state = '';
            let county = '';
            let zip = '';
            address.forEach((part) => {
                switch (part.types[0]) {
                    case 'street_number':
                        streetNumber = part.short_name;
                        break;
                    case 'route':
                        streetName = part.long_name;
                        break;
                    case 'locality':
                        city = part.short_name;
                        break;
                    case 'administrative_area_level_1':
                        state = part.short_name;
                        break;
                    case 'administrative_area_level_2':
                        county = part.short_name;
                        break;
                    case 'postal_code':
                        zip = part.short_name;
                        break;
                }
            });
            this.props.handlePlaceChanged({
                city,
                county,
                state,
                zip,
                addressLine1: `${streetNumber} ${streetName}`,
            });
        }
    }

    handleScriptLoad() {
        const options = { types: ['geocode'] };
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById(this.props.inputId) as HTMLInputElement,
            options
        );
        this.autocomplete.addListener('place_changed', () => this.placeChanged());
    }

    override render() {
        return (
            <Script
                onLoad={this.handleScriptLoad.bind(this)}
                url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBJivR-IT-c8zj5dsjwP1kf1n_3fINsNYQ&libraries=places"
            />
        );
    }
}

export default hot(module)(AddressAutocomplete);
