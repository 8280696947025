import EditableAttribute, { IEditableAttributeProps } from 'components/EditableAttribute';
import EditableDateTextField from 'components/EditableDateTextField';
import { IEditableTextFieldProps } from 'components/EditableTextField';
import React from 'react';
import { hot } from 'react-hot-loader';

type IEditableDateAttribute<T> = IEditableTextFieldProps<T> &
    Omit<IEditableAttributeProps, 'children'>;

const EditableDateAttribute = <T,>({
    emptyValueMessage,
    footer,
    formatter,
    hrClassName,
    infoTooltip,
    label,
    readonly,
    ...props
}: IEditableDateAttribute<T>) => (
    <EditableAttribute
        emptyValueMessage={emptyValueMessage}
        footer={footer}
        formatter={formatter}
        hrClassName={hrClassName}
        infoTooltip={infoTooltip}
        label={label}
        readonly={readonly}
        {...props}
    >
        {({ onBlur }) => (
            <EditableDateTextField placeholder={`Enter ${label}`} {...props} onBlur={onBlur} />
        )}
    </EditableAttribute>
);

export default hot(module)(EditableDateAttribute);
