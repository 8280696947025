import { openUpShopping } from 'actions/taskFlows/openUpShopping';
import ConfirmationModal from 'components/ConfirmationModal';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React from 'react';
import { hot } from 'react-hot-loader';

const OpenUpShoppingModal = ({
    onClose,
    showActivity,
    userId,
}: {
    onClose: () => void;
    showActivity: boolean;
    userId: string;
}) => {
    const dispatch = useThunkDispatch();
    const body =
        'This will move the member to the Shopping experience, so that they or their advisor can complete their enrollment flow in the platform.';

    const handleYesClick = async () => {
        await dispatch(openUpShopping(userId));
        onClose();
    };

    return (
        <ConfirmationModal
            body={body}
            onNoClick={onClose}
            onYesClick={handleYesClick}
            showActivity={showActivity}
            title="Confirm"
        />
    );
};

export default hot(module)(OpenUpShoppingModal);
