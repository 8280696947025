import Button from 'components/Button';
import ConditionalTooltip from 'components/ConditionalTooltip';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

const IS_LOCKED_TOOLTIP_TEXT =
    'Can only modify one cost period at a time; either save or cancel to view/edit another cost period';

const TeamBenefitModalNavigationButton = ({
    children,
    dataCy,
    isDisabled: isButtonDisabled,
    isVisible,
    onClick,
}: {
    children: ReactNode;
    dataCy: string;
    isDisabled: boolean;
    isVisible: boolean;
    onClick: React.MouseEventHandler;
}) =>
    isVisible ? (
        <ConditionalTooltip isDisabled={!isButtonDisabled} title={IS_LOCKED_TOOLTIP_TEXT}>
            <Button
                data-cy={dataCy}
                disabled={isButtonDisabled}
                onClick={onClick}
                variant="outlined"
            >
                {children}
            </Button>
        </ConditionalTooltip>
    ) : (
        <span />
    );

export default hot(module)(TeamBenefitModalNavigationButton);
