import { IMemberVerifiedInfo } from 'api/generated/models';
import { IValueType } from 'components/EditableAttribute';
import EditableNumberAttribute from 'components/EditableNumberAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue, nameof } from 'utilities';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const WeightProfileAttribute = ({
    readonly,
    save: parentSave,
    weightInPounds: initialWeightInPounds,
}: {
    readonly: boolean;
    save: ISaveEditableTextField<IMemberVerifiedInfo>;
    weightInPounds: number | undefined;
}) => {
    const [weightInPounds, setWeightInPounds] = useState(initialWeightInPounds ?? '');
    const save = useCallback(
        async (name: keyof IMemberVerifiedInfo, value: IValueType) => {
            const strippedValue = (value as string)?.replace(/[a-z]|\s/g, '');
            const numberValue = Number(strippedValue);
            const newValue = strippedValue === '' || isNaN(numberValue) ? '' : numberValue;
            return parentSave(name, newValue);
        },
        [parentSave]
    );
    return (
        <EditableNumberAttribute
            format="###lbs"
            formatter={() =>
                !hasValue(initialWeightInPounds) ? '' : `${initialWeightInPounds}lbs`
            }
            isOptional
            label="Weight"
            name={nameof<IMemberVerifiedInfo>('weightInPounds')}
            onChange={onChange(setWeightInPounds)}
            placeholder="Enter a weight"
            readonly={readonly}
            save={save}
            validationSchema={string().label('Weight')}
            value={weightInPounds}
        />
    );
};

export default hot(module)(WeightProfileAttribute);
