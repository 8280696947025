import { dispatchWrapper } from 'actions/clear';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { IPlanInputs } from 'components/planModal/planModalState';

export const UPDATE_SELECTED_HOUSEHOLD_MEMBERS = 'UPDATE_SELECTED_HOUSEHOLD_MEMBERS';
export const updateSelectedHouseholdMemberIds = (
    selectedHouseholdMemberIds: string[] | undefined
) => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { selectedHouseholdMemberIds },
        type: UPDATE_SELECTED_HOUSEHOLD_MEMBERS,
    });
};

export const UPDATE_PLAN_INPUTS = 'UPDATE_PLAN_INPUTS';
export const updatePlanInputs = (planInputs: Partial<IPlanInputs>) => (
    dispatch: AppStoreThunkDispatch
) => {
    dispatch({
        data: { ...planInputs },
        type: UPDATE_PLAN_INPUTS,
    });
};

export const CLEAR_PLAN_INPUTS = 'CLEAR_PLAN_INPUTS';
export const clearPlanInputs = () => dispatchWrapper(CLEAR_PLAN_INPUTS);
