import { toast, ToastContent, ToastId, ToastOptions, UpdateOptions } from 'react-toastify';

const defaults = {
    autoClose: 3000,
    closeOnClick: true,
    hideProgressBar: true,
    pauseOneHover: true,
    position: 'top-right',
};

export default {
    clear(toastId: ToastId) {
        toast.dismiss(toastId);
    },

    error(msg: ToastContent, options: ToastOptions = {}) {
        const settings = { ...defaults, autoClose: false, ...options };
        return toast.error(msg, {
            ...settings,
            bodyClassName: 'toast-error-body',
            className: 'toast-error',
        } as ToastOptions);
    },

    info(msg: ToastContent, options: ToastOptions = {}) {
        const settings = { ...defaults, ...options };
        return toast.info(msg, {
            ...settings,
        } as ToastOptions);
    },

    isActive(toastId: ToastId) {
        return toast.isActive(toastId);
    },

    success(msg: ToastContent, options: ToastOptions = {}) {
        const settings = { ...defaults, ...options };
        return toast.success(msg, {
            ...settings,
            bodyClassName: 'toast-success-body',
            className: 'toast-success',
            progressClassName: 'toast-success-progress',
        } as ToastOptions);
    },

    update(toastId: ToastId, options: UpdateOptions = {}) {
        const settings = { ...defaults, ...options };
        return toast.update(toastId, {
            ...settings,
        } as ToastOptions);
    },
};
