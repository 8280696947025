import Button from 'components/Button';
import Form from 'components/Form';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';

export type ISingleButtonModalProps = {
    'data-cy'?: string;
    backdrop?: boolean | 'static';
    body?: JSX.Element | string;
    buttonClickHandler: () => void;
    buttonText: string;
    children?: JSX.Element | JSX.Element[];
    onHide?: () => void;
    showActivity?: boolean;
    size?: 'lg' | 'sm' | 'xl';
    title: string;
};

const SingleButtonModal = ({
    backdrop,
    body,
    buttonClickHandler,
    buttonText,
    children,
    'data-cy': dataCy,
    onHide,
    showActivity,
    size,
    title,
}: ISingleButtonModalProps) => (
    <Modal
        backdrop={backdrop}
        data-cy={dataCy}
        onHide={onHide ?? buttonClickHandler}
        scrollable
        show
        size={size}
    >
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="single-button-modal" isLoading={showActivity} onSubmit={buttonClickHandler}>
                {body}
                {children}
            </Form>
        </Modal.Body>
        <Modal.Footer className="centered">
            <Button
                data-cy="single-button-modal"
                form="single-button-modal"
                isLoading={showActivity}
                type="submit"
            >
                {buttonText}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default hot(module)(SingleButtonModal);
