import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ExternalLink from 'components/ExternalLink';
import { DRAWER_WIDTH } from 'components/authenticatedPageWrapper/appDrawer/AppDrawer';
import DesktopContext from 'contexts/DesktopContext';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';

const currentYear = new Date().getFullYear();

const Footer = ({ hideWrapperComponents }: { hideWrapperComponents: boolean | undefined }) => {
    const isDesktop = useContext(DesktopContext);
    const version = process.env['VERSION'];
    const footerContent = (
        <React.Fragment>
            <span>
                {currentYear} © Remodel Health {!isDesktop && <br />}v{version}
            </span>
            <span></span>
            <ExternalLink href="https://vault.pactsafe.io/s/7d06b652-ad04-44fe-8dfe-e393ad02d1b8/legal.html?g=3784#privacy-policy">
                Privacy Policy
            </ExternalLink>
        </React.Fragment>
    );

    if (!isDesktop) {
        return (
            <Stack color="white" direction="column-reverse" padding={2} textAlign="left">
                {footerContent}
            </Stack>
        );
    }

    return (
        <Paper
            elevation={3}
            sx={{
                bottom: 0,
                color: 'grey.600',
                left: { lg: hideWrapperComponents ? 0 : DRAWER_WIDTH, xs: 0 },
                position: 'fixed',
                px: 5,
                py: 2,
                right: 0,
                zIndex: 10,
            }}
        >
            <Box alignItems="center" display="flex" justifyContent="space-between">
                {footerContent}
            </Box>
        </Paper>
    );
};
export default hot(module)(Footer);
