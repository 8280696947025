import React, { useCallback, useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const GradientIndicator = styled.div(
    ({ height }: { height: number }) => `
    &:before,
    &:after {
        content: '';
        height: ${height}px;
        width: 100px;
        background: transparent;
        position: absolute;
        z-index: 1;
        pointer-events: none;
    }
    &:before {
        top: 0;
        left: 0;
        background: radial-gradient(ellipse 60% 50% at left, rgba(0,0,0,0.22), transparent);
    }
    &:after {
        top: 0;
        right: 0;
        background: radial-gradient(ellipse 60% 50% at right, rgba(0,0,0,0.22), transparent);
    }
    &.left {
        &:before {
            display: none;
        }
    }
    &.right {
        &:after {
            display: none;
        }
    }
`
);

const HorizontalScrollGradientIndicator = ({ children }: { children: JSX.Element }) => {
    const childRef = useRef<HTMLElement>();
    const [overlayClasses, setOverlayClasses] = useState<string[]>([]);

    const handleScroll = useCallback((event: Event) => {
        const root = event.currentTarget as HTMLElement;
        if (root) {
            const rootStart = Math.round(root.scrollLeft);
            const atEnd = rootStart >= root.scrollWidth - root.offsetWidth;
            const newOverlayClasses = [];
            if (rootStart === 0) {
                newOverlayClasses.push('left');
            }
            if (atEnd) {
                newOverlayClasses.push('right');
            }
            setOverlayClasses(newOverlayClasses);
        }
    }, []);
    const updateGradientIndicator = useCallback(() => {
        handleScroll({ currentTarget: childRef.current } as never);
    }, [handleScroll]);

    useEffect(() => {
        const childElement = childRef.current;
        childElement?.addEventListener('scroll', handleScroll);
        const resizeObserver = new ResizeObserver(updateGradientIndicator);
        if (childElement) {
            resizeObserver.observe(childElement);
        }
        updateGradientIndicator();
        return () => {
            childElement?.removeEventListener('scroll', handleScroll);
            resizeObserver?.disconnect();
        };
    }, [handleScroll, updateGradientIndicator]);

    const childrenWithRef = React.Children.map(children, (child) =>
        React.cloneElement(child, {
            ref: childRef,
        })
    );
    return (
        <GradientIndicator
            className={overlayClasses.join(' ')}
            height={childRef.current?.clientHeight ?? 0}
        >
            {childrenWithRef}
        </GradientIndicator>
    );
};

export default hot(module)(HorizontalScrollGradientIndicator);
