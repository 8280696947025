import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { hot } from 'react-hot-loader';

const ChipChip = ({ sx, ...props }: ChipProps) => (
    <Chip
        key="chip"
        label="CHIP"
        size="small"
        sx={{
            backgroundColor: (theme) => theme.palette.info.light,
            color: 'grey.900',
            ...sx,
        }}
        {...props}
    />
);

export default hot(module)(ChipChip);
