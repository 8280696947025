import { Search } from '@mui/icons-material';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { AutocompleteRenderInputParams, IconButton } from '@mui/material';
import { globalSearch } from 'actions/search/globalSearch';
import { AccessOtherTeams, ViewUsers } from 'api/generated/permissions';
import { MIN_CHARACTERS_TO_SEARCH } from 'components/Autocomplete';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';

type IGlobalSearchTextField = {
    includeArchived: boolean;
    props: AutocompleteRenderInputParams;
    toggleIncludeArchived: () => void;
};
const GlobalSearchTextField = ({
    includeArchived,
    props,
    toggleIncludeArchived,
}: IGlobalSearchTextField) => {
    const dispatch = useThunkDispatch();
    const { canAccessOtherTeams, canViewUsers } = useSelector((state: AppStore) => ({
        canAccessOtherTeams: hasSomePermissions(state, AccessOtherTeams),
        canViewUsers: hasSomePermissions(state, ViewUsers),
    }));
    const [query, setQuery] = useState('');
    const placeholder = useMemo(() => {
        if (canAccessOtherTeams && canViewUsers) {
            return 'Search for a team, user, or household member by name, email, or phone number';
        } else if (canViewUsers) {
            return 'Search for a user or household member by name, email, or phone number';
        } else if (canAccessOtherTeams) {
            return 'Search for a team by name or phone number';
        }
        return '';
    }, [canAccessOtherTeams, canViewUsers]);
    const onToggleClick = () => {
        if (hasValue(query) && query.trim().length >= MIN_CHARACTERS_TO_SEARCH) {
            dispatch(globalSearch(query, !includeArchived));
        }
        toggleIncludeArchived();
    };

    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <React.Fragment>
                        <Tooltip
                            title={`Currently ${
                                includeArchived ? 'including' : 'excluding'
                            } archived teams and members. Click to toggle whether archived teams and members of archived teams are included in search`}
                        >
                            <IconButton
                                edge="end"
                                onClick={onToggleClick}
                                size="large"
                                style={{ marginRight: 4 }}
                            >
                                {includeArchived ? (
                                    <ToggleOnIcon color="secondary" />
                                ) : (
                                    <ToggleOffIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                        {props.InputProps.endAdornment}
                    </React.Fragment>
                ),
                startAdornment: <Search color="disabled" />,
            }}
            onChange={onChange(setQuery)}
            placeholder={placeholder}
            value={query}
        />
    );
};

export default hot(module)(GlobalSearchTextField);
