import {
    UPDATE_MEMBER_COSTS_FOR_TEAM_BENEFIT_TERM_DETAIL,
    updateMemberCostsForTeamBenefitTermDetail,
} from 'actions/teamBenefit/updateMemberCostsForTeamBenefitTermDetail';
import { pageUserTermDetails } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import { downloadEnrollmentCensus } from 'api/downloadEnrollmentCensus';
import { TeamBenefitTypes } from 'api/generated/enums';
import {
    ITeamBenefit,
    ITeamBenefitTermDetail,
    ITeamBenefitWithTermDetailsDto,
} from 'api/generated/models';
import {
    DownloadEnrollmentCensus,
    EditTeamBenefit,
    RecalculateBenefitCosts,
} from 'api/generated/permissions';
import { IActionButtonItems } from 'components/ActionButtons';
import TypedConfirmationModal from 'components/TypedConfirmationModal';
import TeamBenefitEditUsersModal from 'components/manageMembersActionButtons/TeamBenefitEditUsersModal';
import TeamBenefitActionButtons from 'components/teamBenefitActionButtons/TeamBenefitActionButtons';
import TeamBenefitCopyMemberBenefitDataModal from 'components/teamBenefitActionButtons/TeamBenefitCopyMemberBenefitDataModal';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { isTodaySameOrBefore } from 'utilities/moment';
import { downloadFileFromApi } from 'utilities/rhFile';

const MINIMUM_TEAM_BENEFITS_FOR_COPY = 2;
const ENROLLMENT_CENSUS_TYPES = [
    TeamBenefitTypes.CriticalIllness,
    TeamBenefitTypes.GroupDental,
    TeamBenefitTypes.GroupVision,
    TeamBenefitTypes.LifeInsurance,
    TeamBenefitTypes.LongTermDisability,
    TeamBenefitTypes.ShortTermDisability,
    TeamBenefitTypes.DirectPrimaryCare,
];

type IManageMembersActionButtonsProps = {
    selectedMemberIds?: string[];
    teamBenefit: ITeamBenefitWithTermDetailsDto;
    teamBenefitTermDetailId: string;
};

const ManageMembersActionButtons = ({
    selectedMemberIds,
    teamBenefit,
    teamBenefitTermDetailId,
}: IManageMembersActionButtonsProps) => {
    const dispatch = useThunkDispatch();
    const {
        canEditTeamBenefits,
        canRecalculateBenefitCosts,
        canDownloadEnrollmentCensus,
        isRecalculatingBenefitCosts,
        paginationParams,
    } = useSelector((state: AppStore) => ({
        canDownloadEnrollmentCensus:
            hasSomePermissions(state, DownloadEnrollmentCensus) &&
            ENROLLMENT_CENSUS_TYPES.includes(teamBenefit.teamBenefitType),
        canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
        canRecalculateBenefitCosts: hasSomePermissions(state, RecalculateBenefitCosts),
        isRecalculatingBenefitCosts: hasApiActivity(
            state,
            UPDATE_MEMBER_COSTS_FOR_TEAM_BENEFIT_TERM_DETAIL
        ),
        paginationParams: state.manageMembersState,
    }));
    const { teamId } = useTeamProps();
    const {
        openModal: openEditMembersModal,
        closeModal: closeEditMembersModal,
        isVisible: isEditMembersModalVisible,
    } = useModalState();

    const {
        openModal: openRecalculateTeamPlanConfirmationModal,
        closeModal: closeRecalculateTeamPlanConfirmationModal,
        isVisible: isRecalculateTeamPlanConfirmationModalVisible,
    } = useModalState();

    const {
        openModal: openTeamBenefitCopyMemberBenefitDataModal,
        closeModal: closeTeamBenefitCopyMemberBenefitDataModal,
        isVisible: isTeamBenefitCopyMemberBenefitDataModalVisible,
    } = useModalState();

    const onDownloadEnrollmentCensus = async () => {
        await downloadFileFromApi(async () => downloadEnrollmentCensus(teamBenefitTermDetailId));
    };

    const showBulkEdit =
        canEditTeamBenefits && hasValue(selectedMemberIds) && selectedMemberIds.length > 0;
    const teamBenefitTermDetail = (teamBenefit as ITeamBenefit).teamBenefitTermDetails?.find(
        (tbtd) => tbtd.id === teamBenefitTermDetailId
    );
    const hasTwoOrMoreTermDetails =
        (teamBenefit.teamBenefitTermDetails?.length ?? 0) >= MINIMUM_TEAM_BENEFITS_FOR_COPY;
    const canCopyManageMemberData = canEditTeamBenefits && hasTwoOrMoreTermDetails;
    const items = [
        {
            dataCy: 'edit-members',
            isVisible: showBulkEdit,
            onClick: openEditMembersModal,
            text: `Edit Members (${selectedMemberIds?.length})`,
        },
        {
            isVisible: canCopyManageMemberData,
            onClick: openTeamBenefitCopyMemberBenefitDataModal,
            text: 'Copy Member Benefit Data',
        },
        {
            isVisible:
                canRecalculateBenefitCosts &&
                isTodaySameOrBefore(teamBenefitTermDetail?.endDate ?? ''),
            onClick: openRecalculateTeamPlanConfirmationModal,
            text: 'Recalculate Costs',
        },
        {
            isVisible: canDownloadEnrollmentCensus,
            onClick: onDownloadEnrollmentCensus,
            text: 'Download Enrollment Census',
        },
    ] as IActionButtonItems;

    const recalculateTeamPlan = async () => {
        await dispatch(updateMemberCostsForTeamBenefitTermDetail(teamBenefitTermDetailId));
        await dispatch(pageUserTermDetails(teamBenefitTermDetailId, teamId, paginationParams));
        closeRecalculateTeamPlanConfirmationModal();
    };
    const confirmRecalculateCostsModalBody = (
        <ul>
            <li>Are you sure you want to recalculate costs for all members on this benefit?</li>
            <li>
                This will recalculate costs for all members according for the benefit term starting{' '}
                {formatDateForDisplay(teamBenefitTermDetail?.startDate)} and ending{' '}
                {formatDateForDisplay(teamBenefitTermDetail?.endDate)}.
            </li>
        </ul>
    );

    return (
        <React.Fragment>
            {isEditMembersModalVisible && (
                <TeamBenefitEditUsersModal
                    onClose={closeEditMembersModal}
                    termDetails={teamBenefitTermDetail as ITeamBenefitTermDetail}
                    userIds={selectedMemberIds}
                />
            )}
            {isRecalculateTeamPlanConfirmationModalVisible && (
                <TypedConfirmationModal
                    body={confirmRecalculateCostsModalBody}
                    noButtonText="Cancel"
                    onNoClick={closeRecalculateTeamPlanConfirmationModal}
                    onYesClick={recalculateTeamPlan}
                    showActivity={isRecalculatingBenefitCosts}
                    textToMatch="Recalculate costs for all members"
                    title="Recalculate Member Costs?"
                    yesButtonText="Recalculate"
                />
            )}
            {isTeamBenefitCopyMemberBenefitDataModalVisible && (
                <TeamBenefitCopyMemberBenefitDataModal
                    onClose={closeTeamBenefitCopyMemberBenefitDataModal}
                    selectedTermDetailId={teamBenefitTermDetailId}
                    teamBenefit={teamBenefit}
                />
            )}
            <TeamBenefitActionButtons
                additionalActionItems={items}
                selectedTermDetailId={teamBenefitTermDetailId}
                teamBenefit={teamBenefit}
            />
        </React.Fragment>
    );
};

export default hot(module)(ManageMembersActionButtons);
