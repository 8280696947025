import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { readableFileSize } from 'utilities';
const FileNameViolationMessage = ({
    invalidFiles,
    maxFileNameLength,
}: {
    invalidFiles: File[];
    maxFileNameLength: number | undefined;
}) => {
    const fileList = useMemo(() => (Array.isArray(invalidFiles) ? Array.from(invalidFiles) : []), [
        invalidFiles,
    ]);
    if (invalidFiles.length === 1) {
        const file = invalidFiles[0];
        return (
            <span>
                {file?.name}
                <div>The above file name is too long.</div>
                <div>A valid file name must be less than {maxFileNameLength} characters.</div>
            </span>
        );
    }
    return (
        <span>
            <div>File name length must be less than {maxFileNameLength} characters.</div>
            The following files have names longer than {maxFileNameLength} characters:
            <ul>
                {fileList.map((file, index) => (
                    <li key={index}>
                        {file.name} ({readableFileSize(file.size)})
                    </li>
                ))}
            </ul>
        </span>
    );
};
export default hot(module)(FileNameViolationMessage);
