import { Divider, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { clearEnrollmentData } from 'actions/clear';
import { COMPLETE_ENROLLMENT_ACTION, completeEnrollment } from 'actions/user/completeEnrollment';
import { GET_ENROLLMENT_DATA_ACTION, getEnrollmentData } from 'actions/user/getEnrollmentData';
import { listPagedUserProfiles } from 'actions/user/listPagedUserProfiles';
import { PlanStateIds } from 'api/generated/enums';
import { IBasicHouseholdMemberDto, IUser } from 'api/generated/models';
import BenefitCardContent from 'components/BenefitCardContent';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Form from 'components/Form';
import Skeleton from 'components/Skeleton';
import useThunkDispatch from 'hooks/useThunkDispatch';
import UseUserProps from 'hooks/useUserProps';
import { orderBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';

const CompleteEnrollmentModal = ({
    onClose,
    user,
}: {
    onClose: () => void;
    user: IUser | undefined;
}) => {
    const dispatch = useThunkDispatch();
    const { hasUserIdUrlParam } = UseUserProps();
    const year = user?.activeDate?.getYear() ?? 0;

    const {
        effectiveSelectedPlans,
        peopleState,
        selectedPlans,
        selectedYear,
        householdMembers,
        isLoading,
    } = useSelector((state: AppStore) => ({
        effectiveSelectedPlans: orderBy(
            state.dataForEnrollmentCompletion.selectedPlans?.filter(
                (sp) => sp.planStateId === PlanStateIds.Effective
            ),
            'planTypeId'
        ),
        householdMembers: state.dataForEnrollmentCompletion
            .householdMembers as IBasicHouseholdMemberDto[],
        isLoading: hasApiActivity(state, GET_ENROLLMENT_DATA_ACTION, COMPLETE_ENROLLMENT_ACTION),
        peopleState: state.peopleState,
        selectedPlans:
            state.dataForEnrollmentCompletion.selectedPlans?.filter((x) =>
                [PlanStateIds.Selected, PlanStateIds.Submitted, PlanStateIds.Enrolled].includes(
                    x.planStateId
                )
            ) ?? [],
        selectedYear: +state.profileState.selectedYear,
    }));

    const [shouldSendConfirmationEmail, setShouldSendConfirmationEmail] = useState(true);

    const onSubmit = useCallback(async () => {
        await dispatch(completeEnrollment(user?.userId, year, shouldSendConfirmationEmail));
        if (!hasUserIdUrlParam && hasValue(user?.teamId)) {
            await dispatch(
                listPagedUserProfiles((user as IUser).teamId, selectedYear, peopleState)
            );
        }
        onClose();
    }, [
        dispatch,
        hasUserIdUrlParam,
        onClose,
        peopleState,
        selectedYear,
        shouldSendConfirmationEmail,
        user,
        year,
    ]);

    const allSelectedPlans = useMemo(() => [...selectedPlans, ...effectiveSelectedPlans], [
        selectedPlans,
        effectiveSelectedPlans,
    ]);
    const isSelectedPlansEmpty = allSelectedPlans.length === 0;

    useEffect(() => {
        dispatch(getEnrollmentData(user?.userId ?? '', year));
        return () => {
            dispatch(clearEnrollmentData());
        };
    }, [dispatch, user?.userId, year]);

    return (
        <Dialog fullWidth maxWidth="lg" onClose={onClose} open scroll="paper">
            <DialogTitle>
                {year} - Complete Enrollment For {user?.displayName}
            </DialogTitle>
            <DialogContent dividers>
                <Skeleton count={5} height="54px" isEnabled={isLoading} sx={{ mb: 2 }}>
                    <Form id="complete-enrollment-form" isLoading={isLoading} onSubmit={onSubmit}>
                        <Typography className="pb-4" variant="body1">
                            Completing enrollment for this member will update their Member Status to{' '}
                            <b>Active</b> and their {year} Enrollment Status to <b>Enrolled</b>.
                        </Typography>
                        <Stack spacing={4}>
                            {selectedPlans.length !== 0 && (
                                <Stack spacing={2}>
                                    <Divider>
                                        <Typography className="text-muted" variant="h4">
                                            The following plan
                                            {selectedPlans.length === 1 ? ' ' : 's '}
                                            will be updated to Enrolled for the member (if not
                                            already in that status)
                                        </Typography>
                                    </Divider>
                                    {selectedPlans?.map((selectedPlan, index) => (
                                        <BenefitCardContent
                                            benefit={selectedPlan}
                                            householdMembers={householdMembers}
                                            key={index}
                                            showMemberActiveDate
                                            user={user}
                                        />
                                    ))}
                                </Stack>
                            )}
                            {effectiveSelectedPlans.length !== 0 && (
                                <Stack spacing={2}>
                                    <Divider>
                                        <Typography className="text-muted" variant="h4">
                                            The following plan
                                            {effectiveSelectedPlans.length === 1 ? ' ' : 's '}
                                            will remain Effective for the next year
                                        </Typography>
                                    </Divider>
                                    {effectiveSelectedPlans?.map((selectedPlan, index) => (
                                        <BenefitCardContent
                                            benefit={selectedPlan}
                                            householdMembers={householdMembers}
                                            key={index}
                                            showMemberActiveDate
                                            user={user}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                        {!isLoading && isSelectedPlansEmpty && (
                            <Typography variant="body1">
                                {user?.firstName} {user?.lastName} does not have plans for the next
                                year. Add plans to complete enrollment.
                            </Typography>
                        )}
                    </Form>
                </Skeleton>
            </DialogContent>
            <DialogActions>
                {!isSelectedPlansEmpty && (
                    <Grid justifyContent="flex-start" marginLeft={2} width="100%">
                        {' '}
                        <Checkbox
                            checked={shouldSendConfirmationEmail}
                            color="secondary"
                            label="Send Confirmation Email to Member"
                            onClick={() =>
                                setShouldSendConfirmationEmail(!shouldSendConfirmationEmail)
                            }
                        />
                    </Grid>
                )}
                <Button onClick={onClose}>Cancel</Button>
                {!isSelectedPlansEmpty && (
                    <Button
                        form="complete-enrollment-form"
                        isLoading={isLoading}
                        type="submit"
                        variant="contained"
                    >
                        Confirm
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(CompleteEnrollmentModal);
