import WestIcon from '@mui/icons-material/West';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import DiagramGrid from 'components/majorMedicalBenefitCostBreakdownModal/DiagramGrid';
import SuperScript from 'components/majorMedicalBenefitCostBreakdownModal/SuperScript';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';

const TotalPlanCostGrids = ({
    majorMedicalBenefitCost: parentCostData,
}: {
    majorMedicalBenefitCost?: IMajorMedicalBenefitCostDto;
}) => {
    const { memberBenefits } = useSelector((state: AppStore) => ({
        memberBenefits: state.memberBenefits,
    }));
    const costData = parentCostData ?? memberBenefits.majorMedicalBenefitCost;
    return (
        <Grid columns={14} container item>
            <DiagramGrid xs={2}>
                <Typography variant="h3">Total Benefit Cost</Typography>
            </DiagramGrid>
            <DiagramGrid borderRight={1} xs={4}>
                <Typography>
                    <Typography component="span" variant="h4">
                        {formatCurrency(costData?.preTaxTotalPlanCost, { preserveDecimal: true })}
                    </Typography>{' '}
                    per month
                </Typography>
            </DiagramGrid>
            <DiagramGrid borderRight={1} xs={4}>
                <Typography variant="caption">
                    In order to make your cost comparable to a traditional group benefit, we
                    increase the benefit cost by an estimate of your tax responsibility
                </Typography>
                <Typography>
                    <WestIcon sx={{ fontSize: 50 }} />
                </Typography>
                <Typography color={(theme) => theme.palette.muted.main} paragraph variant="caption">
                    {formatCurrency(costData?.postTaxTotalPlanCost, {
                        preserveDecimal: true,
                    })}
                    <SuperScript>b</SuperScript> / (1 - {costData?.totalTaxRate}
                    <SuperScript>d</SuperScript>)
                </Typography>
            </DiagramGrid>
            <DiagramGrid xs={4}>
                <Typography>
                    <Typography component="span" variant="h4">
                        {formatCurrency(costData?.postTaxTotalPlanCost, { preserveDecimal: true })}
                    </Typography>
                    <SuperScript>b</SuperScript> per month
                </Typography>
                <Typography variant="caption">
                    Your total monthly benefit cost, shows as post-tax deduction on your paycheck
                </Typography>
            </DiagramGrid>
        </Grid>
    );
};

export default hot(module)(TotalPlanCostGrids);
