import Button, { ButtonProps } from '@mui/material/Button';
import CalendlyModalBase, { ICalendlyModalBaseProps } from 'components/calendly/CalendlyModalBase';
import React from 'react';
import { hot } from 'react-hot-loader';

type ICalendlyModalButtonProps = ButtonProps & Omit<ICalendlyModalBaseProps, 'children'>;

const CalendlyModalButton = ({
    buttonLabel,
    calendlyLinkOverride,
    memberTermsTask,
    onAdvisementScheduled,
    showAgreeToTerms,
    ...buttonProps
}: ICalendlyModalButtonProps) => (
    <CalendlyModalBase
        calendlyLinkOverride={calendlyLinkOverride}
        memberTermsTask={memberTermsTask}
        onAdvisementScheduled={onAdvisementScheduled}
        showAgreeToTerms={showAgreeToTerms}
    >
        {(openModal) => (
            <Button {...buttonProps} onClick={openModal}>
                {buttonLabel}
            </Button>
        )}
    </CalendlyModalBase>
);

export default hot(module)(CalendlyModalButton);
