import Icon, { IIconProps } from 'components/Icon';
import Tooltip, { TooltipProps } from 'components/Tooltip';
import React from 'react';
import { hot } from 'react-hot-loader';
import { PartialBy } from 'utilities';

export type IIconTooltipProps = PartialBy<TooltipProps, 'children'> & {
    'data-cy'?: string;
    icon: string;
    /**
     * Applies font-size using `em` units
     */
    iconSize?: IIconProps['size'];
    prefix?: IIconProps['prefix'];
    variant?: string;
};

const IconTooltip = ({
    className,
    'data-cy': dataCy,
    icon,
    iconSize = 1,
    prefix = 'dripicons',
    variant = 'primary',
    ...props
}: IIconTooltipProps) => (
    <Tooltip className={`${className}`} {...props}>
        <Icon data-cy={dataCy} prefix={prefix} size={iconSize} variant={variant}>
            {icon}
        </Icon>
    </Tooltip>
);
export default hot(module)(IconTooltip);
