import DateMaskField, { DateTextMask } from 'components/DateTextMask';
import { ITextFieldProps } from 'components/TextField';
import React from 'react';
import { hot } from 'react-hot-loader';

const DateRangeMask = DateTextMask(
    'm{/}`d{/}`Y {-} `m{/}`d{/}`Y',
    (s) => s,
    (s) => s
);

const DateTextRangeField = (props: ITextFieldProps) => (
    <DateMaskField inputComponent={DateRangeMask} {...props}></DateMaskField>
);
export default hot(module)(DateTextRangeField);
