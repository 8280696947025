import EditableSelectAttribute, {
    IEditableSelectAttributeProps,
} from 'components/EditableSelectAttribute';
import React from 'react';
import { hot } from 'react-hot-loader';
import { yesOrNo } from 'reducers/options';
import { isTrue, PartialBy, toNullOrBoolean } from 'utilities';
import { formatYesOrNo } from 'utilities/format';
import { boolean } from 'yup';

type IOptionalProperties = 'items' | 'optionText' | 'optionValue' | 'validationSchema';

const validationScheme = (label: string) =>
    boolean()
        .transform(toNullOrBoolean)
        .typeError(`${label} must be Yes or No.`)
        .label(label);

const nullableValidationScheme = (label: string) =>
    boolean()
        .transform(toNullOrBoolean)
        .nullable(true)
        .label(label);

const EditableYesNoSelectAttribute = <T, Q>(
    props: PartialBy<IEditableSelectAttributeProps<T, Q>, IOptionalProperties>
) => (
    <EditableSelectAttribute
        defaultText="--"
        defaultValue=""
        formatter={formatYesOrNo}
        items={(yesOrNo as unknown) as T[]}
        optionText={'text' as keyof T}
        optionValue={'value' as keyof T}
        validationSchema={
            isTrue(props.isOptional)
                ? nullableValidationScheme(props.label)
                : validationScheme(props.label)
        }
        {...props}
    />
);

export default hot(module)(EditableYesNoSelectAttribute);
