import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { hot } from 'react-hot-loader';

const AdminChip = ({ sx, ...props }: ChipProps) => (
    <Chip
        label="Admin"
        size="small"
        sx={{
            backgroundColor: (theme) => theme.palette.neutral.main,
            color: (theme) => theme.palette.primary.main,
            ...sx,
        }}
        {...props}
    />
);

export default hot(module)(AdminChip);
