import React from 'react';
import { hot } from 'react-hot-loader';

const Circle = ({
    isActive = false,
    isComplete = false,
    x,
}: {
    isActive: boolean;
    isComplete: boolean;
    x: number | string;
}) => {
    const size = 20;
    const center = size / 2;
    return (
        <svg height={size} width={size} x={x}>
            <circle
                className={`${isActive || isComplete ? 'stroke-primary' : 'stroke-grey'} ${
                    isComplete ? 'fill-primary' : ''
                }`}
                cx="50%"
                cy="50%"
                fill="none"
                r={center - 1}
                stroke="black"
                strokeWidth="2"
            />
            {isComplete && (
                <foreignObject height={size} width={size} x="3" y="0">
                    <i className="dripicons-checkmark text-white"></i>
                </foreignObject>
            )}
        </svg>
    );
};

export default hot(module)(Circle);
