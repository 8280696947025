import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from 'components/TextField';
import UnselectableText from 'components/UnselectableText';
import React from 'react';
import { hot } from 'react-hot-loader';

type ITypedConfirmationInlineProps = {
    confirmationText: string;
    dataCy?: string;
    errors: string[] | undefined;
    onFormChange: ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    textToMatch: string;
    typeConfirmationMessage: string;
};

const TypedConfirmationInline = ({
    confirmationText,
    dataCy,
    errors,
    onFormChange,
    textToMatch,
    typeConfirmationMessage,
}: ITypedConfirmationInlineProps) => (
    <React.Fragment>
        <Divider sx={{ mb: 2, mt: 3 }} />
        <Stack alignItems="center" direction="column" spacing={1.5} sx={{ mb: 2 }}>
            <span>{typeConfirmationMessage}</span>
            <span>
                Please type the phrase{' '}
                <UnselectableText as="strong">{textToMatch}</UnselectableText> exactly as it appears
                to confirm this action.
            </span>
            <TextField
                data-cy={dataCy}
                defaultValue={confirmationText}
                errors={errors}
                onChange={onFormChange}
            />
        </Stack>
    </React.Fragment>
);

export default hot(module)(TypedConfirmationInline);
