import { useTheme } from '@mui/material';
import { ReimbursementDisplayLevels } from 'api/generated/enums';
import { IReimbursementOverlayDto } from 'api/generated/models';
import ReimbursementChartCustomLabel from 'components/reimbursementOverlayModal/ReimbursementChartCustomLabel';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { hasValue, nameof } from 'utilities';

export const BAR_MIN_WIDTH = 1900;
const DEFAULT_CHART_END_WIDTH = 20000;
const PLAN_MOOP_RELATIVE_WIDTH = 5000;
const HSA_BAR_MIN_WIDTH = 1500;

type IChartData = {
    employeeResponsibility?: number;
    employeeResponsibilityLabel?: number[];
    employerReimbursementAmount?: number;
    employerReimbursementAmountLabel?: number[];
    gap?: number;
    gapLabel?: (number | undefined)[];
    hsaContribution?: number;
    hsaLabel?: (number | undefined)[];
    name?: 'Family' | 'Individual';
    planCoverage?: number;
    planLabel?: number[];
};

type IReimbursementChart = {
    displayLevel: ReimbursementDisplayLevels | undefined;
    familyMoopCost: number | undefined;
    individualMoopCost: number | undefined;
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined;
};

const ReimbursementChart = ({
    displayLevel,
    familyMoopCost,
    individualMoopCost,
    reimbursementOverlayDto: reimbursement,
}: IReimbursementChart) => {
    const theme = useTheme();
    const hsaEmployerContribution = reimbursement?.hsaEmployerContribution;
    const barChartEnd = hasValue(familyMoopCost)
        ? (familyMoopCost ?? 0) + PLAN_MOOP_RELATIVE_WIDTH
        : DEFAULT_CHART_END_WIDTH;
    const calculateBarWidth = (amount: number | undefined) =>
        (amount ?? 0) > 0 ? Math.max(amount ?? 0, BAR_MIN_WIDTH) : 0;
    const data: IChartData[] = [];
    if ((hsaEmployerContribution ?? 0) > 0) {
        data.push({
            hsaContribution: Math.max(hsaEmployerContribution ?? 0, HSA_BAR_MIN_WIDTH),
            hsaLabel: [hsaEmployerContribution],
        });
    }
    if (
        displayLevel === ReimbursementDisplayLevels.Individual ||
        displayLevel === ReimbursementDisplayLevels.IndividualAndFamily
    ) {
        const individualMedicalResponsibility = reimbursement?.individualMedicalResponsibility ?? 0;
        const individualMedicalReimbursementAmount =
            reimbursement?.individualMedicalReimbursementAmount ?? 0;
        data.push({
            employeeResponsibility: calculateBarWidth(individualMedicalResponsibility),
            employeeResponsibilityLabel: [0, individualMedicalResponsibility],
            employerReimbursementAmount: calculateBarWidth(
                reimbursement?.individualMedicalReimbursementAmount
            ),
            employerReimbursementAmountLabel: [
                individualMedicalResponsibility + 1,
                individualMedicalResponsibility + individualMedicalReimbursementAmount,
            ],
            gap: calculateBarWidth(reimbursement?.individualMedicalResponsibilityGap),
            gapLabel: [
                individualMedicalResponsibility + individualMedicalReimbursementAmount + 1,
                individualMoopCost,
            ],
            name: 'Individual',
            planCoverage:
                barChartEnd -
                (calculateBarWidth(individualMedicalResponsibility) +
                    calculateBarWidth(reimbursement?.individualMedicalReimbursementAmount) +
                    calculateBarWidth(reimbursement?.individualMedicalResponsibilityGap)),
            planLabel: [(individualMoopCost ?? 0) + 1],
        });
    }

    if (
        displayLevel === ReimbursementDisplayLevels.IndividualAndFamily ||
        displayLevel === ReimbursementDisplayLevels.Family
    ) {
        const familyMedicalResponsibility = reimbursement?.familyMedicalResponsibility ?? 0;
        const familyMedicalReimbursementAmount =
            reimbursement?.familyMedicalReimbursementAmount ?? 0;
        data.push({
            employeeResponsibility: calculateBarWidth(familyMedicalResponsibility),
            employeeResponsibilityLabel: [0, familyMedicalResponsibility],
            employerReimbursementAmount: calculateBarWidth(familyMedicalReimbursementAmount),
            employerReimbursementAmountLabel: [
                familyMedicalResponsibility + 1,
                familyMedicalResponsibility + familyMedicalReimbursementAmount,
            ],
            gap: calculateBarWidth(reimbursement?.familyMedicalResponsibilityGap),
            gapLabel: [
                familyMedicalResponsibility + familyMedicalReimbursementAmount + 1,
                familyMoopCost,
            ],
            name: 'Family',
            planCoverage:
                barChartEnd -
                (calculateBarWidth(familyMedicalResponsibility) +
                    calculateBarWidth(familyMedicalReimbursementAmount) +
                    calculateBarWidth(reimbursement?.familyMedicalResponsibilityGap)),
            planLabel: [(familyMoopCost ?? 0) + 1],
        });
    }

    return (
        <ResponsiveContainer height="100%" minHeight={200} minWidth={900} width="100%">
            <BarChart
                barSize={100}
                data={data}
                height={200}
                layout="vertical"
                margin={{
                    bottom: 0,
                    left: 20,
                    right: 0,
                    top: 20,
                }}
                width={900}
            >
                <XAxis hide type="number" />
                <YAxis
                    axisLine={false}
                    dataKey={nameof<IChartData>('name')}
                    tickLine={false}
                    type="category"
                />

                <Bar
                    dataKey={nameof<IChartData>('hsaContribution')}
                    fill={theme.palette.orange.main}
                    name="HSA Contribution"
                    stackId="a"
                >
                    <LabelList
                        content={<ReimbursementChartCustomLabel bar="hsa" />}
                        dataKey={nameof<IChartData>('hsaLabel')}
                        position="center"
                    />
                </Bar>

                <Bar
                    dataKey={nameof<IChartData>('employeeResponsibility')}
                    fill={theme.palette.secondary.main}
                    name="Employee Responsibility"
                    stackId="a"
                >
                    <LabelList
                        content={<ReimbursementChartCustomLabel />}
                        dataKey={nameof<IChartData>('employeeResponsibilityLabel')}
                        position="center"
                    />
                </Bar>
                <Bar
                    dataKey={nameof<IChartData>('employerReimbursementAmount')}
                    fill={theme.palette.primary.dark}
                    name="Employer Responsibility"
                    stackId="a"
                >
                    <LabelList
                        content={<ReimbursementChartCustomLabel />}
                        dataKey={nameof<IChartData>('employerReimbursementAmountLabel')}
                        position="center"
                    />
                </Bar>
                <Bar
                    dataKey={nameof<IChartData>('gap')}
                    fill={theme.palette.secondary.main}
                    name="Employee Responsibility"
                    stackId="a"
                >
                    <LabelList
                        content={<ReimbursementChartCustomLabel bar="gap" />}
                        dataKey={nameof<IChartData>('gapLabel')}
                        position="center"
                    />
                </Bar>
                <Bar
                    dataKey={nameof<IChartData>('planCoverage')}
                    fill={theme.palette.grey[600]}
                    name="Plan Coverage"
                    stackId="a"
                >
                    <LabelList
                        content={<ReimbursementChartCustomLabel bar="plan" />}
                        dataKey={nameof<IChartData>('planLabel')}
                        position="center"
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default hot(module)(ReimbursementChart);
