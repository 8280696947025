import { IAction } from 'actions/commonAction';
import { PlanStateIds, PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan } from 'api/generated/models';
import {
    CLEAR_PLAN_INPUTS,
    UPDATE_PLAN_INPUTS,
    UPDATE_SELECTED_HOUSEHOLD_MEMBERS,
} from 'components/planModal/planModalActions';
import { CMS_OR_IDEON_PLAN_TYPES } from 'constants/selectedPlans';
import moment from 'moment';
import { hasValue, stringToFloat } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { IFormErrors } from 'utilities/forms';
import { number, object, string } from 'yup';

const MAX_COMMENT_LENGTH = 600;
const MAX_DEDUCTIBLE_AMOUNT = 9_999_999.99;

export const getPlanModalSchema = (
    planType: PlanTypeIds,
    planStateId: PlanStateIds | undefined,
    planYear: string
) =>
    object({
        applicationDate: string()
            .isValidDate(false)
            .label('Application Date'),
        applicationLink: string()
            .url()
            .label('Application Link'),
        comment: string()
            .trim()
            .max(MAX_COMMENT_LENGTH)
            .label('Comment'),
        coverageEndDate: string()
            .isRequiredWhen({
                is: () => {
                    const isParentOrSpouse = [
                        PlanTypeIds.ParentEmployer,
                        PlanTypeIds.SpouseEmployer,
                    ].includes(planType);
                    return (
                        planStateId === PlanStateIds.Terminated ||
                        CMS_OR_IDEON_PLAN_TYPES.includes(planType) ||
                        isParentOrSpouse
                    );
                },
            })
            .isValidDate(false)
            .label('Termination Date'),
        coverageStartDate: string()
            .isRequiredWhen({
                is: () => {
                    const isMedicareAndNotSelected =
                        planType === PlanTypeIds.Medicare && planStateId !== PlanStateIds.Selected;
                    const isParentOrSpouse = [
                        PlanTypeIds.ParentEmployer,
                        PlanTypeIds.SpouseEmployer,
                    ].includes(planType);
                    const isVaAndEnrolled =
                        planType === PlanTypeIds.VeteransAffairs &&
                        planStateId === PlanStateIds.Enrolled;
                    return (
                        [PlanStateIds.Effective, PlanStateIds.Terminated].contains(planStateId) ||
                        CMS_OR_IDEON_PLAN_TYPES.includes(planType) ||
                        isMedicareAndNotSelected ||
                        isParentOrSpouse ||
                        isVaAndEnrolled
                    );
                },
            })
            .test(
                'marketplaceCoverageStartDateMatchesYear',
                'Effective Date Year must match Year for Marketplace plans',
                (value) =>
                    planType === PlanTypeIds.Marketplace && hasValue(value)
                        ? moment(value).year() === +planYear
                        : true
            )
            .isValidDate(false)
            .label(planType === PlanTypeIds.MediShare ? 'Member Since' : 'Effective Date'),
        deductible: number()
            .transform(stringToFloat)
            .max(
                MAX_DEDUCTIBLE_AMOUNT,
                `Deductible must be less than or equal to ${formatCurrency(MAX_DEDUCTIBLE_AMOUNT, {
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}`
            )
            .label('Deductible'),
        healthCareTypeId: string()
            .isRequiredWhen(
                [
                    PlanTypeIds.Ancillary,
                    PlanTypeIds.CustomMajorMedical,
                    PlanTypeIds.CustomOffExchange,
                    PlanTypeIds.CustomStateBasedExchange,
                    PlanTypeIds.TermMedical,
                    PlanTypeIds.CustomAncillary,
                ].includes(planType)
            )
            .label('Healthcare Type'),
        issuerName: string()
            .isRequiredWhen(planType === PlanTypeIds.Ancillary)
            .label('Carrier'),
        planName: string()
            .isRequiredWhen(
                [
                    PlanTypeIds.Ancillary,
                    PlanTypeIds.CustomMajorMedical,
                    PlanTypeIds.CustomOffExchange,
                    PlanTypeIds.CustomStateBasedExchange,
                    PlanTypeIds.TermMedical,
                    PlanTypeIds.VeteransAffairs,
                    PlanTypeIds.CustomAncillary,
                ].includes(planType)
            )
            .label('Plan Name'),
        planPremium: string()
            .isRequiredWhen(
                [
                    PlanTypeIds.Marketplace,
                    PlanTypeIds.CustomStateBasedExchange,
                    PlanTypeIds.CustomOffExchange,
                    PlanTypeIds.TermMedical,
                    PlanTypeIds.MediShare,
                    PlanTypeIds.Ancillary,
                    PlanTypeIds.OffExchange,
                ].includes(planType)
            )
            .label(planType === PlanTypeIds.MediShare ? 'Monthly Share Amount' : 'Premium'),
        planPremiumWithCredits: string()
            .isRequiredWhen(
                [PlanTypeIds.Marketplace, PlanTypeIds.CustomStateBasedExchange].includes(planType)
            )
            .label('Premium with Tax Credits'),
        selectedAhp: string()
            .isRequiredWhen(planType === PlanTypeIds.MediShare)
            .label('Annual Household Portion (AHP)'),
        selectedCarrier: string()
            .isRequiredWhen(CMS_OR_IDEON_PLAN_TYPES.includes(planType))
            .label('Marketplace Carrier'),
        selectedMarketplacePlan: string()
            .isRequiredWhen(CMS_OR_IDEON_PLAN_TYPES.includes(planType))
            .label('Plan Name'),
    });

type SelectedPlanPropsToOmit =
    | 'applicationDate'
    | 'applicationLink'
    | 'binderStatus'
    | 'coverageEndDate'
    | 'coverageStartDate'
    | 'healthCareTypeId'
    | 'isAddedToPaymentTracking'
    | 'isAddedToPayrollReport'
    | 'isHsaEligible'
    | 'isPassiveRenewal'
    | 'issuerName'
    | 'payTypeId'
    | 'planName'
    | 'planPremium'
    | 'planPremiumWithCredits'
    | 'planType';

export type IPlanInputs = Partial<Omit<ISelectedPlan, SelectedPlanPropsToOmit>> & {
    applicationDate: string;
    applicationLink: string;
    binderStatus: string;
    comment: string;
    coverageEndDate: string;
    coverageStartDate: string;
    deductible: number | undefined;
    errors: IFormErrors<ReturnType<typeof getPlanModalSchema>>;
    exchange: string;
    healthCareTypeId: string | undefined;
    isAddedToPaymentTracking: string;
    isAddedToPayrollReport: string;
    isHsaEligible: string | undefined;
    isPassiveRenewal: string;
    issuerName: string;
    payTypeId: string;
    planName: string;
    planPremium: string;
    planPremiumWithCredits: string;
    planType: PlanTypeIds;
    selectedAhp: string;
    selectedCarrier: string;
    selectedMarketplacePlan: string;
    selectedYear: string;
};

export type IPlanModalState = {
    planInputs: IPlanInputs;
    selectedHouseholdMemberIds: string[];
};
const initialState: IPlanModalState = {
    planInputs: {
        applicationDate: '',
        applicationLink: '',
        binderStatus: '',
        comment: '',
        coverageEndDate: '',
        coverageStartDate: '',
        deductible: undefined,
        errors: null,
        exchange: '',
        healthCareTypeId: '',
        isAddedToPaymentTracking: '',
        isAddedToPayrollReport: '',
        isHsaEligible: '',
        isPassiveRenewal: 'false',
        issuerName: '',
        memberId: '',
        payTypeId: '',
        planName: '',
        planPremium: '',
        planPremiumWithCredits: '',
        planStateId: 0 as PlanStateIds,
        planType: ('' as unknown) as PlanTypeIds,
        selectedAhp: '',
        selectedCarrier: '',
        selectedMarketplacePlan: '',
        selectedYear: '',
    },
    selectedHouseholdMemberIds: [],
};

export const planModalState = (
    state = initialState,
    action: IAction<IPlanModalState>
): IPlanModalState => {
    switch (action.type) {
        case UPDATE_SELECTED_HOUSEHOLD_MEMBERS:
            return {
                ...state,
                ...action.data,
            };
        case CLEAR_PLAN_INPUTS:
            return initialState;
        case UPDATE_PLAN_INPUTS:
            return {
                ...state,
                planInputs: {
                    ...state.planInputs,
                    ...action.data,
                },
            };
    }
    return state;
};
