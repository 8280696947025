import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MuiRadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import useTheme from '@mui/material/styles/useTheme';
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

export type IRadioGroupProps = Omit<RadioGroupProps, 'children'> & {
    disabled?: boolean;
    errors?: string[];
    idPrefix?: string;
    isOptional?: boolean;
    label: ReactNode;
    options: Omit<FormControlLabelProps, 'control'>[];
};
const RadioGroup = ({
    disabled = false,
    errors,
    idPrefix,
    isOptional = false,
    label,
    options,
    ...props
}: IRadioGroupProps) => {
    const theme = useTheme();
    return (
        <FormControl error={!!errors}>
            <FormLabel>
                {label}{' '}
                {isOptional && <em style={{ color: theme.palette.secondary.main }}>- Optional</em>}
            </FormLabel>
            <MuiRadioGroup {...props}>
                {options.map((optionProps) => {
                    let onClick: React.MouseEventHandler<HTMLButtonElement> | undefined = undefined;
                    if (isOptional) {
                        onClick = (e) => {
                            if (
                                props.value?.toString() ===
                                (optionProps.value as object)?.toString()
                            ) {
                                props.onChange?.(
                                    ({
                                        ...e,
                                        target: { ...e.target, value: '' },
                                    } as unknown) as React.ChangeEvent<HTMLInputElement>,
                                    ''
                                );
                            }
                        };
                    }
                    return (
                        <FormControlLabel
                            control={
                                <Radio
                                    data-cy={kebabCase(optionProps.label as string)}
                                    onClick={onClick}
                                />
                            }
                            disabled={disabled}
                            key={`${kebabCase(idPrefix ?? (label as string))}-${kebabCase(
                                optionProps.label as string
                            )}`}
                            sx={{ m: 0, p: 0 }}
                            {...optionProps}
                        />
                    );
                })}
            </MuiRadioGroup>
            {errors && <FormHelperText>{errors[0]}</FormHelperText>}
        </FormControl>
    );
};

export default hot(module)(RadioGroup);
