import { TeamBenefitTypes } from 'api/generated/enums';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import TeamBenefitAgeRangeTermDetailsModal from './TeamBenefitAgeRangeTermDetailsModal';
import GenericTeamBenefitTermDetailsModal, {
    IGenericTeamBenefitTermDetailsModalProps,
} from './GenericTeamBenefitTermDetailsModal';

export const TEXT_TO_MATCH = 'Recalculate costs for all members';
export const TEXT_TO_MATCH_REGEX = new RegExp(`^${TEXT_TO_MATCH}$`);
export const TEXT_DOES_NOT_MATCH_MESSAGE =
    'Input text must match the bolded phrase above exactly to confirm this action';

export type ITeamBenefitTermDetailModalProps = Omit<
    IGenericTeamBenefitTermDetailsModalProps,
    'currentTermDetailIndex'
> & {
    currentTermDetailId: string | undefined;
};
const TeamBenefitTermDetailsModal = ({
    currentTermDetailId,
    onClose,
    teamBenefit,
}: ITeamBenefitTermDetailModalProps) => {
    const TermDetailModal =
        teamBenefit?.teamBenefitTypesCarrier?.teamBenefitType?.id === TeamBenefitTypes.LifeInsurance
            ? TeamBenefitAgeRangeTermDetailsModal
            : GenericTeamBenefitTermDetailsModal;

    const currentTermDetailIndex = useMemo(() => {
        const index = teamBenefit?.teamBenefitTermDetails?.findIndex(
            (termDetail) => termDetail.id === currentTermDetailId
        );
        if (index === undefined || index === -1) {
            return 0;
        }
        return index;
    }, [currentTermDetailId, teamBenefit?.teamBenefitTermDetails]);

    return (
        <TermDetailModal
            currentTermDetailIndex={currentTermDetailIndex}
            onClose={onClose}
            teamBenefit={teamBenefit}
        />
    );
};

export default hot(module)(TeamBenefitTermDetailsModal);
