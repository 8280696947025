import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { downloadPreventativeMecEnrollmentCensus } from 'api/downloadPreventativeMecEnrollmentCensus';
import { ITeamBenefitTermDetail, ITeamBenefitTermDetailDto } from 'api/generated/models';
import Button from 'components/Button';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import { handleTermDisplay } from 'pages/teamBenefitMemberManagement/TeamBenefitMemberManagementPage';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useParams } from 'react-router';
import { onChange } from 'utilities/forms';
import { downloadFileFromApi } from 'utilities/rhFile';

type IDownloadPrevMecCensusModalProps = {
    onClose: () => void;
    termDetails: ITeamBenefitTermDetail[] | ITeamBenefitTermDetailDto[];
};

const DownloadPrevMecCensusModal = ({ termDetails, onClose }: IDownloadPrevMecCensusModalProps) => {
    const { teamId } = useTeamProps();
    const params = useParams<{
        teamBenefitTermDetailId?: string;
    }>();
    const [downloadTermId, setDownloadTermId] = useState(
        params.teamBenefitTermDetailId ?? termDetails[0]?.id ?? ''
    );
    const [isDownloading, setIsDownloading] = useState(false);
    const handleDownloadEnrollmentCensusClick = useCallback(async () => {
        setIsDownloading(true);
        await downloadFileFromApi(
            async () => downloadPreventativeMecEnrollmentCensus(teamId, downloadTermId),
            'Failed to download enrollment census'
        );
        setIsDownloading(false);
    }, [teamId, downloadTermId]);

    return (
        <Dialog onClose={onClose} open>
            <DialogTitle>Select a term for enrollment census download</DialogTitle>
            <DialogContent>
                <Select
                    items={termDetails}
                    onChange={onChange(setDownloadTermId)}
                    optionText={handleTermDisplay}
                    optionValue="id"
                    value={downloadTermId}
                />
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                    <Button
                        isLoading={isDownloading}
                        onClick={handleDownloadEnrollmentCensusClick}
                        type="submit"
                        variant="contained"
                    >
                        Download
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(DownloadPrevMecCensusModal);
