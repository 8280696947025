import { AppStoreThunkDispatch } from 'actions/commonAction';
import { DELETE_TEAM_RH_FILE_ACTION } from 'actions/rhFile/deleteTeamRhFile';
import { DELETE_USER_RH_FILE_ACTION } from 'actions/rhFile/deleteUserRhFile';
import { TOGGLE_TEAM_RH_FILE_ARCHIVE_ACTION } from 'actions/rhFile/toggleTeamRhFileArchived';
import { TOGGLE_TEAM_RH_FILE_SHARED_ACTION } from 'actions/rhFile/toggleTeamRhFileShared';
import { TOGGLE_USER_RH_FILE_ARCHIVE_ACTION } from 'actions/rhFile/toggleUserRhFileArchived';
import { TOGGLE_USER_RH_FILE_SHARED_ACTION } from 'actions/rhFile/toggleUserRhFileShared';
import { FileResponse, IRhFile } from 'api/generated/models';
import { AxiosResponse } from 'axios';
import Icon from 'components/Icon';
import RenameRhFileModal from 'components/rhFile/RenameRhFileModal';
import RhFileDetailsModal from 'components/rhFile/RhFileDetailsModal';
import RhFilePreviewModal from 'components/rhFile/RhFilePreviewModal';
import ThingCard, { IThingAction, IThingItems } from 'components/ThingCard';
import WrappableH4 from 'components/WrappableH4';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import clone from 'lodash/clone';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { getFileIconDataForExtension } from 'utilities/files';
import { formatDateForDisplay } from 'utilities/format';
import { downloadFileFromApi } from 'utilities/rhFile';

export enum DefaultRhFileActions {
    Details,
    Rename,
    Download,
    Share,
    Archive,
    Delete,
}

export type IDefaultRhFileActionPermissions = {
    canArchiveRhFiles: boolean;
    canDeleteRhFiles: boolean;
    canDownloadRhFiles: boolean;
    canEditRhFiles: boolean;
    canShareRhFiles: boolean;
};

export type IRhFileItems = (DefaultRhFileActions | IThingAction)[];

type IRhFileProps = {
    deleteRhFile: () => (
        dispatch: AppStoreThunkDispatch,
        getState: () => AppStore
    ) => Promise<void>;
    downloadRhFile: () => Promise<AxiosResponse<FileResponse>>;
    isUnSharable: boolean;
    items?: IRhFileItems;
    permissions: IDefaultRhFileActionPermissions;
    renameRhFile: (
        rhFile: IRhFile
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
    rhFile: IRhFile;
    sharedTooltipText: string;
    toggleRhFileArchived: () => (
        dispatch: AppStoreThunkDispatch,
        getState: () => AppStore
    ) => Promise<void>;
    toggleRhFileShared: () => (
        dispatch: AppStoreThunkDispatch,
        getState: () => AppStore
    ) => Promise<void>;
};

const RhFile = ({
    deleteRhFile,
    rhFile,
    downloadRhFile,
    isUnSharable,
    items: parentItems,
    permissions: {
        canArchiveRhFiles,
        canDeleteRhFiles,
        canDownloadRhFiles,
        canEditRhFiles,
        canShareRhFiles,
    },
    renameRhFile,
    sharedTooltipText,
    toggleRhFileArchived,
    toggleRhFileShared,
}: IRhFileProps) => {
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { createdByUser, createdDate, fileExtension, isArchived, isShared, name } = useMemo(
        () => rhFile,
        [rhFile]
    );
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showRenameRhFileModal, setShowRenameRhFileModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const { isArchiveLoading, isDeleteLoading, isShareLoading } = useSelector(
        (state: AppStore) => ({
            isArchiveLoading: hasApiActivity(
                state,
                TOGGLE_TEAM_RH_FILE_ARCHIVE_ACTION,
                TOGGLE_USER_RH_FILE_ARCHIVE_ACTION
            ),
            isDeleteLoading: hasApiActivity(
                state,
                DELETE_TEAM_RH_FILE_ACTION,
                DELETE_USER_RH_FILE_ACTION
            ),
            isShareLoading: hasApiActivity(
                state,
                TOGGLE_TEAM_RH_FILE_SHARED_ACTION,
                TOGGLE_USER_RH_FILE_SHARED_ACTION
            ),
        })
    );

    const handleDownloadClick = useCallback(async () => {
        setIsDownloading(true);
        await downloadFileFromApi(downloadRhFile);
        setIsDownloading(false);
    }, [downloadRhFile]);
    const onShareClick = useCallback(async () => await dispatch(toggleRhFileShared()), [
        dispatch,
        toggleRhFileShared,
    ]);
    const onArchiveClick = useCallback(async () => await dispatch(toggleRhFileArchived()), [
        dispatch,
        toggleRhFileArchived,
    ]);
    const onDeleteClick = useCallback(async () => await dispatch(deleteRhFile()), [
        deleteRhFile,
        dispatch,
    ]);

    const fileIconData = useMemo(() => getFileIconDataForExtension(fileExtension ?? ''), [
        fileExtension,
    ]);
    const openPreviewModal = useCallback(() => setShowPreviewModal(true), []);
    const openDetailsModal = useCallback(() => setShowDetailsModal(true), []);
    const openRenameRhFileModal = useCallback(() => setShowRenameRhFileModal(true), []);
    const items = useMemo(() => {
        const defaultItems = [
            {
                id: DefaultRhFileActions.Details,
                isVisible: true,
                label: 'Details',
                onClick: openDetailsModal,
            },
            {
                id: DefaultRhFileActions.Rename,
                isVisible: canEditRhFiles,
                label: 'Rename',
                onClick: openRenameRhFileModal,
            },
            {
                id: DefaultRhFileActions.Download,
                isLoading: isDownloading,
                isVisible: canDownloadRhFiles,
                label: 'Download',
                onClick: handleDownloadClick,
            },
            {
                id: DefaultRhFileActions.Share,
                isConfirm: true,
                isLoading: isShareLoading,
                isVisible: canShareRhFiles && !isUnSharable,
                label: isShared ? 'Unshare' : 'Share',
                onClick: onShareClick,
            },
            {
                id: DefaultRhFileActions.Archive,
                isConfirm: true,
                isLoading: isArchiveLoading,
                isVisible: canArchiveRhFiles,
                label: isArchived ? 'Un-Archive' : 'Archive',
                onClick: onArchiveClick,
            },
            {
                id: DefaultRhFileActions.Delete,
                isConfirm: true,
                isLoading: isDeleteLoading,
                isVisible: canDeleteRhFiles,
                label: 'Delete',
                onClick: onDeleteClick,
            },
        ];
        if (parentItems) {
            return parentItems.map((x) => {
                if ((x as number) in DefaultRhFileActions) {
                    return defaultItems.find((i) => i.id === x);
                }
                const item = clone(x) as IThingAction;
                if (item.onClick) {
                    const parentOnClick = item.onClick as (e: unknown, rhFile: IRhFile) => void;
                    item.onClick = (e) => parentOnClick(e, rhFile);
                    return item;
                }
                return x;
            }) as IThingItems;
        }
        return defaultItems;
    }, [
        canArchiveRhFiles,
        canDeleteRhFiles,
        canDownloadRhFiles,
        canEditRhFiles,
        canShareRhFiles,
        rhFile,
        handleDownloadClick,
        isArchiveLoading,
        isArchived,
        isDeleteLoading,
        isDownloading,
        isShareLoading,
        isShared,
        isUnSharable,
        onArchiveClick,
        onDeleteClick,
        onShareClick,
        openDetailsModal,
        openRenameRhFileModal,
        parentItems,
    ]);
    return (
        <React.Fragment>
            {showPreviewModal && (
                <RhFilePreviewModal
                    canDownloadRhFiles={canDownloadRhFiles}
                    downloadRhFile={downloadRhFile}
                    onClose={() => setShowPreviewModal(false)}
                    rhFile={rhFile}
                />
            )}
            {showDetailsModal && (
                <RhFileDetailsModal onClose={() => setShowDetailsModal(false)} rhFile={rhFile} />
            )}
            {showRenameRhFileModal && (
                <RenameRhFileModal
                    onClose={() => setShowRenameRhFileModal(false)}
                    renameRhFile={renameRhFile}
                    rhFile={rhFile}
                />
            )}
            <ThingCard
                bottomLeftContent={`Uploaded by ${
                    createdByUser?.displayName
                } on ${formatDateForDisplay(createdDate)}`}
                isArchived={isArchived}
                isShared={isTeamManagementPage && isShared}
                isUnSharable={isUnSharable}
                items={items}
                onClick={openPreviewModal}
                sharedTooltipText={sharedTooltipText}
                topLeftContent={
                    <span>
                        <Row className="align-items-center" noGutters>
                            <Icon prefix="mdi" size="2" variant={fileIconData.color}>
                                {fileIconData.icon}
                            </Icon>
                            {isArchived && <small className="text-muted ml-1">(Archived)</small>}
                        </Row>
                    </span>
                }
            >
                <Row>
                    <WrappableH4>{name}</WrappableH4>
                </Row>
            </ThingCard>
        </React.Fragment>
    );
};

export default hot(module)(RhFile);
