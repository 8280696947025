import CopyText from 'components/CopyText';
import InformationIconTooltip from 'components/InformationIconTooltip';
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const ChildrenContainer = styled.div`
    min-width: 0;
`;

export type IProfileAttributeProps = {
    'data-private'?: boolean;
    ColProps?: React.ComponentProps<typeof Col>;
    OverrideProps?: React.ReactNode;
    children: ReactNode;
    className?: string;
    footer?: ReactNode;
    hrClassName?: string;
    infoTooltip?: string;
    label?: ReactNode;
    textToCopy?: ReactNode;
};

const ProfileAttribute = ({
    'data-private': dataPrivate,
    children,
    className = '',
    ColProps = {},
    footer = null,
    hrClassName = '',
    infoTooltip = '',
    label,
    OverrideProps,
    textToCopy = children,
}: IProfileAttributeProps) => (
    <React.Fragment>
        <div
            className={'d-flex flex-nowrap align-items-center justify-content-between ' + className}
            data-cy={kebabCase(label?.toString())}
        >
            {OverrideProps ? (
                OverrideProps
            ) : (
                <Col className="ml-0 text-muted mr-2" {...((ColProps as unknown) as object)}>
                    <div>
                        <strong>
                            {label}
                            {infoTooltip && (
                                <span className="ml-1">
                                    <InformationIconTooltip title={infoTooltip} />
                                </span>
                            )}
                        </strong>
                    </div>
                    {footer}
                </Col>
            )}
            <ChildrenContainer className="text-wrap text-right" data-private={dataPrivate}>
                {typeof textToCopy === 'string' ? (
                    <CopyText text={textToCopy}>{children}</CopyText>
                ) : (
                    children
                )}
            </ChildrenContainer>
        </div>
        <hr className={hrClassName} />
    </React.Fragment>
);

export default hot(module)(ProfileAttribute);
