import React, { ReactNode } from 'react';
import Col, { ColProps } from 'react-bootstrap/Col';
import { hot } from 'react-hot-loader';
const Column = ({
    children,
    isRight = false,
    ...props
}: ColProps & { children: ReactNode; isRight?: boolean }) => {
    let className = 'p-0 pr-md-3 pr-lg-6';
    if (isRight) {
        className = 'p-0 pl-md-3 pl-lg-6';
    }
    return (
        <Col className={className} md="6" xs="12" {...props}>
            {children}
        </Col>
    );
};

export default hot(module)(Column);
