import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { hot } from 'react-hot-loader';

const UnsupportedStateBasedExchangeChip = ({ sx, ...props }: ChipProps) => (
    <Chip
        color="primary"
        key="unsupportedStateBasedExchange"
        label="Unsupported SBE"
        size="small"
        sx={{ ...sx }}
        {...props}
    />
);

export default hot(module)(UnsupportedStateBasedExchangeChip);
