import DateMaskField, { DateTextMask } from 'components/DateTextMask';
import { ITextFieldProps } from 'components/TextField';
import moment from 'moment';
import React from 'react';
import { hot } from 'react-hot-loader';
import { DISPLAY_DATE_FORMAT } from 'utilities/moment';

export const SingleDateMask = DateTextMask(
    'm{/}`d{/}`Y',
    (str: string) => moment(str, DISPLAY_DATE_FORMAT),
    (date: moment.Moment) => moment(date).format(DISPLAY_DATE_FORMAT)
);

const DateTextField = (props: ITextFieldProps) => (
    <DateMaskField inputComponent={SingleDateMask} {...props}></DateMaskField>
);

export default hot(module)(DateTextField);
