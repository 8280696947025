import Typography from '@mui/material/Typography';
import React from 'react';
import { hot } from 'react-hot-loader';

export const BoldHeaderText = ({ children }: { children: string }) => (
    <Typography display="inline" fontWeight="900" variant="inherit">
        {children}
    </Typography>
);

export default hot(module)(BoldHeaderText);
