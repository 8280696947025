import { BAR_MIN_WIDTH } from 'components/reimbursementOverlayModal/ReimbursementChart';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';

type IReimbursementChartCustomLabel = {
    bar?: 'gap' | 'hsa' | 'plan';
    height?: number;
    value?: number[];
    width?: number;
    x?: number;
    y?: number;
};
const ReimbursementChartCustomLabel = ({
    bar,
    height,
    value = [],
    width,
    x,
    y,
}: IReimbursementChartCustomLabel) => {
    const xPos = x ?? 0;
    const yPos = y ?? 0;
    const barHeightMidpoint = (height ?? 0) / 2;
    const barWidthMidpoint = (width ?? 0) / 2;
    const barValue = (value[1] ?? 0) - (value[0] ?? 0);
    const barValueCutoff = BAR_MIN_WIDTH + 300;
    let labelString = '';
    const labelSplit = [] as string[];
    value.forEach((num, idx) => {
        labelString += formatCurrency(num);
        labelSplit.push(formatCurrency(num));
        if (idx !== value.length - 1) {
            labelString += ' - ';
        }
    });
    if (bar === 'plan') {
        labelString += '+';
        labelSplit[0] += '+';
        labelSplit.unshift('Plan kicks in...');
    }
    if (
        (barValue > 1 && barValue < barValueCutoff && bar !== 'hsa') ||
        (bar === 'plan' && (value[0] ?? 0) > 0)
    ) {
        return (
            <React.Fragment>
                <text
                    fill="#fff"
                    fontWeight={700}
                    textAnchor="middle"
                    x={xPos + barWidthMidpoint}
                    y={yPos + barHeightMidpoint - 7}
                >
                    {labelSplit[0]}
                </text>
                {bar !== 'plan' && (
                    <text
                        fill="#fff"
                        fontWeight={700}
                        style={{ textAlign: 'center' }}
                        textAnchor="middle"
                        x={xPos + barWidthMidpoint}
                        y={yPos + barHeightMidpoint + 4}
                    >
                        -
                    </text>
                )}
                <text
                    fill="#fff"
                    fontWeight={700}
                    textAnchor="middle"
                    x={xPos + barWidthMidpoint}
                    y={yPos + barHeightMidpoint + 17}
                >
                    {labelSplit[1]}
                </text>
            </React.Fragment>
        );
    } else if (barValue >= barValueCutoff || bar === 'hsa') {
        return (
            <text
                fill="#fff"
                fontWeight={700}
                textAnchor="middle"
                x={xPos + barWidthMidpoint}
                y={yPos + barHeightMidpoint + 5}
            >
                {labelString}
            </text>
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
};

export default hot(module)(ReimbursementChartCustomLabel);
