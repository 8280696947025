import Button, { IButtonProps } from 'components/Button';
import ConditionalTooltip, { IConditionalTooltipProps } from 'components/ConditionalTooltip';
import ConfirmText from 'components/ConfirmText';
import Spinner from 'components/Spinner';
import ThreeDotDropdownToggle from 'components/ThreeDotDropdownToggle';
import DesktopContext from 'contexts/DesktopContext';
import kebabCase from 'lodash/kebabCase';
import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { ButtonProps as BootstrapButtonProps } from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import SafeAnchor from 'react-bootstrap/SafeAnchor';
import { hot } from 'react-hot-loader';
import { Link, LinkProps } from 'react-router-dom';
import { hasValue, isFalse } from 'utilities';

type IActionButtonItem = {
    className?: string;
    confirmText?: React.ReactNode;
    dataCy?: string;
    href?: string;
    isConfirm?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isVisible: boolean | undefined;
    link?: LinkProps;
    onClick: () => void;
    rel?: string;
    target?: string;
    text: string;
    tooltip?: string;
    variant?: IButtonProps['variant'];
};

const getActionButton = (
    {
        className = '',
        confirmText,
        dataCy,
        href,
        isConfirm,
        isDisabled,
        isLoading,
        link,
        onClick,
        rel,
        target,
        text,
        tooltip,
        variant = 'outlined',
    }: IActionButtonItem,
    globalClassName: string,
    ButtonProps: IButtonProps | undefined,
    disabled: boolean,
    closeDropdown: () => void
) => {
    const commonProps = {
        href,
        onClick,
        rel,
        target,
        className: `${globalClassName} mr-1 ${className}`,
        'data-cy': dataCy ? dataCy : kebabCase(text),
    };
    const buttonProps = {
        disabled,
        link,
        variant,
        className: `${globalClassName} ${className}`,
        ...ButtonProps,
    };
    const tooltipProps: Omit<IConditionalTooltipProps, 'children'> = {
        isDisabled: isFalse(isDisabled) || !hasValue(tooltip),
        placement: 'left',
        title: tooltip as React.ReactChild | React.ReactFragment | React.ReactPortal | boolean,
    };
    let button = (
        <ConditionalTooltip {...tooltipProps}>
            <Button
                {...commonProps}
                {...buttonProps}
                disabled={isDisabled || disabled}
                isLoading={isLoading}
            >
                {text}
            </Button>
        </ConditionalTooltip>
    );
    let item = (
        <ConditionalTooltip {...tooltipProps}>
            <Dropdown.Item
                disabled={isLoading || isDisabled}
                {...commonProps}
                as={((link ? Link : SafeAnchor) as unknown) as 'symbol' | undefined}
                to={link?.to as string}
            >
                {isLoading ? <Spinner size="20" /> : text}
            </Dropdown.Item>
        </ConditionalTooltip>
    );
    if (isConfirm) {
        const commonConfirmTextProps = {
            confirmText,
            isLoading,
            disabled: isDisabled,
            onComplete: closeDropdown,
            ...commonProps,
        };
        button = (
            <ConditionalTooltip {...tooltipProps}>
                <ConfirmText as={Button} asProps={buttonProps} {...commonConfirmTextProps}>
                    {text}
                </ConfirmText>
            </ConditionalTooltip>
        );
        item = (
            <ConditionalTooltip {...tooltipProps}>
                <ConfirmText as={Dropdown.Item} {...commonConfirmTextProps}>
                    {text}
                </ConfirmText>
            </ConditionalTooltip>
        );
    }
    return {
        button,
        item,
    };
};

export type IActionButtonItems = IActionButtonItem[];

type IActionButtonsProps = {
    ButtonProps?: IButtonProps;
    className?: string;
    dataCy?: string;
    disabled?: boolean;
    dropdownWhenLengthGreaterThan?: number;
    isAlwaysDropdown?: boolean;
    isThreeDotButton?: boolean;
    items: IActionButtonItems;
    size?: BootstrapButtonProps['size'];
    title?: ReactNode;
    variant?: BootstrapButtonProps['variant'];
};

const ActionButtons = ({
    isAlwaysDropdown,
    ButtonProps,
    className: globalClassName = '',
    dataCy,
    disabled = false,
    dropdownWhenLengthGreaterThan = 1,
    isThreeDotButton,
    items,
    size,
    title = 'Actions',
    variant = 'outline-secondary',
}: IActionButtonsProps) => {
    const isDesktop = useContext(DesktopContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const onToggle = useCallback((isOpen, event, metadata) => {
        event.stopPropagation?.();
        if (isOpen || metadata.source !== 'select') {
            setShowDropdown(isOpen);
        }
        event.persist?.();
    }, []);
    const buttons = useMemo(() => {
        const actionButtons: {
            button: React.ReactElement;
            item: React.ReactElement;
        }[] = [];
        items.forEach((item) => {
            if (item.isVisible) {
                actionButtons.push(
                    getActionButton(item, globalClassName, ButtonProps, disabled, () =>
                        setShowDropdown(false)
                    )
                );
            }
        });
        return actionButtons;
    }, [ButtonProps, disabled, globalClassName, items]);
    if (buttons.length === 0) {
        return <React.Fragment />;
    }
    if (
        isAlwaysDropdown ||
        ((buttons.length > dropdownWhenLengthGreaterThan || !isDesktop) && buttons.length !== 1)
    ) {
        return (
            <Dropdown data-cy={dataCy} onToggle={onToggle} show={showDropdown}>
                {isThreeDotButton ? (
                    <ThreeDotDropdownToggle dataCy={`${dataCy}-toggle`} />
                ) : (
                    <Dropdown.Toggle
                        block
                        className="px-3 py-2"
                        data-cy={`${dataCy}-toggle`}
                        disabled={disabled}
                        id="actions-dropdown-button"
                        size={size}
                        variant={variant}
                    >
                        {title}
                    </Dropdown.Toggle>
                )}
                <Dropdown.Menu>
                    {buttons.map((x, key) => React.cloneElement(x.item, { key }))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
    return (
        <React.Fragment>
            {buttons.map((x, key) => React.cloneElement(x.button, { key }))}
        </React.Fragment>
    );
};

export default hot(module)(ActionButtons);
