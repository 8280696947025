import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import { Cell, Column, Hooks, RowPropGetter } from 'react-table';

export const addPaginatedSelectionColumnHook = <T extends object>(
    checkedRows: string[],
    setCheckedRows: (checkedRows: string[]) => void,
    allPossibleRows: string[]
) => (hooks: Hooks<T>) => {
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const selectionColumn: Column<T> = {
        Cell: ({ row }: Cell<T>) => {
            const updatedCheckedRows = (checked: boolean) => {
                const updatedRows = [...checkedRows];
                if (checked) {
                    updatedRows.push(row.id);
                } else {
                    const index = updatedRows.indexOf(row.id);
                    updatedRows.splice(index, 1);
                }
                setCheckedRows(updatedRows);
            };
            return (
                <div>
                    <Checkbox
                        checked={checkedRows.contains(row.id)}
                        onChange={({ target: { checked } }) => updatedCheckedRows(checked)}
                    />
                </div>
            );
        },
        className: 'justify-content-center',
        disableResizing: true,
        Header: () => {
            const toggleSelectAll = (checked: boolean) => {
                setAllRowsSelected(checked);
                if (checked) {
                    setCheckedRows(allPossibleRows);
                } else {
                    setCheckedRows([]);
                }
            };

            return (
                <div>
                    <Checkbox
                        checked={allRowsSelected}
                        indeterminate={
                            checkedRows.length > 0 && allPossibleRows.length !== checkedRows.length
                        }
                        onClick={() => toggleSelectAll(!allRowsSelected)}
                        title="Toggle All Rows Selected"
                    />
                </div>
            );
        },
        headerClassName: 'justify-content-center',
        id: 'selection',
        width: 30,
    };

    hooks.visibleColumns.push((columns) => [selectionColumn, ...columns]);

    const getRowStyles: RowPropGetter<T> = (props, { row }) => [
        props,
        {
            style: {
                background: checkedRows.contains(row.id) ? '#e3eaef' : undefined,
            },
        },
    ];
    hooks.getRowProps.push(getRowStyles);
};
