import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { readableFileSize } from 'utilities';
const FileSizeViolationMessage = ({
    invalidFiles,
    fileSizeLimitInBytes,
    isMin = false,
}: {
    fileSizeLimitInBytes: number | undefined;
    invalidFiles: File[];
    isMin?: boolean;
}) => {
    const fileList = useMemo(() => (Array.isArray(invalidFiles) ? Array.from(invalidFiles) : []), [
        invalidFiles,
    ]);
    const readableFileSizeLimit = useMemo(() => readableFileSize(fileSizeLimitInBytes), [
        fileSizeLimitInBytes,
    ]);
    const minOrMaxString = useMemo(() => (isMin ? 'minimum' : 'max'), [isMin]);
    if (invalidFiles.length === 1) {
        const file = invalidFiles[0];
        return (
            <span>
                {file?.name} file size of {readableFileSize(file?.size)}{' '}
                {isMin ? 'is less than the' : 'exceeds the'} {minOrMaxString} file size of{' '}
                {readableFileSizeLimit}
            </span>
        );
    }
    return (
        <span>
            Files {isMin ? 'that are less than the' : 'that exceed the'} {minOrMaxString} file size
            of {readableFileSizeLimit}
            <ul>
                {fileList.map((file, index) => (
                    <li key={index}>
                        {file.name} ({readableFileSize(file.size)})
                    </li>
                ))}
            </ul>
        </span>
    );
};
export default hot(module)(FileSizeViolationMessage);
