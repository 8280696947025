import ScheduleAdvisementModalLink from 'components/helpComponents/ScheduleAdvisementModalLink';
import useTeamProps from 'hooks/useTeamProps';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { hot } from 'react-hot-loader';

export const HELPSCOUT_BEACON_ORGANIZATION_NAME_FIELD_ID = 17603;

const HelpContact = ({ isIchraFlow }: { isIchraFlow?: boolean }) => {
    const { teamName } = useTeamProps();
    const { user } = useUserProps();
    const openBeacon = useCallback(() => {
        window.Beacon('navigate', '/ask/message/');
        window.Beacon('prefill', {
            company: teamName,
            email: user?.email,
            fields: [{ id: HELPSCOUT_BEACON_ORGANIZATION_NAME_FIELD_ID, value: teamName }],
            name: `${user?.firstName} ${user?.lastName}`,
            subject: 'Need help with my benefits decision',
        });
        window.Beacon('open');
    }, [teamName, user?.email, user?.firstName, user?.lastName]);
    return (
        <Col className="text-center">
            <Row className="justify-content-center">
                <div className="font-weight-bold">Need help making a decision?</div>
            </Row>
            <Row className="justify-content-center">
                <div>
                    You can{' '}
                    <span className="text-primary clickable" onClick={openBeacon}>
                        email
                    </span>{' '}
                    us or <ScheduleAdvisementModalLink isIchraFlow={isIchraFlow} />
                </div>
            </Row>
        </Col>
    );
};

export default hot(module)(HelpContact);
