import EastIcon from '@mui/icons-material/East';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IMajorMedicalBenefitCostDto } from 'api/generated/models';
import DiagramGrid from 'components/majorMedicalBenefitCostBreakdownModal/DiagramGrid';
import SuperScript from 'components/majorMedicalBenefitCostBreakdownModal/SuperScript';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { formatCurrency } from 'utilities/format';

const WagePlusGrids = ({
    majorMedicalBenefitCost: parentCostData,
}: {
    majorMedicalBenefitCost?: IMajorMedicalBenefitCostDto;
}) => {
    const { memberBenefits } = useSelector((state: AppStore) => ({
        memberBenefits: state.memberBenefits,
    }));
    const costData = parentCostData ?? memberBenefits.majorMedicalBenefitCost;
    return (
        <Grid columns={14} container item>
            <DiagramGrid xs={2}>
                <Typography variant="h3">Wage+</Typography>
            </DiagramGrid>
            <DiagramGrid borderRight={1} xs={4}>
                <Typography>
                    <Typography component="span" variant="h4">
                        {formatCurrency(costData?.preTaxWagePlus)}
                    </Typography>
                    <SuperScript>a</SuperScript> per month
                </Typography>
                <Typography variant="caption">
                    Additional monthly wages from your employer on your paycheck
                </Typography>
            </DiagramGrid>
            <DiagramGrid borderRight={1} paddingBottom={10} paddingTop={3} xs={4}>
                <Typography variant="caption">
                    To calculate your net pay increase, we take an estimate of your tax
                    responsibility out of your Wage+
                </Typography>
                <Typography>
                    <EastIcon sx={{ fontSize: 50 }} />
                </Typography>
                <Typography color={(theme) => theme.palette.muted.main} variant="caption">
                    {formatCurrency(costData?.preTaxWagePlus)}
                    <SuperScript>a</SuperScript> x (1 - {costData?.totalTaxRate}
                    <SuperScript>d</SuperScript>)
                </Typography>
            </DiagramGrid>
            <DiagramGrid xs={4}>
                <Typography>
                    <Typography component="span" variant="h4">
                        {formatCurrency(costData?.postTaxWagePlus, {
                            preserveDecimal: true,
                        })}
                    </Typography>{' '}
                    per month
                </Typography>
            </DiagramGrid>
        </Grid>
    );
};

export default hot(module)(WagePlusGrids);
