import React from 'react';
import { hot } from 'react-hot-loader';

const ExternalLink = ({ children, href }: { children?: React.ReactNode; href: string }) => (
    <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
    </a>
);

export default hot(module)(ExternalLink);
