import Stack from '@mui/material/Stack';
import {
    SEARCH_IDEON_PROVIDERS_ACTION,
    searchIdeonProviders,
} from 'actions/providerSearch/searchIdeonProviders';
import {
    SEARCH_IDEON_PROVIDERS_WITH_TOKEN_ACTION,
    searchIdeonProvidersWithToken,
} from 'actions/providerSearch/searchIdeonProvidersWithToken';
import { IIdeonProvider } from 'api/generated/models';
import { IAutocompleteProps } from 'components/Autocomplete';
import {
    HighlightedSearchText,
    ICreatableAutocompleteProps,
} from 'components/creatableAutocomplete/CreatableAutocomplete';
import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IIdeonProviderWithSource } from 'reducers/ideonProviders';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { formatPhoneNumber } from 'utilities/format';
import { toStartCase } from 'utilities/string';

export const getIdeonProviderName = (provider: Partial<IIdeonProvider & { name: string }>) => {
    if (hasValue(provider.presentationName)) {
        return hasValue(provider.doingBusinessAsName)
            ? `${toStartCase(provider.presentationName)}; DBA ${toStartCase(
                  provider.doingBusinessAsName
              )}`
            : toStartCase(provider.presentationName);
    } else {
        return toStartCase(provider.name);
    }
};

const getIdeonProviderRenderOptions: IAutocompleteProps<
    Partial<IIdeonProvider & { name: string }>,
    true,
    false,
    false
>['renderOption'] = (props, option, _state) => {
    const { id, name: nameFromField, phone, presentationName, specialty, type } = option;
    const name = hasValue(presentationName) ? getIdeonProviderName(option) : nameFromField;
    return (
        <li {...props} key={`${id}-${kebabCase(name)}-${kebabCase(specialty)}-${kebabCase(type)}`}>
            <Stack
                alignItems="center"
                borderBottom={0.5}
                direction="row"
                justifyContent="space-between"
                pb={1}
                width="100%"
            >
                <div>
                    <div>
                        <HighlightedSearchText query={_state.inputValue} text={name} />
                    </div>
                    <div>{formatPhoneNumber(phone)}</div>
                    <div>{specialty}</div>
                </div>
                <div>
                    {type && (
                        <i
                            className={
                                lowerCase(type) === 'individual'
                                    ? 'dripicons-user'
                                    : 'dripicons-store'
                            }
                        />
                    )}
                </div>
            </Stack>
        </li>
    );
};

const useIdeonProviderAutocomplete = <T extends IIdeonProviderWithSource>({
    onChange,
    options: parentOptions,
    token,
    value,
    year,
    zipCode,
}: {
    onChange?: ICreatableAutocompleteProps<T>['onChange'];
    options?: readonly T[];
    token?: string | null;
    value?: T[];
    year: number;
    zipCode: string;
}): ICreatableAutocompleteProps<T> => {
    const { loading, options } = useSelector((state: AppStore) => ({
        loading: hasApiActivity(
            state,
            SEARCH_IDEON_PROVIDERS_ACTION,
            SEARCH_IDEON_PROVIDERS_WITH_TOKEN_ACTION
        ),
        options: state.ideonProviders,
    }));
    const search = useCallback(
        (searchTerm) =>
            hasValue(token)
                ? searchIdeonProvidersWithToken(searchTerm, zipCode, year, token)
                : searchIdeonProviders(searchTerm, zipCode, year),
        [zipCode, year, token]
    );
    return {
        loading,
        onChange,
        search,
        value,
        getOptionLabel: (option: T) => getIdeonProviderName(option) ?? '',
        helperText:
            'Include doctors, specialists, or hospitals that are important to your household.',
        isOptionEqualToValue: (option: Partial<T> | string, _value: Partial<T> | string) => {
            const optionKey =
                typeof option === 'string' ? option : option.id ?? getIdeonProviderName(option);
            const valueKey =
                typeof _value === 'string' ? _value : _value.id ?? getIdeonProviderName(_value);
            return optionKey === valueKey;
        },
        label: 'Search Doctor, Specialist or Hospital',
        options: (parentOptions ?? options) as readonly T[],
        renderOption: getIdeonProviderRenderOptions,
    };
};

export default useIdeonProviderAutocomplete;
