import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const completedColor = '#5cc2a7';
const disabledColor = '#999999';
const enabledColor = '#374958';

const CompletedTaskIndicator = ({
    completed,
    enabled = true,
    onClick,
}: {
    completed: boolean | undefined;
    enabled?: boolean;
    onClick: () => void;
}) => {
    let color = completedColor;
    if (!enabled && !completed) {
        color = disabledColor;
    } else if (!completed) {
        color = enabledColor;
    }

    return (
        <Circle color={color} onClick={enabled && !completed ? onClick : undefined}>
            {completed && <Checkmark color={color} />}
        </Circle>
    );
};

const Circle = styled.div`
    border: 3px solid ${({ color }) => color};
    border-radius: 20px;
    height: 40px;
    margin-left: 12px;
    text-align: center;
    width: 40px;
`;

const Checkmark = styled.i.attrs(() => ({
    className: 'dripicons-checkmark',
    'data-cy': 'complete-icon',
}))`
    font-size: 22px;
    color: ${({ color }) => color};
`;

export default hot(module)(CompletedTaskIndicator);
