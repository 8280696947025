import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import PageHeader from 'components/PageHeader';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useUserProps from 'hooks/useUserProps';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const UserNameEntityHeader = ({ entity }: { entity: string }) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { hasUser } = useUserProps();
    const { isLoadingUser } = useSelector((state: AppStore) => ({
        isLoadingUser: hasApiActivity(state, GET_USER_PROFILE_ACTION),
    }));
    if (isTeamManagementPage) {
        return <React.Fragment />;
    }
    return isLoadingUser && !hasUser ? (
        <Skeleton count={1} />
    ) : (
        <PageHeader color={(theme) => theme.palette.muted.main} mb={1} variant="h4">
            My
            {` ${entity}`}
        </PageHeader>
    );
};

export default hot(module)(UserNameEntityHeader);
