import { AppStoreThunkDispatch } from 'actions/commonAction';
import { FileAndName } from 'actions/rhFile/addRhFiles';
import Button from 'components/Button';
import FileInput from 'components/fileInput/FileInput';
import Form from 'components/Form';
import { VALID_UPLOAD_FILE_EXTENSIONS } from 'constants/fileExtensions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { AppStore } from 'reducers/appReducer';
import { convertFilesToFileAndName } from 'utilities/files';

const ONE_HUNDRED_MEGABYTES = 100000000;
const FIFTY_BYTES = 50;
const TWO_HUNDRED_AND_FIFTY_FIVE_CHARACTERS = 255;

export type AddFilesActionThunk = (
    files: FileAndName[],
    toastSuccessMessage: string
) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;

type IFileUploadModal = {
    'data-cy'?: string;
    accept?: string[];
    acceptSingle?: boolean;
    body?: React.ReactNode;
    fileData?: File[];
    isLoading: boolean;
    onHide: () => void;
    onSubmit: AddFilesActionThunk;
    size?: 'lg' | 'sm' | 'xl';
};

const FileUploadModal = ({
    accept = VALID_UPLOAD_FILE_EXTENSIONS,
    acceptSingle = false,
    body,
    'data-cy': dataCy,
    fileData: parentFileData,
    isLoading,
    onHide,
    onSubmit: onSubmitParent,
    size,
}: IFileUploadModal) => {
    const dispatch = useThunkDispatch();
    const [fileData, setFileData] = useState(parentFileData);
    const onSubmit = useCallback(async () => {
        const filesToAdd = convertFilesToFileAndName(fileData);
        await dispatch(
            onSubmitParent(
                filesToAdd,
                `Successfully uploaded file${filesToAdd.length > 1 ? 's' : ''}`
            )
        );
        onHide();
    }, [dispatch, fileData, onHide, onSubmitParent]);
    const onChange = useCallback((newFileData) => setFileData(newFileData), []);
    const disableSubmit = useMemo(() => (acceptSingle ? isNull(fileData) : isEmpty(fileData)), [
        acceptSingle,
        fileData,
    ]);
    return (
        <Modal
            backdrop={isLoading ? 'static' : true}
            data-cy={dataCy}
            onHide={onHide}
            scrollable
            show
            size={size}
        >
            <Modal.Header closeButton={!isLoading}>
                <Modal.Title>Upload Files</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="file-upload-modal-form" isLoading={isLoading} onSubmit={onSubmit}>
                    <FileInput
                        accept={accept}
                        disabled={isLoading}
                        fileData={fileData}
                        isMultiple={!acceptSingle}
                        label="Files"
                        maxFileNameLength={TWO_HUNDRED_AND_FIFTY_FIVE_CHARACTERS}
                        maxFileSizeInBytes={ONE_HUNDRED_MEGABYTES}
                        minFileSizeInBytes={FIFTY_BYTES}
                        onChange={onChange}
                    />
                    {body}
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button
                    disabled={disableSubmit}
                    form="file-upload-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Upload
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(FileUploadModal);
