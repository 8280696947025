import useTeamProps from 'hooks/useTeamProps';
import { useRouteMatch } from 'react-router';

/**
 * For getting team props in components that are rendered
 * outside of react-router `Route`. If a component is rendered outside
 * of the `Route` component then we do not have access to the url
 * params for that route so we are unable to get the proper `teamId`
 * **Important**: Downside is we rely on url structure
 * @returns Team props from `useTeamProps`
 */
const useExternalTeamProps = () => {
    const match = useRouteMatch<{ teamId?: string }>({
        exact: false,
        path: '/teams/:teamId',
        strict: false,
    });
    return useTeamProps(match?.params?.teamId);
};

export default useExternalTeamProps;
