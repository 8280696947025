import { AppStoreThunkDispatch } from 'actions/commonAction';

export const SHOW_NEW_VERSION_MODAL = 'SHOW_NEW_VERSION_MODAL';
export const showNewVersionModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { isNewVersionModalVisible: true },
        type: SHOW_NEW_VERSION_MODAL,
    });
};

export const HIDE_NEW_VERSION_MODAL = 'HIDE_NEW_VERSION_MODAL';
export const hideNewVersionModal = () => (dispatch: AppStoreThunkDispatch) => {
    dispatch({
        data: { isNewVersionModalVisible: false, willUpdateLater: true },
        type: HIDE_NEW_VERSION_MODAL,
    });
};
