import CheckMark, { NoCheckmarkSpacer } from 'components/headerDropdown/CheckMark';
import useQuery from 'hooks/useQuery';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import React, { useCallback, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { hot } from 'react-hot-loader';

export type ISetSelected = React.Dispatch<React.SetStateAction<string[]>>;
export type IDropdownMenuItemsProps = {
    options: string[];
    selected: string[];
    setSelected: ISetSelected;
    title: string;
};
const DropdownMenuItems = ({ options, selected, setSelected, title }: IDropdownMenuItemsProps) => {
    const [query, setQuery] = useQuery();
    const setQueryString = useCallback(
        (newSelected) => {
            query.delete(title);
            if (newSelected.length > 0 && newSelected.length !== options.length) {
                query.set(title, newSelected.join(','));
            }
            setQuery(query);
        },
        [options.length, query, setQuery, title]
    );
    const onSelect = useCallback(
        (item) => () => {
            const newSelected = selected.includes(item)
                ? selected.filter((s) => s !== item)
                : [...selected, item];
            setSelected(newSelected);
            setQueryString(newSelected);
        },
        [selected, setQueryString, setSelected]
    );
    const isAllSelected = useMemo(() => isEmpty(xor(options, selected)), [options, selected]);
    const menuItems = options
        .filter((x) => x !== null)
        .map((item, index) => (
            <Dropdown.Item className="text-dark" key={index + 1} onSelect={onSelect(item)}>
                <CheckMark isVisible={selected.includes(item)} /> {item}
            </Dropdown.Item>
        ));
    menuItems.unshift(<Dropdown.Divider key="divider" />);
    menuItems.unshift(
        <Dropdown.Item
            className="text-dark"
            key={0}
            onSelect={() => {
                const newSelected = isAllSelected ? [] : options;
                setSelected(newSelected);
                setQueryString(newSelected);
            }}
        >
            <NoCheckmarkSpacer />
            {isAllSelected ? 'Deselect All' : 'Select All'}
        </Dropdown.Item>
    );
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default hot(module)(DropdownMenuItems);
