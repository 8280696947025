import { HouseholdMemberTypes } from 'api/generated/enums';
import { IHouseholdMember, ISurveyHouseholdMemberDto, IUser } from 'api/generated/models';
import Column from 'components/verifiedInfoSummary/Column';
import ContentWithLabel from 'components/verifiedInfoSummary/ContentWithLabel';
import Section from 'components/verifiedInfoSummary/Section';
import { SurveyTabs } from 'constants/surveyTabs';
import { ISurveyHouseholdMember, ISurveyState } from 'pages/survey/surveyState';
import React, { ReactNode, useCallback, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import titleCase from 'titleize';
import { hasValue } from 'utilities';
import { formatYesOrNo } from 'utilities/format';
import {
    getDisplayFirstName,
    getMemberNames,
    isEmployedAndNotSameEmployer,
} from 'utilities/household';

const ListSummary = <T extends { entityIds?: string[]; name?: string }>({
    householdMembers,
    keyProperty,
    label,
    selectedData,
}: {
    householdMembers: Partial<ISurveyHouseholdMember>[];
    keyProperty: keyof T;
    label: ReactNode;
    selectedData: T[];
}) => (
    <div className="pb-2">
        <Row className="text-secondary-45-lighten" noGutters>
            {label}
        </Row>
        {selectedData.length > 0
            ? selectedData.map((x, index) => (
                  <React.Fragment key={`${x[keyProperty] ?? index}`}>
                      <Row>
                          <Col>{titleCase(x.name ?? '')}</Col>
                          <Col className="text-right">
                              {getMemberNames(
                                  x.entityIds,
                                  householdMembers as (IHouseholdMember | IUser)[]
                              )?.join(', ')}
                          </Col>
                      </Row>
                      {index !== selectedData.length - 1 && <hr className="my-1" />}
                  </React.Fragment>
              ))
            : 'None'}
    </div>
);

const getMemberFirstNames = (
    prop: keyof ISurveyHouseholdMember,
    members: Partial<ISurveyHouseholdMember>[]
) =>
    members
        .filter((x) => x[prop])
        .map((x) => {
            const name = getDisplayFirstName(x);
            if (
                prop === 'isEmployed' &&
                x.householdMemberTypeId === HouseholdMemberTypes.Spouse &&
                x.hasSameEmployer
            ) {
                return `${name} (same as primary)`;
            }
            return name;
        });

type ICoverageProps = ISurveyState['coverage'] & {
    editSection: ((tabIndex: number) => void) | undefined;
    householdMembers: Partial<ISurveyHouseholdMemberDto>[];
};

const Coverage = ({
    editSection,
    hasHouseholdPreExistingConditions,
    householdMembers,
    householdPreExistingConditionsNote,
    selectedDrugs,
    selectedProviders,
}: ICoverageProps) => {
    const getMemberStringForProp = useCallback(
        (prop: keyof ISurveyHouseholdMember) =>
            getMemberFirstNames(prop, householdMembers).join(', ') || 'No',
        [householdMembers]
    );
    const tobaccoString = useMemo(() => getMemberStringForProp('usesTobacco'), [
        getMemberStringForProp,
    ]);
    const membersWithEmployerName = householdMembers.filter(
        (x) => isEmployedAndNotSameEmployer(x) && hasValue(x.employerName)
    );
    return (
        <Section onEdit={editSection && (() => editSection(SurveyTabs.Coverage))} title="Coverage">
            <Column>
                <ContentWithLabel label="Tobacco">{tobaccoString}</ContentWithLabel>
                {membersWithEmployerName.length > 0 && (
                    <ContentWithLabel label="Employer Names" useCol>
                        {membersWithEmployerName.map((member) => (
                            <div key={member.householdMemberId}>
                                {getDisplayFirstName(member)} - {member.employerName}
                            </div>
                        ))}
                    </ContentWithLabel>
                )}
            </Column>
            <Column isRight>
                <ListSummary
                    householdMembers={householdMembers}
                    keyProperty="nationalProviderId"
                    label="Preferred Providers"
                    selectedData={selectedProviders}
                />
                <ListSummary
                    householdMembers={householdMembers}
                    keyProperty="rxcui"
                    label="Prescriptions"
                    selectedData={selectedDrugs}
                />
                <ContentWithLabel data-private label="Has Conditions" useCol>
                    {formatYesOrNo(hasHouseholdPreExistingConditions)}
                    {hasHouseholdPreExistingConditions && (
                        <div className="text-muted">{householdPreExistingConditionsNote}</div>
                    )}
                </ContentWithLabel>
            </Column>
        </Section>
    );
};

export default hot(module)(Coverage);
