import { Paper } from '@mui/material';
import React from 'react';
import { hot } from 'react-hot-loader';

type ITeamManagementWrapper = {
    children: JSX.Element;
    isTeamManageMentPage?: boolean;
};
const TeamManagementWrapper = ({
    children,
    isTeamManageMentPage = false,
}: ITeamManagementWrapper) =>
    isTeamManageMentPage ? (
        <Paper sx={{ padding: '0 0 16px' }}>{children}</Paper>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
export default hot(module)(TeamManagementWrapper);
