import Check from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { IValueType } from 'components/EditableAttribute';
import TextField, { ITextFieldProps } from 'components/TextField';
import useForm from 'hooks/useForm';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { BaseSchema, object } from 'yup';

export type ISaveEditableTextField<T> = (name: keyof T, value: IValueType) => Promise<unknown>;

export type IEditableTextFieldProps<T> = ITextFieldProps & {
    name: keyof T;
    save: ISaveEditableTextField<T>;
    validationSchema: BaseSchema;
    value: IValueType;
};

const EditableTextField = <T,>({
    disabled,
    InputProps,
    name,
    onBlur: onBlurParent,
    onChange: onChangeParent,
    onKeyDown: onKeyDownParent,
    save,
    SelectProps,
    validationSchema,
    value,
    ...props
}: IEditableTextFieldProps<T>) => {
    let schema = validationSchema;
    if (typeof props.label === 'string') {
        schema = schema.label(props.label);
    }
    const initialSchema = schema.type !== 'object' ? object({ [name]: schema }) : schema;
    const { errors, setErrors, validate } = useForm(initialSchema);
    const [lastValue, setLastValue] = useState(value);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const cancelEdit = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent) => {
        onChangeParent?.(({
            ...e,
            target: {
                focus: () => ({}), // This is needed because of an error we get when trying to cancel by using escape
                ...e?.target,
                value: (lastValue as string) ?? '',
            },
        } as unknown) as React.ChangeEvent<HTMLInputElement>);
    };
    const onBlur = async (
        e?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | React.SyntheticEvent,
        _value: IValueType = value
    ) => {
        if (_value === lastValue) {
            setErrors(null);
            onBlurParent?.(e as React.FocusEvent<HTMLInputElement>);
            SelectProps?.onClose?.(e as React.FocusEvent<HTMLInputElement>);
            return;
        }
        const { data, isValid } = await validate({ [name]: _value });
        if (isValid) {
            try {
                setIsLoading(true);
                await save(name, data[name]);
                setIsLoading(false);
                setIsSuccessVisible(true);
                setLastValue(data[name]);
                setTimeout(() => {
                    setIsSuccessVisible(false);
                    onBlurParent?.(e as React.FocusEvent<HTMLInputElement>);
                    SelectProps?.onClose?.(e as React.FocusEvent<HTMLInputElement>);
                }, 2500); // NOSONAR
            } catch (err) {
                cancelEdit(e as React.FocusEvent<HTMLInputElement>);
                setIsLoading(false);
            }
        }
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.select && !SelectProps?.multiple) {
            onBlur(undefined, e.target.value);
        }
        onChangeParent?.(e);
    };
    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (e) => {
        const { key } = e;
        switch (key) {
            case 'Enter':
                await onBlur();
                break;
            case 'Escape':
                cancelEdit(e);
                onBlurParent?.((e as unknown) as React.FocusEvent<HTMLInputElement>);
                SelectProps?.onClose?.(e);
                break;
        }
        onKeyDownParent?.(e);
    };
    const endAdornment =
        isLoading || isSuccessVisible ? (
            <InputAdornment position="end" sx={props.select ? [{ mr: 2 }] : {}}>
                {isLoading && <CircularProgress color="primary" size={30} />}
                {isSuccessVisible && <Check color="success" />}
            </InputAdornment>
        ) : (
            <React.Fragment />
        );
    return (
        <TextField
            autoFocus
            disabled={isLoading || isSuccessVisible || disabled}
            errors={errors?.[name]}
            InputProps={{ endAdornment, ...InputProps }}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            SelectProps={{
                ...SelectProps,
                onClose: onBlur,
            }}
            value={value}
            {...props}
        />
    );
};

export default hot(module)(EditableTextField);
