import { UserStatus } from 'api/generated/enums';
import { IUserProfile } from 'api/generated/models';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import startCase from 'lodash/startCase';
import React from 'react';
import { HeaderProps } from 'react-table';
import { getEnumNames } from 'utilities';

export const USER_STATUS_OPTIONS = [...getEnumNames(UserStatus, startCase), 'None'];
export const STATUS_PARAM = 'Member Status';
export const MEMBER_STATUS_COLUMN_ID = 'memberStatus';
export const MEMBER_STATUS_FILTER_ID = 'memberStatusFilter';
export const MemberStatusHeader = ({
    column: { id, filterValue, setFilter },
}: HeaderProps<IUserProfile>) => (
    <HeaderDropdown
        columnId={id}
        id="status-filter-dropdown"
        infoTooltip="This status describes the member's current standing with Remodel Health."
        options={USER_STATUS_OPTIONS}
        selected={filterValue}
        setSelected={setFilter}
        title={STATUS_PARAM}
    />
);
