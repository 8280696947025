import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { TeamBenefitTypes } from 'api/generated/enums';
import { IRhFile, ITeamBenefit, ITeamBenefitDto } from 'api/generated/models';
import ExternalLink from 'components/ExternalLink';
import useTeamProps from 'hooks/useTeamProps';
import kebabCase from 'lodash/kebabCase';
import TeamBenefitFilesList from 'pages/teamBenefits/TeamBenefitFilesList';
import React, { ReactNode } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { isRhSelector } from 'selectors/role';
import { hasContents, hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';

export type ITeamBenefitField = {
    isVisible: boolean;
    label: string;
    value: number | string | undefined;
};

type ITeamBenefitData = ITeamBenefit | ITeamBenefitDto | undefined;

export const nextMemberEnrollmentPeriodField = (
    teamBenefit: ITeamBenefitData
): ITeamBenefitField => ({
    isVisible:
        hasValue(teamBenefit?.nextMemberEnrollmentStartDate) &&
        hasValue(teamBenefit?.nextMemberEnrollmentEndDate),
    label: 'Next Member Enrollment Period',
    value: `${formatDateForDisplay(
        teamBenefit?.nextMemberEnrollmentStartDate
    )} - ${formatDateForDisplay(teamBenefit?.nextMemberEnrollmentEndDate)}`,
});

export const eligibilityWaitingDaysField = (teamBenefit: ITeamBenefitData): ITeamBenefitField => ({
    isVisible: hasValue(teamBenefit?.eligibilityWaitingDays),
    label: 'Eligibility Waiting Days',
    value: teamBenefit?.eligibilityWaitingDays,
});

export const groupPlanIdField = (teamBenefit: ITeamBenefitData): ITeamBenefitField => ({
    isVisible: hasValue(teamBenefit?.groupPlanId),
    label: 'Group Plan Id',
    value: teamBenefit?.groupPlanId,
});

const SpaceBetweenRow = ({ children }: { children: ReactNode }) => (
    <Row className="justify-content-space-between align-items-center">{children}</Row>
);

type ITeamBenefitCardBaseProps = {
    actions?: JSX.Element;
    endDate: string | undefined;
    fields: ITeamBenefitField[];
    membersCovered?: string;
    showRecordCreatedDate?: boolean;
    showSubmitExpenseUrl?: boolean;
    startDate: string | undefined;
    teamBenefit: ITeamBenefitData;
};

const NOT_SPECIFIED_CARRIER = 100;
const TeamBenefitCardBase = ({
    actions,
    endDate,
    fields,
    membersCovered,
    showSubmitExpenseUrl: parentShowSubmitExpenseUrl = false,
    showRecordCreatedDate,
    startDate,
    teamBenefit,
}: ITeamBenefitCardBaseProps) => {
    const { useReimbursementProgramManagement } = useTeamProps();
    const { isRh } = useSelector((state: AppStore) => ({
        isRh: isRhSelector(state),
    }));
    const isReimbursementProgram =
        teamBenefit?.teamBenefitType === TeamBenefitTypes.ReimbursementProgram;
    const showSubmitExpenseUrl =
        !isRh &&
        (!isReimbursementProgram || !useReimbursementProgramManagement) &&
        hasValue(teamBenefit?.submitExpenseUrl) &&
        parentShowSubmitExpenseUrl;
    const { teamBenefitCarrier, teamBenefitType } = teamBenefit?.teamBenefitTypesCarrier ?? {};
    const hideCarrierName =
        [TeamBenefitTypes.HealthSavingsAccount, TeamBenefitTypes.FlexibleSavingsAccount].contains(
            teamBenefitType?.id
        ) || teamBenefitCarrier?.id === NOT_SPECIFIED_CARRIER;

    return (
        <Card data-cy={kebabCase(teamBenefitType?.name)} sx={{ my: 3 }}>
            <CardContent>
                <SpaceBetweenRow>
                    <Col>
                        <Typography variant="h4">
                            {teamBenefitType?.name}
                            {!hideCarrierName && ` - ${teamBenefitCarrier?.name}`}
                        </Typography>
                        <Typography variant="h5">{teamBenefit?.planName}</Typography>
                    </Col>
                    <Col xs="auto">
                        <div>
                            {startDate && (
                                <SpaceBetweenRow>
                                    <Col className="text-right text-muted">Start Date</Col>
                                    <Col>{formatDateForDisplay(startDate)}</Col>
                                </SpaceBetweenRow>
                            )}
                            {endDate && (
                                <SpaceBetweenRow>
                                    <Col className="text-right text-muted">End Date</Col>
                                    <Col>{formatDateForDisplay(endDate)}</Col>
                                </SpaceBetweenRow>
                            )}
                        </div>
                    </Col>
                </SpaceBetweenRow>
                <Divider sx={{ mt: 2 }} />
                <Stack sx={{ py: 3 }}>
                    {fields
                        .filter((row) => row.isVisible)
                        .map((row, index) => (
                            <SpaceBetweenRow key={index}>
                                <Col className="text-right text-muted">
                                    <strong>{row.label}</strong>
                                </Col>
                                <Col className="text-left">{row.value}</Col>
                            </SpaceBetweenRow>
                        ))}
                    {hasContents(teamBenefit?.rhFiles) && (
                        <TeamBenefitFilesList
                            files={teamBenefit?.rhFiles as IRhFile[]}
                            teamBenefitId={teamBenefit?.id}
                        />
                    )}
                </Stack>
                <Divider sx={{ marginBottom: 3 }} />
                <SpaceBetweenRow>
                    <Col>
                        {hasValue(membersCovered) && (
                            <Row className="align-items-center font-weight-bold">
                                <Col className="text-right text-muted">Members Covered</Col>
                                <Col>{membersCovered}</Col>
                            </Row>
                        )}
                        {showRecordCreatedDate && (
                            <SpaceBetweenRow>
                                <Col className="text-right text-muted">Record Created</Col>
                                <Col>{formatDateForDisplay(teamBenefit?.createdDate)}</Col>
                            </SpaceBetweenRow>
                        )}
                    </Col>
                    <Col xs="auto">
                        {actions}
                        {showSubmitExpenseUrl && (
                            <ExternalLink href={teamBenefit?.submitExpenseUrl as string}>
                                Submit Expense
                            </ExternalLink>
                        )}
                    </Col>
                </SpaceBetweenRow>
            </CardContent>
        </Card>
    );
};

export default hot(module)(TeamBenefitCardBase);
