import EditableAttribute, { IEditableAttributeProps } from 'components/EditableAttribute';
import EditablePhoneTextField from 'components/EditablePhoneTextField';
import { IEditableTextFieldProps } from 'components/EditableTextField';
import React from 'react';
import { hot } from 'react-hot-loader';
import { formatPhoneNumber } from 'utilities/format';

type IEditablePhoneNumberAttribute<T> = IEditableTextFieldProps<T> &
    Omit<IEditableAttributeProps, 'children'>;

const EditablePhoneNumberAttribute = <T,>({
    emptyValueMessage,
    footer,
    formatter = formatPhoneNumber,
    hrClassName,
    infoTooltip,
    label,
    readonly,
    ...props
}: IEditablePhoneNumberAttribute<T>) => (
    <EditableAttribute
        emptyValueMessage={emptyValueMessage}
        footer={footer}
        formatter={formatter}
        hrClassName={hrClassName}
        infoTooltip={infoTooltip}
        label={label}
        readonly={readonly}
        {...props}
    >
        {({ onBlur }) => <EditablePhoneTextField {...props} onBlur={onBlur} />}
    </EditableAttribute>
);

export default hot(module)(EditablePhoneNumberAttribute);
