import { GroupLevels } from 'api/generated/enums';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import React from 'react';
import { HeaderProps } from 'react-table';
import { getEnumNames } from 'utilities';

export const COVERAGE_LEVEL_PARAM = 'Coverage Level';
export const COVERAGE_LEVEL_OPTIONS = getEnumNames(GroupLevels);
export const COVERAGE_LEVEL_COLUMN_ID = 'coverageLevel';
export const COVERAGE_LEVEL_FILTER_ID = 'coverageLevelFilter';
export const CoverageLevelHeader = ({
    column: { id, filterValue, setFilter },
    setSortBy,
    state: { sortBy },
}: HeaderProps<object>) => (
    <HeaderDropdown
        columnId={id}
        id="coverage-level-filter-dropdown"
        options={COVERAGE_LEVEL_OPTIONS}
        selected={filterValue}
        setSelected={setFilter}
        setSorted={setSortBy}
        sorted={sortBy}
        title={COVERAGE_LEVEL_PARAM}
    />
);
