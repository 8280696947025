import { hot } from 'react-hot-loader';
import styled from 'styled-components';

const ParagraphWithLineBreaks = styled.p`
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left;
`;

export default hot(module)(ParagraphWithLineBreaks);
