import FileNameViolationMessage from 'components/fileInput/FileNameViolationMessage';
import FileSizeViolationMessage from 'components/fileInput/FileSizeViolationMessage';
import FileTypeViolationMessage from 'components/fileInput/FileTypeViolationMessage';
import { FileViolation } from 'components/fileInput/FileViolation';
import React from 'react';
import { hot } from 'react-hot-loader';
const FileViolationMessage = ({
    accept,
    invalidFiles,
    maxFileNameLength,
    maxFileSizeInBytes,
    minFileSizeInBytes,
    violationType,
}: {
    accept: string | undefined;
    invalidFiles: File[];
    maxFileNameLength: number | undefined;
    maxFileSizeInBytes: number | undefined;
    minFileSizeInBytes: number | undefined;
    violationType: FileViolation;
}) => {
    switch (violationType) {
        case FileViolation.MaxNameLength:
            return (
                <FileNameViolationMessage
                    invalidFiles={invalidFiles}
                    maxFileNameLength={maxFileNameLength}
                />
            );
        case FileViolation.Type:
            return <FileTypeViolationMessage accept={accept} invalidFiles={invalidFiles} />;
        case FileViolation.MaxSize:
        case FileViolation.MinSize: {
            const isMin = violationType === FileViolation.MinSize;
            return (
                <div>
                    <FileSizeViolationMessage
                        fileSizeLimitInBytes={isMin ? minFileSizeInBytes : maxFileSizeInBytes}
                        invalidFiles={invalidFiles}
                        isMin={isMin}
                    />
                </div>
            );
        }
    }
};
export default hot(module)(FileViolationMessage);
