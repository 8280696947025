import {
    CHARLESTON_SOUTHERN_UNIVERSITY_LOGO_URL,
    CHARLESTON_SOUTHERN_UNIVERSITY_TEAM_ID,
    UNIVERSITY_OF_SAINT_FRANCIS_LOGO_URL,
    UNIVERSITY_OF_SAINT_FRANCIS_TEAM_ID,
} from 'constants/teams';
import React from 'react';

const DEFAULT_HEIGHT_IN_PIXELS = 30;
export const Logo = ({
    height,
    src: parentSrc = 'assets/images/logo-lettermark.svg',
    teamId = '',
}: {
    height?: number | string;
    src?: string;
    teamId?: string;
}) => {
    let src = parentSrc;
    if (teamId === CHARLESTON_SOUTHERN_UNIVERSITY_TEAM_ID) {
        src = CHARLESTON_SOUTHERN_UNIVERSITY_LOGO_URL;
    }
    if (teamId === UNIVERSITY_OF_SAINT_FRANCIS_TEAM_ID) {
        src = UNIVERSITY_OF_SAINT_FRANCIS_LOGO_URL;
    }
    return <img alt="" height={height ?? DEFAULT_HEIGHT_IN_PIXELS} src={src} />;
};
