import Grid, { GridProps } from '@mui/material/Grid';
import React from 'react';
import { hot } from 'react-hot-loader';

const DiagramGrid = (props: GridProps) => (
    <Grid
        alignItems="center"
        container
        direction="column"
        item
        justifyContent="center"
        paddingX={2}
        {...props}
    />
);

export default hot(module)(DiagramGrid);
