import { Card, CardContent } from '@mui/material';
import HeaderTitle from 'components/HeaderTitle';
import LaunchDetailsModal from 'components/launchDetails/LaunchDetailsModal';
import LaunchDetailsTable from 'components/launchDetails/LaunchDetailsTable';
import Tooltip from 'components/Tooltip';
import useModalState from 'hooks/useModalState';
import useTeamProps from 'hooks/useTeamProps';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import 'scss/launchDetailsCard.scss';
import { isRhSelector, isTeamAdminSelector } from 'selectors/role';
import styled from 'styled-components';
import { isCorrectStateForLaunchDetails } from 'utilities/launch';

const EditButton = styled.a.attrs(() => ({
    className: 'dripicons-document-edit',
    'data-cy': 'edit-launch-details',
}))`
    font-size: 20px;
`;

const LaunchDetailsCard = () => {
    const { teamId, teamStateId } = useTeamProps();
    const { canEdit, launchDetails, shouldRenderLaunchDetails } = useSelector((state: AppStore) => {
        const props = {
            canEdit: isRhSelector(state),
            launchDetails: state.launchDetails,
            shouldRenderLaunchDetails: false,
        };
        const isRh = isRhSelector(state);
        const isTeamAdmin = isTeamAdminSelector(state);
        const isCorrectLaunchState = isCorrectStateForLaunchDetails(teamStateId);
        const hasLaunchDetails = !isEmpty(props.launchDetails);
        props.shouldRenderLaunchDetails =
            isCorrectLaunchState && (isTeamAdmin || isRh) && hasLaunchDetails;
        return props;
    });
    const {
        closeModal: closeLaunchDetailsModal,
        isVisible: isLaunchDetailsVisible,
        openModal: openLaunchDetailsModal,
    } = useModalState();
    return (
        <React.Fragment>
            {shouldRenderLaunchDetails && (
                <div data-cy="launch-details-card">
                    {isLaunchDetailsVisible && (
                        <LaunchDetailsModal
                            handleSubmit={closeLaunchDetailsModal}
                            isEditMode
                            onNoClick={closeLaunchDetailsModal}
                            teamId={teamId}
                        />
                    )}
                    <Card className="tilebox-one">
                        <CardContent>
                            <div className="d-flex">
                                <HeaderTitle mr={1}>Launch Details</HeaderTitle>

                                {canEdit && (
                                    <Tooltip title="Click to edit launch details">
                                        <EditButton onClick={openLaunchDetailsModal} />
                                    </Tooltip>
                                )}
                            </div>
                            <hr />
                            <LaunchDetailsTable canEdit={canEdit} launchDetails={launchDetails} />
                        </CardContent>
                    </Card>
                </div>
            )}
        </React.Fragment>
    );
};

export default hot(module)(LaunchDetailsCard);
