import { addUserRhFiles } from 'actions/rhFile/addUserRhFiles';
import { deleteUserRhFile } from 'actions/rhFile/deleteUserRhFile';
import { renameUserRhFile } from 'actions/rhFile/renameUserRhFile';
import { toggleUserRhFileArchived } from 'actions/rhFile/toggleUserRhFileArchived';
import { toggleUserRhFileShared } from 'actions/rhFile/toggleUserRhFileShared';
import { downloadUserFile } from 'api/downloadUserFile';
import { IRhFile } from 'api/generated/models';
import {
    ArchiveUserRhFile,
    CreateUserRhFile,
    DeleteUserRhFile,
    DownloadUserRhFile,
    EditUserRhFile,
    ShareUserRhFiles,
} from 'api/generated/permissions';
import { AddFilesActionThunk } from 'components/FileUploadModal';
import { IRhFileItems } from 'components/rhFile/RhFile';
import RhFiles from 'components/rhFile/RhFiles';
import UserNameEntityHeader from 'components/UserNameEntityHeader';
import useUserProps from 'hooks/useUserProps';
import React, { useCallback, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';

type IUserRhFilesProps = {
    RhFileContainer?: React.ElementType;
    addRhFiles?: AddFilesActionThunk;
    canCreateRhFiles?: boolean;
    data: IRhFile[] | undefined;
    disableFullScreenDropZone?: boolean;
    hideHeader?: boolean;
    isCentered?: boolean;
    items?: IRhFileItems;
};
const UserRhFiles = ({
    addRhFiles: parentAddRhFiles,
    canCreateRhFiles = true,
    data,
    disableFullScreenDropZone,
    RhFileContainer,
    hideHeader,
    isCentered,
    items,
}: IUserRhFilesProps) => {
    const { isCurrent, userId } = useUserProps();
    const { enrollmentVerifications, permissions } = useSelector((state: AppStore) => ({
        enrollmentVerifications: state.enrollmentVerifications,
        permissions: {
            canArchiveRhFiles: hasSomePermissions(state, ArchiveUserRhFile),
            canCreateRhFiles:
                (hasSomePermissions(state, CreateUserRhFile) || isCurrent) && canCreateRhFiles,
            canDeleteRhFiles: hasSomePermissions(state, DeleteUserRhFile),
            canDownloadRhFiles: hasSomePermissions(state, DownloadUserRhFile) || isCurrent,
            canEditRhFiles: hasSomePermissions(state, EditUserRhFile) || isCurrent,
            canShareRhFiles: hasSomePermissions(state, ShareUserRhFiles),
        },
    }));

    const filesIdsWithEnrollmentVerification = useMemo(
        () =>
            enrollmentVerifications.reduce<string[]>(
                (acc, value) => acc.concat(value.rhFiles?.map((file) => file.id) ?? []),
                []
            ),
        [enrollmentVerifications]
    );

    const getIsUnSharableAndText = useCallback(
        (createdByUser, fileId) => {
            let isUnSharable = false;
            let sharedTooltipText = 'This file is shared with this user.';
            if (createdByUser.userId === userId) {
                isUnSharable = true;
                sharedTooltipText =
                    'This file was uploaded by this user and therefore cannot be unshared.';
            } else if (filesIdsWithEnrollmentVerification.includes(fileId)) {
                isUnSharable = true;
                sharedTooltipText =
                    'This file is associated with a verification document and therefore cannot be unshared.';
            }
            return { isUnSharable, sharedTooltipText };
        },
        [filesIdsWithEnrollmentVerification, userId]
    );

    const addRhFiles: AddFilesActionThunk = useMemo(
        () =>
            parentAddRhFiles ??
            ((files, toastSuccessMessage) => addUserRhFiles(userId, files, toastSuccessMessage)),
        [parentAddRhFiles, userId]
    );

    return (
        <RhFiles
            addRhFiles={addRhFiles}
            data={data}
            deleteRhFile={deleteUserRhFile}
            disableFullScreenDropZone={disableFullScreenDropZone}
            downloadRhFile={async ({ id }) => downloadUserFile(userId, id)}
            getIsUnSharableAndText={getIsUnSharableAndText}
            hideHeader={hideHeader}
            isCentered={isCentered}
            items={items}
            labelWrapper={(label) => (
                <Col className="text-left">
                    <UserNameEntityHeader entity="Files" />
                    <span>{label}</span>
                </Col>
            )}
            permissions={permissions}
            renameRhFile={(renamedRhFile) => renameUserRhFile(userId, renamedRhFile)}
            RhFileContainer={RhFileContainer}
            toggleRhFileArchived={toggleUserRhFileArchived}
            toggleRhFileShared={({ id }) => toggleUserRhFileShared(userId, id)}
        />
    );
};

export default hot(module)(UserRhFiles);
