import Button from 'components/Button';
import { showNewVersionModal } from 'components/newVersion/newVersionActions';
import DesktopContext from 'contexts/DesktopContext';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { ReactNode, useCallback, useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';

const UnauthenticatedPageWrapper = ({ children }: { children: ReactNode }) => {
    const dispatch = useThunkDispatch();
    const { willUpdateLater } = useSelector((state: AppStore) => ({
        willUpdateLater: state.newVersionState.willUpdateLater,
    }));
    const isDesktop = useContext(DesktopContext);
    const showModal = useCallback(() => dispatch(showNewVersionModal()), [dispatch]);
    return (
        <div className="wrapper">
            {willUpdateLater && (
                <Button
                    ButtonClassName="animated flash"
                    onClick={showModal}
                    style={{
                        left: '10px',
                        position: 'sticky',
                        top: '10px',
                        width: isDesktop ? '140px' : '50px',
                        zIndex: 1,
                    }}
                >
                    <i className="dripicons-clockwise" />
                    {isDesktop ? ' Update' : ''}
                </Button>
            )}
            {children}
        </div>
    );
};

export default hot(module)(UnauthenticatedPageWrapper);
