import { AppStoreThunkDispatch } from 'actions/commonAction';
import { RENAME_TEAM_BENEFIT_RH_FILE_ACTION } from 'actions/rhFile/renameTeamBenefitRhFile';
import { RENAME_TEAM_RH_FILES_ACTION } from 'actions/rhFile/renameTeamRhFile';
import { RENAME_USER_RH_FILES_ACTION } from 'actions/rhFile/renameUserRhFile';
import { IRhFile, RhFile } from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import TextField from 'components/TextField';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

const MAX_FILE_NAME_LENGTH = 500;
const schema = object({
    name: string()
        .trim()
        .required()
        .max(MAX_FILE_NAME_LENGTH)
        .label('Name'),
});

type IRenameRhFileModal = {
    onClose: () => void;
    renameRhFile: (
        rhFile: RhFile
    ) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;
    rhFile: IRhFile;
};

const RenameRhFileModal = ({ rhFile, onClose, renameRhFile }: IRenameRhFileModal) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            RENAME_TEAM_RH_FILES_ACTION,
            RENAME_USER_RH_FILES_ACTION,
            RENAME_TEAM_BENEFIT_RH_FILE_ACTION
        ),
    }));
    const [name, setName] = useState(rhFile.name ?? '');
    const { errors, validate } = useForm(schema);

    const onSubmit = useCallback(async () => {
        const { isValid } = await validate({ name });
        if (isValid) {
            await dispatch(renameRhFile(new RhFile({ ...rhFile, name })));
            onClose();
        }
    }, [validate, name, dispatch, renameRhFile, rhFile, onClose]);

    return (
        <Modal onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Rename File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="rename-rh-file-modal" isLoading={isLoading} onSubmit={onSubmit}>
                    <TextField
                        autoFocus
                        data-cy="name"
                        errors={errors?.name}
                        label="Name"
                        name="name"
                        onChange={onChange(setName)}
                        placeholder="Enter a name"
                        value={name}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Close</Button>
                <Button form="rename-rh-file-modal" isLoading={isLoading} type="submit">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(RenameRhFileModal);
