import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { hot } from 'react-hot-loader';

const MedicaidChip = ({ sx, ...props }: ChipProps) => (
    <Chip
        color="secondary"
        key="medicaid"
        label="Medicaid"
        size="small"
        sx={{ ...sx }}
        {...props}
    />
);

export default hot(module)(MedicaidChip);
