import { Stack } from '@mui/material';
import {
    EDIT_USER_TEAM_BENEFIT_TERM_DETAILS_ACTION,
    editUserTeamBenefitTermDetails,
} from 'actions/userTeamBenefitTermDetail/editUserTeamBenefitTermDetails';
import { pageUserTermDetails } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import { UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import { ITeamBenefitTermDetail, ITeamBenefitTermDetailDto } from 'api/generated/models';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import Select from 'components/Select';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { startCase } from 'lodash';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { enumToNameValueArray, hasValue, stringToInt } from 'utilities';
import { onChange } from 'utilities/forms';
import { boolean, number, object, string } from 'yup';
import { formatDateForDisplay } from '../../utilities/format';

const schema = object({
    allHouseholdMembersCovered: boolean().label('All Household Members Covered'),
    coverageEndDate: string()
        .isValidDate(false)
        .isSameOrBefore('termEnd')
        .isSameOrAfter('termStart')
        .isAfter('coverageStartDate'),
    coverageStartDate: string()
        .isValidDate(false)
        .isSameOrBefore('termEnd')
        .isSameOrAfter('termStart')
        .isBefore('coverageEndDate'),
    status: number()
        .transform(stringToInt)
        .label('Status'),
}).shape(
    {
        coverageEndDate: string().when(['coverageStartDate'], {
            is: (coverageStartDate: string) => hasValue(coverageStartDate),
            then: (_schema) =>
                _schema.required('Coverage End Date is required when Coverage Start Date is set'),
        }),
        coverageStartDate: string().when(['coverageEndDate'], {
            is: (coverageEndDate: string) => hasValue(coverageEndDate),
            then: (_schema) =>
                _schema.required('Coverage Start Date is required when Coverage End Date is set'),
        }),
    },
    [['coverageEndDate', 'coverageStartDate']]
);

type ITeamBenefitEditUsersModalProps = {
    onClose: () => void;
    termDetails: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto;
    userIds: string[] | undefined;
};

const USER_TEAM_BENEFIT_TERM_DETAIL_STATUS_ITEMS = enumToNameValueArray(
    UserTeamBenefitTermDetailStatuses,
    {
        formatName: startCase,
    }
);

const TeamBenefitEditUsersModal = ({
    onClose,
    userIds,
    termDetails,
}: ITeamBenefitEditUsersModalProps) => {
    const dispatch = useThunkDispatch();
    const { isLoading, paginationParams } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, EDIT_USER_TEAM_BENEFIT_TERM_DETAILS_ACTION),
        paginationParams: state.manageMembersState,
    }));
    const [coverageStartDate, setCoverageStartDate] = useState<string>('');
    const [coverageEndDate, setCoverageEndDate] = useState<string>('');
    const [allHouseholdMembersCovered, setAllHouseholdMembersCovered] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('');

    const { errors, validate } = useForm(schema);
    const { teamId } = useTeamProps();

    const save = async () => {
        const { data, isValid } = await validate(
            {
                allHouseholdMembersCovered,
                coverageEndDate,
                coverageStartDate,
                status: (status as unknown) as number,
                userIds: userIds as string[],
            },
            {
                context: {
                    termEnd: formatDateForDisplay(termDetails.endDate),
                    termStart: formatDateForDisplay(termDetails.startDate),
                },
            }
        );
        if (isValid) {
            await dispatch(editUserTeamBenefitTermDetails(termDetails.id, { ...data }));
            await dispatch(pageUserTermDetails(termDetails.id, teamId, paginationParams));
            onClose();
        }
    };

    const isAllHouseholdMembersCoveredDisabled =
        status === UserTeamBenefitTermDetailStatuses.Unknown.toString();

    const areDateFieldsDisabled = [
        UserTeamBenefitTermDetailStatuses.Unknown.toString(),
        UserTeamBenefitTermDetailStatuses.Offered.toString(),
    ].contains(status);

    const onStatusChange = onChange((value: string) => {
        setStatus(value);
        if (value === UserTeamBenefitTermDetailStatuses.Unknown.toString()) {
            setAllHouseholdMembersCovered(false);
            setCoverageStartDate('');
            setCoverageEndDate('');
        }
    });

    return (
        <Modal onHide={onClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Users for Team Benefit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    id="team-benefits-edit-users-modal-form"
                    isLoading={isLoading}
                    onSubmit={save}
                >
                    <Stack spacing={2}>
                        <Select
                            data-cy="status-dropdown"
                            defaultText="Choose a Status"
                            defaultValue=""
                            id="status-dropdown"
                            isOptional
                            items={USER_TEAM_BENEFIT_TERM_DETAIL_STATUS_ITEMS}
                            label="Status"
                            name="status"
                            onChange={onStatusChange}
                            optionText="name"
                            optionValue="value"
                            value={status}
                        />
                        <Checkbox
                            checked={allHouseholdMembersCovered}
                            data-cy="household-member-checkbox"
                            disabled={isAllHouseholdMembersCoveredDisabled}
                            label="All Household Members Covered?"
                            onChange={({ target: { checked } }) =>
                                setAllHouseholdMembersCovered(checked)
                            }
                        />
                        <DateTextField
                            data-cy="coverage-start-date"
                            disabled={areDateFieldsDisabled}
                            errors={errors?.['coverageStartDate']}
                            isOptional
                            label="Coverage Start Date"
                            name="coverageStartDate"
                            onChange={onChange(setCoverageStartDate)}
                            value={coverageStartDate}
                        />
                        <DateTextField
                            data-cy="coverage-end-date"
                            disabled={areDateFieldsDisabled}
                            errors={errors?.['coverageEndDate']}
                            isOptional
                            label="Coverage End Date"
                            name="coverageEndDate"
                            onChange={onChange(setCoverageEndDate)}
                            value={coverageEndDate}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    form="team-benefits-edit-users-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(TeamBenefitEditUsersModal);
