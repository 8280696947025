import Stack from '@mui/material/Stack';
import { ADD_TEAM_BENEFIT_ACTION, addTeamBenefit } from 'actions/teamBenefit/addTeamBenefit';
import { EDIT_TEAM_BENEFIT_ACTION, editTeamBenefit } from 'actions/teamBenefit/editTeamBenefit';
import {
    GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION,
    getTeamBenefitCarriersByType,
} from 'actions/teamBenefitType/getTeamBenefitCarriersByType';
import {
    GET_TEAM_BENEFIT_TYPES_ACTION,
    getTeamBenefitTypes,
} from 'actions/teamBenefitType/getTeamBenefitTypes';
import { OfferLevels, RhManagementTypes, TeamBenefitTypes } from 'api/generated/enums';
import { ITeamBenefit, ITeamBenefitDto } from 'api/generated/models';
import { EditTeamBenefit, ViewTeamBenefit } from 'api/generated/permissions';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import NumberTextField from 'components/NumberTextField';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { ALLOWED_ANCILLARY_SELECTION_TYPE_VALUES } from 'constants/ancillarySelection';
import useForm from 'hooks/useForm';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { yesOrNo } from 'reducers/options';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { enumToNameValueArray, hasValue, isTrue, stringToInt } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { boolean, number, object, string } from 'yup';

const MAX_PLAN_NAME_LENGTH = 200;
const MAX_GROUP_PLAN_ID_LENGTH = 50;
const MAX_ELIGIBILITY_WAITING_DAYS = 999;

const getTitle = (isEdit: boolean, canViewButNotEdit: boolean) => {
    let title = isEdit ? 'Edit Team Benefit' : 'Add Team Benefit';
    if (canViewButNotEdit) {
        title = 'Team Benefit Details';
    }

    return title;
};

const schema = (useForAncillarySelection: boolean) =>
    object({
        eligibilityWaitingDays: number()
            .transform(stringToInt)
            .min(0)
            .max(MAX_ELIGIBILITY_WAITING_DAYS)
            .label('Eligibility Waiting Days'),
        endDate: string()
            .isValidDate(false)
            .isAfter('startDate')
            .isAfter('nextRenewalDate'),
        groupPlanId: string()
            .max(MAX_GROUP_PLAN_ID_LENGTH)
            .label('Group Plan Id'),
        nextMemberEnrollmentEndDate: string()
            .isValidDate(false)
            .isAfter('nextMemberEnrollmentStartDate'),
        nextMemberEnrollmentStartDate: string()
            .isValidDate(false)
            .isBefore('nextMemberEnrollmentEndDate'),
        nextRenewalDate: string()
            .isValidDate(false)
            .isAfter('startDate')
            .isBefore('endDate'),
        offerLevel: number()
            .isRequiredWhen(useForAncillarySelection)
            .label('Offer Level'),
        planName: string()
            .max(MAX_PLAN_NAME_LENGTH)
            .label('Plan Name'),
        rhManagementDate: string().isValidDate(false),
        startDate: string()
            .isValidDate(false)
            .isBefore('nextRenewalDate')
            .isBefore('endDate'),
        submitExpenseUrl: string()
            .url()
            .label('Submit Expense URL'),
        teamBenefitTypeId: string()
            .required()
            .label('Team Benefit Type'),
        teamBenefitTypesCarrierId: number()
            .transform(stringToInt)
            .required()
            .label('Team Benefit Carrier'),
        useForAncillarySelection: boolean()
            .required()
            .label('Use for Ancillary Benefit Selection in Platform'),
        useForReimbursementOverlay: boolean()
            .required()
            .label('Use for Reimbursement Overlay'),
    });

type ITeamBenefitModalProps = {
    onClose: () => void;
    teamBenefit?: ITeamBenefit | ITeamBenefitDto;
    teamId: string;
};

const RH_MANAGEMENT_TYPE_ITEMS = enumToNameValueArray(RhManagementTypes, {
    formatName: startCase,
});
const offerLevels = [
    { name: 'Employee Only', value: Number(OfferLevels.EmployeeOnly) },
    { name: 'Employee + Spouse', value: Number(OfferLevels.EmployeeSpouse) },
    { name: 'Employee + Spouse + Children', value: Number(OfferLevels.EmployeeSpouseChildren) },
];

const TeamBenefitModal = ({ onClose, teamBenefit, teamId }: ITeamBenefitModalProps) => {
    const dispatch = useThunkDispatch();
    const isEdit = !!teamBenefit;
    const {
        canEditTeamBenefits,
        canViewTeamBenefits,
        isLoading,
        isRh,
        teamBenefitTypeCarriers,
        teamBenefitTypes,
    } = useSelector((state: AppStore) => ({
        canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
        canViewTeamBenefits: hasSomePermissions(state, ViewTeamBenefit),
        isLoading: hasApiActivity(
            state,
            GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION,
            GET_TEAM_BENEFIT_TYPES_ACTION,
            ADD_TEAM_BENEFIT_ACTION,
            EDIT_TEAM_BENEFIT_ACTION
        ),
        isRh: isRhSelector(state),
        teamBenefitTypeCarriers: state.teamBenefitTypeCarriers,
        teamBenefitTypes: state.teamBenefitTypes,
    }));
    const canViewButNotEdit = canViewTeamBenefits && !canEditTeamBenefits;

    const [teamBenefitTypeId, setTeamBenefitTypeId] = useState<string | undefined>(
        teamBenefit?.teamBenefitTypesCarrier?.teamBenefitTypeId?.toString()
    );
    const teamBenefitType = Number(teamBenefitTypeId);
    const [teamBenefitTypesCarrierId, setTeamBenefitTypesCarrierId] = useState<number | string>(
        teamBenefit?.teamBenefitTypesCarrier?.id || ''
    );
    const [planName, setPlanName] = useState<string>(teamBenefit?.planName || '');
    const [groupPlanId, setGroupPlanId] = useState<string>(teamBenefit?.groupPlanId || '');
    const [startDate, setStartDate] = useState<string>(
        formatDateForDisplay(teamBenefit?.startDate) ?? ''
    );
    const [nextRenewalDate, setNextRenewalDate] = useState<string>(
        formatDateForDisplay(teamBenefit?.nextRenewableDate) ?? ''
    );
    const [endDate, setEndDate] = useState<string>(
        formatDateForDisplay(teamBenefit?.endDate) ?? ''
    );
    const [nextMemberEnrollmentStartDate, setNextMemberEnrollmentStartDate] = useState<string>(
        formatDateForDisplay(teamBenefit?.nextMemberEnrollmentStartDate) ?? ''
    );
    const [nextMemberEnrollmentEndDate, setNextMemberEnrollmentEndDate] = useState<string>(
        formatDateForDisplay(teamBenefit?.nextMemberEnrollmentEndDate) ?? ''
    );
    const [rhManagementDate, setRHManagementDate] = useState<string>(
        formatDateForDisplay(teamBenefit?.rhManagementDate) ?? ''
    );
    const [eligibilityWaitingDays, setEligibilityWaitingDays] = useState<number | undefined>(
        teamBenefit?.eligibilityWaitingDays
    );
    const [rhManagementTypeId, setRhManagementTypeId] = useState<RhManagementTypes | undefined>(
        teamBenefit?.rhManagementTypeId
    );
    const [rhFee, setRhFee] = useState<number | undefined>(teamBenefit?.rhFee);
    const [planMinimumCost, setPlanMinimumCost] = useState<number | undefined>(
        teamBenefit?.planMinimumCost
    );
    const [submitExpenseUrl, setSubmitExpenseUrl] = useState<string>(
        teamBenefit?.submitExpenseUrl ?? ''
    );

    const [utilization, setUtilization] = useState<number | undefined>(teamBenefit?.utilization);
    const [useForReimbursementOverlay, setUseForReimbursementOverlay] = useState<
        boolean | undefined
    >(teamBenefit?.useForReimbursementOverlay ?? false);
    const [useForAncillarySelection, setUseForAncillarySelection] = useState<boolean | undefined>(
        teamBenefit?.useForAncillarySelection ?? false
    );
    const [offerLevel, setOfferLevel] = useState(teamBenefit?.offerLevel);

    const { errors, validate } = useForm(schema(useForAncillarySelection ?? false));

    useEffect(() => {
        dispatch(getTeamBenefitTypes());
    }, [dispatch]);

    useEffect(() => {
        if (hasValue(teamBenefitTypeId)) {
            dispatch(getTeamBenefitCarriersByType(teamBenefitType));
        }
    }, [dispatch, teamBenefitTypeId, teamBenefitType]);

    const showPlanMinimumCost = [
        TeamBenefitTypes.PreventativeMinimumEssentialCoverage,
        TeamBenefitTypes.HealthReimbursementArrangement,
        TeamBenefitTypes.ReimbursementProgram,
    ].includes(teamBenefitType);

    const showRhFeeInput = [
        TeamBenefitTypes.ComplianceMinimumEssentialCoverage,
        TeamBenefitTypes.HealthReimbursementArrangement,
        TeamBenefitTypes.HealthSavingsAccount,
        TeamBenefitTypes.FlexibleSavingsAccount,
        TeamBenefitTypes.ReimbursementProgram,
    ].includes(teamBenefitType);

    const save = async () => {
        const { data, isValid } = await validate({
            eligibilityWaitingDays,
            endDate,
            groupPlanId,
            nextMemberEnrollmentEndDate,
            nextMemberEnrollmentStartDate,
            nextRenewalDate,
            offerLevel,
            planMinimumCost,
            planName,
            rhFee,
            rhManagementDate,
            rhManagementTypeId,
            startDate,
            submitExpenseUrl,
            teamBenefitTypeId,
            teamId,
            useForAncillarySelection,
            useForReimbursementOverlay,
            utilization,
            nextRenewableDate: nextRenewalDate,
            teamBenefitTypesCarrierId: (teamBenefitTypesCarrierId as unknown) as number,
        });
        if (isValid) {
            if (teamBenefit) {
                await dispatch(editTeamBenefit({ ...data, id: teamBenefit.id } as ITeamBenefit));
            } else {
                await dispatch(addTeamBenefit((data as unknown) as ITeamBenefit));
            }
            onClose();
        }
    };

    const onBenefitTypeChange = useCallback(
        (setValue) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setValue(value);
            setTeamBenefitTypesCarrierId('');
            setUseForReimbursementOverlay(false);
            setUseForAncillarySelection(false);
            setOfferLevel(undefined);
        },
        []
    );

    const onBenefitTypeCarrierChange = useCallback(
        (setValue) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (teamBenefitType === TeamBenefitTypes.HealthReimbursementArrangement) {
                const defaultLinkText = teamBenefitTypeCarriers.find((x) => x.id === Number(value))
                    ?.teamBenefitCarrier?.submitExpenseUrlDefault;
                setSubmitExpenseUrl(defaultLinkText ?? '');
            }
            setValue(value);
        },
        [teamBenefitTypeCarriers, teamBenefitType]
    );
    const onUseForAncillarySelectionChange = useCallback(
        (setValue) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setValue(isTrue(value));
            setOfferLevel(isTrue(value) ? OfferLevels.EmployeeOnly : undefined);
        },
        []
    );

    const title = getTitle(isEdit, canViewButNotEdit);
    const isHra = teamBenefitType === TeamBenefitTypes.HealthReimbursementArrangement;
    const isHsa = teamBenefitType === TeamBenefitTypes.HealthSavingsAccount;
    const isReimbursementProgram = teamBenefitType === TeamBenefitTypes.ReimbursementProgram;
    const showReimbursementSpecificFields = isHra || isReimbursementProgram;
    const showUseForReimbursementOverlay = isRh && (isReimbursementProgram || isHsa || isHra);
    const showUseForAncillarySelection =
        canEditTeamBenefits && ALLOWED_ANCILLARY_SELECTION_TYPE_VALUES.includes(teamBenefitType);

    let expenseUrlPlaceholder = 'Enter a URL where members can submit HRA expenses';
    let utilizationHelperText =
        'This is an estimate of how much should be set aside for employee HRA utilization per month.';
    let utilizationLabel = 'Monthly HRA Utilization Estimate';
    let utilizationPlaceholder = 'Enter an estimate for the Monthly HRA Utilization amount';
    const utilizationValue = utilization;

    if (isReimbursementProgram) {
        expenseUrlPlaceholder = 'Enter a URL where members can submit reimbursable expenses';
        utilizationHelperText =
            'This is an estimate of how much should be set aside for employee reimbursement program utilization each month';
        utilizationLabel = 'Monthly Utilization Estimate';
        utilizationPlaceholder = 'Enter an estimate for the Monthly Utilization amount';
    }
    const useForReimbursementOverlayTooltip = isHsa
        ? "Members will see their employer's annualized HSA Contribution as part of a visualization of how the their reimbursement program interacts with the major medical plan they are looking at, either in pathway benefit selection, renewal, or shopping."
        : 'Members will see a visualization of how the details of this reimbursement program interact with the major medical plan they are looking at, either in pathway benefit selection, renewal, or shopping.';

    return (
        <Modal onHide={onClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="team-benefits-modal-form" isLoading={isLoading} onSubmit={save}>
                    <Stack spacing={2}>
                        <Select
                            data-cy="team-benefit-type"
                            defaultText="Choose a Type"
                            defaultValue=""
                            disabled={isEdit}
                            errors={errors?.teamBenefitTypeId}
                            items={teamBenefitTypes}
                            label="Team Benefit Type"
                            name="teamBenefitTypeId"
                            onChange={onBenefitTypeChange(setTeamBenefitTypeId)}
                            optionText="name"
                            optionValue="id"
                            value={teamBenefitTypeId}
                        />
                        <Select
                            data-cy="team-benefit-carrier"
                            defaultText="Choose a Carrier"
                            defaultValue=""
                            disabled={canViewButNotEdit || !hasValue(teamBenefitTypeId)}
                            errors={errors?.teamBenefitTypesCarrierId}
                            items={teamBenefitTypeCarriers}
                            label="Team Benefit Carrier"
                            name="teamBenefitTypesCarrierId"
                            onChange={onBenefitTypeCarrierChange(setTeamBenefitTypesCarrierId)}
                            optionText={(typeCarrier) =>
                                typeCarrier?.teamBenefitCarrier?.name ?? 'Unknown'
                            }
                            optionValue="id"
                            value={teamBenefitTypesCarrierId}
                        />

                        <TextField
                            data-cy="plan-name"
                            disabled={canViewButNotEdit}
                            errors={errors?.planName}
                            isOptional
                            label="Plan Name"
                            onChange={onChange(setPlanName)}
                            placeholder="Enter a plan name"
                            value={planName}
                        />
                        <TextField
                            data-cy="group-plan-id"
                            disabled={canViewButNotEdit}
                            errors={errors?.groupPlanId}
                            isOptional
                            label="Group Plan Id"
                            onChange={onChange(setGroupPlanId)}
                            placeholder="Enter a group plan id"
                            value={groupPlanId}
                        />
                        {showReimbursementSpecificFields && (
                            <React.Fragment>
                                <TextField
                                    errors={errors?.submitExpenseUrl}
                                    inputProps={{ maxLength: 500 }}
                                    isOptional
                                    label="Submit Expense URL"
                                    onChange={onChange(setSubmitExpenseUrl)}
                                    placeholder={expenseUrlPlaceholder}
                                    value={submitExpenseUrl}
                                />
                                <CurrencyTextField
                                    decimalScale={2}
                                    disabled={canViewButNotEdit}
                                    helperText={utilizationHelperText}
                                    isOptional
                                    label={utilizationLabel}
                                    name="utilization"
                                    onChange={onChange(setUtilization)}
                                    placeholder={utilizationPlaceholder}
                                    value={utilizationValue}
                                />
                            </React.Fragment>
                        )}
                        {showUseForReimbursementOverlay && (
                            <Select
                                data-cy="benefit-use-for-reimbursement-overlay"
                                errors={errors?.useForReimbursementOverlay}
                                helperText={useForReimbursementOverlayTooltip}
                                items={yesOrNo}
                                label="Use for Reimbursement Overlay"
                                name="useForReimbursementOverlay"
                                onChange={onChange(setUseForReimbursementOverlay)}
                                optionText="text"
                                optionValue="value"
                                value={useForReimbursementOverlay}
                            />
                        )}
                        {showUseForAncillarySelection && (
                            <React.Fragment>
                                <Select
                                    data-cy="benefit-use-for-ancillary-selection"
                                    errors={errors?.useForAncillarySelection}
                                    helperText="Setting this to 'Yes' will give members the option to select or waive this benefit through the platform enrollment flow."
                                    items={yesOrNo}
                                    label="Use for Ancillary Benefit Selection in Platform"
                                    name="useForAncillarySelection"
                                    onChange={onUseForAncillarySelectionChange(
                                        setUseForAncillarySelection
                                    )}
                                    optionText="text"
                                    optionValue="value"
                                    value={useForAncillarySelection}
                                />
                                {useForAncillarySelection && (
                                    <Select
                                        data-cy="benefit-offer-level"
                                        errors={errors?.offerLevel}
                                        helperText="This field determines which household member checkboxes show up in the ancillary selection form"
                                        items={offerLevels}
                                        label="Offer Level"
                                        name="offerLevel"
                                        onChange={onChange(setOfferLevel)}
                                        optionText="name"
                                        optionValue="value"
                                        value={offerLevel}
                                    />
                                )}
                            </React.Fragment>
                        )}
                        {showPlanMinimumCost && (
                            <CurrencyTextField
                                disabled={canViewButNotEdit}
                                isOptional
                                label="Plan Minimum Cost"
                                name="planMinimumCost"
                                onChange={onChange(setPlanMinimumCost)}
                                placeholder="Enter plan minimum cost"
                                value={planMinimumCost}
                            />
                        )}

                        <DateTextField
                            data-cy="benefit-original-start-date"
                            disabled={canViewButNotEdit}
                            errors={errors?.startDate}
                            helperText="This is the date that your organization has started offering this benefit to its employees"
                            isOptional
                            label="Benefit Original Start Date"
                            name="startDate"
                            onChange={onChange(setStartDate)}
                            value={startDate}
                        />
                        <DateTextField
                            data-cy="benefit-end-date"
                            disabled={canViewButNotEdit}
                            errors={errors?.endDate}
                            isOptional
                            label="Benefit End Date"
                            name="endDate"
                            onChange={onChange(setEndDate)}
                            value={endDate}
                        />
                        <DateTextField
                            data-cy="next-team-benefit-renewal-date"
                            disabled={canViewButNotEdit}
                            errors={errors?.nextRenewalDate}
                            isOptional
                            label="Next Team Benefit Renewal Date"
                            name="nextRenewalDate"
                            onChange={onChange(setNextRenewalDate)}
                            value={nextRenewalDate}
                        />
                        <DateTextField
                            data-cy="next-member-enrollment-start-date"
                            disabled={canViewButNotEdit}
                            errors={errors?.nextMemberEnrollmentStartDate}
                            isOptional
                            label="Next Member Enrollment Start Date"
                            name="nextMemberEnrollmentStartDate"
                            onChange={onChange(setNextMemberEnrollmentStartDate)}
                            value={nextMemberEnrollmentStartDate}
                        />
                        <DateTextField
                            data-cy="next-member-enrollment-end-date"
                            disabled={canViewButNotEdit}
                            errors={errors?.nextMemberEnrollmentEndDate}
                            isOptional
                            label="Next Member Enrollment End Date"
                            name="nextMemberEnrollmentEndDate"
                            onChange={onChange(setNextMemberEnrollmentEndDate)}
                            value={nextMemberEnrollmentEndDate}
                        />
                        <DateTextField
                            data-cy="rh-management-date"
                            disabled={canViewButNotEdit}
                            errors={errors?.rhManagementDate}
                            isOptional
                            label="RH Management Date"
                            name="rhManagementDate"
                            onChange={onChange(setRHManagementDate)}
                            value={rhManagementDate}
                        />
                        <NumberTextField
                            data-cy="eligibility-waiting-days"
                            disabled={canViewButNotEdit}
                            errors={errors?.eligibilityWaitingDays}
                            isOptional
                            label="Eligibility Waiting Days"
                            name="eligibilityWaitingDays"
                            onChange={onChange(setEligibilityWaitingDays)}
                            placeholder="Enter eligibility waiting days"
                            value={eligibilityWaitingDays}
                        />
                        <Select
                            data-cy="rh-management-type"
                            defaultText="Choose a Management Type"
                            defaultValue=""
                            disabled={canViewButNotEdit}
                            isOptional
                            items={RH_MANAGEMENT_TYPE_ITEMS}
                            label="RH Management Type"
                            onChange={onChange(setRhManagementTypeId)}
                            optionText="name"
                            optionValue="value"
                            value={rhManagementTypeId}
                        />
                        {showRhFeeInput && (
                            <CurrencyTextField
                                disabled={canViewButNotEdit}
                                isOptional
                                label="RH Fee"
                                name="rhFee"
                                onChange={onChange(setRhFee)}
                                placeholder="Enter RH fee"
                                value={rhFee}
                            />
                        )}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={canViewButNotEdit}
                    form="team-benefits-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(TeamBenefitModal);
