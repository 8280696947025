import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import {
    ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION,
    addOrEditTeamBenefitTermDetail,
} from 'actions/teamBenefit/addOrEditTeamBenefitTermDetail';
import { ADD_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/addTeamBenefit';
import { EDIT_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/editTeamBenefit';
import { GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION } from 'actions/teamBenefitType/getTeamBenefitCarriersByType';
import { GET_TEAM_BENEFIT_TYPES_ACTION } from 'actions/teamBenefitType/getTeamBenefitTypes';
import { pageUserTermDetails } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import {
    ITeamBenefitTermDetail,
    ITeamBenefitTermDetailAgeRanges,
    ITeamBenefitTermDetailDto,
    TeamBenefitTermDetailAgeRanges,
} from 'api/generated/models';
import { EditTeamBenefit } from 'api/generated/permissions';
import Button from 'components/Button';
import CurrencyTextField from 'components/CurrencyTextField';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import PercentTextField from 'components/PercentTextField';
import Skeleton from 'components/Skeleton';
import TeamBenefitModalPreviousNext from 'components/teamBenefitActionButtons/TeamBenefitModalPreviousNext';
import {
    TEXT_DOES_NOT_MATCH_MESSAGE,
    TEXT_TO_MATCH_REGEX,
} from 'components/teamBenefitActionButtons/TeamBenefitTermDetailsModal';
import TeamBenefitTypedConfirmation from 'components/teamBenefitActionButtons/TeamBenefitTypedConfirmation';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasContents, hasValue, stringToFloat, stringToInt } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { onChange as onFormChange } from 'utilities/forms';
import { API_DATE_FORMAT, isTodayAfter, isTodayBetween } from 'utilities/moment';
import { array, number, object, string } from 'yup';
import { IGenericTeamBenefitTermDetailsModalProps } from './GenericTeamBenefitTermDetailsModal';

const schema = object({
    ageRangeCosts: array().of(
        number()
            .transform(stringToFloat)
            .required()
            .min(0)
            .label('Per Month Cost')
    ),
    confirmationText: string().when('$isEdited', {
        is: true,
        then: string().matches(TEXT_TO_MATCH_REGEX, TEXT_DOES_NOT_MATCH_MESSAGE),
    }),
    employerCoverEmployeePlanPercentage: number()
        .transform(stringToInt)
        .required()
        .label('Employer Covers Employee Percentage'),
    employerCoverHouseholdMembersPlanPercentage: number()
        .transform(stringToInt)
        .required()
        .label('Employer Covers Household Members Percentage'),
    endDate: string()
        .required()
        .isValidDate()
        .isAfter('startDate')
        .label('End Date'),
    startDate: string()
        .required()
        .isValidDate()
        .isBefore('endDate')
        .label('Start Date'),
});

const MAX_AGE = 140;
const TWO = 2;
const AGE_OFFSET = 10;
const DEFAULT_SKELETON_COUNT = 3;
const TeamBenefitAgeRangeTermDetailsModal = ({
    currentTermDetailIndex,
    onClose,
    teamBenefit,
}: IGenericTeamBenefitTermDetailsModalProps) => {
    const dispatch = useThunkDispatch();
    const { canEditTeamBenefits, isLoading, paginationParams } = useSelector((state: AppStore) => ({
        canEditTeamBenefits: hasSomePermissions(state, EditTeamBenefit),
        isLoading: hasApiActivity(
            state,
            GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION,
            GET_TEAM_BENEFIT_TYPES_ACTION,
            ADD_TEAM_BENEFIT_ACTION,
            EDIT_TEAM_BENEFIT_ACTION,
            ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION
        ),
        paginationParams: state.manageMembersState,
    }));
    const { teamId } = useTeamProps();
    const [isLocked, setIsLocked] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [termDetailPeriodIndex, setTermDetailPeriodIndex] = useState(currentTermDetailIndex);
    const termDetails = useMemo(
        (): ITeamBenefitTermDetail[] | ITeamBenefitTermDetailDto[] =>
            hasValue(teamBenefit) && hasValue(teamBenefit.teamBenefitTermDetails)
                ? teamBenefit.teamBenefitTermDetails ??
                  (({} as unknown) as ITeamBenefitTermDetail[])
                : (({} as unknown) as ITeamBenefitTermDetail[]),
        [teamBenefit]
    );
    const previousTermDetailPeriod: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto | undefined =
        termDetails[termDetailPeriodIndex - 1];
    const termDetailPeriod: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto | undefined =
        termDetails[termDetailPeriodIndex];

    const isExistingTermDetailPeriodContainingOrAfterToday = useMemo(
        () =>
            !!termDetailPeriod?.id &&
            (isTodayBetween(termDetailPeriod.startDate, termDetailPeriod.endDate) ||
                isTodayAfter(termDetailPeriod.startDate?.toMomentDate())),
        [termDetailPeriod?.endDate, termDetailPeriod?.id, termDetailPeriod?.startDate]
    );
    const isReadonly = isExistingTermDetailPeriodContainingOrAfterToday || !canEditTeamBenefits;
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [costData, setCostData] = useState<{ ageMax: number; cost?: number; id?: string }[]>([]);
    const [employerCoverEmployeePlanPercentage, setEmployerCoverEmployeePlanPercentage] = useState<
        number | string | null
    >();
    const [
        employerCoverHouseholdMembersPlanPercentage,
        setEmployerCoverHouseholdMembersPlanPercentage,
    ] = useState<number | string | null>();
    const [isRendering, setIsRendering] = useState(true);
    const { errors, validate } = useForm(schema);
    const termBenefitDetailsLength = teamBenefit?.teamBenefitTermDetails?.length;
    const isNewTerm = termDetailPeriodIndex === termBenefitDetailsLength;
    const isLastTerm =
        termDetailPeriodIndex === (termBenefitDetailsLength ? termBenefitDetailsLength - 1 : true);
    const termDetail = isNewTerm ? previousTermDetailPeriod : termDetailPeriod;
    useEffect(() => {
        const previousPeriodEndDate = teamBenefit?.teamBenefitTermDetails?.[
            termDetailPeriodIndex - 1
        ]?.endDate.toMomentDate();
        setStartDate(
            formatDateForDisplay(
                termDetailPeriod?.startDate ??
                    previousPeriodEndDate?.add(1, 'days')?.format(API_DATE_FORMAT) ??
                    ''
            )
        );
        setEndDate(formatDateForDisplay(termDetailPeriod?.endDate ?? ''));
        setCostData(
            hasValue(termDetail) && hasContents(termDetail.teamBenefitTermDetailAgeRanges)
                ? termDetail.teamBenefitTermDetailAgeRanges.map((band, index) => {
                      const nextRange = termDetail.teamBenefitTermDetailAgeRanges?.[index + 1];
                      return {
                          ageMax: nextRange ? nextRange.minimumAge - 1 : MAX_AGE,
                          cost: band.cost,
                          id: !isNewTerm ? band.id : undefined,
                      };
                  })
                : [{ ageMax: 25 }, { ageMax: 50 }, { ageMax: 75 }, { ageMax: MAX_AGE }]
        );
        setEmployerCoverEmployeePlanPercentage(
            termDetail?.employerCoverEmployeePlanPercentage ?? ''
        );
        setEmployerCoverHouseholdMembersPlanPercentage(
            termDetail?.employerCoverHouseholdMembersPlanPercentage ?? ''
        );
        setIsRendering(false);
    }, [isNewTerm, termDetail, termDetailPeriod, termDetailPeriodIndex, teamBenefit]);

    const params = useParams<{
        teamBenefitId?: string;
        teamBenefitTermDetailId?: string;
    }>();

    const save = async () => {
        const { data, isValid } = await validate(
            {
                confirmationText,
                endDate,
                startDate,
                ageRangeCosts: costData.map((x) => x.cost),
                employerCoverEmployeePlanPercentage: (employerCoverEmployeePlanPercentage as unknown) as number,
                employerCoverHouseholdMembersPlanPercentage: (employerCoverHouseholdMembersPlanPercentage as unknown) as number,
                id: !isNewTerm ? termDetailPeriod?.id : undefined,
                teamBenefitId: teamBenefit?.id,
                teamBenefitTermDetailAgeRanges: costData.map((x, index) => {
                    const previousRange = costData[index - 1];
                    return new TeamBenefitTermDetailAgeRanges(({
                        ...x,
                        minimumAge: previousRange ? previousRange.ageMax + 1 : 0,
                    } as unknown) as ITeamBenefitTermDetailAgeRanges);
                }),
            },
            { context: { isEdited } }
        );
        if (isValid) {
            await dispatch(
                addOrEditTeamBenefitTermDetail(
                    teamBenefit?.id,
                    data as Partial<ITeamBenefitTermDetail>
                )
            );
            if (
                hasValue(params.teamBenefitTermDetailId) &&
                params.teamBenefitTermDetailId !== '0'
            ) {
                await dispatch(
                    pageUserTermDetails(params.teamBenefitTermDetailId, teamId, paginationParams)
                );
            }
            onClose();
        }
    };

    const onKeyDown = useCallback(() => {
        if (!isLocked) {
            setIsLocked(true);
        }
    }, [isLocked]);
    const onAgeChange = useCallback(
        (index: number) => (_e: Event, value: number[] | number) => {
            const newCostData = [...costData];
            const currentCostData = newCostData[index];
            if (currentCostData) {
                currentCostData.ageMax = Number(value);
            }
            const isSecondToLast = index + TWO === costData.length;
            const nextCostData = costData[index + 1];
            if (isSecondToLast && nextCostData) {
                nextCostData.ageMax = Number(value) + 1;
            }
            setCostData(newCostData);
            onKeyDown();
            if (isExistingTermDetailPeriodContainingOrAfterToday) {
                setIsEdited(true);
            }
        },
        [costData, isExistingTermDetailPeriodContainingOrAfterToday, onKeyDown]
    );
    const onCostChange = useCallback(
        (index: number) => (value: number | string) => {
            const newCostData = [...costData];
            const changedCostData = newCostData[index];
            if (changedCostData) {
                changedCostData.cost = value as number;
            }
            setCostData(newCostData);
            if (isExistingTermDetailPeriodContainingOrAfterToday) {
                setIsEdited(true);
            }
        },
        [costData, isExistingTermDetailPeriodContainingOrAfterToday]
    );
    const onChange = <T,>(setState: React.Dispatch<React.SetStateAction<T>>) =>
        onFormChange((value: T) => {
            setState(value);
            if (isExistingTermDetailPeriodContainingOrAfterToday) {
                setIsEdited(true);
            }
        });
    const secondToLastAgeMaxPlusOffset =
        (costData[costData.length - TWO]?.ageMax ?? 0) + AGE_OFFSET;
    const onAddClick = () => {
        const newCostData = [...costData];
        const firstCostData = newCostData[0];
        const lastCostData = newCostData[newCostData.length - 1];
        const secondToLastCostData = newCostData[newCostData.length - TWO];
        if (firstCostData) {
            firstCostData.ageMax = 0;
        } else if (lastCostData && secondToLastCostData) {
            lastCostData.ageMax = secondToLastCostData.ageMax + AGE_OFFSET;
        }
        newCostData.push({ ageMax: MAX_AGE });
        setCostData(newCostData);
        if (isExistingTermDetailPeriodContainingOrAfterToday) {
            setIsEdited(true);
        }
    };
    const onRemoveClick = useCallback(
        (index: number) => () => {
            const newCostData = [...costData];
            newCostData.splice(index, 1);
            const firstCostData = newCostData[0];
            const lastCostData = newCostData[newCostData.length - 1];
            if (firstCostData) {
                firstCostData.ageMax = 0;
            } else if (lastCostData) {
                lastCostData.ageMax = MAX_AGE;
            }
            setCostData(newCostData);
            if (isExistingTermDetailPeriodContainingOrAfterToday) {
                setIsEdited(true);
            }
        },
        [costData, isExistingTermDetailPeriodContainingOrAfterToday]
    );
    const onPreviousClick = () => {
        setIsRendering(true);
        setTermDetailPeriodIndex(termDetailPeriodIndex - 1);
    };
    const onNextClick = () => {
        setIsRendering(true);
        setTermDetailPeriodIndex(termDetailPeriodIndex + 1);
    };
    const isPreviousVisible = !!teamBenefit?.teamBenefitTermDetails?.[termDetailPeriodIndex - 1];
    const isNextVisible =
        !!termDetailPeriod?.id &&
        (canEditTeamBenefits || !!teamBenefit?.teamBenefitTermDetails?.[termDetailPeriodIndex + 1]);

    const ageRanges = useMemo(
        () => (
            <Grid alignItems="center" columnSpacing={2} container rowSpacing={4}>
                {costData.map(({ ageMax, cost }, index) => {
                    const prev = costData[index - 1];
                    const min = prev ? prev.ageMax + 1 : 0;
                    const next = costData[index + 1];
                    const isSecondToLast = index + TWO === costData.length;
                    const max = next && !isSecondToLast ? next.ageMax - 1 : MAX_AGE;
                    const isLast = index === costData.length - 1;
                    let costPlaceholder = `Enter ${min}-${ageMax} range cost`;
                    let costLabel = `${min}-${ageMax} Per Month Cost`;
                    if (isLast) {
                        costPlaceholder = `Enter ${min}+ cost`;
                        costLabel = `${min}+ Per Month Cost`;
                    }
                    return (
                        <React.Fragment key={index}>
                            <Grid item sm={6} xs={12}>
                                {isLast ? (
                                    <div className="text-center">{min}+</div>
                                ) : (
                                    <Slider
                                        disabled={!canEditTeamBenefits}
                                        marks={[{ label: min, value: min }]}
                                        max={max}
                                        min={min}
                                        onChange={onAgeChange(index)}
                                        step={1}
                                        value={ageMax}
                                        valueLabelDisplay="on"
                                    />
                                )}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CurrencyTextField
                                    data-cy={`cost-${index}`}
                                    disabled={!canEditTeamBenefits}
                                    errors={
                                        errors?.[
                                            (`ageRangeTermDetails[${index}]` as unknown) as keyof typeof errors
                                        ]
                                    }
                                    InputProps={{
                                        endAdornment:
                                            costData.length > 1 && isReadonly ? (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={onRemoveClick(index)}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : (
                                                <React.Fragment />
                                            ),
                                    }}
                                    label={costLabel}
                                    name={`ageRangeTermDetails[${index}]`}
                                    onChange={onFormChange(onCostChange(index))}
                                    onKeyDown={onKeyDown}
                                    placeholder={costPlaceholder}
                                    value={cost}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        ),
        [
            canEditTeamBenefits,
            costData,
            errors,
            isReadonly,
            onAgeChange,
            onCostChange,
            onKeyDown,
            onRemoveClick,
        ]
    );
    return (
        <Modal onHide={onClose} scrollable show size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {canEditTeamBenefits ? 'Manage ' : ''}Benefit Term Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="team-benefits-term-details-modal-form" onSubmit={save}>
                    <Stack gap={2}>
                        <Stack direction="row" gap={2} justifyContent="space-between">
                            <TeamBenefitModalPreviousNext
                                isLastTerm={isLastTerm}
                                isLocked={isLocked}
                                isNewTerm={isNewTerm}
                                isNextVisible={isNextVisible}
                                isPreviousVisible={isPreviousVisible}
                                onNextClick={onNextClick}
                                onPreviousClick={onPreviousClick}
                            />
                        </Stack>
                        {(isPreviousVisible || isNextVisible) && <Divider />}
                        <Stack direction="row" gap={2} justifyContent="space-between">
                            <DateTextField
                                disabled={
                                    isReadonly || hasContents(teamBenefit?.teamBenefitTermDetails)
                                }
                                errors={errors?.startDate}
                                label="Start Date"
                                onChange={onFormChange(setStartDate)}
                                onKeyDown={onKeyDown}
                                value={startDate}
                            />
                            <DateTextField
                                disabled={isReadonly}
                                errors={errors?.endDate}
                                label="End Date"
                                onChange={onFormChange(setEndDate)}
                                onKeyDown={onKeyDown}
                                value={endDate}
                            />
                        </Stack>
                        <Skeleton
                            count={costData.length > 0 ? costData.length : DEFAULT_SKELETON_COUNT}
                            height="56px"
                            isEnabled={isRendering}
                        >
                            <InputLabel>Age Range</InputLabel>
                            {ageRanges}
                        </Skeleton>
                        {(secondToLastAgeMaxPlusOffset <= MAX_AGE || costData.length === 1) && (
                            <Row className="justify-content-center mb-2" noGutters>
                                <Button onClick={onAddClick} variant="outlined">
                                    Add Age Range
                                </Button>
                            </Row>
                        )}
                        <Skeleton count={2} height="56px" isEnabled={isRendering}>
                            <PercentTextField
                                data-cy="employer-covers-employee"
                                disabled={!canEditTeamBenefits}
                                errors={errors?.employerCoverEmployeePlanPercentage}
                                label="Employer Covers Employee %"
                                name="employerCoverEmployeePlanPercentage"
                                onChange={onChange(setEmployerCoverEmployeePlanPercentage)}
                                onKeyDown={onKeyDown}
                                placeholder="Enter % employer covers of employee plan"
                                value={employerCoverEmployeePlanPercentage}
                            />
                            <PercentTextField
                                data-cy="employer-covers-other-household-members"
                                disabled={!canEditTeamBenefits}
                                errors={errors?.employerCoverHouseholdMembersPlanPercentage}
                                label="Employer Covers Other Household Members %"
                                name="employerCoverHouseholdMembersPlanPercentage"
                                onChange={onChange(setEmployerCoverHouseholdMembersPlanPercentage)}
                                onKeyDown={onKeyDown}
                                placeholder="Enter % employer covers of HHM plan"
                                value={employerCoverHouseholdMembersPlanPercentage}
                            />
                        </Skeleton>
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                {isEdited && (
                    <TeamBenefitTypedConfirmation
                        confirmationText={confirmationText}
                        errors={errors?.confirmationText}
                        onFormChange={onFormChange(setConfirmationText)}
                    />
                )}
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={!canEditTeamBenefits}
                    form="team-benefits-term-details-modal-form"
                    isLoading={isLoading}
                    type="submit"
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(TeamBenefitAgeRangeTermDetailsModal);
