import Box from '@mui/material/Box';
import SingleButtonModal from 'components/SingleButtonModal';
import React, { useCallback, useState } from 'react';
import { hot } from 'react-hot-loader';

let cursorCheck: number | undefined;
const RhLogo = () => {
    const [isEggVisible, setIsEggVisible] = useState(false);
    const isWithinXAndY = (
        { pageX: x, pageY: y }: React.MouseEvent,
        x1: number,
        x2: number,
        y1: number,
        y2: number
    ) => x >= x1 && x <= x2 && y >= y1 && y <= y2;
    const mouseMove = useCallback((pageData: React.MouseEvent) => {
        if (
            !cursorCheck &&
            isWithinXAndY(pageData, 95, 110, 20, 35) // NOSONAR
        ) {
            cursorCheck = 1;
        } else if (
            cursorCheck === 1 && // NOSONAR
            isWithinXAndY(pageData, 95, 110, 45, 60) // NOSONAR
        ) {
            cursorCheck = 2; // NOSONAR
        } else if (
            cursorCheck === 2 && // NOSONAR
            isWithinXAndY(pageData, 80, 95, 35, 50) // NOSONAR
        ) {
            cursorCheck = 3; // NOSONAR
        } else if (
            cursorCheck === 3 && // NOSONAR
            isWithinXAndY(pageData, 105, 120, 35, 50) // NOSONAR
        ) {
            cursorCheck = 4; // NOSONAR
        } else if (
            cursorCheck === 4 && // NOSONAR
            isWithinXAndY(pageData, 110, 125, 20, 30) // NOSONAR
        ) {
            cursorCheck = 5; // NOSONAR
        } else if (
            cursorCheck === 5 && // NOSONAR
            isWithinXAndY(pageData, 135, 145, 20, 45) // NOSONAR
        ) {
            cursorCheck = 6; // NOSONAR
        } else if (
            cursorCheck === 6 && // NOSONAR
            isWithinXAndY(pageData, 125, 135, 35, 47) // NOSONAR
        ) {
            cursorCheck = 7; // NOSONAR
        } else if (
            cursorCheck === 7 && // NOSONAR
            isWithinXAndY(pageData, 125, 155, 50, 60) // NOSONAR
        ) {
            cursorCheck = undefined;
            setIsEggVisible(true);
        }
    }, []);
    const closeEgg = () => setIsEggVisible(false);
    return (
        <React.Fragment>
            {isEggVisible && (
                <SingleButtonModal
                    body="We are honored that you would use the Remodel Health Platform for your healthcare needs."
                    buttonClickHandler={closeEgg}
                    buttonText="You're Welcome"
                    title="Thank you!!!"
                />
            )}
            <Box py={2} textAlign="center">
                <a href="/dashboard" onMouseMove={mouseMove}>
                    <img alt="" height="40" src="assets/images/logo_sm.png" />
                </a>
            </Box>
        </React.Fragment>
    );
};

export default hot(module)(RhLogo);
