import Typography from '@mui/material/Typography';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import { ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION } from 'actions/enrollmentVerification/addEnrollmentVerificationRhFile';
import { ADD_TEAM_RH_FILES_ACTION } from 'actions/rhFile/addTeamRhFiles';
import { ADD_USER_RH_FILES_ACTION } from 'actions/rhFile/addUserRhFiles';
import { FileResponse, IRhFile, IUser } from 'api/generated/models';
import { AxiosResponse } from 'axios';
import { IActionButtonItems } from 'components/ActionButtons';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import DropZone from 'components/DropZone';
import FileUploadModal, { AddFilesActionThunk } from 'components/FileUploadModal';
import PageSectionWrapper from 'components/PageSectionWrapper';
import RhFile, { IDefaultRhFileActionPermissions, IRhFileItems } from 'components/rhFile/RhFile';
import TableHeader from 'components/TableHeader';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const DefaultRhFileContainer = ({ children, ...props }: React.PropsWithChildren<Col>) => (
    <Col lg="3" sm="6" {...props}>
        {children}
    </Col>
);

type RhFileActionThunk = (
    rhFile: IRhFile
) => (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => Promise<void>;

type IRhFilesProps = {
    RhFileContainer?: React.ElementType;
    addRhFiles: AddFilesActionThunk;
    data: IRhFile[] | undefined;
    deleteRhFile: RhFileActionThunk;
    disableFullScreenDropZone?: boolean;
    downloadRhFile: (rhFile: IRhFile) => Promise<AxiosResponse<FileResponse>>;
    getIsUnSharableAndText: (
        createdByUser: IUser | undefined,
        rhFileId: string
    ) => { isUnSharable: boolean; sharedTooltipText: string };
    hideHeader?: boolean;
    isCentered?: boolean;
    items?: IRhFileItems;
    labelWrapper?: (label: string) => React.ReactNode;
    permissions: IDefaultRhFileActionPermissions & { canCreateRhFiles: boolean };
    renameRhFile: RhFileActionThunk;
    toggleRhFileArchived: RhFileActionThunk;
    toggleRhFileShared: RhFileActionThunk;
};

const RhFiles = ({
    addRhFiles,
    data,
    deleteRhFile,
    disableFullScreenDropZone,
    RhFileContainer = DefaultRhFileContainer,
    downloadRhFile,
    getIsUnSharableAndText,
    hideHeader,
    isCentered: parentIsCentered,
    items: parentItems,
    labelWrapper = (label) => <span>{label}</span>,
    permissions,
    renameRhFile,
    toggleRhFileArchived,
    toggleRhFileShared,
}: IRhFilesProps) => {
    const [showArchivedRhFiles, setShowArchivedRhFiles] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [fileData, setFileData] = useState(null);
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            ADD_TEAM_RH_FILES_ACTION,
            ADD_USER_RH_FILES_ACTION,
            ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION
        ),
    }));

    const openFileModal = useCallback(() => setShowFileUploadModal(true), []);
    const closeFileModal = useCallback(() => {
        setShowFileUploadModal(false);
        setFileData(null);
    }, []);
    const onDrop = useCallback((files, e: React.DragEvent) => {
        e.stopPropagation();
        setFileData(files);
        setShowFileUploadModal(true);
    }, []);
    const onFileSubmit: AddFilesActionThunk = useCallback(
        (rhFilesToAdd, successTooltipMessage) => addRhFiles(rhFilesToAdd, successTooltipMessage),
        [addRhFiles]
    );
    const DropZoneWrapper = useCallback(
        ({ children }) => {
            if (permissions.canCreateRhFiles) {
                return (
                    <DropZone
                        disableFullScreen={disableFullScreenDropZone}
                        isDisabled={showFileUploadModal}
                        isMultiple
                        onDrop={onDrop}
                    >
                        {children}
                    </DropZone>
                );
            } else {
                return <React.Fragment>{children}</React.Fragment>;
            }
        },
        [disableFullScreenDropZone, onDrop, permissions.canCreateRhFiles, showFileUploadModal]
    );

    const rhFiles = useMemo(
        () =>
            sortBy(
                data?.filter(
                    (x) =>
                        (showArchivedRhFiles && x.isArchived) ||
                        (!showArchivedRhFiles && !x.isArchived)
                ),
                (x) => x.originalFileName?.toLowerCase()
            ).map((rhFile: IRhFile, index) => {
                const { isUnSharable, sharedTooltipText } = getIsUnSharableAndText(
                    rhFile.createdByUser,
                    rhFile.id
                );
                return (
                    <RhFileContainer key={index}>
                        <RhFile
                            deleteRhFile={() => deleteRhFile(rhFile)}
                            downloadRhFile={async () => downloadRhFile(rhFile)}
                            isUnSharable={isUnSharable}
                            items={parentItems}
                            permissions={permissions}
                            renameRhFile={renameRhFile}
                            rhFile={rhFile}
                            sharedTooltipText={sharedTooltipText}
                            toggleRhFileArchived={() => toggleRhFileArchived(rhFile)}
                            toggleRhFileShared={() => toggleRhFileShared(rhFile)}
                        />
                    </RhFileContainer>
                );
            }),
        [
            data,
            deleteRhFile,
            downloadRhFile,
            getIsUnSharableAndText,
            parentItems,
            permissions,
            renameRhFile,
            RhFileContainer,
            showArchivedRhFiles,
            toggleRhFileArchived,
            toggleRhFileShared,
        ]
    );
    const totalArchivedRhFiles = useMemo(() => data?.filter((x) => x.isArchived).length ?? 0, [
        data,
    ]);
    const items = useMemo(
        () =>
            [
                {
                    dataCy: 'upload-files',
                    isVisible: permissions.canCreateRhFiles,
                    onClick: openFileModal,
                    text: 'Upload Files',
                },
            ] as IActionButtonItems,
        [openFileModal, permissions.canCreateRhFiles]
    );
    const showingFileLabel = useMemo(
        () => `Showing ${rhFiles.length} File${rhFiles.length !== 1 ? 's' : ''}`,
        [rhFiles.length]
    );
    const showNoFilesMessage = useMemo(() => !showArchivedRhFiles && rhFiles.length === 0, [
        rhFiles.length,
        showArchivedRhFiles,
    ]);
    const isCentered = useMemo(() => parentIsCentered || showNoFilesMessage, [
        parentIsCentered,
        showNoFilesMessage,
    ]);

    useEffect(() => {
        if (showArchivedRhFiles && totalArchivedRhFiles === 0) {
            setShowArchivedRhFiles(false);
        }
    }, [showArchivedRhFiles, totalArchivedRhFiles]);
    return (
        <React.Fragment>
            {showFileUploadModal && (
                <FileUploadModal
                    fileData={fileData ?? []}
                    isLoading={isLoading}
                    onHide={closeFileModal}
                    onSubmit={onFileSubmit}
                />
            )}

            {!hideHeader && (
                <PageSectionWrapper>
                    <TableHeader
                        items={items}
                        label={
                            <span>
                                <Row className="align-items-center">
                                    {labelWrapper(showingFileLabel)}
                                    {permissions.canArchiveRhFiles && totalArchivedRhFiles > 0 && (
                                        <Checkbox
                                            checked={showArchivedRhFiles}
                                            className="m-0 ml-3"
                                            label={`View Archived (${totalArchivedRhFiles})`}
                                            onClick={() =>
                                                setShowArchivedRhFiles(!showArchivedRhFiles)
                                            }
                                        />
                                    )}
                                </Row>
                            </span>
                        }
                    />
                </PageSectionWrapper>
            )}

            <DropZoneWrapper>
                <Row className={isCentered ? 'align-items-center justify-content-center' : ''}>
                    {showNoFilesMessage ? (
                        <div>
                            <Typography textAlign="center" variant="h4">
                                No Files
                            </Typography>
                            {permissions.canCreateRhFiles && (
                                <Button onClick={openFileModal}>Upload</Button>
                            )}
                        </div>
                    ) : (
                        rhFiles
                    )}
                </Row>
            </DropZoneWrapper>
        </React.Fragment>
    );
};

export default hot(module)(RhFiles);
