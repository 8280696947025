import { dispatchWrapper } from 'actions/clear';
import { AppStoreThunkDispatch, IAction } from 'actions/commonAction';
import { Address } from 'api/generated/models';
import {
    IAddressInputState,
    IAddressInputs,
    getSchema,
} from 'components/addressInput/addressInputState';
import Toast from 'components/Toast';
import { isEqual, pick } from 'lodash';
import { AppStore } from 'reducers/appReducer';
import { IFormErrors, formatErrors, validate } from 'utilities/forms';
import { ValidationError } from 'yup';

export const CLEAR_ADDRESS_INPUTS = 'CLEAR_ADDRESS_INPUTS';
export const clearAddressInputs = () => dispatchWrapper(CLEAR_ADDRESS_INPUTS);

export const UPDATE_ADDRESS_INPUTS = 'UPDATE_ADDRESS_INPUTS';
export const updateAddressInputs = (
    addressInputs: IAddressInputs
): IAction<IAddressInputState> => ({
    data: {
        addressInputs,
        errors: null,
    },
    type: UPDATE_ADDRESS_INPUTS,
});

export const VALIDATE_ADDRESS_INPUTS = 'VALIDATE_ADDRESS_INPUTS';
export const validateAddressInputs = (addressInputs: IAddressInputs) => async (
    dispatch: AppStoreThunkDispatch,
    getState: () => AppStore
) => {
    let isValid = true;
    let errors = null;
    const { addressInputState, counties } = getState();
    try {
        await validate(getSchema(counties), addressInputs);
    } catch (_errors) {
        errors = _errors;
        isValid = false;
    }
    dispatch<IAction<IAddressInputState>>({
        data: {
            addressInputs: addressInputState.addressInputs,
            errors: (errors ? formatErrors(errors as ValidationError) : errors) as IFormErrors<
                ReturnType<typeof getSchema>
            >,
        },
        type: VALIDATE_ADDRESS_INPUTS,
    });
    return isValid;
};

const isMatchingTeamAddress = (teamAddress: Address | undefined, surveyAddress: IAddressInputs) => {
    if (!teamAddress || !surveyAddress) {
        return false;
    }
    const isAddressLine2Matching =
        (!teamAddress.addressLine2 && !surveyAddress.addressLine2) ||
        teamAddress.addressLine2 === surveyAddress.addressLine2 ||
        (!teamAddress.addressLine2 && surveyAddress.addressLine2) ||
        (teamAddress.addressLine2 && !surveyAddress.addressLine2);

    const relevantFields = ['addressLine1', 'city', 'state', 'zip'];
    const teamAddressFields = pick(teamAddress, relevantFields);
    const surveyAddressFields = pick(surveyAddress, relevantFields);
    return isAddressLine2Matching && isEqual(teamAddressFields, surveyAddressFields);
};

export const validateAndCheckMatchTeamAddress = (
    addressInputs: IAddressInputs,
    teamAddress: Address | undefined,
    ignoreCheckMatchTeamAddress: boolean
) => async (dispatch: AppStoreThunkDispatch, getState: () => AppStore) => {
    let isValid = true;
    let errors = null;
    const { addressInputState, counties } = getState();
    try {
        if (!ignoreCheckMatchTeamAddress && isMatchingTeamAddress(teamAddress, addressInputs)) {
            const message =
                'This is the address listed for your organization. Please enter the address of your primary residence.';
            Toast.error(message);
            throw new Error(message);
        }
        await validate(getSchema(counties), addressInputs);
    } catch (_errors) {
        errors = _errors;
        isValid = false;
    }
    dispatch<IAction<IAddressInputState>>({
        data: {
            addressInputs: addressInputState.addressInputs,
            errors: (errors ? formatErrors(errors as ValidationError) : errors) as IFormErrors<
                ReturnType<typeof getSchema>
            >,
        },
        type: VALIDATE_ADDRESS_INPUTS,
    });
    return isValid;
};
