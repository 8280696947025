import Stack from '@mui/material/Stack';
import SingleButtonModal from 'components/SingleButtonModal';
import TextArea from 'components/TextArea';
import useForm from 'hooks/useForm';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { object, string } from 'yup';

type ITextAreaModalProps = {
    'data-cy'?: string;
    'data-private'?: boolean;
    buttonText?: string;
    isRequired?: boolean;
    label: string;
    maxCharacters?: number;
    onHide: () => void;
    placeholder?: string;
    save: (value: string | undefined) => void;
    showActivity: boolean;
    subtext?: string;
    title: string;
    value: string | undefined;
};

const TextAreaModal = ({
    'data-cy': dataCy,
    'data-private': dataPrivate,
    buttonText = 'Save',
    isRequired,
    label,
    maxCharacters,
    onHide,
    placeholder,
    save,
    showActivity,
    subtext,
    title,
    value: originalValue,
}: ITextAreaModalProps) => {
    const [value, setValue] = useState(originalValue ?? '');
    const schema = useMemo(() => {
        const valueSchema = string()
            .trim()
            .isRequiredWhen(isRequired as boolean)
            .when({
                is: () => hasValue(maxCharacters),
                then: (_schema) => _schema.max(maxCharacters as number),
            })
            .label(label);
        return object({
            value: valueSchema,
        });
    }, [isRequired, label, maxCharacters]);
    const { errors, setErrors, validate } = useForm(schema);
    const handleClick = useCallback(async () => {
        const { data, isValid } = await validate({ value });
        if (isValid) {
            save(data?.value);
        }
    }, [save, validate, value]);
    const onTextAreaChange = onChange((_value: string) => {
        setValue(_value);
        setErrors(null);
    });
    return (
        <SingleButtonModal
            body={
                <Stack gap={2}>
                    <TextArea
                        autoFocus
                        data-cy={dataCy}
                        data-private={dataPrivate}
                        errors={errors?.value}
                        isOptional={!isRequired}
                        label={label}
                        maxLength={maxCharacters}
                        minRows={3}
                        onChange={onTextAreaChange}
                        placeholder={placeholder}
                        value={value}
                    />
                    {hasValue(subtext) && <div className="text-muted">{subtext}</div>}
                </Stack>
            }
            buttonClickHandler={handleClick}
            buttonText={buttonText}
            onHide={onHide}
            showActivity={showActivity}
            title={title}
        />
    );
};

export default hot(module)(TextAreaModal);
