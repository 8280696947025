import Chip, { ChipProps } from '@mui/material/Chip';
import React from 'react';
import { hot } from 'react-hot-loader';

const TaxCreditsChip = ({ sx, ...props }: ChipProps) => (
    <Chip
        key="taxCredits"
        label="Tax Credits"
        size="small"
        sx={{ backgroundColor: (theme) => theme.palette.success.light, color: 'grey.900', ...sx }}
        {...props}
    />
);

export default hot(module)(TaxCreditsChip);
