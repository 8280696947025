import CommonPlanInputs from 'components/planModal/CommonPlanInputs';
import { IGetCommonPropsPlanInputs } from 'components/planModal/PlanInputs';
import Select from 'components/Select';
import TextField from 'components/TextField';
import {
    ANCILLARY_CARRIERS,
    APEX,
    ASI,
    CHRISTIAN_HEALTHCARE_MINISTRIES,
    COLONIAL_LIFE,
    DELTADENTAL,
    HEALTHVALUES,
    HEALTH_INSURANCE_INNOVATIONS,
    MANNA,
    NATIONAL_GENERAL,
    SAFEGUARD_HEALTH,
    UNITED_HEALTHCARE,
} from 'constants/ancillaryCarriers';
import { HEALTHCARE_TYPES } from 'constants/healthCareTypes';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';

const ANCILLARY_CARRIERS_HEALTHCARE_TYPES = {
    [COLONIAL_LIFE]: [
        HEALTHCARE_TYPES.Dental,
        HEALTHCARE_TYPES.Vision,
        HEALTHCARE_TYPES.Accident,
        HEALTHCARE_TYPES.CriticalIllness,
        HEALTHCARE_TYPES.Cancer,
        HEALTHCARE_TYPES.TermLife,
        HEALTHCARE_TYPES.WholeLife,
        HEALTHCARE_TYPES.Disability,
        HEALTHCARE_TYPES.GAP,
        HEALTHCARE_TYPES.MedicalBridge,
    ],
    [NATIONAL_GENERAL]: [
        HEALTHCARE_TYPES.Accident,
        HEALTHCARE_TYPES.CriticalIllness,
        HEALTHCARE_TYPES.Dental,
        HEALTHCARE_TYPES.Foundation,
        HEALTHCARE_TYPES.HospitalIndemnity,
        HEALTHCARE_TYPES.TrioMed,
        HEALTHCARE_TYPES.PlanEnhancer,
        HEALTHCARE_TYPES.LifeMembership,
    ],
    [SAFEGUARD_HEALTH]: [HEALTHCARE_TYPES.MEC],
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    [HEALTH_INSURANCE_INNOVATIONS]: [HEALTHCARE_TYPES.CriticalIllness],
    [UNITED_HEALTHCARE]: [
        HEALTHCARE_TYPES.Dental,
        HEALTHCARE_TYPES.Vision,
        HEALTHCARE_TYPES.Accident,
        HEALTHCARE_TYPES.CriticalIllness,
        HEALTHCARE_TYPES.HospitalIndemnity,
        HEALTHCARE_TYPES.Disability,
        HEALTHCARE_TYPES.TermLife,
    ],
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    [APEX]: [HEALTHCARE_TYPES.MEC],
    [HEALTHVALUES]: [HEALTHCARE_TYPES.GAP],
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    [DELTADENTAL]: [HEALTHCARE_TYPES.Dental],
    [MANNA]: [HEALTHCARE_TYPES.Disability],
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    [CHRISTIAN_HEALTHCARE_MINISTRIES]: [HEALTHCARE_TYPES.ShareFee],
    // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
    [ASI]: [HEALTHCARE_TYPES.MEC],
};

type ICarrierNames = keyof typeof ANCILLARY_CARRIERS_HEALTHCARE_TYPES;

const AncillaryPlanInputs = ({
    getCommonProps,
    isEdit,
}: {
    getCommonProps: IGetCommonPropsPlanInputs;
    isEdit: boolean;
}) => {
    const { planInputs } = useSelector((state: AppStore) => ({
        planInputs: state.planModalState.planInputs,
    }));

    return (
        <React.Fragment>
            <Select
                {...getCommonProps('Carrier', 'issuerName')}
                data-cy="ancillary-carrier"
                defaultText="Choose an Option"
                defaultValue=""
                disabled={isEdit}
                items={ANCILLARY_CARRIERS}
                optionText="name"
                optionValue="name"
            />
            <Select
                {...getCommonProps('Healthcare Type', 'healthCareTypeId')}
                data-cy="ancillary-type"
                defaultText="Choose an option"
                defaultValue=""
                disabled={isEdit}
                items={
                    hasValue(planInputs.issuerName)
                        ? ANCILLARY_CARRIERS_HEALTHCARE_TYPES[
                              planInputs.issuerName as ICarrierNames
                          ]
                        : []
                }
                optionText="name"
                optionValue="value"
            />
            <TextField {...getCommonProps('Plan Name', 'planName')} />
            <CommonPlanInputs />
        </React.Fragment>
    );
};

export default hot(module)(AncillaryPlanInputs);
