import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import TabScrollButton from '@mui/material/TabScrollButton';
import styled from '@mui/system/styled';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { matchPath, useLocation } from 'react-router';
import { Link, LinkProps } from 'react-router-dom';

const MyTabScrollButton = styled(TabScrollButton)({
    '&.Mui-disabled': {
        width: 0,
    },
    overflow: 'hidden',
    transition: 'width 0.5s',
    width: 28,
});

export type ITab = {
    TabProps?: LinkProps & TabProps & { 'data-cy'?: string };
    isVisible: boolean;
    possiblePaths?: string[];
};

type ITabsProps = {
    data: ITab[];
};

const Tabs = ({ data }: ITabsProps) => {
    const location = useLocation();
    const visibleTabs = useMemo(() => data.filter((x) => x.isVisible), [data]);
    const value = useMemo(
        () =>
            visibleTabs.findIndex(
                (tab) =>
                    matchPath(location.pathname, {
                        exact: true,
                        path: tab.possiblePaths,
                        strict: false,
                    })?.isExact
            ),
        [location.pathname, visibleTabs]
    );
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {value !== -1 && (
                <MuiTabs
                    ScrollButtonComponent={MyTabScrollButton}
                    value={value}
                    variant="scrollable"
                >
                    {visibleTabs.map((tab, index) => (
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        <Tab component={Link as any} key={index} {...tab.TabProps} />
                    ))}
                </MuiTabs>
            )}
        </Box>
    );
};

export default hot(module)(Tabs);
