import Stack from '@mui/material/Stack';
import { AppStoreThunkDispatch } from 'actions/commonAction';
import {
    addLaunchDetails,
    ADD_LAUNCH_DETAILS_ACTION
} from 'actions/launchDetails/addLaunchDetails';
import {
    updateLaunchDetails,
    UPDATE_LAUNCH_DETAILS_ACTION
} from 'actions/launchDetails/updateLaunchDetails';
import { ILaunchDetails } from 'api/generated/models';
import Button from 'components/Button';
import DateTextField from 'components/DateTextField';
import Form from 'components/Form';
import PhoneTextField from 'components/PhoneTextField';
import Select from 'components/Select';
import TextField from 'components/TextField';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { getTeamProps } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import {
    formatDateForApi,
    formatDateForDisplay,
    formatPhoneNumber,
    formatTimeForDropdownValue
} from 'utilities/format';
import { formatErrors, IFormErrors, validate } from 'utilities/forms';
import { DISPLAY_DATE_FORMAT } from 'utilities/moment';
import { object, string, TestConfig, ValidationError } from 'yup';
import { AnyObject } from 'yup/lib/object';

const phoneNumberMask = '(---) --------';

const isBeforeTeamActiveDateArguments: TestConfig<string | undefined, AnyObject> = {
    message: (d) => `${d.label} must be before Active Date`,
    name: 'isBeforeTeamActiveDate',
    test: (value, context) =>
        moment(value, DISPLAY_DATE_FORMAT).isBefore(
            moment(context.options.context?.['teamActiveDate'], DISPLAY_DATE_FORMAT)
        ),
};
const schema = object({
    advisementDeadlineDate: string()
        .trim()
        .required()
        .isValidDate()
        .isAfter('kickoffWebinarDate')
        .test(isBeforeTeamActiveDateArguments)
        .label('Advisement Deadline Date'),
    conferenceId: string()
        .trim()
        .when(['dialInNumber', 'webinarLink'], {
            is: (dialInNumber: string, webinarLink: string) =>
                hasValue(dialInNumber) || hasValue(webinarLink),
            then: (_schema) => _schema.required(),
        })
        .label('Conference ID'),
    dialInNumber: string()
        .trim()
        .when('webinarLink', {
            is: (webinarLink: string) => hasValue(webinarLink),
            then: (_schema) => _schema.required(),
        })
        .isValidPhoneNumber(false)
        .label('Webinar Dial-in Number'),
    kickoffWebinarDate: string()
        .trim()
        .required()
        .isValidDate()
        .test(isBeforeTeamActiveDateArguments)
        .isBefore('advisementDeadlineDate')
        .label('Kickoff Webinar Date'),
    kickoffWebinarEndTime: string()
        .trim()
        .required()
        .label('End Time'),
    kickoffWebinarStartTime: string()
        .trim()
        .required()
        .label('Start Time'),
    webinarLink: string()
        .trim()
        .url()
        .label('Webinar Link'),
});

const getFormattedOrDefault = function<T>(
    value: T,
    format: (value: T) => string | undefined,
    defaultValue = ''
): string {
    const formattedValue = format(value);
    return value && formattedValue ? formattedValue : defaultValue;
};

type ILaunchDetailsModalProps = DispatchProps &
    RouteComponentProps &
    StateProps & {
        handleSubmit: () => void;
        isEditMode?: boolean;
        onNoClick: () => void;
        teamId: string;
    };

const initialState = {
    advisementDeadlineDate: '',
    conferenceId: '' as string | undefined,
    dialInNumber: '',
    errors: null as IFormErrors<typeof schema>,
    isEditMode: false,
    kickoffWebinarDate: '',
    kickoffWebinarEndTime: '',
    kickoffWebinarStartTime: '',
    teamId: undefined as string | undefined,
    webinarLink: '' as string | undefined,
};
class LaunchDetailsModal extends React.PureComponent<ILaunchDetailsModalProps> {
    override state = initialState;

    static getDerivedStateFromProps(props: ILaunchDetailsModalProps, state: typeof initialState) {
        const { launchDetails } = props;
        if (state.teamId !== props.teamId && !isEmpty(launchDetails)) {
            state.teamId = props.teamId;

            state.kickoffWebinarDate = getFormattedOrDefault(
                launchDetails.meetingStartDateTime,
                formatDateForDisplay
            );
            state.kickoffWebinarStartTime = getFormattedOrDefault(
                launchDetails.meetingStartDateTime,
                formatTimeForDropdownValue
            );
            state.kickoffWebinarEndTime = getFormattedOrDefault(
                launchDetails.meetingEndDateTime,
                formatTimeForDropdownValue
            );
            state.advisementDeadlineDate = getFormattedOrDefault(
                launchDetails.advisementDeadlineDate,
                formatDateForDisplay
            );
            state.webinarLink = launchDetails.meetingLink;
            state.dialInNumber = getFormattedOrDefault(
                launchDetails.meetingPhoneNumber,
                formatPhoneNumber
            );
            state.conferenceId = launchDetails.meetingConferenceId;
        }

        return state;
    }

    generateModalTitle = () => {
        if (!isEmpty(this.props.launchDetails)) {
            return 'Modify Launch Details';
        } else {
            return 'Add Launch Details';
        }
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async () => {
        try {
            await validate(
                schema,
                {
                    advisementDeadlineDate: this.state.advisementDeadlineDate,
                    conferenceId: this.state.conferenceId,
                    dialInNumber: this.state.dialInNumber,
                    kickoffWebinarDate: this.state.kickoffWebinarDate,
                    kickoffWebinarEndTime: this.state.kickoffWebinarEndTime,
                    kickoffWebinarStartTime: this.state.kickoffWebinarStartTime,
                    webinarLink: this.state.webinarLink,
                },
                { context: { teamActiveDate: this.props.teamActiveDate } }
            );
            const launchDetails: Partial<ILaunchDetails> = {
                advisementDeadlineDate: moment(
                    this.state.advisementDeadlineDate,
                    DISPLAY_DATE_FORMAT
                ).format(),
                meetingConferenceId: this.state.conferenceId,
                meetingEndDateTime: moment(
                    `${formatDateForApi(this.state.kickoffWebinarDate)}T${
                        this.state.kickoffWebinarEndTime
                    }`
                ).format(),
                meetingLink: this.state.webinarLink,
                meetingPhoneNumber: this.state.dialInNumber.removeNonNumericCharacters(),
                meetingStartDateTime: moment(
                    `${formatDateForApi(this.state.kickoffWebinarDate)}T${
                        this.state.kickoffWebinarStartTime
                    }`
                ).format(),
                teamId: this.props.teamId,
            };

            if (this.props.isEditMode) {
                await this.props.updateLaunchDetails(launchDetails as ILaunchDetails);
            } else {
                await this.props.addLaunchDetails(launchDetails as ILaunchDetails);
            }
            this.props.handleSubmit();
        } catch (_errors) {
            this.setState({ errors: formatErrors(_errors as ValidationError) });
        }
    };

    onNoClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.onNoClick();
    };

    override render() {
        const { onNoClick, timeOptions, showActivity, teamActiveDate } = this.props;
        const {
            advisementDeadlineDate,
            conferenceId,
            dialInNumber,
            errors,
            kickoffWebinarDate,
            kickoffWebinarEndTime,
            kickoffWebinarStartTime,
            webinarLink,
        } = this.state;
        const hasWebinar = hasValue(webinarLink);
        const hasDialIn = hasValue(dialInNumber) && dialInNumber !== phoneNumberMask;

        return (
            <Modal onHide={onNoClick} scrollable show size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{this.generateModalTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="launch-details-modal-form"
                        isLoading={showActivity}
                        onSubmit={this.handleSubmit}
                    >
                        <Stack gap={2}>
                            <div>Active Date: {teamActiveDate}</div>
                            <DateTextField
                                autoFocus
                                errors={errors?.kickoffWebinarDate}
                                label="Kickoff Webinar Date"
                                name="kickoffWebinarDate"
                                onChange={this.onChange}
                                value={kickoffWebinarDate}
                            />
                            <Stack direction="row" gap={2}>
                                <Select
                                    data-cy="start-time"
                                    defaultText="Choose a Start Time"
                                    defaultValue=""
                                    errors={errors?.kickoffWebinarStartTime}
                                    items={timeOptions.filter(
                                        ({ value }) =>
                                            !hasValue(kickoffWebinarEndTime) ||
                                            moment(value, 'HH:mm:ss').isBefore(
                                                moment(kickoffWebinarEndTime, 'HH:mm:ss')
                                            )
                                    )}
                                    label="Start Time (EST)"
                                    name="kickoffWebinarStartTime"
                                    onChange={this.onChange}
                                    optionText="display"
                                    optionValue="value"
                                    value={kickoffWebinarStartTime}
                                />
                                <Select
                                    data-cy="end-time"
                                    defaultText="Choose an End Time"
                                    defaultValue=""
                                    errors={errors?.kickoffWebinarEndTime}
                                    items={timeOptions.filter(
                                        ({ value }) =>
                                            !hasValue(kickoffWebinarStartTime) ||
                                            moment(value, 'HH:mm:ss').isAfter(
                                                moment(kickoffWebinarStartTime, 'HH:mm:ss')
                                            )
                                    )}
                                    label="End Time (EST)"
                                    name="kickoffWebinarEndTime"
                                    onChange={this.onChange}
                                    optionText="display"
                                    optionValue="value"
                                    value={kickoffWebinarEndTime}
                                />
                            </Stack>
                            <TextField
                                data-cy="webinar-link"
                                errors={errors?.webinarLink}
                                isOptional
                                label="Webinar Link"
                                name="webinarLink"
                                onChange={this.onChange}
                                placeholder="Enter the URL to the webinar"
                                value={webinarLink}
                            />
                            <Stack direction="row" gap={2}>
                                <PhoneTextField
                                    errors={errors?.dialInNumber}
                                    isOptional={!hasWebinar}
                                    label="Webinar Dial-in Number"
                                    name="dialInNumber"
                                    onChange={this.onChange}
                                    placeholder="Enter the dial-in phone number for the webinar"
                                    value={dialInNumber}
                                />
                                <TextField
                                    data-cy="webinar-dial-in-conference-id"
                                    errors={errors?.conferenceId}
                                    isOptional={!hasDialIn && !hasWebinar}
                                    label="Webinar Dial-in Conference ID"
                                    name="conferenceId"
                                    onChange={this.onChange}
                                    placeholder="Enter the conference ID for the dial-in phone number"
                                    value={conferenceId}
                                />
                            </Stack>
                            <DateTextField
                                errors={errors?.advisementDeadlineDate}
                                label="Advisement Deadline Date"
                                name="advisementDeadlineDate"
                                onChange={this.onChange}
                                value={advisementDeadlineDate}
                            />
                        </Stack>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="centered">
                    <Button onClick={this.onNoClick}>Cancel</Button>
                    <Button
                        data-cy="submit-launch-details"
                        form="launch-details-modal-form"
                        isLoading={showActivity}
                        type="submit"
                    >
                        Save Details
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state: AppStore, { match }: RouteComponentProps) => ({
    launchDetails: state.launchDetails,
    showActivity: hasApiActivity(state, ADD_LAUNCH_DETAILS_ACTION, UPDATE_LAUNCH_DETAILS_ACTION),
    teamActiveDate: formatDateForDisplay(
        getTeamProps(state, match.params as { teamId: string }).activeDate
    ),
    timeOptions: state.options.fifteenMinuteIntervalTimes,
});

const mapDispatchToProps = (dispatch: AppStoreThunkDispatch) => ({
    addLaunchDetails: async (launchDetails: ILaunchDetails) =>
        dispatch(addLaunchDetails(launchDetails)),
    updateLaunchDetails: async (launchDetails: ILaunchDetails) =>
        dispatch(updateLaunchDetails(launchDetails)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default hot(module)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(LaunchDetailsModal))
);
