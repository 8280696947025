import EditableTextField, { IEditableTextFieldProps } from 'components/EditableTextField';
import NumberTextField from 'components/NumberTextField';
import React from 'react';
import { hot } from 'react-hot-loader';
import { NumberFormatProps } from 'react-number-format';

export type IEditableNumberTextFieldProps<T> = Omit<
    NumberFormatProps<typeof EditableTextField>,
    'size'
> &
    Omit<IEditableTextFieldProps<T>, 'onChange' | 'ref'>;

const EditableNumberTextField = <T,>(props: IEditableNumberTextFieldProps<T>) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <NumberTextField customInput={EditableTextField as any} {...props} />
);
export default hot(module)(EditableNumberTextField);
