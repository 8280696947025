import { IPathwayBlueprint } from 'api/generated/models';
import Icon from 'components/Icon';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { hasValue } from 'utilities/index';

const ApplyingStaleContributionsWarning = ({
    pathwayBlueprint,
}: {
    pathwayBlueprint: Partial<IPathwayBlueprint>;
}) => (
    <React.Fragment>
        {pathwayBlueprint.isStale && (
            <div className="text-danger">
                <Row className="align-items-center" noGutters>
                    <Icon variant="danger">warning</Icon>
                    <Col className="ml-2">
                        The current Pathway Blueprint is stale thus you may be potentially applying
                        stale Contributions.
                    </Col>
                </Row>
            </div>
        )}
        {hasValue(pathwayBlueprint.ichraClasses?.find((ic) => ic.isStale)) && (
            <div className="text-danger">
                <br />
                <Row className="align-items-center" noGutters>
                    <Icon variant="danger">warning</Icon>
                    <Col className="ml-2">
                        There is at least one stale ICHRA Class in the Pathway Blueprint, thus you
                        may be potentially applying stale Contributions.
                    </Col>
                </Row>
            </div>
        )}
    </React.Fragment>
);

export default hot(module)(ApplyingStaleContributionsWarning);
