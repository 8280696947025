import BreakableSpan from 'components/BreakableSpan';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

const ContentWithLabel = ({
    'data-private': dataPrivate,
    children,
    isSecondary = false,
    label,
    shouldIndentLabel = false,
    shouldTruncateValue = false,
    useCol = false,
}: {
    'data-private'?: boolean;
    children: ReactNode;
    isSecondary?: boolean;
    label: ReactNode;
    shouldIndentLabel?: boolean;
    shouldTruncateValue?: boolean;
    useCol?: boolean;
}) => (
    <div className="d-flex flex-md-wrap align-items-start justify-content-between pb-2">
        <span
            className={`${useCol ? 'col p-0' : 'flex-fill'} ${
                shouldIndentLabel ? 'pl-3 ' : ''
            }text-secondary-45-lighten mr-1`}
        >
            {label}
        </span>
        <BreakableSpan
            className={`${useCol ? 'col p-0' : ''} text-right ${
                isSecondary ? 'text-secondary-45-lighten' : ''
            } ${shouldTruncateValue ? 'text-truncate' : ''}`}
            data-private={dataPrivate}
        >
            {children}
        </BreakableSpan>
    </div>
);
export default hot(module)(ContentWithLabel);
