import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField, { ITextFieldProps } from 'components/TextField';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { hot } from 'react-hot-loader';
import { IMaskInput } from 'react-imask';
import { MaskedElement } from 'react-imask/dist/mixin';

export const PhoneTextMask = React.forwardRef<
    HTMLElement,
    { name?: string; onChange?: React.ChangeEventHandler<HTMLInputElement> }
>(function PhoneTextMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={(ref as unknown) as React.Ref<HTMLElement> & ((el: MaskedElement) => void)}
            mask="(#00) 000-0000"
            onAccept={(value: string) =>
                onChange?.(({
                    target: { value, name: props.name },
                } as unknown) as React.ChangeEvent<HTMLInputElement>)
            }
            overwrite
        />
    );
});

const PhoneTextField = (props: ITextFieldProps) => (
    <TextField
        data-cy={kebabCase(props.label as string)}
        data-private
        InputProps={{
            inputComponent: PhoneTextMask as React.ElementType<InputBaseComponentProps> | undefined,
        }}
        {...props}
    />
);

export default hot(module)(PhoneTextField);
